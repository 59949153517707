<template>
  <div class="health-icons">
    <img v-if="diagnostics.oil" src="@/assets/diag-icons/oil-life.svg?external" />
    <img v-if="diagnostics.tire && !inEssentialsMode" src="@/assets/diag-icons/flat-tire.svg?external" />
    <img v-if="diagnostics.fuel" src="@/assets/diag-icons/low-fuel.svg?external" />
    <img v-if="diagnostics.battery" src="@/assets/diag-icons/battery.svg?external" />
</div>
</template>

<script>
export default {
  props: {
    diagnostics: Object,
  },
  computed: {
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  }
};
</script>

<style lang="scss" scoped>
.health-icons {
  display: flex;
  align-items: center;
  img {
    width: 30px; 
    margin-right: 4px;
  }
}
</style>