<template>
  <div class="guided-tour-splash">
    <div class="monitor-bg" />
    <div class="monitor" />
    <img class="blue-overlay" src="@/assets/BlueShape.png" />
    <div class="content">
      <svg
        class="envolve-logo"
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="22.454"
        viewBox="0 0 72 22.454"
      >
        <path
          id="Path_12"
          data-name="Path 12"
          d="M60.124,20.652H58.463a1.805,1.805,0,0,0-1.855,1.75v4.3a1.8,1.8,0,0,0,1.855,1.75h3.073v-1.17H59.083a.762.762,0,0,1-.783-.74v-.488c0-.369.138-.518.6-.643l1.4-.38c1.1-.3,1.678-.8,1.678-1.469V22.4A1.8,1.8,0,0,0,60.124,20.652Zm.163,2.586a.715.715,0,0,1-.585.734l-1.4.393v-1.8a.762.762,0,0,1,.783-.739H59.5a.762.762,0,0,1,.783.739Zm38.549-2.586H97.174A1.8,1.8,0,0,0,95.32,22.4v4.3a1.8,1.8,0,0,0,1.854,1.75h3.074v-1.17H97.795a.763.763,0,0,1-.784-.74v-.488c0-.369.139-.518.6-.643l1.4-.38c1.1-.3,1.678-.8,1.678-1.469V22.4A1.8,1.8,0,0,0,98.836,20.652ZM99,23.238a.715.715,0,0,1-.585.734l-1.4.393v-1.8a.763.763,0,0,1,.784-.739h.42a.763.763,0,0,1,.784.739ZM87.757,28.451H87.3a2.355,2.355,0,0,1-2.475-2.481V17.814h1.692v8.251c0,.673.26,1.219,1.175,1.219h.067Zm-18.68-6.045v6.045H67.386V22.566a.763.763,0,0,0-.784-.739H65.4v6.624H63.707v-7.8h3.516A1.808,1.808,0,0,1,69.077,22.406Zm12.164-1.754H79.579a1.8,1.8,0,0,0-1.854,1.75v4.3a1.8,1.8,0,0,0,1.854,1.75h1.662A1.8,1.8,0,0,0,83.1,26.7V22.4A1.8,1.8,0,0,0,81.241,20.652Zm.163,5.889a.763.763,0,0,1-.784.74H80.2a.762.762,0,0,1-.783-.74V22.562a.762.762,0,0,1,.783-.739h.421a.763.763,0,0,1,.784.739Zm-4.6-5.889-2.576,7.8H72.571L70,20.652h1.7l1.678,5.661h.064L75.1,20.652Zm17.992,0-2.576,7.8H90.563l-2.576-7.8h1.7l1.678,5.661h.064l1.668-5.661Zm-43.653-3.4a3,3,0,0,0-3.2-3.2H31.893a3,3,0,0,0-3.2,3.2V33.3a3,3,0,0,0,3.2,3.2H47.942a3,3,0,0,0,3.2-3.2ZM47.793,35.081H32.042a1.714,1.714,0,0,1-1.927-1.927V17.4a1.714,1.714,0,0,1,1.927-1.927H47.793A1.714,1.714,0,0,1,49.72,17.4V33.154A1.714,1.714,0,0,1,47.793,35.081ZM46.984,21.7v5.95H45.348V21.94a.611.611,0,0,0-.645-.652h-.925v6.363H42.142V21.288H40.571v6.363H38.935V19.868h6.216A1.7,1.7,0,0,1,46.984,21.7Zm-9.291-1.833v8.1c0,.953-.425,2.721-3.206,2.721H33.8V29.251h.689c1.093-.02,1.547-.481,1.566-1.273v-.327H34.684a1.738,1.738,0,0,1-1.834-1.832V21.7a1.732,1.732,0,0,1,1.834-1.833Zm-1.636,1.42h-.922a.62.62,0,0,0-.648.647v3.632a.636.636,0,0,0,.667.662h.9Zm2.878,9.4h8.049V29.251H38.935Z"
          transform="translate(-28.69 -14.051)"
          fill="#fff"
        />
      </svg>
      <h1>Turning insight into action.</h1>
      <p>
        See firsthand how OnStar Vehicle Insights provides actionable insights to
        help optimize your fleet’s operations, allow remote vehicle management and
        equip drivers with in-vehicle coaching for increased safety on the road.
      </p>
      <router-link :to="fleetRoute"><button>Start Demo →</button></router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
    fleetRoute() {
      if (this.inEssentialsMode) return '/essentials';
      return '/home';
    },
  },
  mounted() {
    document.body.style.backgroundColor = "#0072cc";
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "";
  },
};
</script>

<style lang="scss" scoped>
.guided-tour-splash {
  width: 100vw;
  height: 100vh;
  // background: url("../assets/Monitor_BG.jpg"),
  //   linear-gradient(to right, #f3f3f3 0%, #fcfcfc 40%, #fbfbfb 60%);
  font-family: Overpass;
}
.monitor, .monitor-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.monitor {
  background: url("../assets/Monitor.png");
  z-index: 10;
}
.monitor-bg {
  background: url("../assets/Monitor_BG.jpg"),
    linear-gradient(to right, #f3f3f3 0%, #fcfcfc 40%, #fbfbfb 60%);
}
.guided-tour-splash,
.monitor,
.monitor-bg {
  background-size: 110%;
  background-position-x: -50%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}
.blue-overlay {
  width: 67vw;
  height: 100%;
  position: fixed;
}
.envolve-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 72px;
}
h1 {
  position: absolute;
  top: 172px;
  left: 0px;
  width: 475px;
  height: 163px;
  font-size: 66px;
  color: #fff;
  margin: 0;
}
p {
  position: absolute;
  top: 428px;
  left: 0px;
  width: 577px;
  height: 210px;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  margin: 0;
}
button {
  position: absolute;
  top: 698px;
  left: 0px;
  width: 278px;
  height: 48px;
  border: 2px solid #fff;
  border-radius: 24px;
  opacity: 1;
  background-color: rgba(0,0,0,0);
  font-size: 24px;
  font-family: inherit;
  color: #fff;
  cursor: pointer;
}
.content {
  position: absolute;
  top: 60px;
  left: 60px;
  width: 700px;
  height: 800px;
  transform-origin: top left;
  z-index: 20;
}
@media screen and (max-width: 1700px) {
  .content {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 1500px) {
  .content {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1200px) {
  .content {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1000px) {
  .content {
    transform: scale(0.6);
  }
}
@media screen and (max-width: 800px) {
  .content {
    transform: scale(0.5);
  }
}
@media screen and (max-width: 600px) {
  .content {
    transform: scale(0.4);
  }
}
@media screen and (max-width: 500px) {
  .content {
    transform: scale(0.3);
  }
}
@media screen and (max-width: 400px) {
  .content {
    transform: scale(0.2);
  }
}
</style>
