<template>
  <div class="ev-charger-filter-toggles">
    <div class="charger-section-label">{{ $t("message.chargerType") }}</div>
    <div class="charger-types">
      <BaseIcon name="charge" />
      <span>{{ $t("message.level1and2") }}</span>
      <ToggleButton
        :value="true"
        :color="toggle.color"
        :switch-color="toggle.switchColor"
        :margin="toggle.margin"
        :height="toggle.height"
        :width="toggle.width"
      />
      <BaseIcon name="fastCharge" />
      <span>{{ $t("message.dcFastCharging") }}</span>
      <ToggleButton
        :value="true"
        :color="toggle.color"
        :switch-color="toggle.switchColor"
        :margin="toggle.margin"
        :height="toggle.height"
        :width="toggle.width"
      />
    </div>
    <div class="borderline" />
    <div class="charger-section-label">{{ $t("message.chargerNetworks") }}</div>
    <div class="charger-networks">
      <div
        class="charger-network"
        v-for="(network, i) of chargeNetworks"
        :key="i"
      >
        <img :src="require('@/assets/map-ev-networks/' + network)" />
        <ToggleButton
          :value="true"
          :color="toggle.color"
          :switch-color="toggle.switchColor"
          :margin="toggle.margin"
          :height="toggle.height"
          :width="toggle.width"
        />
      </div>
      <div class="charger-network">
        {{ $t("message.otherNetworks") }}
        <ToggleButton
          :value="true"
          :color="toggle.color"
          :switch-color="toggle.switchColor"
          :margin="toggle.margin"
          :height="toggle.height"
          :width="toggle.width"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    ToggleButton,
    BaseIcon,
  },
  data() {
    return {
      toggle: {
        color: {
          checked: "rgba(28, 217, 170, 0.3)",
          unchecked: "rgba(151, 161, 180, 0.2)",
        },
        switchColor: {
          checked: "#1CD8AA",
          unchecked: "#969FB3",
        },
        margin: -3,
        height: 12,
        width: 34,
      },
      chargeNetworks: [
        "evgo.png",
        "chargepoint.png",
        "greenlots.png",
        "electrify_america.png",
        "electric_circuit.png",
        "flo.png",
        "evconnect.png",
        "blink.png",
        "semaconnect.png",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// .ev-charger-filter-toggles {
//   margin: 32px 16px 16px 32px;
// }
.charger-section-label {
  margin: 8px 0;
  font-weight: 100;
  color: var(--dark-grey);
}
.charger-types {
  display: grid;
  grid-template-columns: 16px 1fr auto;
  grid-template-rows: repeat(auto-fill, 24px);
  align-items: center;
  gap: 8px;
  padding-bottom: 4px;
  svg {
    width: 16px;
    border-radius: 50%;
    background-color: black;
    fill: white;
  }
}
.borderline {
  margin: 16px -28px;
  width: calc(100% + 56px);
  height: 1px;
  background-color: var(--light-grey-2);
}
.charger-networks {
  .charger-network {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    img {
      height: 32px;
    }
  }
}
</style>
