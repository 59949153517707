<template>
  <div class="vehicle-selection">
    <h3>{{ $t("message.selectVehicles") }}</h3>
    <div class="vs-main">
      <div class="flex center">
        <div
          @click="showOnlySelected = false"
          class="nav-tab"
          :class="{ selected: !showOnlySelected }"
        >
          {{ $t("message.compatible") }}
        </div>
        <div
          @click="showOnlySelected = true"
          class="nav-tab"
          :class="{ selected: showOnlySelected }"
        >
          {{ $t("message.selected") }} ({{ selected.length }})
        </div>
      </div>
      <div class="search-area">
        <input
          v-model="searchQuery"
          :placeholder="$t('message.nameMakeModelVin')"
          :style="showOnlySelected ? 'margin: 0 auto;' : ''"
        />
        <div class="vline" v-if="!showOnlySelected" />
        <div class="action-button" v-if="!showOnlySelected">
          <svg id="gfs:icon1/upload" viewBox="0 0 24 24">
            <path
              d="M20.5 19.35l.1.1V21l-.1.1h-17l-.1-.1v-1.55l.1-.1h17zM12.07 3.9l6.18 6.61v.14l-.06.06c-.63.6-1.63.57-2.23-.06l-3.09-3.3v10.22l-.1.1h-1.54l-.1-.1V7.35l-3.1 3.3-.07.06a1.56 1.56 0 01-2.21-.06v-.14l6.18-6.61h.14z"
            ></path>
          </svg>
          {{ $t("message.uploadCsv") }}
        </div>
      </div>
      <div class="table-mass-selection">
        <span v-if="!showOnlySelected" class="results"
          >{{ dataset.vehicles.length }} {{ $t("message.found") }}</span
        >
        <span v-else class="results" />
        <div class="flex center">
          <b @click="addAllVehicles" v-if="!showOnlySelected">{{
            $t("message.selectAll")
          }}</b>
          <div class="vline" v-if="!showOnlySelected" />
          <b @click="removeAllVehicles">{{ $t("message.clearAll") }}</b>
        </div>
      </div>
      <div class="table-area">
        <div class="table-row head">
          <span />
          <span>{{ $t("message.vehicleLabel") }}</span>
          <span>{{ $t("message.modelName") }}</span>
          <span>{{ $t("message.vin") }}</span>
          <span>{{ $t("message.fleet") }}</span>
        </div>
        <div
          class="table-row"
          v-for="(vehicle, index) of vehiclesToDisplay"
          :style="
            index + 1 === vehiclesToDisplay.length ? 'border-bottom: none;' : ''
          "
          :key="index"
        >
          <svg
            class="check"
            @click="removeVehicle(vehicle)"
            v-if="selected.indexOf(vehicle['Vehicle Label']) !== -1"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            <path
              class="remove"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
          <svg
            class="plus"
            @click="addVehicle(vehicle)"
            v-else
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>

          <b>{{ vehicle["Vehicle Label"] }}</b>
          <span>{{ vehicle["Model Name"] }}</span>
          <span>{{ vehicle.VIN }}</span>
          <span>{{ dataset.fleetName }}</span>
        </div>
        <div v-if="showOnlySelected && !selected.length" class="none-selected">
          {{ $t("message.noSelectedVehicles") }}
        </div>
      </div>
    </div>
    <div class="bottom-btns">
      <div
        class="action-button"
        @click="
          undoAndClose();
          $store.commit('toggleModal');
        "
      >
        {{ $t("message.cancel") }}
      </div>
      <div
        class="action-button major"
        @click="
          $emit('done');
          $store.commit('toggleModal');
        "
      >
        {{ $t("message.saveSelection") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataset: Object,
    selected: Array[String],
  },
  data() {
    return {
      showOnlySelected: false,
      selectedVehicles: [],
      selectedNamesBackup: [],
      searchQuery: "",
    };
  },
  computed: {
    allVehicleNames() {
      const arr = [];
      for (let i = 0; i < this.dataset.vehicles.length; i += 1) {
        arr.push(this.dataset.vehicles[i]["Vehicle Label"]);
      }
      return arr;
    },
    vehiclesToDisplay() {
      let data;
      if (this.showOnlySelected) data = this.selectedVehicles;
      else data = this.dataset.vehicles;

      if (this.searchQuery) {
        data = data.filter((row) => this.search(row));
      }
      return data;
    },
  },
  methods: {
    addVehicle(vehicle) {
      this.$emit("add", vehicle["Vehicle Label"]);
      this.selectedVehicles.push(vehicle);
      this.$forceUpdate();
    },
    removeVehicle(vehicle) {
      this.$emit("remove", vehicle["Vehicle Label"]);
      const i = this.selectedVehicles.indexOf(vehicle);
      this.selectedVehicles.splice(i, 1);
      this.$forceUpdate();
    },
    addAllVehicles() {
      this.$emit("mass-action", this.allVehicleNames);
      this.selectedVehicles = Array.from(this.dataset.vehicles);
      this.$forceUpdate();
    },
    removeAllVehicles() {
      this.$emit("mass-action", []);
      this.selectedVehicles = [];
      this.$forceUpdate();
    },
    undoAndClose() {
      this.$emit("mass-action", this.selectedNamesBackup);
      this.$emit("done");
    },
    search(row) {
      for (let str of Object.values(row)) {
        if (
          str &&
          str
            .toString()
            .toLowerCase()
            .indexOf(this.searchQuery.toLowerCase()) !== -1
        )
          return true;
      }
      return false;
    },
  },
  mounted() {
    this.selectedNamesBackup = Array.from(this.selected);
  },
};
</script>

<style scoped lang="scss">
.vehicle-selection {
  max-width: 800px;
  height: 603px;
}
h3 {
  font-size: 19px;
  margin: 16px 24px;
}
.vs-main {
  width: 100%;
  height: 492px;
  padding: 8px 24px;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #eaf1f9, #ffffff 20px);
}
.nav-tab {
  width: 100%;
  height: 30px;
  color: var(--blue);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 2px;
}
.nav-tab:hover {
  color: #05b5e4;
}
.nav-tab.selected {
  margin-bottom: 0;
  border-bottom: 2px solid var(--blue);
}
.nav-tab.selected:hover {
  color: var(--blue);
}
.search-area {
  display: flex;
  margin: 30px 0 8px 0;
}
.search-area input {
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  border: 1px solid var(--light-grey-2);
  border-radius: 2px;
  margin-right: 16px;
  padding-left: 10px;
}
.search-area input::placeholder {
  color: var(--grey);
  font-size: 15px;
  font-family: ProximaNova;
  line-height: 15px;
}
.vline {
  width: 1px;
  height: 30px;
  margin: 0 8px;
  background-color: var(--light-grey-2);
}
.action-button {
  margin-left: 8px;
  height: 30px;
  white-space: nowrap;
  min-width: 120px;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}
.action-button svg {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  fill: var(--blue);
  margin-right: 8px;
}
.action-button.major {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-left: 16px;
}
.table-mass-selection {
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-mass-selection .results {
  color: var(--dark-grey);
}
.table-mass-selection b {
  color: var(--blue);
  cursor: pointer;
}
.table-area {
  width: 100%;
  max-height: 330px;
  overflow-y: scroll;
  border: 1px solid var(--light-grey-2);
  /* border-bottom: none; */
  display: grid;
  grid-template-columns: 1fr;
}
.table-row {
  border-bottom: 1px solid var(--light-grey-2);
  width: 100%;
  height: 38px;
  display: grid;
  align-items: center;
  grid-template-columns: 54px 123px 173px 150px 1fr;
  gap: 12px;
}
.table-row.head {
  background-color: #eaf1f9;
  font-size: 12px;
  color: var(--dark-grey);
  font-weight: 600;
}
b {
  font-weight: 600;
}
.table-row svg {
  width: 20px;
  height: 20px;
  justify-self: center;
  cursor: pointer;
}
.check {
  fill: var(--green);
}
.plus {
  fill: var(--blue);
}
.remove {
  fill: var(--dark-grey);
}
.check .remove,
.check:hover path {
  display: none;
}
.check:hover .remove,
.check path {
  display: unset;
}
.none-selected {
  width: 100%;
  height: 51px;
  padding: 16px;
  box-sizing: border-box;
}
.bottom-btns {
  width: 100%;
  height: 63px;
  margin-top: 9px;
  border-top: 1px solid var(--light-grey-2);
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
