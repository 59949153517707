var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$store.commit('toggleModal')}}},[_c('div',{staticClass:"modal-container",class:( _obj = {}, _obj[_vm.$route.name] = true, _obj.invite = _vm.$route.name === 'vehicles-screen' ||
            _vm.$route.name === 'drivers-screen', _obj )},[(_vm.currentModalPage === 1)?_c('header',{staticClass:"modal-header"},[_c('h3',[_vm._t("header")],2),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.x),expression:"x"}],staticClass:"x",on:{"click":_vm.toggleModal}},[_c('svg',{staticClass:"x-icon",attrs:{"id":"gml:icon1/dialog-close","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"}})])])]):_vm._e(),(
            _vm.$route.name !== 'in-vehicle-coaching' &&
              _vm.$route.name !== 'reports-screen' &&
              _vm.$route.name !== 'fleet-adapter'
          )?_c('div',{staticClass:"gradient-divider short"}):_vm._e(),_c('section',{staticClass:"modal-body"},[_vm._t("body")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }