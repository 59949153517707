<template>
  <div class="icon-container">
    <!-- <svg class="speed-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60">
      <path fill="#171C26" fill-rule="evenodd" d="M30.5 60a29.86 29.86 0 0 0 14.39-3.67 30.113 30.113 0 0 0 13.331-14.841A29.91 29.91 0 0 0 60.5 30a29.877 29.877 0 0 0-6.79-19.008 30.129 30.129 0 0 0-9.321-7.59A29.87 29.87 0 0 0 30.5 0a29.88 29.88 0 0 0-13.055 2.982 30.095 30.095 0 0 0-9.043 6.727 30.015 30.015 0 0 0-5.744 9.096A29.918 29.918 0 0 0 .5 30c0 3.88.737 7.59 2.078 10.993a30.027 30.027 0 0 0 6.403 9.91 30.037 30.037 0 0 0 10.688 7.082A29.926 29.926 0 0 0 30.5 60Z" clip-rule="evenodd"/>
      <path fill="#fff" fill-rule="evenodd" d="M30.5 55c4.346 0 8.432-1.109 11.992-3.059a25.096 25.096 0 0 0 11.11-12.367A24.925 24.925 0 0 0 55.5 30a24.897 24.897 0 0 0-5.658-15.84 25.105 25.105 0 0 0-7.768-6.325A24.891 24.891 0 0 0 30.5 5c-3.9 0-7.59.893-10.88 2.485A25.08 25.08 0 0 0 7.3 20.67 24.931 24.931 0 0 0 5.5 30c0 3.234.614 6.324 1.732 9.161a25.021 25.021 0 0 0 5.335 8.258 25.03 25.03 0 0 0 8.907 5.902A24.939 24.939 0 0 0 30.5 55Z" clip-rule="evenodd"/>
    </svg>
    <svg class="speed-limit-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40">
      <path fill="#FF4F72" fill-rule="evenodd" d="M20.5 40a19.91 19.91 0 0 0 9.594-2.447 20.074 20.074 0 0 0 8.887-9.894A19.94 19.94 0 0 0 40.5 20a19.92 19.92 0 0 0-4.526-12.672 20.086 20.086 0 0 0-6.215-5.06A19.913 19.913 0 0 0 20.5 0c-3.12 0-6.072.714-8.703 1.988a20.064 20.064 0 0 0-9.858 10.549A19.945 19.945 0 0 0 .5 20c0 2.587.491 5.06 1.385 7.329a20.018 20.018 0 0 0 4.269 6.606 20.024 20.024 0 0 0 7.125 4.722A19.95 19.95 0 0 0 20.5 40Z" clip-rule="evenodd"/>
      <path fill="#fff" fill-rule="evenodd" d="M20.5 36.67c2.897 0 5.621-.74 7.994-2.04a16.729 16.729 0 0 0 7.407-8.245 16.618 16.618 0 0 0 1.265-6.382c0-4.008-1.414-7.686-3.771-10.56a16.739 16.739 0 0 0-5.18-4.217 16.595 16.595 0 0 0-7.715-1.89c-2.6 0-5.06.595-7.253 1.657a16.72 16.72 0 0 0-8.215 8.79 16.621 16.621 0 0 0-1.199 6.22c0 2.155.41 4.216 1.155 6.107a16.68 16.68 0 0 0 3.556 5.505 16.687 16.687 0 0 0 5.938 3.935 16.627 16.627 0 0 0 6.018 1.12Z" clip-rule="evenodd"/>
    </svg> -->
    <div class="speed">
      {{ speed }}
    </div>
    <div class="speed-limit">
      {{ speedLimit }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeedingDoubleIcon',
  props: {
    speed: {
      type: Number,
      required: true,
    },
    speedLimit: {
      type: Number,
      required: true,
    },
  }
};
</script>

<style lang="scss" scoped>

.icon-container {
  box-sizing: border-box;
  width: 43px;
  margin-top: 10px;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  .speed, .speed-limit {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .speed {
    width: 30px;
    height: 30px;
    top: 1.5px;
    border: 2.5px solid var(--dark);
    border-radius: 50%;
    font-size: 11px;
    font-weight: 700;
    color: var(--red);
  }
  .speed-limit {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 0;
    border: 2.5px solid var(--red);
    border-radius: 50%;
    font-size: 6.5px;
    font-weight: 700;
  }
  .speed-icon {
    position: relative;
    width: 30px;
    height: 30px;
    top: 1.5px;
  }
  .speed-limit-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
  }
}

</style>