<template>
  <div class="content">
    <AddCameraDrivers class="add-drivers-img" />
    <h3>By clicking Confirm, you agree to be contacted by an OnStar Vehicle Insights representative, which will contact you to place the order for your cameras.</h3>
    <p>The representative will call you within the next 2 business days.</p>
    <p><strong>Choose how to be contacted:</strong></p>
    <div class="checkboxes">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="" id="phone-check" v-model="isPhoneSelected">
        <label class="form-check-label" for="phone-check">
          <IconPhone /> +1 (833) 266-6565
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="" id="email-check" v-model="isEmailSelected">
        <label class="form-check-label" for="email-check">
          <IconEmail /> fleetemail@handh.com
        </label>
      </div>
    </div>
    <p class="muted">You can update your contact info in the Account Details tab in the Account Settings Page</p>
    
    <hr />
    <div class="btn-container">
      <button class="btn btn-minor" @click="$emit('goBack')">Back</button>
      <button class="btn btn-primary" @click="$emit('accepted')" :disabled="!isBtnEnabled">Confirm</button>
    </div>
  </div>
</template>

<script>
import AddCameraDrivers from '@/assets/icons/add-camera-drivers.svg';
import IconPhone from '@/assets/icons/phone-icon.svg';
import IconEmail from '@/assets/icons/email-icon.svg';
export default {
  name: 'AddCameraModal1',
  components: {
    AddCameraDrivers,
    IconPhone,
    IconEmail,
  },
  data() {
    return {
      isPhoneSelected: false,
      isEmailSelected: false,
    }
  },
  computed: {
    isBtnEnabled () {
      return this.isPhoneSelected || this.isEmailSelected;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.content {
  padding: 8px;
  text-align: center;
  *, ::after, ::before {
    box-sizing: border-box;
  }
  h3 {
    text-align: center;
    margin: 0 auto 24px auto;
    font-size: 17px;
    max-width: 465px;
  }
  p {
    margin: 0 20px 18px 20px;
    &.muted {
      color: var(--dark-grey);
    }
  }
  hr {
    margin: 20px 0;
    color: inherit;
    border: 0;
    border-top: 1px solid #9EC9EA;
  }
}

.add-drivers-img {
  display: block;
  max-width: 248px;
  margin-top: 38px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.form-check {
  margin-bottom: 27px;
  margin-left: 8px;
  margin-right: 8px;
  .form-check-input {
    margin-right: 10px;
  }
  .form-check-label {
    svg {
      width: 12px;
      height: auto;
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-container {
  display: flex;
  margin-left: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-family: $font-family-nova;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  white-space: nowrap;
  padding: 5px 18px 6px 18px;
  border-radius: 4px;
  &:first-child {
    margin-left: auto;
    margin-right: 5px;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.btn-minor {
  color: var(--dark-grey);
}

.btn-primary {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
  &:disabled {
    background-color: var(--pale-grey);
    border-color: var(--pale-grey);
    color: #969FB2;
  }
}

</style>
