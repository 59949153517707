<template>
  <div class="carousel" ref="carousel">
    <!-- <div style="display: inline-block; white-space: nowrap; min-width: 100%; height: 100%;"> -->
    <div class="slide" ref="slide0">
      <img src="@/assets/ivc-select-vehicles.svg?external" />
      <h2>{{$t('message.selectVehicles')}}</h2>
      <div class="light-txt">
        {{$t('message.chooseVehicles')}}
      </div>
    </div>
    <div class="slide" ref="slide1" style="left: 100%">
      <img src="@/assets/ivc-activated.svg?external" />
      <h2>{{$t('message.inVehicleCoachingIsActivated')}}</h2>
      <div class="light-txt">
        {{$t('message.driverIsNotifiedRealtime')}}
      </div>
    </div>
    <div class="slide" ref="slide2" style="left: 200%">
      <img src="@/assets/ivc-get-notified.svg?external" />
      <h2>{{$t('message.getNotified')}}</h2>
      <div class="light-txt">
       {{$t('message.fleetManagersCanSubscribe')}}
      </div>
    </div>
    <div class="slide" ref="slide3" style="left: 300%">
      <img src="@/assets/ivc-analyze-driving.svg?external" />
      <h2>{{$t('message.analyzeDrivingBehavior')}}</h2>
      <div class="light-txt">
        {{$t('message.allTripDetails')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentSlide: Number,
  },
  watch: {
    currentSlide() {
      // this.$refs.carousel.scrollTo({
      //   left: this.$refs["slide" + this.currentSlide].offsetWidth,
      //   behavior: "smooth",
      // });
      this.$refs["slide" + this.currentSlide].scrollBy({top: 0, left: 1});
      this.$refs["slide" + this.currentSlide].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
};
</script>

<style scoped>
.carousel {
  overflow: hidden;
  grid-area: carousel;
  position: relative;
}
.slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
}
img {
  width: 300px;
  margin-bottom: 16px;
}
h2 {
  font-size: 19px;
  text-align: center;
}
.light-txt {
  font-size: 19px;
  font-weight: 300;
  text-align: center;
}
</style>