<template>
  <svg v-if="iconId === 6" id="gfs:icon1/i-home" width="20" height="20" viewBox="0 0 24 24">
    <path 
      :fill="color"
      d="M5.47 11.48c1.014.117 1.777.98 1.77 2v6.12H10v-5.42h4v5.44h2.72V13.5c-.007-1.02.756-1.883 1.77-2L12 4.94l-6.53 6.54zm-1.35 1.34L3 14l-1-1L12 3l10 10-1 1-1.16-1.16H18.8c-.376 0-.68.304-.68.68V21H5.88v-7.5c0-.376-.304-.68-.68-.68H4.12zm8.56 6.8v-4.08h-1.36v4.08h1.36z">
    </path>
  </svg>
  <!-- Map -->
  <svg v-else-if="iconId === 0" id="gfs:icon/i-map" width="20" height="20" viewBox="0 0 24 24">
    <path 
      :fill="color"
      d="M12 16.117l-.375-.284c-.217-.158-5.25-3.983-5.25-8a5.625 5.625 0 0 1 11.25 0c0 4.017-5 7.842-5.25 8l-.375.284zm0-12.659a4.375 4.375 0 0 0-4.375 4.375c0 2.825 3.25 5.767 4.375 6.667 1.125-.933 4.375-3.883 4.375-6.667A4.375 4.375 0 0 0 12 3.458zm0 6.667a2.292 2.292 0 1 1 0-4.583 2.292 2.292 0 0 1 0 4.583zm0-3.333a1.042 1.042 0 1 0 0 2.083 1.042 1.042 0 0 0 0-2.083zm9.667 12.566l-1.475-4.708A2.292 2.292 0 0 0 18 13.042h-1.125c-.317.433-.657.85-1.017 1.25H18c.455-.001.86.292 1 .725l1.475 4.716a.617.617 0 0 1-.092.55.6.6 0 0 1-.5.259H4.117a.6.6 0 0 1-.5-.259.633.633 0 0 1-.092-.558L5 15.017a1.05 1.05 0 0 1 1-.725h2.142c-.36-.4-.7-.817-1.017-1.25H6a2.292 2.292 0 0 0-2.192 1.608l-1.475 4.7a1.875 1.875 0 0 0 1.784 2.442h15.766a1.875 1.875 0 0 0 1.784-2.434z"
    />
  </svg>
  <!-- Fleet Management -->
  <svg v-else-if="iconId === 11" id="gfs:icon1/i-manage-fleet" width="20" height="20" viewBox="0 0 24 24">
    <path
      :fill="color"
      d="M21.4 8.042a1.042 1.042 0 0 1-.95-.609l-1.667-3.558h-8.591L8.525 7.433c-.17.372-.541.61-.95.609H5.158l-1.625 4.241c-.129.418-.497.718-.933.759H2v1.25h.6c.149-.003.297-.022.442-.059v6.934h1.25v-2.134c.322.167.679.255 1.041.259h8.334c.362-.004.72-.092 1.041-.259v2.134h1.25v-6.934c.145.037.293.056.442.059h2.267c.362-.004.72-.092 1.041-.259v2.134h1.25V9.242c.145.033.293.05.442.05h.6v-1.25h-.6zM9.683 7.958l1.309-2.833h7.016l1.309 2.833.05.084H9.633l.05-.084zM6.017 9.292h6.966l1.317 3.441c.042.107.092.21.15.309h-9.9c.058-.099.108-.202.15-.309l1.317-3.441zm7.65 8.75H5.333A1.042 1.042 0 0 1 4.292 17v-2.708h10.416V17c0 .575-.466 1.042-1.041 1.042zm5-5H16.4a1.083 1.083 0 0 1-.933-.759l-1.15-2.991h5.391V12c0 .575-.466 1.042-1.041 1.042zM17 10.542h1.667v1.25H17v-1.25zm-5 5h1.667v1.25H12v-1.25zm-6.667 0H7v1.25H5.333v-1.25z"
    />
  </svg>
  <!-- Account Management -->
  <svg v-else-if="iconId === 12" id="gfs:icon1/i-manage-account" width="20" height="20" viewBox="0 0 24 24">
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M16.2833333,17.2083333 L12.625,15.7416666 L12.625,12 L11.375,12 L11.375,15.7416666 L7.71666667,17.2083333 L3.875,17.2083333 L3.875,21.7916666 L8.45833333,21.7916666 L8.45833333,18.2583333 L12,16.8416666 L15.5416667,18.2583333 L15.5416667,21.7916666 L20.125,21.7916666 L20.125,17.2083333 L16.2833333,17.2083333 Z M7.20833333,20.5416666 L5.125,20.5416666 L5.125,18.4583333 L7.20833333,18.4583333 L7.20833333,20.5416666 Z M18.875,20.5416666 L16.7916667,20.5416666 L16.7916667,18.4583333 L18.875,18.4583333 L18.875,20.5416666 Z M10.125,12.2083334 L7.3,12.2083334 L8.55833333,10.225 L8.55833333,10.15 C8.87539009,9.61776696 9.45574154,9.29889254 10.075,9.31666663 L13.8416667,9.31666663 C14.4927407,9.31109819 15.0930777,9.66744791 15.4,10.2416666 L16.6583333,12.2333333 L13.875,12.2333333 L13.875,13.4833333 L18.9666667,13.4833333 L16.5166667,9.59166663 C16.0204513,8.66630514 15.0663067,8.07791597 14.0166667,8.04999997 C14.6382684,7.4841223 14.9922959,6.68226512 14.9916667,5.84166663 L14.9916667,5.24166663 C14.9916666,3.56640292 13.633597,2.20833337 11.9583333,2.20833337 C10.2830696,2.20833337 8.92500004,3.56640292 8.925,5.24166663 L8.925,5.84166663 C8.93498716,6.68873502 9.30461603,7.49161741 9.94166667,8.04999997 C8.92206682,8.09788134 7.99917462,8.66799563 7.5,9.5583333 L5,13.4583333 L10.125,13.4583333 L10.125,12.2083334 Z M10.2166667,5.24166663 C10.2166667,4.25675883 11.0150922,3.4583333 12,3.4583333 C12.9849078,3.4583333 13.7833333,4.25675883 13.7833333,5.24166663 L13.7833333,5.84166663 C13.7833333,6.82657444 12.9849078,7.62499997 12,7.62499997 C11.0150922,7.62499997 10.2166667,6.82657444 10.2166667,5.84166663 L10.2166667,5.24166663 Z"
    />
  </svg>
  <!-- Finance and Billing -->
  <svg v-else-if="iconId === 13" id="gfs:icon1/i-subscription-lg" width="20" height="20" viewBox="0 0 24 24">
    <path
      :fill="color"
      d="M8.36 16l1.14 2.65v3.85h-1v-1.09a1.64 1.64 0 0 1-.33.077L8 21.5H4.5a1.64 1.64 0 0 1-.337-.043L4 21.41v1.09H3v-3.85L4.14 16h4.22zm.14 3H4v1a.5.5 0 0 0 .41.492l.09.008H8a.5.5 0 0 0 .492-.41L8.5 20v-1zm11.9-8.903c.094.42.13 1.01.106 1.775L20.5 12a8.5 8.5 0 0 1-8.5 8.5l-.193-.002-.193-.007.045-.999a7.501 7.501 0 0 0 7.776-6.5h-.858l1.823-2.895zM7.71 17H4.79l-.42 1h3.76l-.42-1zM12 3.5c.877 0 1.736.133 2.556.391l.14.048.05.017-.183.993a7.5 7.5 0 0 0-10.061 6.89l.868-.14L4 15c-.117-.28-.216-.663-.297-1.147l.066.276A8.5 8.5 0 0 1 12 3.5zm0 8a2.4 2.4 0 0 1 2.312 1.757l.346 1.243H9.342l.346-1.243a2.406 2.406 0 0 1 2.133-1.75zm0 1l-.117.005a1.4 1.4 0 0 0-1.232 1.02l.008-.025h2.681l-.033-.102a1.4 1.4 0 0 0-1.174-.892L12 12.5zm0-4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 1a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zM19.512 2v1.499l2.302.001v1h-4.346a.522.522 0 0 0-.094 1.036l.094.008h2.987a1.49 1.49 0 0 1 .143 2.974l-.143.007-.943-.001v1.293h-1V8.524H16v-1h4.455a.49.49 0 0 0 .088-.972l-.088-.008h-2.987a1.522 1.522 0 0 1-.139-3.038l.139-.006 1.044-.001V2h1z"
    />
  </svg>
  <!-- In-Vehicle Coaching -->
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M16.204 7.024c1.518 1.47 2.463 3.53 2.463 5.81 0 2.173-.859 4.147-2.255 5.6l-.825-.825c1.185-1.242 1.913-2.924 1.913-4.776 0-1.958-.814-3.726-2.121-4.984l.825-.825zm-1.768 1.768c1.066 1.017 1.73 2.452 1.73 4.041 0 1.484-.578 2.833-1.522 3.833l-.825-.826C14.552 15.05 15 13.995 15 12.833c0-1.267-.534-2.41-1.39-3.216l.826-.825zM6.793 2.5c2.83 0 4.025 1.575 4.586 4.11l.028.133 1.426 2.94c.19.394.027.867-.367 1.058-.08.04-.167.064-.256.074l-.09.005h-.423v1.915c0 .742-.569 1.35-1.294 1.412l-.122.005H8.924v2.265H7.758v-3.432h2.523c.118 0 .217-.082.243-.192l.007-.058V9.654h.99l-1.194-2.465-.047-.137c-.444-2.177-1.303-3.385-3.487-3.385-2.509 0-4.093 1.83-4.093 4 0 1.218.218 2.016.803 3.239l.217.442.09.18c.83 1.673 1.189 2.808 1.228 4.554l.003.335H3.875c0-1.625-.264-2.61-.964-4.071l-.323-.654c-.756-1.526-1.055-2.508-1.055-4.025 0-2.779 2.066-5.167 5.26-5.167z"
    />
  </svg> -->
  <!-- Business Solutions -->
  <svg v-else-if="iconId === 14" id="gfs:icon1/add-ons" width="20" height="20" viewBox="0 0 24 24">
    <path
      :fill="color"
      d="M9.1 1v7.076c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h7.1c.552 0 1 .448 1 1zm-1.211.21H1.21v6.655h6.679V1.21zM19.4 11.416v7.076c0 .552-.448 1-1 1h-7.1c-.552 0-1-.448-1-1v-7.076c0-.552.448-1 1-1h7.1c.552 0 1 .448 1 1zm-1.211.21h-6.68v6.656h6.68v-6.656zM8.1 10.276c.552 0 1 .448 1 1v7.216c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1v-7.216c0-.552.448-1 1-1h7.1zm-.211 1.209H1.21v6.797h6.679v-6.797zM15.456 1.21v2.763l2.733.001v1.21h-2.733v2.765h-1.21V5.183l-2.737.001v-1.21h2.737V1.21h1.21z"
      transform="translate(2.300000, 2.300000)">
    </path>
  </svg>
  <!-- No Icon Match -->
  <svg v-else width="20" height="20"></svg>
</template>

<script>
export default {
  props: {
    iconId: Number,
    color: String,
  },
}
</script>

<style lang="scss" scoped>

</style>