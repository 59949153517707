<template>
  <div class="upper-area">
    <span>
      {{ numResults }}
      {{ $t("message.result") }}{{ numResults !== 1 ? "s" : "" }}
    </span>
    <div class="buttons">
      <BaseIcon
        v-for="icon of visibleIcons"
        :key="icon"
        :name="icon"
        :class="{ disabled: disabledIcons.indexOf(icon) !== -1 }"
      />
    </div>
  </div>
</template>

<script>
import { camelize } from "../../utils/camelize.js";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: { BaseIcon },
  props: {
    numResults: Number,
    headings: Array[Object],
    tags: Array[Object],
    iconsToShow: Array[String],
  },
  data() {
    return {
      tagPopup: false,
      batchPopup: false,
      addPopup: false,
      columnsPopup: false,
      uploadPopup: false,
      hoverPopup: false,
      camelize: camelize,
      disabledIcons: ["batch", "commandTag"],
    };
  },
  computed: {
    visibleIcons() {
      if (this.iconsToShow?.length) return this.iconsToShow;
      return [
        "download",
        "columns",
        "batch",
        "uploadFile",
        "addSolid",
      ];
    },
  },
  methods: {
    /**
     * Turns off all the other popups before turning on the relevant one
     * @param String icon the user is activating, e.g. "batch". See data function for all the items being tracked.
     * @return undefined
     */
    handlePopups(icon) {
      if (!this[`${icon}Popup`]) {
        //close everything else before opening
        switch (icon) {
          case "hover":
            this.addPopup = false;
            this.columnsPopup = false;
            this.uploadPopup = false;
            this.batchPopup = false;
            this.tagPopup = false;
            break;
          case "tag":
            this.addPopup = false;
            this.columnsPopup = false;
            this.uploadPopup = false;
            this.batchPopup = false;
            this.tagPopup = false;
            break;
          case "batch":
            //batch icon and upload icon don't show at the same time, no need to regulate
            this.addPopup = false;
            this.columnsPopup = false;
            this.tagPopup = false;
            break;
          case "upload":
            //batch icon and upload icon don't show at the same time, no need to regulate
            this.addPopup = false;
            this.columnsPopup = false;
            this.tagPopup = false;
            break;
          case "add":
            this.batchPopup = false;
            this.uploadPopup = false;
            this.columnsPopup = false;
            this.tagPopup = false;
            if (this.$route.name === "vehicles-screen") {
              this.$store.commit("toggleModal");
            }
            break;
          case "columns":
            this.addPopup = false;
            this.uploadPopup = false;
            this.batchPopup = false;
            this.tagPopup = false;
            break;
          default:
            break;
        }
        //open relevant popup now that everything else is closed
        this[`${icon}Popup`] = true;
      } else {
        //close if already open
        this[`${icon}Popup`] = false;
      }
    },
    setHeadings() {
      for (let i = 0; i < this.headings.length; i += 1) {
        const col = this.headings[i];
        if (col.manuallyHidden !== undefined) col.manuallyHidden = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upper-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  left: 0;
  min-height: 36px;
  z-index: 2;
}
.upper-area span {
  color: var(--dark-grey);
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.upper-area .buttons {
  display: flex;
  align-items: top;
  margin-top: 5px;
  gap: 22px;
}
.upper-area .buttons svg {
  /* the colors are applied inline for this component */
  width: 20px;
  height: 20px;
  fill: var(--blue);
  &.disabled {
    fill: #c6c6ca;
  }
}
</style>
