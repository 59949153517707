<template>
  <div class="metrics-container">
    <div class="content">
      <!-- Buttons -->
      <div class="buttons">
        <button
          class="button"
          @click="activeTable = 'demo-session-started'"
          :class="{ active: activeTable === 'demo-session-started' }"
        >
          DEMO SESSION STARTED
        </button>
        <button
          class="button"
          @click="activeTable = 'menu-clicks'"
          :class="{ active: activeTable === 'menu-clicks' }"
        >
          MENU CLICKS
        </button>
        <div class="sync">
          <button :disabled="!canSync" @click="syncAll">Sync All</button>
          <span>Num Unsynced Records: {{ canSync ? unsyncedLocalRecords.length : unsyncedLocalRecords.length - syncSuccessCount }}</span>
          <span v-if="syncSuccessCount || syncFailCount">
            Status: {{ syncSuccessCount }} succeeded, {{ syncFailCount }} failed
          </span>
        </div>
      </div>

      <!-- Demo Session Started -->
      <div
        v-if="activeTable === 'demo-session-started'"
        class="answer-selected three-columns"
      >
        <table>
          <tr class="title">
            <td>Date</td>
            <td>Count</td>
            <td>Avg Duration</td>
          </tr>
          <tr
            class="day"
            v-for="(day, index) in demoSessions"
            :key="index"
          >
            <td>{{ day.date }}</td>
            <td>{{ day.count }}</td>
            <td>
              {{ day.avgTime ? day.avgTime.toFixed(2) : '' }}
              seconds
            </td>
          </tr>
        </table>
      </div>

      <!-- Menu Clicks -->
      <div
        v-if="activeTable === 'menu-clicks'"
        class="answer-selected three-columns"
      >
        <table>
          <tr class="title">
            <td>Date</td>
            <td>Page</td>
            <td>Count</td>
          </tr>
        </table>

        <table class="day">
          <tr v-for="(session, i) in menuItemClicks" :key="`dS${i}`">
            <td v-if="session.count > 0">{{ session.date }}</td>
            <td v-if="session.count > 0">{{ session.page }}</td>
            <td v-if="session.count > 0">{{ session.count }}</td>
          </tr>
        </table>
      </div>
    </div>
    <button class="return button" @click="reset()">Return To App</button>
  </div>
</template>

<script>
import { getDemoSessionTable, getMenuItemClickTable, getUnsyncedLocalRecords, updateSyncStatus } from "@/utils/localMetrics.js";
const axios = require("axios").default;

export default {
  data() {
    return {
      activeTable: 'menu-clicks',
      demoSessions: [],
      menuItemClicks: [],
      unsyncedLocalRecords: [],
      syncSuccessCount: 0,
      syncFailCount: 0,
      canSync: true,
    };
  },
  methods: {
    reset() {
      this.$emit('hideMetrics');
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async syncOne(record) {
      try {
        const timestamp = new Date(record.timestamp * 1000);
        await axios.post(process.env.VUE_APP_INSIGHTS_URL, {
          activationId: record.activationId,
          sessionIdentifier: record.sessionIdentifier,
          action: record.action,
          value: record.value,
          createdAt: timestamp,
          updatedAt: timestamp,
        });
        await updateSyncStatus(record.id, true);
        this.syncSuccessCount += 1;
      } catch (e) {
        console.error(e);
        this.syncFailCount += 1;
      }
    },
    async syncAll() {
      if (this.canSync) {
        this.canSync = false;
        this.syncSuccessCount = 0;
        this.syncFailCount = 0;
        for (let i = 0; i < this.unsyncedLocalRecords.length; i += 1) {
          await this.syncOne(this.unsyncedLocalRecords[i]);
          await this.timeout(200);
        }
        const unsyncRaw = await getUnsyncedLocalRecords();
        if (unsyncRaw.values.length) {
          this.unsyncedLocalRecords = unsyncRaw.values.slice(1);
        } else {
          this.unsyncedLocalRecords = [];
        }
        this.canSync = true;
      }
    },
  },
  async mounted() {
    const dSRaw = await getDemoSessionTable();
    if (dSRaw.values.length) {
      this.demoSessions = dSRaw.values.slice(1);
    }
    const mICRaw = await getMenuItemClickTable();
    if (mICRaw.values.length) {
      this.menuItemClicks = mICRaw.values.slice(1);
    }
    const unsyncRaw = await getUnsyncedLocalRecords();
    if (unsyncRaw.values.length) {
      this.unsyncedLocalRecords = unsyncRaw.values.slice(1);
    }
  },
};
</script>

<style lang="css" scoped>
.metrics-container {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
}
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: atu; */
  margin-bottom: 16px;
  /* grid-template-rows: 50px 50px; */
}
.button {
  flex: 1;
  border: none;
  color: #fff;
  background: gray;
}
.active {
  background: blue;
}
.sync {
  padding: 8px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 8px;
}
.sync button {
  grid-row: 1 / -1;
}
table {
  width: 100%;
  border-spacing: 0px;
  text-align: center;
}
.two-columns td {
  width: 50%;
}
.three-columns td {
  width: 33%;
}
.four-solumns td {
  width: 25%;
}
.title {
  font-weight: 700;
}
.content {
  max-height: calc(100vh - 50px);
  overflow: scroll;
}
.return {
  width: 100vw;
  height: 50px;
  position: absolute;
  bottom: 0;
}
</style>
