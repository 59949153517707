<template>
  <div class="measure-days-select">
    <div class="dd2" :class="{ emph: expanded }" @click="expanded = !expanded">
      {{$t('message.last')}} {{ value }} {{$t('message.days')}}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    </div>
    <div class="dd2-expanded" v-if="expanded">
      <div
        class="dd2-item"
        v-for="(item, i) of possibilities"
        :class="{ selected: value === item }"
        @click="select(item)"
        :key="i"
      >
        {{$t('message.last')}} {{ item }} {{$t('message.days')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    activeChallenge: String,
  },
  data() {
    return {
      expanded: false,
      possibilities: [7, 30, 60],
    };
  },
  methods: {
    select(item) {
      this.$emit("input", item);
      this.expanded = false;
      if (this.activeChallenge === "go-the-extra-mile" && item === 60) {
        this.$emit("checkChallenge", 21);
      }
    },
  },
};
</script>

<style scoped>
.measure-days-select {
  position: relative;
}
/* dd2 indicates "dropdown version 2" */
.dd2 {
  width: fit-content;
  height: 25px;
  color: var(--dark-grey);
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dd2.emph {
  background-color: #eaf1f9;
}
.dd2 svg {
  width: 18px;
  height: 18px;
  fill: var(--dark-grey);
}
.dd2-expanded {
  position: absolute;
  top: 25px;
  left: -8px;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.dd2-item {
  min-width: 115px;
  height: 30px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dd2-item.selected {
  background-color: #eaf1f9;
  color: var(--blue);
}
.dd2-item:hover {
  background-color: #eaf1f9;
}
</style>
