<template>
  <div class="diagnostic-view">
    <div class="diag-head">
      <h1>{{ $t("message.commandsExplained") }}</h1>
      <svg
        id="gml:icon/dialog-close"
        viewBox="0 0 24 24"
        @click="$emit('hide')"
      >
        <path
          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
        />
      </svg>
    </div>
    <div class="diagnostic-info">
      <div class="text">
        <h2>{{ $t("message.lockUnlock") }}</h2>
        <ul style="margin-bottom: 42px">
          <li>{{ $t("message.pressTheLockButtonToLockAllDoors") }}</li>
          <li>{{ $t("message.pressTheUnlockButtonToUnlockAllDoors") }}</li>
          <li>
            {{
              $t(
                "message.onVehiclesWithAutomaticTransmissionTheGearSelectorMust"
              )
            }}
          </li>
        </ul>
        <h2>{{ $t("message.vehicleLocatorPanicAlarm") }}</h2>
        <p>
          {{ $t("message.pressTheHornAndLightsButton") }}
        </p>
        <ul>
          <li>{{ $t("message.theDriverDoorIsOpened") }}</li>
          <li>
            {{ $t("message.aVehicleIsTurnedOnWithTheKey") }}
          </li>
          <li>{{ $t("message.aPeriodOf30SecondsElapses") }}</li>
        </ul>
        <p style="margin-top: 20px">
          {{
            $t(
              "message.onVehiclesWithAutomaticTransmissionVehicleLocatorPanicAlarm"
            )
          }}
        </p>
      </div>
      <div class="line" />
      <div class="action-button major" @click="$emit('hide')">
        {{ $t("message.close") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.diagnostic-view {
  width: 620px;
  max-width: 100%;
  height: 650px;
  position: relative;
}
.diag-head {
  top: 0;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
}
.diag-head h1 {
  font-size: 28px;
  font-weight: 700;
}
.diag-head svg {
  width: 20px;
  height: 20px;
  fill: var(--dark);
  margin: 22px;
  margin-top: 28px;
  cursor: pointer;
}
.diagnostic-info {
  display: grid;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 30px);
}
.diagnostic-info .text {
  margin-top: 14px;
  padding: 24px 32px;
}
h2 {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 6px;
}
p,
li,
span {
  font-size: 15px;
}
p {
  line-height: 20px;
}
ul {
  margin-top: 0;
  padding-left: 24px;
}
li {
  /* line-height: 28px; */
  margin: 10px 0;
}
.action-button {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 28px;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}
.action-button.major {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
}
.line {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
}
@media screen and (max-width: 408px) {
  .diagnostic-view {
    height: 80vh;
    margin-top: -25%;
    overflow: hidden;
    background-color: white;
    display: grid;
    grid-template-rows: 104px calc(100% - 104px);
    /* not sure why you can't just use 1fr in place of the calc, but iOS doesn't like it */
  }
  .diagnostic-info {
    height: calc(100% - 60px);
    overflow: scroll;
  }
}
</style>
