<template>
  <div class="assign-driver-vehicle">
    <div class="assign-head">
      <h2>{{ $t("message.assignDriverToVehicle") }}</h2>
      <img src="@/assets/clear.svg?external" @click="$emit('hide')" />
    </div>
    <div class="assign-main">
      <input class="search" :placeholder="$t('message.search')" />
      <div
        class="assign-list-item"
        v-for="(driver, index) of drivers"
        :key="'L' + index"
      >
        <div class="icon">{{ $t("message." + driver["Image"]) }}</div>
        <div class="detail-grid">
          <b>{{ $t("message." + camelize(driver["Name"])) }}</b>
          <span> {{ $t("message.phone") }} </span>
          <span class="long">{{ driver["Phone"] }}</span>
          <span> {{ $t("message.email") }} </span>
          <span class="long">{{
            $t("message." + camelize(driver["Name"]) + "Email")
          }}</span>
        </div>
        <div class="actions">
          <div v-if="driver['Vehicle'] === '' && index < 5" class="flex center">
            <div class="status-light green" />
            <span>{{ $t("message.available") }}</span>
          </div>
          <div v-else class="flex center">
            <div class="status-light" />
            <span>{{ $t("message.assigned") }}</span>
          </div>
          <div
            class="action-btn"
            v-if="index < 5"
            @click="assignDriver(driver, index)"
          >
            {{ $t("message.selectDriver") }}
          </div>
        </div>
        <div class="line" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { camelize } from "../../utils/camelize.js";

export default {
  props: {
    drivers: Array[Object],
    currentVehicle: String,
    multiplier: Number,
  },
  data() {
    return {
      camelize: camelize,
    };
  },
  methods: {
    assignDriver(driverObj, driverIndex) {
      Vue.set(this.drivers[driverIndex], "Vehicle", this.currentVehicle);
      this.$emit("hide");
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.assign-driver-vehicle {
  width: 440px;
  height: 500px;
  overflow: scroll;
  max-width: 100%;
}
.assign-head {
  position: sticky;
  top: 0;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
}
.assign-head h2 {
  margin: 20px 24px;
  font-weight: 700;
  font-size: 21px;
}
.assign-head img {
  width: 11px;
  height: 11px;
  margin: 28px;
  /* margin-top: 24px; */
  cursor: pointer;
}
.assign-main {
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 30px);
}
.search {
  margin: 32px;
  width: calc(100% - 64px);
  height: 30px;
  padding-left: 8px;
  font-family: ProximaNova;
  font-size: 15px;
  box-sizing: border-box;
}
.assign-list-item {
  display: grid;
  grid-template-rows: 65px 24px 1px;
  grid-template-columns: 48px 1fr;
  grid-gap: 16px;
  margin: 0 5%;
  margin-bottom: 20px;
}
.icon {
  width: 48px;
  height: 48px;
  background-color: #6f166d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 18px;
  line-height: unset;
  font-weight: 700;
}
.detail-grid {
  display: grid;
  grid-template-rows: repeat(3, 19px);
  grid-template-columns: 56px 1fr;
  grid-gap: 4px;
}
.detail-grid b {
  grid-column-start: 1;
  grid-column-end: 3;
}
.actions {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.status-light {
  background-color: var(--orange);
  width: 8px;
  height: 8px;
  margin: 0 6px 0 8px;
  border-radius: 50%;
}
.status-light.green {
  background-color: var(--green);
}
.action-btn {
  /* width: 117px;
  height: 28px; */
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: -4px;
  cursor: pointer;
}
.line {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 1px;
  background-color: var(--light-grey);
}
.long {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
