<template>
  <div class="in-vehicle-coaching">
    <Modal v-show="$store.state.showModal" class="ivc" x>
      <template v-if="currentModalPage === 0">
        <span slot="header">Fleet Adapter Installation Guide</span>
        <div slot="body">
          <iframe
            src="fleet_adapter_installation_guide.pdf"
            style="
              width: 100%;
              max-width: 60vh;
              max-height: 60vh;
              aspect-ratio: 1 / 1;
              display: block;
              margin: 0 auto;
            "
          />
        </div>
      </template>
      <template v-else-if="currentModalPage === 1">
        <span slot="header">Adapter Compatibility</span>
        <div slot="body" class="adapter-compat">
          <span>Enter the VIN you would like to check:</span>
          <div class="flex" style="gap: 16px">
            <div class="field" style="width: 280px">
              <input
                placeholder="VIN"
                class="has-right-icon"
              />
              <!-- Right Aligned Icons -->
              <svg
                id="gml:icon1/field-search"
                class="search icon"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                ></path>
              </svg>
            </div>
            <button class="action-button" disabled>Check</button>
            <button class="action-button">Clear</button>
          </div>
          <div class="add-vin-msg">
            <img src="@/assets/fleet-of-vehicles.svg?external" />
            <span>Add VIN to see Adapter Compatibility</span>
          </div>
        </div>
      </template>
      <template v-else-if="currentModalPage === 2">
        <span slot="header">Fleet Adapter Terms and Conditions</span>
        <div slot="body" class="terms-confirm">
          <b>Accept Terms</b>
          <p>By selecting "Accept" you agree to the OnStar Vehicle Insights <b class="link" @click="currentModalPage = 3">Fleet Adapter Addendum to User Terms</b></p>
          <div class="flex" style="gap: 4px">
            <CheckBox />
            <span>I accept the user terms</span>
          </div>
          <div class="btns">
            <button class="action-button decline" disabled>Decline</button>
            <button class="action-button" disabled>Accept</button>
          </div>
        </div>
      </template>
      <template v-if="currentModalPage === 3">
        <span slot="header">Fleet Adapter Addendum to User Terms</span>
        <div slot="body">
          <iframe
            src="fleet-adapter-user-terms-EN-US.pdf"
            style="
              width: 100%;
              max-width: 60vh;
              max-height: 60vh;
              aspect-ratio: 1 / 1;
              display: block;
              margin: 0 auto;
            "
          />
        </div>
      </template>
    </Modal>
    <div class="ivc-head" v-if="!isMobile">
      <div class="flex center">
        <div class="ivc-circle">
          <svg id="gfs:icon1/mixedfleet" viewBox="0 0 36 36">
            <path
              d="M15.119 28.08h5.95v1.149h-5.95V28.08zm-4.247-14.812h14.263v1.148H10.872v-1.148zm4.828-6.38h1.24v2.426H15.7V6.887zm-3.37 0h1.241v2.426h-1.24V6.887zm10.109 0h1.24v2.426h-1.24V6.887zm-3.37 0h1.241v2.426h-1.24V6.887zm-9.128 4.265c-.006.007-.01.007-.01.01h.086c.04 0 .07-.007.102-.01h-.178zm16.131 0h-.185c.033.007.07.01.103.01h.092a.025.025 0 0 1-.01-.01zM29.25 25.04V10.562l-.007-.85h-2.428V3H9.179v6.71H7.595l-.845.006v16.223c0 .269.017.538.043.8a7.115 7.115 0 0 0 .667 2.307c.267.541.597 1.053.996 1.51l.007.007c.409.508.88.943 1.409 1.298.468.312.99.558 1.544.734a6.94 6.94 0 0 0 2.34.405h1.759c.663 0 1.426-.01 2.478-.043 1.047.033 1.809.043 2.472.043h1.769c.822 0 1.624-.143 2.39-.422a5.942 5.942 0 0 0 1.498-.717c.488-.328.93-.723 1.313-1.178a7.068 7.068 0 0 0 1.772-3.947c.026-.263.043-.531.043-.8v-.9h-.007v.003h.007zm-1.686.671c-.007.17-.132 3.243-2.373 4.74a4.404 4.404 0 0 1-1.871.694c-1.317.186-2.875.186-5.29.116h-.05c-2.415.07-3.973.07-5.29-.116a4.46 4.46 0 0 1-1.871-.694c-2.247-1.507-2.37-4.57-2.373-4.74V11.405h1.568l.01-.01h.848V4.696h14.263V11.4h.861l.01.01h1.561l-.003 14.301zM10.123 11.153a.534.534 0 0 1-.103.01h-.085c.006-.007.01-.007.01-.01h.178z"
              fill-rule="nonzero"
            ></path>
          </svg>
        </div>
        <h1 v-if="!isMobile">{{ $t("message.fleetAdapter") }}</h1>
      </div>
      <div class="flex column center add-container">
        <div class="flex center">
          <button @click="openModal(2)" class="add-button">
            <Icon :icon="plusIcon" class="plus-icon" />
            <span>{{ $t("message.add") }}</span>
          </button>
        </div>
        <b>{{ $i18n.locale === "fr-ca" ? "85,00$" : "$85.00" }}</b>
        <span class="unit">{{ $t("message.device") }}</span>
      </div>
    </div>
    <div class="ivc-gradient" />
    <div class="ivc-main">
      <div class="slideshow">
        <svg
          class="prev-btn"
          @click="
            currentSlide > 0 ? currentSlide-- : (currentSlide = numSlides - 1)
          "
          id="gml:icon1/carousel-prev"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
          ></path>
        </svg>
        <AdapterCarousel :currentSlide="currentSlide" />

        <svg
          class="next-btn"
          @click="
            currentSlide < numSlides - 1 ? currentSlide++ : (currentSlide = 0)
          "
          id="gml:icon1/carousel-next"
          viewBox="0 0 24 24"
        >
          <path
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          ></path>
        </svg>
        <div class="dots flex center">
          <div
            class="dot"
            v-for="i in numSlides"
            :class="currentSlide === i - 1 ? 'blue' : 'grey'"
            @click="currentSlide = i - 1"
            :key="i"
          />
        </div>
      </div>
      <div class="flex column rhs">
        <div class="check-compatibility">
          <span>{{ $t("message.checkAdapterCompatibility") }}</span>
          <button class="action-button" @click="openModal(1)">
            {{ $t("message.checkNow") }}
          </button>
        </div>
        <div class="flex column">
          <h3 class="">{{ $t("message.howItWorks") }}</h3>
          <p>
            {{ $t("message.adapterParagraphOne") }}
          </p>
          <p>
            {{ $t("message.adapterParagraphTwo") }}
          </p>
          <p>
            {{ $t("message.adapterParagraphThree") }}
          </p>
          <p>
            {{ $t("message.forMoreInformationViewThe") }}
            <b @click="openModal(0)">{{
              $t("message.fleetAdapterInstallationGuide")
            }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdapterCarousel from "@/components/demo/AdapterCarousel.vue";
import Modal from "@/components/demo/Modal.vue";
import CheckBox from "@/components/demo/CheckBox.vue";
import { Icon } from "@iconify/vue2";
import plusIcon from "@iconify/icons-mdi/plus";

export default {
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  methods: {
    checkChallenge() {
      //checks if go the extra mile challenge is completed and alerts parent components
      if (this.activeChallenge === "not-buckled-not-cool") {
        this.$emit("challengeComplete", 17);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
    },
    openModal(num) {
      this.$store.commit("toggleModal");
      this.currentModalPage = num;
    },
  },
  components: {
    AdapterCarousel,
    Icon,
    Modal,
    CheckBox,
  },
  data() {
    return {
      radio: false,
      showVehicleSelection: false,
      numNotEnrolled: 0,
      currentSlide: 0,
      numSlides: 3,
      bingo: false,
      currentModalPage: 0,
      search: "",
      plusIcon: plusIcon,
    };
  },
  computed: {
    selectedVehicles() {
      return this.$store.state.ivcVehicles;
    },
    numEnrolled() {
      return this.dataset.vehicles.length;
    },
    percentEnrolled() {
      const total = this.numEnrolled + this.numNotEnrolled;
      return (this.numEnrolled / total) * 100;
    },
  },
};
</script>

<style scoped lang="scss">
.ivc-head {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 36px;
  box-sizing: border-box;
}
.ivc-circle {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}
.ivc-circle svg {
  width: 32px;
  height: 32px;
}
.ivc-gradient {
  background: linear-gradient(to bottom, #f5f5f5, #ffffff 100%);
  width: 100%;
  height: 50px;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  margin-right: 8px;
  margin-bottom: 16px;
}
.dot.blue {
  background-color: var(--blue);
}
.dot.grey {
  background-color: var(--grey);
}
.ivc-status {
  font-size: 12px;
  color: var(--dark);
  margin-bottom: 16px;
}
.unit {
  color: var(--dark-grey);
}
.ivc-main {
  display: grid;
  // grid-template-rows: 180px 420px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 72px;
}
.slideshow {
  width: 100%;
  height: 100%;
  grid-row: 1 / 3;
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-template-rows: 1fr 40px;
  grid-template-areas:
    "prev carousel next"
    "dots dots dots";
}
.slideshow .prev-btn,
.slideshow .next-btn {
  fill: var(--blue);
  width: 20px;
  height: 20px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
}
.slideshow .prev-btn {
  grid-area: prev;
}
.slideshow .next-btn {
  grid-area: next;
}
.slideshow .dots {
  grid-area: dots;
  padding-top: 16px;
}
.slideshow .dots .dot {
  cursor: pointer;
}
h3 {
  font-size: 19px;
}
.vehicles-dot-dot-dot {
  fill: var(--blue);
  width: 20px;
  height: 20px;
}
.big-number-wrap {
  margin-right: 22px;
}
.big-number {
  font-size: 40px;
  color: var(--dark);
  font-weight: 300;
  margin-left: 4px;
  margin-top: 8px;
}
.ivc-vox-grid {
  display: grid;
  grid-template-columns: 32px 32px 1fr;
  grid-template-rows: repeat(5, 1fr 1px) 1px;
  gap: 12px;
  align-items: center;
}
.ivc-vox-grid svg {
  width: 32px;
  height: 32px;
  fill: var(--dark-grey);
}
.ivc-vox-grid .line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
  grid-column: 1 / 4;
}
.add-container {
  align-items: unset;
  text-align: center;
}

.add-button {
  background: var(--blue);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: bold;
  width: 100%;
  height: 30px;
  margin-bottom: 4px;

  .plus-icon {
    height: 16px;
    width: 16px;
  }
}
.coaching-content-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .options {
    max-width: 55%;
    .heading {
      margin-bottom: 20px;
    }
    .row {
      display: flex;
      svg {
        height: 18px;
        width: 18px;
        min-height: 18px;
        max-height: 18px;
        min-width: 18px;
        max-width: 18px;

        margin-right: 2px;
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .semibold {
        font-weight: 600;
      }
    }
  }
  .overview {
    width: 35%;
    padding: 50px 10px 20px 10px;
    border: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      margin: 5px 0;
      font-weight: bold;
    }
    .heading {
      font-size: 1.6rem;
      text-align: center;
    }
    .number {
      border-bottom: 1px solid var(--grey);
      width: fit-content;
      padding-bottom: 10px;
      font-size: 3.5rem;
    }
    .price {
      font-size: 2rem;
      font-weight: normal;
    }
  }
  .info {
    width: 100%;
    margin: 60px 0 20px 0;
    color: var(--grey);
  }
}
.select-vehicles-container {
  width: 90%;
  padding: 0 5%;
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    .tab {
      width: 50%;
      text-align: center;
      font-weight: bold;
      color: var(--blue);
    }
    .compatible {
      border-bottom: 1px solid var(--blue);
    }
  }
  .selection {
    width: 20%;
  }
  .search-and-upload-container {
    display: flex;
    .search-container {
      width: 30%;
      border-right: 1px solid var(--light-grey);
      margin-right: 10px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      .input {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border: 1px solid var(--light-grey);
        padding: 0 5px;
        border-radius: 0.25rem;
        max-height: 25px;
        input {
          border: 0;
          width: 100%;
        }
      }
      .icon {
        width: 24px;
        height: 24px;
      }
    }

    button {
      max-height: 35px;
      min-height: 25px;
      font-weight: bold;
      border-radius: 0.25rem;
      width: fit-content;
      color: var(--blue);
      background: #fff;
      border: 1px solid var(--light-grey);
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }
}
.table-container {
  .table-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    color: var(--light-grey);
    margin-top: 20px;
    .selection {
      color: var(--blue);
      display: flex;
      justify-content: flex-end;
      width: 30%;
      .select-all {
        border-right: 1px solid var(--light-grey);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
}
.buttons {
  border-top: 1px solid var(--grey);
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  button {
    font-weight: bold;
    border: 0;
    border-radius: 0.25rem;

    &.pg-1 {
      color: var(--blue);
      background: #fff;
      margin-right: 10px;
    }
    &.pg-2 {
      background: var(--blue);
      color: #fff;
    }
    &.pg-1:disabled {
      color: var(--grey);
      background: #fff;
    }
    &.pg-2:disabled {
      border: 1px solid var(--grey);
    }
    &.cancel {
      width: 10%;
      color: var(--blue);
      margin-right: 10px;
      background: #fff;
      border: 1px solid var(--grey);
    }
  }
}

.select-vehicles {
  .vehicle-selection {
    padding: 15px 0;
  }
}
@media screen and (max-width: 845px),
  (max-width: 1120px) and (min-width: 970px) {
  .ivc-main {
    grid-template-columns: 1fr;
    grid-template-rows: auto 420px auto;
    margin: 0 10%;
  }
  .slideshow {
    grid-row: 2 / 3;
  }
}
@media screen and (max-width: 425px) {
  .ivc-main {
    margin: 0 5%;
  }
}

.check-compatibility {
  background-color: #f4f3f7;
  padding: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rhs {
  h3 {
    margin: 24px 0 12px 0;
  }
  p {
    margin: 10px 0;
    color: #606164;
    b {
      color: #0072c8;
    }
  }
}

.action-button {
  // margin-left: 8px;
  height: 30px;
  min-width: 100px;
  padding: 0 4px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  &:disabled {
    border-color: rgb(232, 232, 235);
    color: rgb(198, 198, 202);
  }
}

.field {
  position: relative;
  &.wide {
    grid-column: 1 / -1;
  }
  .icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 24px;
    fill: var(--grey);
    padding: 3px;
    &.search {
      left: unset;
      right: 0;
      border-left: 1px solid var(--pale-grey);
      padding-left: 1px;
    }
  }
  input, textarea {
    color: #76777a;
    font-family: inherit;
    border: 1px solid rgba(0, 28, 56, 0.24);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    padding: 0 8px;
    &::-webkit-calendar-picker-indicator {
      filter: contrast(0.001);
      transform: scale(1.25);
    }

    &.has-left-icon {
      padding-left: 32px;
    }
    &.has-right-icon {
      padding-right: 32px;
    }
  }
  textarea {
    height: 90px;
  }
}

.adapter-compat {
  color: #76777a;
  padding-top: 8px;
}
.add-vin-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 32px auto 64px auto;
  color: #606164;
  img {
    max-width: 280px;
  }
}

.terms-confirm {
  padding-top: 24px;
  color: #606164;
  b {
    color: #1a1c1e;
    &.link {
      color: var(--blue);
      font-weight: normal;
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 24px;
  }
  .btns {
    display: flex;
    margin-top: 160px;
    justify-content: flex-end;
    gap: 8px;
    border-top: 1px solid #e8e8eb;
    padding-top: 16px;
    .action-button {
      background-color: #e8e8eb;
      &.decline {
        background-color: #ffffff00;
        border: none;
      }
    }
  }
}
</style>
