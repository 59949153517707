<template>
  <div class="chart-area flex">
    <div class="chart-wrap">
      <svg width="180px" height="180px" viewBox="0 0 42 42" class="chart">
        <circle
          class="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#d2d3d4"
          stroke-width="1.6"
        />
        <circle
          class="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#35D090"
          stroke-width="1.6"
          stroke-dasharray="100 0"
          stroke-dashoffset="25"
        />
      </svg>
      <div class="chart-text">
        <h1>{{ chartTotal }}</h1>
        <span>{{$t('message.vehicles')}}</span>
      </div>
    </div>
    <div class="chart-key" v-if="showChartKey">
      <div
        class="color-dot"
        v-for="(value, key, index) of chartKey"
        :style="{ 'background-color': value.color }"
        :key="index"
      />
      <span v-for="(value, key, index) of chartKey" :key="'A' + index"
        >{{ value.value }} {{ $t('message.' + camelize(key)) }}</span
      >
    </div>
  </div>
</template>

<script>
import { camelize } from '../../utils/camelize.js';

export default {
  props: {
    chartTotal: Number, // Total number for middle of chart
    chartKey: Object, // Breakdown of stats for key,
    showChartKey: Boolean,
  },
  data() {
    return {
      camelize: camelize,
    }
  },
};
</script>

<style scoped>
.chart-area {
  /* margin-top: 36px;
  margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}
.chart-text span {
  font-size: 12px;
  color: var(--dark-grey);
}
.chart-wrap {
  position: relative;
  height: 180px;
  transform: scale(1.25);
}
.chart-text {
  position: absolute;
  top: 0;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chart-text h1 {
  margin: 0;
  /* margin-top: 8px; */
  font-size: 20px;
  line-height: 34px;
}
.chart-key {
  display: grid;
  grid-template: repeat(auto-fit, 15px) / 8px 140px;
  grid-gap: 12px 8px;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 12px;
  margin-left: 32px;
}
.color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  grid-column: 1 / 1;
}
.chart-key span {
  color: var(--dark);
  font-size: 12px;
  grid-column: 2 / 2;
}
</style>
