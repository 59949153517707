<template>
  <div
    class="dropdown"
    :class="{ open: open }"
    @click="disabled ? '' : open = !open"
  >
    <div class="dropdown-selected-option">
      {{ translate(value) }}
    </div>
    <img class="dropdown-arrow" :class="{ open: open }" src="@/assets/arrow_down.svg?external" />
    <div class="dropdown-options" v-if="open">
      <div v-for="option in options" :key="option" class="option" @click="$emit('input', option)">
        <DropDownIcon :name="option" />
        {{ translate(option) }}
      </div>
    </div>
  </div>
</template>

<script>
import { camelize } from "../../utils/camelize.js";

import DropDownIcon from "@/components/demo/DropDownIcon.vue";

export default {
  components: {
    DropDownIcon,
  },
  props: {
    disabled: Boolean,
    options: Array[String | Number],
    value: [String, Number],
    width: Number,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    translate(str) {
      if (this.$te(`message.${camelize(str)}`)) return this.$t(`message.${camelize(str)}`);
      return str;
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  background-color: var(--white);
  font-family: ProximaNova;
  padding: 3px 3px 3px 12px;
  border: 1px solid var(--light-grey-2);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.dropdown.open {
  border: 1px solid var(--blue);
  z-index: 5;
  cursor: unset;
}

.dropdown-arrow {
  transition: transform 0.5s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-options {
  position: absolute;
  top: 31px;
  background-color: var(--white);
  margin-left: -12px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-rows: repeat(auto-fit, 30px);
}

.dropdown-options .option {
  height: 30px;
  color: var(--dark-grey);
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
}
.dropdown-options .option:hover {
  background-color: #69cdff3d;
}
.dropdown-selected-option {
  margin-bottom: 1px;
}
</style>