<template>
  <div class="base-pagination">
    <BaseIcon name="firstPage" />
    <BaseIcon name="arrowLeft" />
    <b class="current-page">{{ currentPage }}/{{ totalPages }}</b>
    <BaseIcon name="arrowLeft" style="transform: rotate(180deg)" />
    <BaseIcon name="firstPage" style="transform: rotate(180deg)" />
  </div>
</template>

<script>

import BaseIcon from "@/components/icons/BaseIcon.vue";
export default {
  components: {
    BaseIcon,
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },
};

</script>

<style lang="scss" scoped>
.base-pagination {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    margin: 0 10px;
    fill: var(--light-grey);
  }
  b {
    margin: 0 10px 1px 10px;
    font-weight: 600;
    color: var(--dark-grey);
    flex-grow: 1;
    text-align: center;
  }
}
</style>