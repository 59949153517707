<template>
  <section class="section">
    <h2>Orders</h2>
    <table>
      <thead>
        <tr>
          <th>Order Number</th>
          <th>Status</th>
          <th>Last Update</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1092837468</td>
          <td>Delivered to Dealer</td>
          <td>04/29/2023</td>
        </tr>
        <tr>
          <td>1092837468</td>
          <td>Delivered to Dealer</td>
          <td>04/29/2023</td>
        </tr>
        <tr>
          <td>1092837468</td>
          <td>Delivered to Dealer</td>
          <td>04/29/2023</td>
        </tr>
      </tbody>
    </table>
    <ViewMore>Find More Orders</ViewMore>
  </section>
</template>

<script>
import ViewMore from '@/components/demo/myba/ViewMore.vue';

export default {
  components: {
    ViewMore
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

section.section {
  font-family: $font-family-base;
  table {
    border-collapse: collapse;
    margin-bottom: 24px;
    th, td {
      font-size: 12px;
      padding: 10px 12px;
    }
    th {
      font-weight: 600;
      text-align: inherit;
      text-align: -webkit-match-parent;
      color: $accent-blue;
      border-bottom: 1px solid #C3D7EE;
    }
    td {
      border-bottom: 1px solid rgba(195, 215, 238, 0.50);
    }
  }
}
</style>