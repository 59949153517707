<template>
  <div class="in-vehicle-coaching">
    <div class="ivc-head" v-if="!isMobile">
      <div class="flex center">
        <div class="ivc-circle">
          <img src="@/assets/Video2.svg?external" style="height: 18px;" />
        </div>
        <h1 v-if="!isMobile">{{ $t("message.insightsDualCam") }}</h1>
      </div>
      <div class="flex column center add-container" v-if="!isCameraAdded">
        <div class="flex center">
          <button class="add-button" @click="openAddModal">
            <Icon :icon="plusIcon" class="plus-icon" />
            <span>{{ $t("message.add") }}</span>
          </button>
        </div>
        <b>$ 300</b>
        <span class="unit">$25 subscription<br />Vehicle / Month*</span>
      </div>
    </div>
    <div class="ivc-gradient" />
    <div class="ivc-main">
      <div class="slideshow">
        <svg
          class="prev-btn"
          @click="
            currentSlide > 0 ? currentSlide-- : (currentSlide = numSlides - 1)
          "
          id="gml:icon1/carousel-prev"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
          ></path>
        </svg>
        <LytxCameraCarousel :currentSlide="currentSlide" />

        <svg
          class="next-btn"
          @click="
            currentSlide < numSlides - 1 ? currentSlide++ : (currentSlide = 0)
          "
          id="gml:icon1/carousel-next"
          viewBox="0 0 24 24"
        >
          <path
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          ></path>
        </svg>
        <div class="dots flex center">
          <div
            class="dot"
            v-for="i in numSlides"
            :class="currentSlide === i - 1 ? 'blue' : 'grey'"
            @click="currentSlide = i - 1"
            :key="i"
          />
        </div>
      </div>
      <div class="flex column rhs">
        <div class="flex column">
          <div class="camera-info" v-if="isCameraAdded">
            <span class="dots">
              <img src="@/assets/icons/horizontal-dots.svg?external" />
            </span>
            <BaseDropdown class="dots" :dropdownOptions="['Settings', 'Remove']" @selection-clicked-emitter="dropdownOptionClicked" />
            <div class="my-cameras">
              <h4>My Onstar DuelCam Cameras</h4>
              <div @click="isCameraStatusModalOpen = true">
                <div class="flex" style="margin-top: -12px">
                  <div class="big-number-wrap flex column">
                    <div class="big-number">{{ numAssigned }}</div>
                    <div class="flex center">
                      <div class="dot" />
                      <span class="ivc-status">{{ $t("message.assigned") }}</span>
                    </div>
                  </div>
                  <div class="big-number-wrap flex column">
                    <div class="big-number">{{ numUnassgined }}</div>
                    <div class="flex center">
                      <div class="dot orange" />
                      <span class="ivc-status">Unassigned</span>
                    </div>
                  </div>
                  <div class="big-number-wrap flex column">
                    <div class="big-number">{{ numOrdered }}</div>
                    <div class="flex center">
                      <div class="dot grey" />
                      <span class="ivc-status">Ordered</span>
                    </div>
                  </div>
                </div>
                <BarChart
                  class="ivc-bar"
                  :value="percentAssigned"
                  :width="363"
                  :height="6"
                  :color="'#1CD8AA'"
                  :secondaryValue="percentUnassigned"
                  :secondaryColor="'#FFAB40'"
                  rounded
                />
              </div>
              
              <p><button class="add-button">
                <span>Buy Cameras</span>
              </button></p>
            </div>
            <h4>Need Help?</h4>
            <p>Please contact us at <a class="blue">support.onstarvehicleinsights@onstar.com</a> for assistance. Please be prepared to share the camera provider name, an order ID, and a camera ID if you have received them.</p>
          </div>
          <h3>Lytx AI-14</h3>
          <h4>Features</h4>
          <ul>
            <li>Interior and exterior view of driving events</li>
            <li>Compatible with all vehicles</li>
            <li>Provides perception-based camera events, unlike many low-cost providers</li>
            <li>Places camera events along the route of your driver's trip history</li>
            <li>Internal screen on camera for live view</li>
            <li>Ability to enable real-time event alerts for driver coaching and correction</li>
          </ul>
          <div class="separator" />
          <h4>Subscription</h4>
          <span>This camera must be used with monthly camera subscription service.</span>
          <div class="separator" />
          <h4>Installation</h4>
          <span>
            Preferred third-party installer highly recommended. Please view the list of preferred installers <span class="blue">here</span>.
            <br /><br />
            Installation will be a separate charge not included in the camera purchase price.
          </span>
          <div class="separator" />
          <h4>Camera Events</h4>
          <span>Help protect your assets and coach your drivers with perception-based video of hard brakes, accidents/collisions, and distracted driving.</span>
          <div class="separator" />
          <h4>Camera Specifications</h4>
          <span>
            Link to AI-14 spec sheet <span class="blue">here</span>.
            <br /><br />
            For more information view <span class="blue">OnStar DualCam Guide</span> and the <span class="blue">Camera User Terms</span>.
            <br /><br />
            *Pricing terms and conditions apply.
          </span>
          <div class="separator" />
          <h4>Important Information</h4>
          <span>Camera hardware is not returnable</span>
        </div>
      </div>
    </div>
    <BaseModal class="add-modal" :show="isAddModalOpen" :disableScrolling="true" @closed="isAddModalOpen = false">
      <template v-slot:header>
        <div class="modal-header">
          Onstar DuelCam
          <button class="btn-close" @click="isAddModalOpen = false"><IconClose /></button>
        </div>
      </template>
      <div class="modal-content">
        <AddCameraModal1 v-if="addCameraModalScreen === 1" @closed="isAddModalOpen = false" @accepted="addCameraModalScreen = 2" />
        <AddCameraModal2 v-if="addCameraModalScreen === 2" @goBack="addCameraModalScreen = 1" @accepted="addCameraModalScreen = 3" />
        <AddCameraModal3 v-if="addCameraModalScreen === 3" @accepted="buyCamera" />
      </div>
    </BaseModal>
    <BaseModal class="add-modal" :show="isCameraStatusModalOpen" :disableScrolling="true" @closed="isCameraStatusModalOpen = false">
      <template v-slot:header>
        <div class="modal-header">
          Camera Statuses
          <!-- <button class="btn-close" @click="isCameraStatusModalOpen = false"><IconClose /></button> -->
        </div>
      </template>
      <CameraStatuses @closed="isCameraStatusModalOpen = false" />
    </BaseModal>
    <BaseModal class="cancel-modal" :class="{'cancel-modal-2': removeCameraModalScreen === 2}" :show="isRemoveModalOpen" :disableScrolling="true" @closed="isRemoveModalOpen = false">
      <template v-slot:header>
        <div class="modal-header">
          Cancel Camera service
          <!-- <button class="btn-close" @click="isCameraStatusModalOpen = false"><IconClose /></button> -->
        </div>
      </template>
      <CancelCameraModal1 v-if="removeCameraModalScreen === 1" @closed="isRemoveModalOpen = false" @accepted="removeCameraModalScreen = 2" />
      <CancelCameraModal2 v-if="removeCameraModalScreen === 2" @closed="isRemoveModalOpen = false" />
    </BaseModal>
  </div>
</template>

<script>
import LytxCameraCarousel from "@/components/demo/LytxCameraCarousel.vue";
import { Icon } from "@iconify/vue2";
import plusIcon from "@iconify/icons-mdi/plus";
import BaseModal from "@/components/BaseModal.vue";
import IconClose from '@/assets/icons/close-icon.svg';
import AddCameraModal1 from "@/components/demo/AddCameraModal1.vue";
import AddCameraModal2 from "@/components/demo/AddCameraModal2.vue";
import AddCameraModal3 from "@/components/demo/AddCameraModal3.vue";
import BarChart from "@/components/demo/BarChart.vue";
import CameraStatuses from "@/components/demo/CameraStatuses.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import CancelCameraModal1 from "@/components/demo/CancelCameraModal1.vue";
import CancelCameraModal2 from "@/components/demo/CancelCameraModal2.vue";
import { mapGetters } from 'vuex';

const CAMERA_STATE = {
  ASSIGNED: 5,
  UNASSIGNED: 9,
  ORDERED: 3,
};

export default {
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  components: {
    LytxCameraCarousel,
    Icon,
    BaseModal,
    IconClose,
    AddCameraModal1,
    AddCameraModal2,
    AddCameraModal3,
    BarChart,
    CameraStatuses,
    BaseDropdown,
    CancelCameraModal1,
    CancelCameraModal2
  },
  data() {
    return {
      radio: false,
      showVehicleSelection: false,
      numNotEnrolled: 0,
      currentSlide: 0,
      numSlides: 3,
      bingo: false,
      search: "",
      plusIcon: plusIcon,
      isAddModalOpen: false,
      addCameraModalScreen: 1,
      isCameraStatusModalOpen: false,
      isRemoveModalOpen: false,
      removeCameraModalScreen: 1,
    };
  },
  computed: {
    ...mapGetters(['isCameraAdded']),
    selectedVehicles() {
      return this.$store.state.ivcVehicles;
    },
    numAssigned() {
      return CAMERA_STATE.ASSIGNED;
    },
    numUnassgined() {
      return CAMERA_STATE.UNASSIGNED;
    },
    numOrdered() {
      return CAMERA_STATE.ORDERED;
      // return this.cameras.filter(o => o.status === CAMERA_STATE.ORDERED).length;
    },
    percentAssigned() {
      const total = this.numAssigned + this.numUnassgined + this.numOrdered;
      return (this.numAssigned / total) * 100;
    },
    percentUnassigned() {
      const total = this.numAssigned + this.numUnassgined + this.numOrdered;
      return (this.numUnassgined / total) * 100;
    },
  },
  methods: {
    openAddModal() {
      console.log("Add Modal");
      this.isAddModalOpen = true;
    },
    buyCamera() {
      this.isAddModalOpen = false;
      console.log("Camera bought");
      this.$store.dispatch('setCamera', true);
    },
    dropdownOptionClicked(option) {
      console.log(option);
      if (option !== 'Remove') {
        return;
      }
      this.isRemoveModalOpen = true;
      // Logic to open remove modal
    },
  }
};
</script>

<style scoped lang="scss">
.ivc-head {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 36px;
  box-sizing: border-box;
}
.ivc-circle {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}
.ivc-circle svg {
  width: 32px;
  height: 32px;
}
.ivc-gradient {
  background: linear-gradient(to bottom, #f5f5f5, #ffffff 100%);
  width: 100%;
  height: 50px;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  margin-right: 8px;
  margin-bottom: 9px;
}
.dot.blue {
  background-color: var(--blue);
}
.dot.orange {
  background-color: #FFAB40;
}
.dot.grey {
  background-color: var(--pale-grey);
}
.ivc-status {
  font-size: 14px;
  line-height: 1.35;
  color: var(--dark);
  margin-bottom: 9px;
}
.unit {
  color: var(--dark-grey);
}
.ivc-main {
  display: grid;
  // grid-template-rows: 180px 420px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 72px;
}
.slideshow {
  width: 100%;
  height: max-content;
  grid-row: 1 / 3;
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-template-rows: 1fr 40px;
  grid-template-areas:
    "prev carousel next"
    "dots dots dots";
}
.slideshow .prev-btn,
.slideshow .next-btn {
  fill: var(--blue);
  width: 20px;
  height: 20px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
}
.slideshow .prev-btn {
  grid-area: prev;
}
.slideshow .next-btn {
  grid-area: next;
}
.slideshow .dots {
  grid-area: dots;
  padding-top: 30px;
}
.slideshow .dots .dot {
  cursor: pointer;
}
h3 {
  font-size: 19px;
}
.vehicles-dot-dot-dot {
  fill: var(--blue);
  width: 20px;
  height: 20px;
}
.big-number-wrap {
  margin-right: 22px;
}
.big-number {
  font-size: 40px;
  color: var(--blue);
  font-weight: 400;
  margin-left: 4px;
  line-height: 1.2;
  // margin-top: 8px;
}
.ivc-vox-grid {
  display: grid;
  grid-template-columns: 32px 32px 1fr;
  grid-template-rows: repeat(5, 1fr 1px) 1px;
  gap: 12px;
  align-items: center;
}
.ivc-vox-grid svg {
  width: 32px;
  height: 32px;
  fill: var(--dark-grey);
}
.ivc-vox-grid .line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
  grid-column: 1 / 4;
}
.add-container {
  align-items: unset;
  text-align: center;
}

.add-button {
  background: var(--blue);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: bold;
  width: 100%;
  height: 30px;
  margin-bottom: 4px;
  cursor: pointer;

  .plus-icon {
    height: 16px;
    width: 16px;
  }
}
.coaching-content-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .options {
    max-width: 55%;
    .heading {
      margin-bottom: 20px;
    }
    .row {
      display: flex;
      svg {
        height: 18px;
        width: 18px;
        min-height: 18px;
        max-height: 18px;
        min-width: 18px;
        max-width: 18px;

        margin-right: 2px;
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .semibold {
        font-weight: 600;
      }
    }
  }
  .overview {
    width: 35%;
    padding: 50px 10px 20px 10px;
    border: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      margin: 5px 0;
      font-weight: bold;
    }
    .heading {
      font-size: 1.6rem;
      text-align: center;
    }
    .number {
      border-bottom: 1px solid var(--grey);
      width: fit-content;
      padding-bottom: 10px;
      font-size: 3.5rem;
    }
    .price {
      font-size: 2rem;
      font-weight: normal;
    }
  }
  .info {
    width: 100%;
    margin: 60px 0 20px 0;
    color: var(--grey);
  }
}
.select-vehicles-container {
  width: 90%;
  padding: 0 5%;
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    .tab {
      width: 50%;
      text-align: center;
      font-weight: bold;
      color: var(--blue);
    }
    .compatible {
      border-bottom: 1px solid var(--blue);
    }
  }
  .selection {
    width: 20%;
  }
  .search-and-upload-container {
    display: flex;
    .search-container {
      width: 30%;
      border-right: 1px solid var(--light-grey);
      margin-right: 10px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      .input {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border: 1px solid var(--light-grey);
        padding: 0 5px;
        border-radius: 0.25rem;
        max-height: 25px;
        input {
          border: 0;
          width: 100%;
        }
      }
      .icon {
        width: 24px;
        height: 24px;
      }
    }

    button {
      max-height: 35px;
      min-height: 25px;
      font-weight: bold;
      border-radius: 0.25rem;
      width: fit-content;
      color: var(--blue);
      background: #fff;
      border: 1px solid var(--light-grey);
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }
}
.table-container {
  .table-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    color: var(--light-grey);
    margin-top: 20px;
    .selection {
      color: var(--blue);
      display: flex;
      justify-content: flex-end;
      width: 30%;
      .select-all {
        border-right: 1px solid var(--light-grey);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
}
.buttons {
  border-top: 1px solid var(--grey);
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  button {
    font-weight: bold;
    border: 0;
    border-radius: 0.25rem;

    &.pg-1 {
      color: var(--blue);
      background: #fff;
      margin-right: 10px;
    }
    &.pg-2 {
      background: var(--blue);
      color: #fff;
    }
    &.pg-1:disabled {
      color: var(--grey);
      background: #fff;
    }
    &.pg-2:disabled {
      border: 1px solid var(--grey);
    }
    &.cancel {
      width: 10%;
      color: var(--blue);
      margin-right: 10px;
      background: #fff;
      border: 1px solid var(--grey);
    }
  }
}

.select-vehicles {
  .vehicle-selection {
    padding: 15px 0;
  }
}
@media screen and (max-width: 845px),
  (max-width: 1120px) and (min-width: 970px) {
  .ivc-main {
    grid-template-columns: 1fr;
    grid-template-rows: auto 420px auto;
    margin: 0 10%;
  }
  .slideshow {
    grid-row: 2 / 3;
  }
}
@media screen and (max-width: 425px) {
  .ivc-main {
    margin: 0 5%;
  }
}

.check-compatibility {
  background-color: #f4f3f7;
  padding: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rhs {
  h3 {
    margin: 0 0 20px 0;
  }
  h4 {
    color: #171C26;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    text-transform: unset;
    margin: 0;
  }
  ul {
    margin: 0;
    padding-left: 16px;
  }
  .separator {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: #969FB2;
    opacity: 0.4;
  }
  p {
    margin: 10px 0;
    color: #606164;
    b {
      color: #0072c8;
    }
  }
}

.action-button {
  // margin-left: 8px;
  height: 30px;
  min-width: 100px;
  padding: 0 4px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  &:disabled {
    border-color: rgb(232, 232, 235);
    color: rgb(198, 198, 202);
  }
}

.field {
  position: relative;
  &.wide {
    grid-column: 1 / -1;
  }
  .icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 24px;
    fill: var(--grey);
    padding: 3px;
    &.search {
      left: unset;
      right: 0;
      border-left: 1px solid var(--pale-grey);
      padding-left: 1px;
    }
  }
  input, textarea {
    color: #76777a;
    font-family: inherit;
    border: 1px solid rgba(0, 28, 56, 0.24);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    padding: 0 8px;
    &::-webkit-calendar-picker-indicator {
      filter: contrast(0.001);
      transform: scale(1.25);
    }

    &.has-left-icon {
      padding-left: 32px;
    }
    &.has-right-icon {
      padding-right: 32px;
    }
  }
  textarea {
    height: 90px;
  }
}

.adapter-compat {
  color: #76777a;
  padding-top: 8px;
}
.add-vin-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 32px auto 64px auto;
  color: #606164;
  img {
    max-width: 280px;
  }
}

.terms-confirm {
  padding-top: 24px;
  color: #606164;
  b {
    color: #1a1c1e;
    &.link {
      color: var(--blue);
      font-weight: normal;
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 24px;
  }
  .btns {
    display: flex;
    margin-top: 160px;
    justify-content: flex-end;
    gap: 8px;
    border-top: 1px solid #e8e8eb;
    padding-top: 16px;
    .action-button {
      background-color: #e8e8eb;
      &.decline {
        background-color: #ffffff00;
        border: none;
      }
    }
  }
}
.blue {
  color: #0072C8;
}

// Modals
.add-modal, .cancel-modal {
  .modal-header {
    padding: 24px;
    font-size: 21px;
    line-height: 1.14;
    font-weight: bold;
  }
}
.add-modal {
  ::v-deep .content {
    width: 100%;
    max-width: 720px;
    border-radius: 4px;
  }
  .btn-close{
    margin-left: auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    svg {
      width: 14px;
    }
  }
}
.cancel-modal {
  ::v-deep .content {
    width: 100%;
    max-width: 503px;
    border-radius: 4px;
  }
  &.cancel-modal-2 ::v-deep .content {
    max-width: 400px;
  }
}
// Camera Info after it's added
.camera-info {
  position: relative;
  .dots {
    position: absolute;
    top: -5px;
    right: 55px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .my-cameras {
    margin-left: 8px;
    h4 {
      margin-bottom: 20px;
    }
  }
  .ivc-bar {
    margin-bottom: 14px;
  }
  p {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--dark);
  }
  .add-button {
    width: auto;
  }
}
</style>
