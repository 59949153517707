<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$store.commit('toggleModal')">
        <div
          class="modal-container"
          :class="{
            [$route.name]: true,
            invite:
              $route.name === 'vehicles-screen' ||
              $route.name === 'drivers-screen',
          }"
        >
          <header class="modal-header" v-if="currentModalPage === 1">
            <h3>
              <slot name="header"> </slot>
            </h3>
            <button v-show="x" class="x" @click="toggleModal">
              <svg
                class="x-icon"
                id="gml:icon1/dialog-close"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                ></path>
              </svg>
            </button>
          </header>
          <div
            class="gradient-divider short"
            v-if="
              $route.name !== 'in-vehicle-coaching' &&
                $route.name !== 'reports-screen' &&
                $route.name !== 'fleet-adapter'
            "
          />
          <section class="modal-body">
            <slot name="body"> </slot>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// show modal controlled in store/index.js
export default {
  props: {
    x: { type: Boolean, default: false },
    currentModalPage: { type: Number, default: 1 },
    // footer: { type: Boolean, default: false },
  },
  methods: {
    toggleModal() {
      this.$emit('closeModalManual');
      this.$store.commit('toggleModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    // @media screen and (max-width: 1112px) {
    //   vertical-align: unset;
    //   padding: 7vh 0;
    // }
    .modal-container {
      margin: 0px auto;
      background-color: #fff;
      border-radius: 0.25rem;
      box-shadow: 0 12px 40px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      max-height: 90vh;
      overflow-y: scroll;

      // &.invite {
      //   width: 25%;
      // }
      // &.vehicles-screen,
      // &.drivers-screen,
      // &.fleets {
      //   width: 35%;
      //   @media screen and (max-width: 1112px) {
      //     width: 70%;
      //     min-width: 70%;
      //   }
      //   @media screen and (max-width: 615px) {
      //     width: 90%;
      //     min-width: 90%;
      //   }
      // }
      // &.subscriptions,
      // &.reports-screen,
      // &.vehicle-detail {
      //   width: 53%;
      //   @media screen and (max-width: 1112px) {
      //     width: 90%;
      //   }
      // }
      // &.in-vehicle-coaching {
      //   width: 50%;
      //   @media screen and (max-width: 1112px) {
      //     width: 90%;
      //   }
      // }

      .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px 0px 30px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
        border-bottom: 1px solid #eaf6f999;

        h3 {
          font-family: ProximaNova;
          margin-top: 0;
          flex-grow: 1;
          text-align: left;
          color: #606164;
          font-size: 19px;
        }

        button.x {
          height: 24px;
          width: 24px;
          padding: 0;
          margin: 0;
          background: transparent;
          border: 0;
        }
      }

      .gradient-divider.short {
        height: 20px;
        min-height: 0;
      }
    }
  }
  &.ivc {
    .modal-container {
      width: 50%;
      padding: 24px;
    }
    .modal-body {
      padding: 0 30px;
    }
  }
}

.modal-body {
  padding: 0;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
