<template>
  <div
    class="notification-type-detail"
    :class="{ geofence: isGeofence }"
    v-if="types[selectedType]"
  >
    <div class="noti-detail-info">
      <h3>{{ types[selectedType].h3 }}</h3>
      <div v-if="isMobile && notifications.length > 0" />
      <div
        v-else-if="notifications.length > 0"
        class="add-btn action-button major"
        :class="{ disabled : types[selectedType].name !== 'Diagnostics' && inEssentialsMode }"
        :disabled="types[selectedType].name !== 'Diagnostics' && inEssentialsMode"
        @click="createNotification(selectedType.toString(), types[selectedType].name)"
      >
        <span class="plus">+</span>
        <span class="text">
          {{ types[selectedType].btnText ? types[selectedType].btnText : $t("message.createNew") }}
        </span>
      </div>
      <div v-else />
      <p v-if="types[selectedType].name === 'Diagnostics' || !inEssentialsMode" :style="isMobile || notifications.length > 0 ? '' : 'grid-column: 1 / 3;'">
        {{ types[selectedType].p }}
      </p>
      <p v-else>{{ types[selectedType].name }} notifications are disabled for your account. Upgrade now to enable all connectivity features!</p>
    </div>
    <div
      v-if="notifications.length === 0"
      class="flex column center"
      style="height: 100%"
    >
      <img class="noresults" src="@/assets/notifications.svg?external" />
      <span style="text-align: center">
        {{ types[selectedType].noResults }}
      </span>
      <div
        @click="createNotification(selectedType.toString(), types[selectedType].name)"
        class="action-button major"
        :class="{ disabled : types[selectedType].name !== 'Diagnostics' && inEssentialsMode }"
        :disabled="types[selectedType].name !== 'Diagnostics' && inEssentialsMode"
      >
        {{ types[selectedType].btnText ? types[selectedType].btnText : $t('message.createNotificationTrigger') }}
      </div>
    </div>
    <div v-else class="check-list">
      <div class="line head-line" />
      <div class="check-item" v-if="!isGeofence">
        <span class="head-lbl">{{ $t("message.notification") }}</span>
        <span class="head-lbl">{{ $t("message.myPreference") }}</span>
      </div>
      <div
        class="check-item"
        v-for="(item, index) of notifications"
        :key="'a' + index"
      >
        <img v-if="isGeofence" :src="require('@/assets/' + mapImages[item.geofence.mapId])" />
        <div
          class="noti-text clickable"
          @click="createNotification(selectedType.toString(), types[selectedType].name, item.id.toString())"
        >
          <div class="noti-title">
            {{ item.name }}
          </div>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
              />
              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
            </svg>
            {{ $t("message." + dateStr(item.lastUpdated)) }}
            {{ $t("message.by") }}
            <svg id="gfs:icon1/i-user" viewBox="0 0 24 24">
              <path
                d="M18.842 13.792a4.275 4.275 0 0 0-3.85-2.417h-1.259a3.95 3.95 0 0 0 2.225-3.542V7a3.958 3.958 0 1 0-7.916 0v.833a3.95 3.95 0 0 0 2.225 3.542H9.008a4.275 4.275 0 0 0-3.8 2.292L2 20.125h20l-3.158-6.333zm-9.55-5.959V7a2.708 2.708 0 1 1 5.416 0v.833a2.708 2.708 0 1 1-5.416 0zM6.25 14.392l.058-.1a3.042 3.042 0 0 1 2.7-1.667h5.984a3.042 3.042 0 0 1 2.733 1.725l2.225 4.525H4.042l2.208-4.483z"
              ></path>
            </svg>
            Juan Torres
          </p>
          <svg id="gfs:icon1/arrow-right" viewBox="0 0 24 24">
            <path
              d="M8.035 16.934a1.938 1.938 0 0 0 0 2.74h.14l7.499-7.498v-.142L8.176 4.533h-.142a1.935 1.935 0 0 0 0 2.737l4.832 4.831-4.83 4.833z"
            ></path>
          </svg>
        </div>
        <div class="noti-toggles" v-if="!isGeofence">
          <img src="@/assets/notifications-24-px.svg?external" />
          <ToggleButton
            v-model="item.inApp"
            :width="36"
            :color="{ checked: '#0072C8', unchecked: '#969FB3' }"
          />
          <img src="@/assets/envelope-24-px.svg?external" />
          <ToggleButton
            v-model="item.email"
            :width="36"
            :color="{ checked: '#0072C8', unchecked: '#969FB3' }"
          />
        </div>
        <!-- <div v-else class="line" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    ToggleButton,
  },
  props: {
    selectedType: Number,
    isMobile: Boolean,
  },
  data() {
    return {
      types: [
        null,
        {
          type: '1',
          name: this.$t('message.diagnostics'),
          storeKey: 'diagnosticNotifications',
          h3: this.$t("message.diagnostics"),
          p: this.$t("message.notifiesDiagnosticsDetected"),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.diagnostics")),
        },
        null,
        {
          type: '3',
          name: this.$t('message.maintenanceReminder'),
          storeKey: 'maintenanceReminderNotifications',
          h3: this.$t("message.maintenenceReminder"),
          p: this.$t("message.vehicleMaintenenceReminderBasedOnOdometer"),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.maintenanceReminder")),
        },
        null,
        null,
        null,
        {
          type: '7',
          name: this.$t('message.driverBehavior'),
          storeKey: 'driverBehaviorNotifications',
          h3: this.$t("message.driverBehavior"),
          p: this.$t("message.notifyWhenDriverBehaviorEvents"),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.driverBehavior")),
        },
        {
          type: '8',
          name: this.$t('message.geofence'),
          storeKey: 'geofenceNotifications',
          h3: this.$t("message.geofence") + 's',
          // p: this.$t("message.notifiesUponEntrance"),
          p: this.$t("message.geofencesAreGeographical"),
          noResults: this.$t("message.thereAreNoGeofences"),
          btnText: this.$t("message.createNewGeofence"),
        },
        {
          type: '9',
          name: this.$t('message.locationMasking'),
          storeKey: 'locationMaskingNotifications',
          h3: this.$t('message.locationMasking'),
          p: this.$t('message.notifiesWhenMaskingEnabledOrDisabled'),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.locationMasking")),
        },
        {
          type: '10',
          name: this.$t('message.vehicleUtilization'),
          storeKey: 'vehicleUtilizationNotifications',
          h3: this.$t('message.vehicleUtilization'),
          p: this.$t('message.noTripsTakenForNumberOfDays'),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.vehicleUtilization")),
        },
        {
          type: '11',
          name: this.$t('message.offHoursViolations'),
          storeKey: 'offHoursNotifications',
          h3: this.$t("message.offHoursViolations"),
          p: this.$t("message.notifiesWhenDrivingInRestrictedHours"),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.offHoursViolations")),
        },
        {
          type: '12',
          name: this.$t('message.drivingLimit'),
          storeKey: 'drivingLimitNotifications',
          h3: this.$t('message.drivingLimit'),
          p: this.$t('message.notifiesWhenExceededWeeklyDrivingLimit'),
          noResults: this.$t("message.noTriggers").replace("_____", this.$t("message.drivingLimit")),
        },
      ],
      mapImages: [
        'us-map-sample.png',
        'city-detroit.png',
        'county-wayne.png',
        'state-mi.png',
        'country-usa.png',
        'zip-midtown.png',
        'radius.png',
        'region-med-ctr.png',
      ],
    };
  },
  computed: {
    showNotificationDetails: {
      get() {
        return this.$store.state.notiSettingsDetails;
      },
      set(value) {
        this.$store.commit("setNotiSettingsDetails", value);
      },
    },
    notifications() {
      return this.$store.state[this.types[this.selectedType]?.storeKey];
    },
    isGeofence() {
      return this.selectedType === 8;
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
  methods: {
    createNotification(type, title, editing) {
      this.$router.push({
        name: "set-notification",
        params: { type, title, editing },
      });
    },
    dateStr(lastUpdated) {
      const now = new Date();
      const yesterday = new Date().setDate(now.getDate - 1);
      if (
        lastUpdated.getDate() == now.getDate() &&
        lastUpdated.getMonth() == now.getMonth() &&
        lastUpdated.getYear() == now.getYear()
      )
        return "today";
      else if (
        lastUpdated.getDate() == yesterday.getDate() &&
        lastUpdated.getMonth() == yesterday.getMonth() &&
        lastUpdated.getYear() == yesterday.getYear()
      )
        return "yesterday";
      else
        return (
          lastUpdated.toString().split(" ")[1] + " " + lastUpdated.getDate()
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-type-detail {
  background-color: var(--white);
  position: relative;
  padding-left: 20px;
}

.noti-detail-info {
  margin: 8px 0;
  margin-right: 0;
  display: grid;
  /* align-items: center; */
  grid-template-rows: auto auto;
  /* grid-template-columns: 125px 162px; */
  grid-template-columns: 1fr 133px;
  margin-bottom: 16px;
  column-gap: 8px;

  @media screen and (max-width: 1112px) {
    align-items: center;
  }
}

.noti-detail-info h3 {
  margin: 0;
  // margin-top: 16px;
}

.noti-detail-info p {
  margin: 0;
  margin-top: 2px;
  color: var(--dark-grey);
  /* grid-column: 1 / 3; */
  line-height: 19px;
}

.noti-detail-info svg {
  width: 21px;
  height: 21px;
  align-self: flex-end;
  margin-left: auto;
}

// .noti-item-list {
//   margin: 56px 20px 0 20px;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: repeat(auto-fill, 91px);
// }
// .noti-item {
//   display: grid;
//   height: 51px;
//   grid-template-rows: 19px 30px;
//   /* grid-template-columns: 240px 28px; */
//   grid-template-columns: 1fr 28px;
//   grid-auto-flow: column;
//   grid-gap: 2px;
//   margin-bottom: 40px;
// }
.noti-item p,
.check-item p {
  margin: 0;
  margin-top: 10px;
  font-size: 15px;
  line-height: 19px;
  color: var(--dark);
}

.noti-text {
  position: relative;
}

.noti-text.clickable {
  cursor: pointer;
  border-right: 1px solid var(--light-grey);
  margin-right: 4px;
  margin-top: -6px;
  margin-bottom: -6px;
  padding: 6px 0;
}

.noti-text.clickable p {
  color: var(--dark-grey);
  font-size: 12px;
}

.noti-text.clickable svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.noti-text.clickable p svg {
  position: unset;
  transform: unset;
  margin: 0 1px -5px 1px;
  width: 18px;
  height: 18px;
  fill: var(--dark-grey);
}

.check-item .noti-title {
  font-weight: 600;
}

.noti-item .noti-title {
  font-weight: 700;
}

// .noti-item .edit {
//   grid-row: 1 / 3;
//   align-self: center;
//   font-weight: 700;
//   color: var(--blue);
//   cursor: pointer;
// }
.line {
  width: calc(100% + 36px);
  height: 1px;
  background-color: var(--light-grey-2);
  margin: 0 -18px;
}

.action-button.major {
  // width: 192px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-top: 32px;
  padding: 0 16px;
  user-select: none;
  &.disabled {
    pointer-events: none;
    color: #c5bfc6;
    background-color: #e5e5e8;
    border-color: #e5e5e8;
  }
}

.action-button.save-speed {
  width: 130px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 500;
}

.action-button.save-speed.inactive {
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
  color: var(--grey);
  cursor: default;
}

.noti-detail-info .add-btn {
  // width: 130px;
  height: 25px;
  min-height: unset;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  margin: unset;
  /* align-self: flex-end; */
  align-self: center;
  justify-self: flex-end;
  user-select: none;
  &.disabled {
    pointer-events: none;
    color: #c5bfc6;
    background-color: #e5e5e8;
    border-color: #e5e5e8;
  }
}

.noti-detail-info .add-btn .text {
  font-weight: 600;
}

@media screen and (max-width: 1030px) {
  .noti-detail-info .add-btn {
    grid-row: 1 / 2;
    /* align-self: center; */
  }

  .noti-detail-info p {
    grid-column: 1 / 3;
  }

  .noti-detail-info h3 {
    margin-top: 0;
    align-self: center;
  }
}

.noti-detail-info .add-btn .plus {
  /* width: 12px;
  height: 12px; */
  font-size: 20px;
  margin-right: 11px;
  /* margin-top: 1px; */
}

@media screen and (max-width: 1030px) {
  .add-btn.action-button.major {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 48px;
    right: 16px;
    border-radius: 50%;
    padding: 0;
  }

  .add-btn.action-button.major .plus {
    margin-right: 0;
    font-size: 30px;
  }

  .add-btn.action-button.major .text {
    display: none;
  }

  .notification-type-detail {
    padding-left: 0;
  }
}

.noresults {
  width: 100%;
  max-width: 340px;
}

.noti-toggles {
  display: grid;
  grid-template-columns: 24px 36px;
  gap: 12px;
}

.check-list {
  margin-top: 24px;
}

.check-item {
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto 80px;
  grid-template-rows: auto;
  line-height: 18px;
  grid-gap: 10px;
  /* margin-left: 16px; */
  border-bottom: 1px solid #d1d6e0;
  align-items: center;
}

.check-item svg {
  width: 18px;
  cursor: pointer;
}

.check-item p {
  grid-column: 2 / 3;
}

.head-line {
  /* width: calc(100% - 16px); */
  width: 100%;
  margin-bottom: 12px;
  /* margin-left: 16px; */
  margin-left: 0;
}

.head-lbl {
  font-size: 12px;
  font-weight: 600;
  color: var(--dark-grey);
}

.geofence {
  .noti-detail-info {
    grid-template-columns: 1fr auto;
  }
  .check-list {
    .head-line {
      margin-bottom: 0;
    }
    .check-item {
      grid-template-columns: 80px 1fr;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .noti-text.clickable {
    border-right: none;
  }
}
</style>