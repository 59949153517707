<template>
  <div class="vehicles">
    <h1 v-if="!isMobile">{{$t('message.vehicles')}}</h1>
    <div v-else style="margin-top: 24px;" />
    <input
      class="search-box"
      :placeholder="$store.state.inDealerMode ? $t('message.labelMakeModelStockNumberVin') : $t('message.labelMakeModelVin')"
      v-model="searchQuery"
      type="text"
    />
    <div class="gradient-divider" />
    <DataTable
      id="vehicles-table"
      :data="filteredData"
      :columnsToShow="columnsToShow"
      :tags="tags"
      :upperIconsToShow="dataTableIconsOrder"
      @row-clicked="handleRowClick"
    >
      <template v-slot:image="{ obj }">
        <img
          :src="require('@/assets/' + obj.Image + '?external')"
          class="car-img"
        />
      </template>
      <template v-slot:tags="{ obj }">
        <TagsList :tags="obj.Tags" />
      </template>
      <template v-slot:status="{ obj }">
        <span class="data status">
          <div class="connected-dot" />
          {{ $t("message." + camelize(obj.Status)) }}
        </span>
      </template>
      <template v-slot:health="{ obj }">
        <HealthIcons
          v-if="obj.details && obj.details.diagnostics"
          :diagnostics="obj.details.diagnostics"
        />
      </template>
      <template v-slot:deliveryDate="{ obj }">
        <span class="data">{{ obj.details.deliveryDate }}</span>
      </template>
    </DataTable>
    <GuidedTourWaypoint :id="20" target="vehicles-table" :xOffset="-20" :yOffset="-60" />
  </div>
</template>

<script>
import DataTable from '@/components/demo/DataTable.vue';
import TagsList from '@/components/demo/TagsList.vue';
import HealthIcons from '@/components/demo/HealthIcons.vue';
import { camelize } from '../../utils/camelize';
import GuidedTourWaypoint from '../../components/demo/GuidedTourWaypoint.vue';

export default {
  components: { DataTable, TagsList, HealthIcons, GuidedTourWaypoint },
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  data() {
    return {
      camelize: camelize,
      searchQuery: '',
      columns: [
        {
          translationKey: 'message.image',
          slot: 'image',
          width: 100,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: 'message.vehicleLabel',
          dataKey: 'Vehicle Label',
          width: 115,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: 'message.modelName',
          dataKey: 'Model Name',
          width: 150,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.modelYear',
          dataKey: 'Model Year',
          width: 91,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.tags',
          slot: 'tags',
          dataKey: 'Tags',
          width: 100,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.status',
          slot: 'status',
          dataKey: 'Status',
          width: 125,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.condition',
          dataKey: 'Condition',
          width: 95,
          fleet: false,
          dealer: true,
          manuallyHidden: false,
          canFilter: true,
          selected: false,
        },
        {
          translationKey: 'message.stockNumber',
          dataKey: 'Stock',
          width: 110,
          fleet: false,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.vIN',
          dataKey: 'VIN',
          width: 125,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.licensePlate',
          dataKey: 'License Plate',
          width: 101,
          fleet: true,
          dealer: false,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.fleet',
          dataKey: 'Fleet',
          width: 125,
          fleet: true,
          dealer: false,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.geofence',
          dataKey: 'Geofence',
          width: 125,
          fleet: false,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.health',
          slot: 'health',
          width: 150,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
        {
          translationKey: 'message.deliveryDate',
          slot: 'deliveryDate',
          dataKey: 'details.deliveryDateISO',
          width: 125,
          fleet: false,
          dealer: true,
          manuallyHidden: false,
          // canFilter: true,
          // selected: false,
        },
      ],
      tags: [
        { text: "Services", value: "Services", color: "#024C52" },
        { text: "Car", value: "Car", color: "#FF6D00" },
        { text: "EV", value: "EV", color: "#EE5450" },
        { text: "SUV", value: "SUV", color: "#BA68C8" },
        { text: "Truck", value: "Truck", color: "#3B8E3F" },
      ],
      dataTableIconsOrder: [
        "download",
        "columns",
        "uploadFile",
        "batch",
        "commandTag",
        "addSolid",
      ]
    };
  },
  computed: {
    selectedFleet() {
      return this.$store.state.selectedFleet;
    },
    columnsToShow() {
      if (this.$store.state.inDealerMode) return this.columns.filter((obj) => obj.dealer);
      return this.columns.filter((obj) => obj.fleet);
    },
    filteredData() {
      let data = JSON.parse(JSON.stringify(!this.$store.state.inDealerMode
        ? this.dataset.vehicles
        : this.dataset.dealerModeVehicles));
      //filters by fleet
      if (this.selectedFleet.text === 'Fleet One') {
        data = data.filter((row) => row.details?.clickable); // optional chaining, not a typo
      } else if (this.selectedFleet.text === 'Fleet Two') {
        data = data.filter((row) => !row.details?.clickable);
      }

      if (this.searchQuery) {
        data = data.filter((row) => this.search(row));
      }

      if (this.$store.state.inDealerMode) {
        data.forEach((row) => {
          row["Model Name"] = `${row["Model Name"]} ${row.Trim}`
        });
      }
      return data;
    },
  },
  methods: {
    search(row) {
      for (let str of Object.values(row)) {
        if (
          str &&
          str
            .toString()
            .toLowerCase()
            .indexOf(this.searchQuery.toLowerCase()) !== -1
        )
          return true;
      }
      return false;
    },
    handleRowClick(vehicle) {
      if (vehicle.clickable || vehicle.details?.clickable) {
        this.$router.push('/1/detail/' + vehicle.id);
      }
    },
  },
  mounted() {
    if (this.$route.query.q) {
      this.searchQuery = this.$route.query.q;
      if (this.filteredData.length === 1 && this.filteredData[0].details?.clickable) {
        this.$router.replace('/1/detail/' + this.filteredData[0].id);
        console.log('we got here');
      }
    }
  },
};
</script>

<style scoped>
.vehicles {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
