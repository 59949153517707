<template>
  <div class="data-table">
    <div class="top-bar">
      <div>
        {{ data.length }} {{ $t("message.result")
        }}{{ data.length > 1 ? "s" : "" }}
      </div>
      <div class="icon-buttons">
        <button class="trash icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.6 2.9v2h4.314v1.2l-.173-.002c-.655.081-1.183.577-1.304 1.226l-.02.142-.009.098v-.006l-1.143 12.093c-.078.822-.768 1.449-1.593 1.449H8.328c-.826 0-1.516-.628-1.593-1.45L5.567 7.296c-.13-.631-.646-1.111-1.285-1.196H4V4.9h4.4v-2h7.2zm1.74 3.2H6.66l1.27 13.438c.02.205.192.362.398.362h7.344c.206 0 .378-.157.398-.362L17.34 6.1zM12.6 7.5v10.796h-1.2V7.5h1.2zm3 0l-.52 9.567c-.036.672-.591 1.197-1.264 1.197L14.401 7.5H15.6zm-6.028 0l.585 10.764c-.672 0-1.227-.526-1.263-1.197L8.375 7.5h1.198-.001zM14.4 4.1H9.6v.8h4.8v-.8z"
            />
          </svg>
        </button>
        <button class="edit icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="nonzero"
              d="M6.586 10.828l5.657-5.656-1.415-1.415-5.656 5.657 1.414 1.414zm0 0l-.54.54.54-.54zm-.79.624l-1.248-1.247-.624 1.87 1.871-.623zm7.154-6.988l.707-.707-1.414-1.414-.707.707 1.414 1.414zm0 0l-.707.708.707-.708zM12.243.93l2.828 2.828-8.485 8.486-4.243 1.414 1.414-4.243L12.243.93z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="table-wrap" v-if="headings.length">
      <div class="table">
        <!-- table header -->
        <div
          class="head-cell"
          v-for="(heading, index) of headings"
          :key="'a' + index"
        >
          <div v-if="heading === 'Check'" class="check-container">
            <svg
              v-if="headingChecked === true"
              class="check icon"
              @click="toggleAllChecks"
              id="heading-checked"
              height="18px"
              width="18px"
              viewBox="0 0 24 24"
              fill="var(--blue)"
            >
              <path
                d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
              />
            </svg>
            <svg
              class="check icon"
              @click="toggleAllChecks"
              id="heading-unchecked"
              height="18px"
              width="18px"
              viewBox="0 0 24 24"
              v-else
            >
              <path
                fill="var(--grey)"
                d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
              />
            </svg>
          </div>
          <div v-else>{{ heading }}</div>
        </div>
      </div>
      <div
        class="table"
        v-for="(obj, index) of data"
        @click="$emit('row-clicked', index)"
        :key="'A' + index"
      >
        <div class="body-cell" :class="{ checked: obj.checked }">
          <div
            @click="obj.checked = !obj.checked"
            class="icon-container check-container"
            v-if="obj.checked"
          >
            <div class="blue-bar"></div>
            <svg
              class="check icon"
              id="gml:icon/checkbox-checked"
              viewBox="0 0 24 24"
              fill="var(--blue)"
            >
              <path
                d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
              />
            </svg>
          </div>
          <div
            @click="obj.checked = !obj.checked"
            class="icon-container check-container"
            v-else
          >
            <svg
              class="check icon"
              id="gml:icon/checkbox-unchecked"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--grey)"
                d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
              />
            </svg>
          </div>
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Name"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Schedule Start Date"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Schedule End Date"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Report Type"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Repeat Every"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Report On"] }}
        </div>
        <div class="body-cell" :class="{ checked: obj.checked }">
          {{ obj["Created By"] }}
        </div>
      </div>
    </div>
    <small
      v-if="$store.state.inDealerMode"
      style="margin: 10px auto; color var(--grey;)"
      >Functionality not active in Dealer demo.</small
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          Name: this.$t("message.vehicleTrips"),
          "Schedule Start Date": this.$t("message.today"),
          "Schedule End Date":
            this.$t("message.after") + " 1 " + this.$t("message.occurences"),
          "Report Type": this.$t("message.vehicles"),
          "Repeat Every": this.$t("message.week"),
          "Report On": this.$t("message.monday"),
          "Created By": this.$t("message.christineDavis"),
          checked: false,
        },
      ],
      headings: [
        "Check",
        this.$t("message.name"),
        this.$t("message.scheduledStartDate"),
        this.$t("message.scheduledEndDate"),
        this.$t("message.reportType"),
        this.$t("message.repeatEvery"),
        this.$t("message.reportOn"),
        this.$t("message.createdBy"),
      ],
      headingChecked: false,
    };
  },
  props: {
    templateColumns: String,
    noClick: Boolean,
  },
  methods: {
    toggleAllChecks() {
      this.data = this.data.map((item) => {
        item.checked = !item.checked;
        return item;
      });
      this.headingChecked = !this.headingChecked;
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-top: 10px;
}
.top-bar {
  width: 90%;
  margin: 0 auto;
  color: var(--grey);
  display: flex;
  justify-content: space-between;
  .icon-buttons {
    button {
      background: none;
      border: 0;
      fill: var(--blue);
      svg {
        width: 16px;
        max-height: 16px;
      }
      &:hover {
        fill: var(--light-blue);
      }
    }
  }
  @media screen and (max-width: 1112px) {
    width: 98%;
  }
}

.table-wrap {
  width: 90%;
  border: 1px solid var(--light-grey-2);
  overflow: scroll;
  z-index: 1;
  margin: 0 auto;

  // hide scroll bars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  //chrome
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 1112px) {
    width: 98%;
  }
}

.table {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 40px repeat(7, 1fr);
  @media screen and (max-width: 615px) {
    //does not need to be adjusted for iPad
    grid-template-columns: 10vw repeat(7, 25vw);
  }
}

.table .head-cell {
  height: 39px;
  border-bottom: 1px solid var(--light-grey-2);
  background-color: #eaf6f9;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 13px;
  padding: 0 3px;

  &:first-child {
    padding: 0;
    justify-content: center;
  }
}

b {
  font-weight: 600;
}

.table .body-cell {
  height: 39px;
  font-size: 12px;
  border-bottom: 1px solid var(--light-grey-2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-all;
  color: #000;
  cursor: pointer;
  padding: 0 3px;

  &.checked {
    background: #eaf6f9;
  }
  &:first-child {
    justify-content: center;
    padding: 0;
    @media screen and (max-width: 1112px) {
      padding: unset;
    }
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  .icon {
    height: 15px;
    width: 15px;
  }
  .move-icon {
    transform: rotate(90deg);
  }
  .check-container {
    justify-content: flex-start;
    position: relative;
    padding-left: 8px; //width of the blue-bar + abs(left px)
    height: 100%;
    width: 100%;

    .blue-bar {
      width: 5px;
      height: 39px;
      padding: 0;
      margin: 0;
      background: var(--blue);
      position: absolute;
      left: -3px;
    }

    .check {
      height: 18px;
      width: 18px;
    }

    #heading-checked,
    #heading-unchecked {
      height: 18px;
      width: 18px;
    }
  }
}

.head-cell,
.body-cell {
}
</style>
