<template>
  <div>
    <div class="home-container">
      <img
        v-if="$i18n.locale !== 'fr-ca'"
        class="logo"
        src="@/assets/logo_stacked_right@2x.png"
        alt="logo"
      />
      <img
        v-if="$i18n.locale === 'fr-ca'"
        class="logo"
        src="@/assets/logo-twolines-right-white-fr.png?external?external"
        alt="logo"
      />
      <nav class="buttons-container">
        <router-link :to="fleetRoute"><button @click="setEssenialsMode(false)">DEMO - FLEET</button></router-link>
        <router-link to="/1"><button @click="setDealerMode">DEMO - DEALER</button></router-link>
        <router-link :to="fleetRoute"><button @click="setEssenialsMode(true)">DEMO - OnStar One Business Essentials</button></router-link>
        <!-- v-if="$i18n.locale === 'en' || $i18n.locale === 'en-CA'" -->
        <!-- <router-link to="/game"
          ><button>
            GAME
          </button>
        </router-link> -->
      </nav>
      <div class="bottom-border-container">
      </div>
      <div class="build-number">20240417.1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModeSelection",
  computed: {
    showGuidedTour() {
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === true) return true;
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === 'true') return true;
      return false;
    },
    fleetRoute() {
      if (this.showGuidedTour) return '/tour';
      return '/home';
    },
  },
  methods: {
    // setFleetMode() is not needed because going to /home already sets fleet mode using the router
    setDealerMode() {
      this.$store.commit("setEssenialsMode", false);
      this.$store.commit('setMode', 3);
    },
    setEssenialsMode(isEssentials) {
      this.$store.commit("setEssenialsMode", isEssentials);
    }
  },
  mounted() {
    this.$store.dispatch('setCamera', false);
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Gotham Book";
  src: url("../assets/fonts/Gotham-Book.woff2") format("woff2"),
    url("../assets/fonts/Gotham-Book.woff") format("woff");
}
.home-container {
  overflow: hidden !important;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2000;
  left: 0;
  background: #ffffff;
}
iframe {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: white;
  border: 0;
  position: absolute;
}
.logo {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}
/* @media screen and (max-height: 530px) {
  .logo {
    top: 10px;
    height: 100px;
  }
} */
.buttons-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button {
  font-size: 15px;
  font-family: "Gotham Book";
  padding-top: 1px;
  display: block;
  /* width: 150px; */
  width: 385px;
  height: 46px;
  background: #236292 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 23px;
  color: #ffffff;
  margin-bottom: 6px;
  cursor: pointer;
  text-transform: uppercase;
}
.close-btn {
  z-index: 2;
  position: absolute;
  top: 16px;
  left: 16px;
}
.bottom-border-container {
  position: absolute;
  bottom: 0px;
  width: 200%;
  height: 60px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  background: transparent linear-gradient(265deg, #0e293f 0%, #045ca0 100%) 0%
    0% no-repeat padding-box;
}
a {
  text-decoration: none;
}
.build-number {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 12px;
  opacity: 0.5;
}
</style>
