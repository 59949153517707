<template>
  <section class="section">
    <h2>Vehicle Availability</h2>
    <div class="data">
      <span>34</span>
      <span>/ 88</span>
    </div>
    <ViewMore>View Available Vehicles</ViewMore>
  </section>
</template>

<script>
import ViewMore from '@/components/demo/myba/ViewMore.vue';

export default {
  components: {
    ViewMore
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

section.section {
  font-family: $font-family-base;
}

.data {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  span {
    display: inline-block;
  }
  span:first-child {
    color: #0F1929;
    font-size: 56px;
    line-height: 1.5;
    font-weight: 800;
  }
  span:last-child {
    color: $accent-blue;
    font-size: 36px;
    line-height: 1.5;
    font-weight: 800;
  }
}
</style>