<template>
  <section class="section">
    <h2>Field Actions/Recalls</h2>
    <div class="data">
      <span>12</span>
      <span>Vehicles</span>
    </div>
    <ViewMore>View Available Vehicles</ViewMore>
  </section>
</template>

<script>
import ViewMore from '@/components/demo/myba/ViewMore.vue';

export default {
  components: {
    ViewMore
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

section.section {
  font-family: $font-family-base;
}

.data {
  span {
    text-align: center;
  }
  span:first-child {
    display: block;
    color: #D96503;
    font-size: 56px;
    line-height: 1;
    font-weight: 800;
  }
  span:last-child {
    display: block;
    color: $gray-6;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 800;
  }
}
</style>