<template>
  <div class="vehicle-behavior-widget">
    <div class="card-top">
      <span class="title">{{ title }}</span>
      <span class="dots"><img src="@/assets/more_horiz.svg?external" /></span>
    </div>
    <div class="card-body">
      <MeasureDaysSelect v-model="economyDays" />
      <div class="columns">
        <div class="column" v-for="column in data.columns" :key="column.icon">
          <div class="icon-container">
            <img :src="require(`@/assets/img/${column.icon}-icon.png`)" />
          </div>
          <div class="title">{{ column.title }}<br />({{ column.unit }})</div>
          <div class="value"><RouterLink v-if="column.link" :to="column.link">{{ column.value }}</RouterLink><span v-else>{{ column.value }}</span></div>
          <div class="change" :class="column.changeColor">
            <img v-if="column.changeColor === 'green'" src="@/assets/img/up-icon.png" />
            <img v-if="column.changeColor === 'red'" src="@/assets/img/down-icon.png" />
            {{ column.change }}
          </div>
          <div class="avg-title">Avg. Daily Per Vehicle</div>
          <div class="avg-value">{{ column.avgValue }}</div>
        </div>
      </div>
      <div class="total-vehicles">
        <IconCar class="icon-car" />
        Total Vehicles {{ data.totalVehicles }}
      </div>
      
    </div>
  </div>
</template>

<script>
// import IconChevronDown from "@/assets/icons/chevron-down.svg";
import IconCar from '@/components/icons/VehicleLgIcon.vue';
import MeasureDaysSelect from "@/components/demo/MeasureDaysSelect.vue";
export default {
  name: 'VehicleBehaviorWidget',
  components: {
    // IconChevronDown,
    IconCar,
    MeasureDaysSelect,
  },
  props: {
    vehicleType: {
      type: String,
      default: 'gas'
    }
  },
  data() {
    return {
      economyDays: 7,
      gasData: {
        columns: [
          {
            icon: 'distance',
            title: 'Distance Driven',
            unit: 'mi',
            value: '1.39K',
            change: 'Up by 67%',
            changeColor: 'green',
            avgValue: '19.8 mi',
            link: '/behavior-distance-driven'
          },
          {
            icon: 'fuel',
            title: 'Fuel Consuption',
            unit: 'gal',
            value: '76',
            change: 'Up by 47%',
            changeColor: 'green',
            avgValue: '1.1 gal',
            link: '/behavior-fuel-consumption'
          },
          {
            icon: 'efficiency',
            title: 'Efficiency',
            unit: 'mpg',
            value: '18',
            change: 'Up by 12%',
            changeColor: 'green',
            avgValue: '18 mpg',
            link: '/behavior-efficiency'
          }
        ],
        totalVehicles: 10
      },
      evData: {
        columns: [
          {
            icon: 'distance',
            title: 'Distance Driven',
            unit: 'mi',
            value: '1.45K',
            change: 'Down by 45%',
            changeColor: 'red',
            avgValue: '8.1 mi'
          },
          {
            icon: 'energy',
            title: 'Energy Consumption',
            unit: 'kWh',
            value: '234',
            change: 'Up by 96%',
            changeColor: 'green',
            avgValue: '1.3 kWh'
          },
          {
            icon: 'efficiency',
            title: 'Efficiency',
            unit: 'mi/kWh',
            value: '6',
            change: 'Down by 25%',
            changeColor: 'green',
            avgValue: '6 mi/kWh'
          }
        ],
        totalVehicles: 3
      }
    }
  },
  computed: {
    title() {
      return this.vehicleType === 'gas' ? 'Gas Powered Vehicle Behavior' : 'EV Behavior'
    },
    data() {
      return this.vehicleType === 'gas' ? this.gasData : this.evData
    }
  }
}
</script>

<style lang="scss" scoped>
.vehicle-behavior-widget {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  *, *:before, *:after {
    box-sizing: border-box;
  }
  .card-top {
    display: flex;
    justify-content: space-between;
    .title,
    .dots {
      font-family: ProximaNova;
      font-weight: 700;
      color: var(--dark);
    }
    .title {
      text-transform: uppercase;
    }
    .dots {
      line-height: 16px;
      display: flex;
      align-items: center;
    }
    .dots img {
      width: 13px;
      height: 13px;
    }
  }
}
.card-body {
  color: #76777a;
}
.card-subhead, .measure-days-select {
  font-size: 12px;
  color: #76777a;
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 12px;
  svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
  }
}
.columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
  .column:not(:last-child) {
    border-right: 1px solid #dbdfe2;
    padding-right: 8px;
  }
}
.column {
  .icon-container {
    margin-bottom: 8px;
  }
  .title {
    height: calc(3 * calc(calc((15 + 6) / 16 * 1rem)));
    margin-bottom: 8px;
  }
  .value, .value a {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #0372c8;
    text-decoration: none;
    &:hover {
      color: #52b2df;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .change {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    img {
      margin-right: 8px;
    }
  }
  .avg-title {
    font-size: 12px
  }
  .avg-value {
    font-size: 15px;
    font-weight: 700;
  }
}
.total-vehicles {
  background-color: #f4f3f7;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-weight: 700;
  .icon-car {
    fill: currentColor;
    height: 18px;
    margin-right: 7.5px;
  }
}
</style>