<template>
  <div id="app" @click="handleTouch">
    <!-- if using demo route, display sidebar and top bar -->
    <div
      v-if="$route.meta.showNavigation"
      class="flex"
      :style="$route.name.includes('map-screen') ? 'height: 100%;' : ''"
    >
      <SideBar
        v-if="enableSidebar"
        :shown="sidebarVisible"
        @toggle="sidebarVisible = false"
        :isMobile="isMobile || isTablet"
        @hide-notifications="showNotificationsPanel = false"
      />
      <div
        class="overlay"
        v-if="(isMobile || isTablet) && sidebarVisible"
        @click="sidebarVisible = false"
      />
      <div
        class="flex main"
        :class="{ 'sidebar-space': sidebarVisible && !isMobile && !isTablet }"
      >
        <TopBar
          :showSidebar="sidebarVisible"
          @toggle-sidebar="sidebarVisible = true"
          @toggle-notifications="showNotificationsPanel = !showNotificationsPanel"
          :isMobile="isMobile || isTablet"
          :notifications="dataset.notifications"
        />
        <router-view
          :isMobile="isMobile || isTablet"
          :isTablet="isTablet"
          :dataset="dataset"
          :sidebarVisible="sidebarVisible"
        />
        <NotificationsPanel
          :notifications="dataset.notifications"
          class="notifications-panel"
          :class="{ visible: showNotificationsPanel }"
          @done="showNotificationsPanel = false"
        />
        <GuidedTourWaypoint
          v-if="showNotificationsTarget"
          :id="2"
          target="gfs:icon1/i-settings"
          :xOffset="60"
          :yOffset="75"
          :initRotation="180"
          :zIndex="10000"
        />
      </div>
    </div>
    <!-- otherwise, just show the route directly -->
    <div v-else class="game">
      <router-view
        :shown="sidebarVisible"
        :isMobile="isMobile"
      />
    </div>
    <Metrics
      class="metrics"
      :metrics="metrics"
      v-if="showMetrics"
      @hideMetrics="hideMetrics()"
    ></Metrics>
  </div>
</template>

<script>
import SideBar from "@/components/demo/SideBar.vue";
import TopBar from "@/components/demo/TopBar.vue";
import sampleData from "./data.js";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { directive as onClickOutside } from "vue-on-click-outside";
import VuePlyr from "vue-plyr";
import { Capacitor } from '@capacitor/core';
import Metrics from "@/views/metrics/Metrics.vue";
import NotificationsPanel from "./components/demo/NotificationsPanel.vue";
import GuidedTourWaypoint from "./components/demo/GuidedTourWaypoint.vue";
import { connectLocalDB, createLocalTable, disconnectLocalDB } from "@/utils/localMetrics.js";

Vue.directive("on-click-outside", onClickOutside);
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ["ended"],
});
Vue.use(VueI18n);

export default {
  name: "App",
  components: { SideBar, TopBar, Metrics, NotificationsPanel, GuidedTourWaypoint },
  data() {
    return {
      enableSidebar: false,
      showNotificationsPanel: false,
      showNotificationsTarget: false,
      displayWidth: -1,
      // metrics: [],
      metrics: [{type:"demo", events: [{id: 1581714571127, page: "Map"}, {id: 1582041087613, page: "Vehicles"}, {id: 1582041087613, page: "Map"}, {id: 1582041087613, page: "Performers"}]}, {type: "game", events: []}, {type:"demoSession", sessions: [{id: 1581714571127, seconds: 25}, {id: 1582041087613, seconds: 60}]}],
      showMetrics: false,
      gesture: {
        ready: true,
        startTimeout: -1,
        count: 0,
        endTimeout: -1,
      }
    };
  },
  computed: {
    dataset() {
      return sampleData;
    },
    isMobile() {
      return this.displayWidth <= 615;
    },
    isTablet() {
      return this.displayWidth <= 1112 && !this.isMobile;
    },
    sidebarVisible: {
      get() {
        return this.$store.state.sidebarVisible;
      },
      set(val) {
        this.$store.commit('setSidebarVisible', val);
      },
    },
  },
  methods: {
    handleTouch() {
      // intentionally left empty
      // this will prevent double-tap zoom on ios
    },
    handleMultiTouch(e) {
      if (!Capacitor.isNativePlatform()) return;
      const numFingerGesture = 3; // 2 for simulator test, 3 otherwise
      const tapsNeeded = 2;
      if (e.touches.length === numFingerGesture) {
        // Only register a tap if 100ms since last tap
        // This is to prevent duplicate touchstart events from registering multiple taps
        if (!this.gesture.ready) return;
        console.log('gesture tap registered');
        this.gesture.ready = false;
        clearTimeout(this.gesture.startTimeout);
        this.gesture.startTimeout = setTimeout(() => {
          this.gesture.ready = true;
        }, 100);
        // If the gesture has been completed, show metrics page
        this.gesture.count += 1;
        if (this.gesture.count >= tapsNeeded) {
          window.scrollTo(0, 0);
          this.showMetrics = true;
          this.gesture.count = 0;
        }
        // Gesture must be completed within 500ms timeframe
        clearTimeout(this.gesture.endTimeout);
        this.gesture.endTimeout = setTimeout(() => {
          this.gesture.count = 0;
        }, 500);
      }
    },
    // this method gets called by the iphone/ipad that is running the app when a specific gesture is performed on the screen
    getMetrics(results) {
      results.forEach((element) => {
        this.metrics.push(element);
      });
      this.showMetrics = true;
    },
    hideMetrics() {
      this.showMetrics = false;
      this.router.push("/");
    },
    updateDisplayWidth() {
      if (window.visualViewport) {
        this.displayWidth = window.visualViewport.width;
      } else {
        this.displayWidth = window.innerWidth;
      }
    },
    async setUpLocalDB() {
      console.log('TestDB Running!');
      await connectLocalDB();
      await createLocalTable();
      // await localMetrics.deleteAllRecords();
      // await localMetrics.addRecord('6936675f-2785-47d5-9bdc-7fd29c92dd11', '196174fa-4df3-4fa9-bf0e-f26d412396aa', 'testAction', 'testValue');
      // const { values } = await localMetrics.getRecords();
      // if (!values.length) {
      //   console.log('No records saved');
      //   return;
      // }
      // console.log(values.slice(1));
    },
  },
  mounted() {
    if (Capacitor.isNativePlatform()) {
      this.setUpLocalDB();
    }
    this.updateDisplayWidth();
    if (!this.isMobile) {
      this.sidebarVisible = true;
    }
    // we only render the sidebar after we decide if it will start as visible or not
    // this ensures the animation won't run on the initial state change from true -> false
    this.enableSidebar = true;
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateDisplayWidth);
    });
    window.addEventListener("touchstart", this.handleMultiTouch);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDisplayWidth);
    window.removeEventListener("touchstart", this.handleMultiTouch);
    if (Capacitor.isNativePlatform()) {
      disconnectLocalDB();
    }
  },
  watch: {
    timeLeft: function () {
      if (this.timeLeft <= 0) {
        this.timesUp();
      }
    },
    showNotificationsPanel() {
      if (this.showNotificationsPanel) {
        setTimeout(() => {
          this.showNotificationsTarget = true;
        }, 500);
      } else {
        this.showNotificationsTarget = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/modals-alerts';
@import '@/assets/scss/buttons';
@import '@/assets/scss/tables';
@font-face {
  font-family: ProximaNova;
  font-weight: 100;
  src: url("assets/fonts/ProximaNovaW05-Light.ttf");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 400;
  src: url("assets/fonts/ProximaNovaW05-Regular.ttf");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 600;
  src: url("assets/fonts/ProximaNovaW05-Semibold.ttf");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 700;
  src: url("assets/fonts/ProximaNovaW05-Bold.ttf");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 800;
  src: url("assets/fonts/ProximaNovaW05-Extrabold.ttf");
}
@font-face {
  font-family: ComicBookCommandoBold;
  src: url("assets/fonts/Comicv3b.ttf");
}
@font-face {
  font-family: GothamBold;
  src: url("assets/fonts/GothamNarrow-Bold.ttf");
}
@font-face {
  font-family: GothamBook;
  src: url("assets/fonts/GothamNarrow-Book.ttf");
}
@font-face {
  font-family: Overpass;
  font-weight: 400;
  src: url("assets/fonts/overpass-regular.otf");
}
@font-face {
  font-family: Overpass;
  font-weight: 600;
  src: url("assets/fonts/overpass-semibold.otf");
}
@font-face {
  font-family: Overpass;
  font-weight: 800;
  src: url("assets/fonts/overpass-extrabold.otf");
}
@font-face {
  font-family: Overpass;
  font-weight: 900;
  src: url("assets/fonts/overpass-heavy.otf");
}

html,
body,
#app {
  margin: 0;
  font-family: ProximaNova;
  -webkit-font-smoothing: antialiased;
  touch-action: pan-y;
  user-select: none;
  color: var(--dark);
  font-size: 15px;
  height: 100%;
}
* {
  -webkit-font-smoothing: antialiased;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  --black: #000000;
  --dark: #171c26;
  --dark-grey: #71798a;
  --grey: #969fb3;
  --light-grey: #c1c8d6;
  --light-grey-2: #d1d6e0;
  --pale-grey: #e6e8ed;
  --white: #ffffff;
  --blue: #0072c8;
  --dark-blue: #00518f;
  --light-blue: #05b5e4;
  --clear-blue: #EDF9FF;
  --light-light-blue: #eaf6f9;
  --green: #1cd8aa;
  --orange: #ffa840;
  --yellow: #fed43b;
  --red: #ff4f72;
  --font-family-base: ProximaNova;
}

h1,
.h1 {
  font-weight: 800;
  font-size: 40px;
  margin: 24px;
  line-height: 1;
}

h2,
.h2 {
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
}

h3,
.h3 {
  font-weight: 700;
  font-size: 21px;
  line-height: 23px;
}

h4,
.h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  margin: 16px;
  color: var(--dark);
}
h4.has-subtitle,
.h4.has-subtitle {
  margin-bottom: 0;
  line-height: 16px;
}

.bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.subtitle {
  color: var(--dark-grey);
  margin: 0;
  margin-left: 16px;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

.large-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.semibold {
  font-weight: 600;
  font-size: 15px;
}
.small.semibold {
  font-size: 13px;
  line-height: 17px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.clickable {
  cursor: pointer;
}

mark {
  background: none;
  font-weight: 800;
  color: #ff5433;
}

.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.center {
  align-items: center;
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.debug {
  border: 1px solid red;
}
.w-100 {
  width: 100%;
}
.height-full {
  height: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.red {
  color: var(--red);
}
.orange {
  color: var(--orange);
}
.green {
  color: var(--green);
}

.main {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.main.sidebar-space {
  width: calc(100% - 275px);
  margin-left: 275px;
}

/* Divider on most section pages */
.gradient-divider {
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 100%);
  width: 100%;
  height: 50px;
  min-height: 50px;
  /* z-index: 2; */
}

/* Search box on pages with tables */
.search-box,
.search-box[type="text"],
.search-box[type="date"],
.search-box[type="time"] {
  border: solid 1px var(--light-grey-2);
  width: 516px;
  max-width: 70%;
  height: 26px;
  flex-shrink: 0;
  font-family: ProximaNova;
  color: var(--dark);
  font-size: 15px;
  font-weight: 400;
  padding: 0 8px 0 12px;
  margin-bottom: 24px;
  border-radius: 4px;
  appearance: none;
}
.search-box:focus {
  border: solid 1px var(--blue);
}
.search-box::placeholder {
  color: var(--grey);
}

/* Animations for sidebar buttons */
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 17%, transparent 20.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.4s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.5;
  transition: 0s;
}

/* Detail pages formatting */
.details-top-grid {
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 300px;
  row-gap: 40px;
  grid-template-rows: 260px 70px;
}
/* .info-wrap {
  margin-top: 36px;
  margin-bottom: 40px;
  min-height: 182px;
} */
.sections {
  margin: 0 0 24px 0;
  display: flex;
}
.section-btn {
  height: 30px;
  /* display: flex;
  flex-direction: column; */
  padding: 0 16px;
  font-size: 15px;
  font-weight: 700;
  line-height: 34px;
  color: var(--dark);
  cursor: pointer;
}
.section-btn.selected {
  color: var(--light-blue);
  border-bottom: 2px solid var(--light-blue);
}
.section-btn.disabled {
  color: var(--light-grey);
  cursor: not-allowed;
}
.info-grid {
  display: grid;
  grid-template: repeat(auto-fit, 26px) / 180px 200px;
  grid-gap: 8px 16px;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin-right: 16px;
}
.info-grid span {
  color: var(--dark-grey);
  font-size: 15px;
}
.info-grid b, 
.vehicle-img-and-tags b {
  font-weight: 600;
  font-size: 15px;
}
.info-grid b {
  line-height: 15px;
  color: #606164;
  font-weight: 500;
}
.info-grid b .blue {
  font-weight: 600;
}
.button-grid {
  display: grid;
  grid-gap: 8px;
  grid-template: 30px / repeat(5, max-content);
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}
.button-grid div {
  /* width: 100%; */
  /* max-width: 147px; */
  padding: 0 16px;
  box-sizing: border-box;
  /* height: 100%; */
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.button-grid div.inactive {
  color: var(--light-grey);
}
// @media screen and (max-width: 1130px) {
//   .details-top-grid {
//     grid-template-columns: 1fr 167px;
//     grid-template-rows: 1fr 1fr;
//     row-gap: 0px;
//     margin-bottom: 30px;
//   }
//   .details-top-grid img.fleet {
//     grid-column: 1 / 3;
//     margin-top: unset;
//     align-self: center;
//     justify-self: center;
//   }
//   .info-grid {
//     grid-row: unset;
//     grid-column: unset;
//   }
//   .button-grid {
//     grid-template: repeat(5, 30px) / 167px;
//     grid-row: unset;
//     grid-column: unset;
//   }
// }
@media screen and (max-width: 1112px) {
  .details-top-grid {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    gap: 16px;
  }
  .details-top-grid img.fleet {
    grid-column: 1 / 2;
  }
  .info-grid {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    grid-template: unset;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin: 0 15% 0 30%;
  }
  .info-grid span {
    font-weight: 600;
  }
  .button-grid {
    grid-template: repeat(5, 30px) / 70%;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    justify-content: center;
  }
  .hide-on-mobile {
    display: none;
  }
}
@media screen and (max-width: 615px) {
  .info-grid {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    grid-template: unset;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin: 0 5%;
  }
}
.border-line {
  width: 80%;
  height: 1px;
  background-color: var(--light-grey);
  opacity: 0.4;
}
.placeholder {
  width: 100%;
  height: 100%;
  border: 4px solid black;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
}

.ti-tag {
  background: var(--pale-grey);
  border-radius: 0.25rem;
  max-height: 20px;
  margin: 0 2px 4px 0;
  padding: 0 4px 0 2px;
}

.vue-tags-input[data-v-61d92e31] {
  width: 100%;
  min-width: 100%;
  .ti-input[data-v-61d92e31] {
    border: 1px solid var(--pale-grey);
  }
  .ti-autocomplete {
    & .ti-selected-item {
      // background: red;
      background: #eaf6f9;
      color: #000;
    }

    .ti-item {
      div {
        padding-bottom: 5px;
      }
      & > div::before {
        content: "";
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        position: relative;
        top: 0.75px;
      }

      &.bg-024C52 > div::before {
        content: "";
        background: #024c52;
      }

      &.bg-FF6D00 > div::before {
        background: #ff6d00;
      }

      &.bg-EE5450 > div::before {
        background: #ee5450;
      }

      &.bg-BA68C8 > div::before {
        background: #ba68c8;
      }

      &.bg-3B8E3F > div::before {
        background: #3b8e3f;
      }
    }
  }
}
.vue-tags-input li.ti-new-tag-input-wrapper {
  padding: 0;
  input {
    caret-color: var(--light-blue);
  }
  input::placeholder {
    color: #fff;
  }
}
.vue-tags-input .ti-tags .ti-tag {
  background: var(--pale-grey);
  color: #000;
  border-radius: 0.25rem;
  max-height: 20px;
  margin: 0 2px 0px 0;
  padding: 0 4px 0 2px;
  .ti-content::before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    margin: 0 3px 2px 1px;
  }

  &.bg-024C52 > .ti-content::before {
    content: "";
    background: #024c52;
  }

  &.bg-FF6D00 > .ti-content::before {
    background: #ff6d00;
  }

  &.bg-EE5450 > .ti-content::before {
    background: #ee5450;
  }

  &.bg-BA68C8 > .ti-content::before {
    background: #ba68c8;
  }

  &.bg-3B8E3F > .ti-content::before {
    background: #3b8e3f;
  }
}
.notifications-panel {
  transform: translateX(400px);
  transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);;
}
.notifications-panel.visible {
  transform: translateX(0);
}

.head-cell.table-popup {
  display: none;
}

.table-popup {
  display: block;
  padding: 0;
  background: #fff;
  z-index: 5000;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  position: absolute;
  border-radius: 4px;
  box-shadow: rgba(23, 28, 38, 0.2) 0px 1px 5px 0px,
    rgba(23, 28, 38, 0.14) 0px 2px 2px 0px,
    rgba(23, 28, 38, 0.12) 0px 3px 1px -2px;
  width: fit-content;
  top: 25px;
  // ;;;;;;
  .tag-row.selected {
    background: #eaf6f9;
  }
  > p {
    font-size: 12px;
    padding: 10px;
    margin: 0;
  }
  > p:hover,
  .reset-default-columns:hover,
  li:hover {
    background: #eaf6f9;
  }
  
  &.batch {
    width: fit-content;
    min-width: fit-content;
    > p {
      min-width: 95px;
    }
  }
  
  &.columns {
    > ul {
      list-style: none;
      padding: 5px;
      margin: 0;
      width: auto;
      border-bottom: 1px solid var(--grey);
      max-height: 180px;
      overflow-y: scroll;
    }
    > ul > li {
      font-size: 12px;
      display: flex;
      min-width: 150px;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: space-between;
    }
    > ul > li > div.checkbox > svg {
      padding: 0;
      margin: 0;
      height: 16px;
      width: 16px;
      padding-right: 3px;
    }
    > ul > li > p.heading {
      flex-grow: 1;
      padding: 1px 0 5px 0;
      margin: 0;
    }
    > .reset-default-columns > p {
      padding: 5px;
      margin: 0;
      font-weight: bold;
      font-size: 14px;
    }
  }
  
  .lock.icon {
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
  }
  
  &.tag {
    .tag-row {
      padding: 0 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        margin: 0 !important;
      }
      span.tag {
        padding-top: 5px;
      }
      &:hover {
        background: #eaf6f9;
      }
    }
  }
}
.underline {
  text-decoration: underline;
}
</style>
