<template>
  <div class="manager-details">
    <h1>{{ obj["Name"] }}</h1>
    <div class="sections">
      <div
        class="section-btn"
        :class="{ selected: page === 1 }"
        @click="page = 1"
      >
        Overview
      </div>
      <div
        class="section-btn"
        :class="{ selected: page === 2 }"
        @click="page = 2"
      >
        Delivery Preferences
      </div>
    </div>
    <div class="gradient-divider" />

    <!-- Overview page -->
    <div class="section" v-if="page === 1">
      <div class="profile-picture-wrap">
        <div class="circle">{{ initials }}</div>
        <b>{{ obj["Name"] }}</b>
        <span>Joined Oct 16, 05:25 PM</span>
      </div>
      <div class="flex info-wrap">
        <div class="info-grid">
          <span>Email</span>
          <b class="blue">{{ obj["Email"] }}</b>
          <span>Mobile Number</span>
          <b class="blue">{{ obj["Phone"] }}</b>
          <span>Address</span>
          <b>{{ obj["Address"] }}</b>
          <span>Time Zone</span>
          <b>Detroit</b>
        </div>
        <div class="button-grid vert">
          <div>Edit Info</div>
          <div>Change Password</div>
        </div>
      </div>
      <div class="border-line" />
    </div>

    <!-- Trip History page -->
    <div
      style="width: 80%; color: var(--dark-grey); font-size: 15px"
      v-else-if="page === 2"
    >
      Configure how to receive notifications in addition to the Notifications
      Center
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    dataset: Object,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    index() {
      return parseInt(this.id);
    },
    obj() {
      return this.dataset.managers[this.index];
    },
    initials() {
      let first;
      let second;
      first = this.obj["Name"].charAt(0);
      second = this.obj["Name"].split(" ")[1].charAt(0);
      return first + second;
    },
  },
  mounted() {
    this.$route.params.title = this.obj["Name"];
  },
};
</script>

<style scoped>
.manager-details,
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-picture-wrap {
  width: 218px;
  height: 130px;
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 16px;
  background-color: var(--pale-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-picture-wrap b {
  font-weight: 700;
  font-size: 15px;
  line-height: 12px;
  margin-top: 11px;
  color: var(--dark);
}
.profile-picture-wrap span {
  margin-top: 2px;
  font-size: 13px;
  color: var(--dark-grey);
}
.circle {
  background-color: #ee544f;
  width: 88px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 34px;
  line-height: unset;
  font-weight: 700;
  padding-top: 3px;
}
img.notifications {
  max-width: 360px;
  max-height: 187px;
  margin-top: 24px;
}
.button-grid.vert {
  grid-template: 30px 30px / 155px;
}
.blue {
  color: var(--blue);
}
</style>