<template>
  <div v-if="mini || searchResults" class="ev-charger-mouseover" :class="{search: searchResults}" @click="$emit('click')">
    <div class="charger-location-details">
      <BaseIcon name="chargerPin" class="img" :pathClass="{ available: !usedPorts, unknown: charger.unknown }" />
      <span class="subtitle">{{charger.brand}}</span>
      <h4>{{charger.name}}</h4>
      <span>{{charger.location}}</span>
    </div>
    <div class="port-status port-counter">
      <div class="port-status-circle" :class="{available: !usedPorts, unknown: charger.unknown}" />
      <span v-if="searchResults">
        <span v-if="charger.unknown">Unknown</span>
        <span v-else-if="usedPorts < charger.ports.length">Available</span>
        <span v-else>In Use</span>
      </span>
      <span v-else>
        <span v-if="charger.unknown">{{charger.ports.length}}/{{charger.ports.length}} Ports Unknown</span>
        <span v-else>{{usedPorts}}/{{charger.ports.length}} Ports in Use</span>
      </span>
    </div>
  </div>
  <div class="ev-charger-info" v-else>
    <div class="top-bar">
      <b>Station Details</b>
      <img src="@/assets/clear.svg?external" @click="$emit('done')" />
    </div>
    <div class="charger-location-details">
      <BaseIcon name="chargerPin" class="img" :pathClass="{ available: !usedPorts, unknown: charger.unknown }" />
      <span class="subtitle">{{charger.brand}}</span>
      <h4>{{charger.name}}</h4>
      <span>{{charger.location}}</span>
    </div>
    <div class="port-status port-counter">
      <div class="port-status-circle" :class="{available: !usedPorts, unknown: charger.unknown}" />
      <span v-if="charger.unknown">{{charger.ports.length}}/{{charger.ports.length}} Ports Unknown</span>
      <span v-else>{{usedPorts}}/{{charger.ports.length}} Ports in Use</span>
    </div>
    <div class="ports">
      <div class="port" v-for="(port, i) of charger.ports" :class="{unknown: charger.unknown}" :key="i">
        <BaseIcon name="J1772" />
        <span>{{port.type}}</span>
        <span class="minor-text">{{port.rating}}</span>
        <span class="minor-text">{{port.cost}}</span>
        <div class="port-status">
          <div class="port-status-circle" :class="{available: port.available, unknown: charger.unknown}" />
          <span>{{port.available ? 'Available' : charger.unknown ? 'Unknown' : 'In Use'}}</span>
        </div>
      </div>
    </div>
    <div class="more-charger-info">
      <Icon v-if="charger.hours" :icon="icons.alarmIcon" class="icon" />
      <span v-if="charger.hours">{{charger.hours}}</span>
      <Icon v-if="charger.paymentMethods" :icon="icons.creditCard" class="icon" />
      <span v-if="charger.paymentMethods">Supported payment methods: {{charger.paymentMethods}} Accepted</span>
      <Icon v-if="charger.phone" :icon="icons.phoneIcon" class="icon" />
      <span v-if="charger.phone">{{charger.phone}}</span>
      <Icon v-if="charger.website" :icon="icons.webIcon" class="icon" />
      <span v-if="charger.website">{{charger.website}}</span>
    </div>
    <div class="report-problem-button">
      <BaseIcon name="exclamationMark" />
      <span>Report a Problem</span>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import alarmIcon from '@iconify/icons-mdi/alarm';
import creditCard from '@iconify/icons-mdi/credit-card-outline';
import phoneIcon from '@iconify/icons-mdi/phone';
import webIcon from '@iconify/icons-mdi/web';
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  components: {
    Icon,
    BaseIcon,
  },
  props: {
    charger: Object,
    mini: Boolean,
    searchResults: Boolean,
  },
  data() {
    return {
      icons: {
        alarmIcon,
        creditCard,
        phoneIcon,
        webIcon,
      }
    }
  },
  computed: {
    usedPorts() {
      let count = 0;
      for (let i = 0; i < this.charger.ports.length; i += 1) {
        if (this.charger.ports[i].available) count++;
      }
      return this.charger.ports.length - count;
    },
  }
}
</script>

<style lang="scss" scoped>
.ev-charger-info {
  margin: 24px;
  width: 360px;
  height: calc(100% - 48px);
  overflow-y: scroll;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgb(23 28 38 / 20%), 0 2px 2px 0 rgb(23 28 38 / 14%), 0 3px 1px -2px rgb(23 28 38 / 12%);
  padding: 16px 28px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1001;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  b {
    color: var(--dark);
    font-size: 16px;
    font-weight: 700;
    margin-left: -12px;
  }
  img {
    width: 10px;
    cursor: pointer;
    // padding: 0 2px;
  }
}
.charger-location-details {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: auto auto auto;
  gap: 5px 10px;
  padding: 18px 12px;
  border-bottom: 1px solid var(--light-grey-2);
  .img {
    grid-row: 1 / 4;
    margin-left: -12px;
  }
  .subtitle {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
}
.port-status {
  display: grid;
  grid-template-columns: 8px auto;
  align-items: center;
  gap: 8px;
  &.port-counter {
    margin: 12px 8px;
  }
  .port-status-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--dark-blue);
    &.available {
      background-color: var(--green);
    }
    &.unknown {
      background-color: var(--dark-grey);
    }
  }
  span {
    color: var(--dark-grey);
  }
}
.ports {
  padding: 0 16px;
  .port {
    padding: 12px 0;
    border-bottom: 1px solid var(--light-grey-2);
    display: grid;
    grid-template-rows: 19px 19px 24px 19px;
    grid-template-columns: 24px auto;
    gap: 4px 20px;
    &.unknown {
      grid-template-rows: 19px;
      svg {
        grid-row: 1 / 3;
      }
    }
    svg {
      grid-row: 1 / -1;
      width: 24px;
      fill: var(--dark-grey);
      
    }
    .minor-text {
      color: var(--dark-grey);
    }
  }
}
.more-charger-info {
  margin-top: 16px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 16px 12px;
  align-items: center;
  .icon {
    width: 24px;
    height: 24px;
    color: var(--dark-grey);
    background-color: var(--light-grey-2);
    border-radius: 50%;
  }
}
.report-problem-button {
  width: 100%;
  height: 30px;
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-weight: 600;
  border-radius: 4px;
  svg {
    width: 18px;
    fill: var(--blue);
    margin-right: 8px;
  }
}
.ev-charger-mouseover {
  width: 300px;
  // height: 150px;
  background-color: white;
  position: absolute;
  bottom: 64px;
  left: -116px;
  z-index: 20;
  &.search {
    position: unset;
    bottom: unset;
    left: unset;
    background-color: inherit;
    font-size: 13px;
    width: 100%;
    padding: 12px 0 4px 0;
    border-bottom: 1px solid var(--light-grey-2);
    cursor: pointer;
    .charger-location-details {
      padding: 0;
      gap: 0 10px;
    }
    h4 {
      font-size: 15px;
    }
    .subtitle {
      margin-bottom: 4px;
    }
    .port-status {
      margin-top: 4px;
      margin-left: 51px;
    }
  }
  .charger-location-details {
    padding-bottom: 0;
    border-bottom: 0;
    .img {
      margin-left: 4px;
      width: 30px;
    }
  }
}
</style>