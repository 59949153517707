<template>
  <div class="timeline-panel">
    <div class="flex tlp-header">
      <div class="datafield">
        <BaseIcon name="schedule" class="cal-icon" />
        Nov 16, 2023 - Nov 23, 2023
      </div>
      <DropDown :options="dropdownItems" :value="dropdownItems[0]" class="datafield dropdown" />
    </div>
    <div class="tlp-split-view">
      <div class="timeline-list-area">
        <div class="timeline-list-head">
          <span>{{ events.length }} {{ $t("message.eventsFound") }}</span>
          <DownloadIcon class="download-btn" />
        </div>
        <div class="timeline-list" v-if="events.length !== 0">
          <div
            v-for="(event, i) of events"
            class="event"
            :class="{ selected: currentEventIndex === i, canSelect: i < 2 }"
            @click="i < 2 ? (currentEventIndex = i) : ''"
            :key="i"
          >
            <div class="event-type" v-if="event.speeding">
              <span class="event-category">{{ $t("message.speeding") }}</span>
              <span class="event-title"
                >{{ $t("message.speedingAbove") }} {{ event.speeding.limit
                }}{{ $t("message.mph") }}</span
              >
            </div>
            <div class="event-type" v-else-if="event.tire">
              <span class="event-category">{{
                $t("message.maintenence")
              }}</span>
              <span class="event-title">{{
                $t("message.lowTirePressure")
              }}</span>
            </div>
            <div class="event-type" v-else-if="event.oil">
              <span class="event-category">{{
                $t("message.maintenence")
              }}</span>
              <span class="event-title">{{ $t("message.lowOilLife") }}</span>
            </div>
            <div class="event-type" v-else-if="event.trip">
              <span class="event-category">Trip</span>
              <span class="event-title">Vehicle Trip Completed</span>
            </div>
            <div class="event-type" v-else-if="event.cameraOn">
              <span class="event-category">Camera</span>
              <span class="event-title">Camera Power was Turned On</span>
            </div>
            <div class="event-type" v-else-if="event.cameraOff">
              <span class="event-category">Camera</span>
              <span class="event-title">Camera Power was Turned Off</span>
            </div>
            <div class="event-type" v-else-if="event.charging">
              <span class="event-category">Charging</span>
              <span class="event-title">Charging Event</span>
            </div>
            <div class="event-date">
              {{ eventListTimeStrA(event) }}<br />{{ eventListTimeStrB(event) }}
            </div>
            <BaseIcon name="speeding" v-if="event.speeding" class="event-icon speeding" />
            <BaseIcon name="miscTrip" v-else-if="event.trip" class="event-icon trip" />
            <BaseIcon name="camera" v-else-if="event.cameraOn || event.cameraOff" class="event-icon camera" />
            <BaseIcon name="chargePlug" v-else-if="event.charging" class="event-icon camera" />
            <BaseIcon name="maintenanceLg" v-else class="event-icon maintenance" />
            <div class="event-connector top" v-if="i !== 0" />
            <div
              class="event-connector bottom"
              v-if="i !== events.length - 1"
            />
          </div>
        </div>
        <div class="timeline-list-controls">
          <BaseIcon name="firstPage" />
          <BaseIcon name="arrowLeft" />
          <b>1/1</b>
          <BaseIcon name="arrowLeft" style="transform: rotate(180deg)" />
          <BaseIcon name="firstPage" style="transform: rotate(180deg)" />
        </div>
      </div>
      <div class="event-details" v-if="events.length !== 0">
        <h4>{{ $t("message.generalInfo") }}</h4>
        <div class="general-info">
          <template v-if="!inEssentialsMode"> <!-- Nonessential / premium -->
            <span>{{ $t("message.eventType") }}</span>
            <b v-if="currentEvent.speeding"
              >{{ $t("message.speedingAbove") }} {{ currentEvent.speeding.limit
              }}{{ $t("message.mph") }}</b
            >
            <b v-else-if="currentEvent.tire">{{
              $t("message.lowTirePressure")
            }}</b>
            <b v-else-if="currentEvent.oil">{{ $t("message.lowOilLife") }}</b>
            <b v-else-if="currentEvent.cameraOn">Camera Power was Turned On</b>
            <b v-else-if="currentEvent.cameraOff">Camera Power was Turned Off</b>
            <b v-else />
            <span>{{ $t("message.time") }}</span>
            <b>{{ generalInfoTimeStr }}</b>
            <span v-if="driver">{{ $t("message.driverName") }}</span>
            <b v-if="driver">{{ driver }}</b>
            <span v-if="vehicleLabel">{{ $t("message.vehicleLabel") }}</span>
            <b v-if="vehicleLabel">{{ vehicleLabel }}</b>
            <span v-if="vin">{{ $t("message.vin") }}</span>
            <b v-if="vin">{{ vin }}</b>
            <span v-if="driver">{{ $t("message.fleetName") }}</span>
            <b v-if="driver">{{ driver }} {{ $t("message.family") }}</b>
          </template>
          
          <template v-if="!currentEvent.cameraOn"> <!-- odometer -->
            <span>{{ $t("message.odometer") }}</span>
            <OdometerGauge class="odometer" :value="odo" xs />
          </template>
        </div>
        <template v-if="!inEssentialsMode">
          <h4>
            {{ $t("message.additionalInfo") }}
          </h4>
          <div v-if="currentEvent.oil && oilLife" class="general-info">
            <span>{{ $t("message.oil") }}</span>
            <div class="oil-bar-chart-area">
              <div>
                <b>{{ oilLife }}%</b>
                <span>{{ $t("message.onArrival") }}</span>
              </div>
              <BarChart
                :value="oilLife"
                :height="7"
                :color="oilLifeColor"
                rounded
              />
              <div>
                <b>{{ oilLife }}%</b>
                <span>{{ $t("message.departure") }}</span>
              </div>
            </div>
          </div>
          <div v-else-if="currentEvent.tire" class="general-info">
            <span>Component</span>
            <b>Tire Pressure Sensor</b>
            <span>Issue Description</span>
            <b>Low pressure was detected in one or more of the tires on your vehicle.</b>
            <span>Recommended Action</span>
            <b>Please inspect before driving. You need to inflate or change the tire(s).</b>
          </div>
          <div v-else-if="currentEvent.cameraOn" class="general-info">
            <span>Camera</span>
            <b>Lytx 36252831</b>
            <span>Camera Status</span>
            <b>Error</b>
            <span>Error Description</span>
            <b>Camera is disconnected</b>
          </div>
          <template v-else>
            <div class="general-info">
              <span>{{ $t("message.tripScore") }}</span>
              <b
                class="red"
                :class="{ 
                  orange: currentEvent.info.score > 69, 
                  green: currentEvent.info.score > 89
                }"
              >{{ currentEvent.info.score }}</b>
              <span>Trip Distance</span>
              <b>{{ currentEvent.info.distance }}</b>
              <span>Trip Total Time</span>
              <b>{{ currentEvent.info.totalTime }}</b>
              <span>Trip Idle Time</span>
              <b>{{ currentEvent.info.idleTime }}</b>
              <span>Trip Type</span>
              <b>Business</b>
              <span>{{ $t("message.tripHistory") }}</span>
              <b class="blue-btn" @click="$emit('tripHistory')">View Trip</b>
            </div>
          </template>
        
          <template v-if="!currentEvent.cameraOn">
            <h4>Driver Behavior Events Breakdown</h4>
            <div class="general-info">
              <span>{{ $t("message.hardBrake") }}</span>
              <b>{{ currentEvent.breakdown.hardBrake }}</b>
              <span>{{ $t("message.hardAcceleration") }}</span>
              <b>{{ currentEvent.breakdown.hardAcceleration }}</b>
              <span>{{ $t("message.speedOver80mph") }} (%)</span>
              <b>{{ currentEvent.breakdown.speedingOver80 }}%</b>
              <span>{{ $t("message.seatbeltUnbuckled") }}</span>
              <b>--</b>
              <span>{{ $t("message.fixedSpeed") }}</span>
              <b></b>
              <span>{{ $t("message.roadSpeedLimit") }}</span>
              <b>0</b>
              <span>{{ $t("message.excessiveIdling") }}</span>
              <b>0</b>
            </div>
          </template>
          
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import DropDown from "@/components/demo/DropDown.vue";
import OdometerGauge from "@/components/demo/OdometerGauge.vue";
import BarChart from "@/components/demo/BarChart.vue";

import DownloadIcon from "@/assets/download.svg";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    DropDown,
    OdometerGauge,
    DownloadIcon,
    BarChart,
    BaseIcon,
  },
  props: {
    events: Array[Object],
    activeDriver: String,
    odo: Number,
    oilLife: Number,
    oilLifeColor: String,
    vehicleLabel: String,
    vin: String,
  },
  data() {
    return {
      currentEventIndex: 0,
      dropdownItems: [
        "All Categories",
        "Onboarding/Offboarding",
        "Check-in/Check-out",
        "Maintenance",
        "Trip",
        "Off-Hours",
        "Geofence",
        "Location Masking",
        "Fuel",
        "Charging",
        "Adapter Issue",
        "Remote Commands",
        "Recall",
        "ADAS",
        "Camera",
      ],
    };
  },
  computed: {
    currentEvent() {
      console.log(this.events[this.currentEventIndex]);
      return this.events[this.currentEventIndex];
    },
    driver() {
      if (this.currentEvent.driver) {
        return this.currentEvent.driver;
      } else if (this.activeDriver) {
        return this.activeDriver;
      } else {
        return "";
      }
    },
    generalInfoTimeStr() {
      let dt = moment();
      if (this.currentEvent.time) dt = moment(this.currentEvent.time);
      return dt.format("MM/DD/YYYY, hh:mm A");
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
  methods: {
    eventListTimeStrA(event) {
      // let dt = moment()
      if (!event.time) return "";
      let dt = moment(event.time);
      return dt.calendar(null, {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: "MMM D",
      });
    },
    eventListTimeStrB(event) {
      // let dt = moment()
      if (!event.time) return "";
      let dt = moment(event.time);
      return dt.format("hh:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 32px);
  max-width: 1200px;
}
.tlp-header {
  flex: 0 1 auto;
}
.datafield {
  width: 218px;
  min-height: 20px;
  margin: 8px 0;
  padding: 4px 16px 4px 12px;
  border: 1px solid var(--light-grey-2);
  border-radius: 4px;
  margin-right: 32px;
  display: flex;
  &.dropdown {
    justify-content: space-between;
    padding-right: 2px;
    ::v-deep .dropdown-options {
      grid-template-rows: repeat(auto-fit, 40px);
    }
    ::v-deep .option {
      height: 40px;
      cursor: default;
      &:first-child {
        border-bottom: 1px solid rgba(0, 28, 56, 0.15);
      }
    }
  }
  .cal-icon {
    width: 18px;
    margin-right: 7px;
    fill: var(--dark);
  }
  .dropdown-icon {
    width: 23px;
    margin-top: -4px;
    margin-bottom: -4px;
    margin-right: -12px;
    fill: var(--dark-grey);
  }
}
.tlp-split-view {
  margin: 16px 0;
  border-top: 1px solid var(--light-grey-2);
  flex: 1 1 620px;
  // overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 3fr;
  .timeline-list-area {
    border-right: 1px solid var(--light-grey-2);
    overflow-y: scroll;
    position: relative;
    .timeline-list-head {
      position: sticky;
      top: 0;
      background-color: white;
      color: var(--dark-grey);
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--light-grey-2);
      .download-btn {
        fill: var(--blue);
        width: 20px;
        height: 20px;
        margin: 8px;
        // cursor: pointer;
        // &:hover {
        //   fill: var(--light-blue);
        // }
      }
    }
    .event {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-rows: 28px 40px 28px;
      grid-template-columns: 60px 1fr 80px;
      &.selected {
        background-color: #edf9ff;
      }
      &.canSelect {
        cursor: pointer;
        &:hover {
          background-color: #d9eaf7;
        }
      }
      .event-type {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        justify-self: left;
        display: grid;
        grid-template-rows: 14px 19px;
        .event-category {
          font-size: 12px;
          color: var(--grey);
        }
        .event-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .event-date {
        grid-row: 2 / 3;
        grid-column: 3 / 4;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        color: var(--grey);
      }
      .event-icon {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        width: 26px;
        height: 26px;
        padding: 6px;
        border: 1px solid var(--light-grey-2);
        border-radius: 50%;
        background-color: white;
        fill: var(--blue);
        &.speeding {
          padding: 6px 6.5px 6px 5.5px;
        }
      }
      .event-connector {
        width: 1px;
        height: 100%;
        background-color: var(--light-grey-2);
        grid-column: 1 / 2;
        &.top {
          grid-row: 1 / 2;
        }
        &.bottom {
          grid-row: 3 / 4;
        }
      }
    }
    .timeline-list-controls {
      position: sticky;
      bottom: 0;
      background-color: white;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        margin: 0 10px;
        fill: var(--light-grey);
      }
      b {
        margin: 0 10px 1px 10px;
        font-weight: 600;
        color: var(--dark-grey);
      }
    }
  }
  .event-details {
    padding: 0 32px;
    h4 {
      margin: 32px 0;
    }
    .general-info {
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 20px 6px;
      padding-bottom: 32px;
      border-bottom: 1px solid var(--light-grey-2);
      .blue-btn {
        color: var(--blue);
        cursor: pointer;
      }
      .red {
        color: var(--red);
      }
      .orange {
        color: var(--orange);
      }
      .green {
        color: var(--green);
      }
      span {
        color: var(--dark-grey);
        font-weight: 400;
      }
      b {
        color: #606164;
        font-weight: 600;
        line-height: 20px;
      }
      .odometer {
        justify-self: left;
      }
      .oil-bar-chart-area {
        display: grid;
        grid-template-columns: 52px 1fr 52px;
        gap: 8px;
        align-items: center;
        width: 100%;
        div {
          display: flex;
          flex-direction: column;
          // margin-right: 8px;
          span {
            font-size: 12px;
            letter-spacing: 0.001em;
            line-height: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
