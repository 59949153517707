export default {
  state: {
    measures: [
      {
        id: "ef3a608a-6ff3-11ef-b864-0242ac120002",
        img: "measure_trip_events.svg",
        name: "message.measureTripEvents",
        desc: "message.measureTripEventsDescription",
      },
      {
        id: "f2478e88-6ff3-11ef-b864-0242ac120002",
        img: "measure_camera_status.svg",
        name: "message.measureCameraStatus",
        desc: "message.measureCameraStatusDescription",
      },
      {
        id: "f5963c56-6ff3-11ef-b864-0242ac120002",
        img: "measure_vehicle_checkout.svg",
        name: "message.checkedOutVehicles",
        desc: "message.vehiclesCurrentlyCheckedOut",
      },
      {
        id: "f8bd35ec-6ff3-11ef-b864-0242ac120002",
        img: "measure_vehicle_statuses.svg",
        name: "message.vehiclesSnapshot",
        desc: "message.currentSnapshotVehicleStatuses",
      },
      {
        id: "fd825d6e-6ff3-11ef-b864-0242ac120002",
        img: "measure_recalls.svg",
        name: "message.recallVehicles",
        desc: "message.totalVehiclesInRecall",
      },
      {
        id: "0ae9002a-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_diagnostics.svg",
        name: "message.diagnosticIssues",
        desc: "message.totalNumberDiagnosticIssues",
      },
      {
        id: "10758e6e-6ff4-11ef-b864-0242ac120002",
        img: "measure_engine_hours.svg",
        name: "message.engineHours",
        desc: "Vehicles with the highest engine hours reading",
      },
      {
        id: "1735f6b2-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_distance.svg",
        name: "message.distanceDriven",
        desc: "message.totalDistanceDriven",
      },
      {
        id: "1cf10ce0-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_fuel_economy.svg",
        name: "message.fuelEconomy",
        desc: "message.vehiclesWithHighestLowestFuelEconomy",
      },
      {
        id: "1fbf96ee-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_fuel_consumption.svg",
        name: "message.fuelConsumption",
        desc: "message.vehiclesFuelConsumption",
      },
      {
        id: "25fba750-6ff4-11ef-b864-0242ac120002",
        img: "measure_fuel_energy.svg",
        name: "message.fuelAndEnergyLevel",
        desc: "An overview of the fuel and energy level",
      },
      {
        id: "34012dd4-6ff4-11ef-b864-0242ac120002",
        img: "measure_odometer.svg",
        name: "message.topMileage",
        desc: "message.vehiclesWithHighestOdometerRating",
      },
      {
        id: "3d4ea222-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_oil_life.svg",
        name: "message.lowOilLife",
        desc: "message.vehiclesWithLowestOilLife",
      },
      {
        id: "433092fe-6ff4-11ef-b864-0242ac120002",
        img: "measure_energy_consumption.svg",
        name: "message.energyConsumption",
        desc: "Vehicles energy consumed aggregation from the electricity grid",
      },
      {
        id: "50e30292-6ff4-11ef-b864-0242ac120002",
        img: "measure_driver_behavior.svg",
        name: "message.driverBehavior",
        desc: "message.lowestAndHighestPerformingDrivers",
      },
      {
        id: "54a1e77c-6ff4-11ef-b864-0242ac120002",
        img: "measure_account_trends.svg",
        name: "message.trends",
        desc: "message.displaysMultipleFleetMetricsOverTime",
      },
      {
        id: "5c5bddc4-6ff4-11ef-b864-0242ac120002",
        img: "measure_expenses_by_type.svg",
        name: "message.expensesByType",
        desc: "Snapshot of expenses by Type",
      },
      {
        id: "61c2b04e-6ff4-11ef-b864-0242ac120002",
        img: "measure_expenses_over_time.svg",
        name: "message.expensesOverTime",
        desc: "Expenses over time by category and total",
      },
      {
        id: "6636b8a0-6ff4-11ef-b864-0242ac120002",
        img: "measure_vehicle_condition.svg",
        name: "message.vehiclesCondition",
        desc: "Current breakdown of inventory vehicle types (new, used, etc.)",
      },
      {
        id: "6a0fa1b2-6ff4-11ef-b864-0242ac120002",
        img: "measure_days_in_inventory.svg",
        name: "message.daysInInventory",
        desc: "Identify and promote sales according to time on the lot",
      },
      {
        id: "6f486222-6ff4-11ef-b864-0242ac120002",
        img: "measure_top_vehicle_locations.svg",
        name: "message.topVehicleLocations",
        desc: "The total number of connected inventory vehicles within each Geofence",
      },
      {
        id: "7895bc76-6ff4-11ef-b864-0242ac120002",
        img: "measure_utilization.svg",
        name: "Days Since Last Recorded Trip",
        desc: "Number of vehicles that have not been seen utilized for a certain amount of days.",
      },
      {
        id: "7f6f1e48-6ff4-11ef-b864-0242ac120002",
        img: "measure_monthly_total.svg",
        name: "Monthly Expense Tracker",
        desc: "Total expenses for current month and two previous calendar months.",
      },
    ]
  },
  getters: {
    measures: (state) => state.measures,
  },
};