<template>
  <div class="customize-measures">
    <div class="top-area flex">
      <div class="flex column">
        <span class="title">{{ $t("message.availableMeasures") }}</span>
        <span class="desc">{{ $t("message.selectPerformanceMeasures") }}</span>
      </div>
      <img
        class="exit"
        src="@/assets/clear.svg?external"
        @click="$emit('hide')"
      />
    </div>
    <div class="measures-gradient-bg">
      <div class="measures">
        <div
          v-for="(measure, i) of measures"
          v-show="availableMeasures.indexOf(i) !== -1"
          @click="toggleMeasure(i)"
          class="measure"
          :key="measure.id"
        >
          <img :src="require('@/assets/measures/' + measure.img + '?external')" />
          <div class="measure-text">
            <b>{{ $t(measure.name) }}</b>
            <span>{{ $t(measure.desc) }}</span>
          </div>
          <div class="check" v-if="measuresToDisplay.indexOf(i) !== -1">
            <img src="@/assets/check.svg?external" />
          </div>
          <div class="check" v-else>
            <img src="@/assets/add.svg?external" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    availableMeasures: Array[Number],
    measuresToDisplay: Array[Number],
  },
  data() {
    return {
      measuresOld: [
        {
          img: "measure_vehicle_checkout.svg",
          name: "message.checkedOutVehicles",
          desc: "message.vehiclesCurrentlyCheckedOut",
        },
        {
          img: "measure_vehicle_diagnostics.svg",
          name: "message.diagnosticIssues",
          desc: "message.totalNumberDiagnosticIssues",
        },
        {
          img: "measure_vehicle_statuses.svg",
          name: "message.vehiclesSnapshot",
          desc: "message.currentSnapshotVehicleStatuses",
        },
        {
          img: "measure_vehicle_distance.svg",
          name: "message.distanceDriven",
          desc: "message.totalDistanceDriven",
        },
        {
          img: "measure_vehicle_fuel_economy.svg",
          name: "message.fuelEconomy",
          desc: "message.vehiclesWithHighestLowestFuelEconomy",
        },
        {
          img: "measure_vehicle_fuel_consumption.svg",
          name: "message.fuelConsumption",
          desc: "message.vehiclesFuelConsumption",
        },
        {
          img: "measure_vehicle_oil_life.svg",
          name: "message.lowOilLife",
          desc: "message.vehiclesWithLowestOilLife",
        },
        {
          img: "measure_driver_behavior.svg",
          name: "message.driverBehavior",
          desc: "message.lowestAndHighestPerformingDrivers",
        },
        {
          img: "measure_account_trends.svg",
          name: "message.trends",
          desc: "message.displaysMultipleFleetMetricsOverTime",
        },
        {
          img: "measure_recalls.svg",
          name: "message.recallVehicles",
          desc: "message.totalVehiclesInRecall",
        },
        {
          img: "measure_odometer.svg",
          name: "message.topMileage",
          desc: "message.vehiclesWithHighestOdometerRating",
        },
        {
          img: "measure_fuel_energy.svg",
          name: "message.fuelAndEnergyLevel",
          desc: "An overview of the fuel and energy level",
        },
        {
          img: "measure_engine_hours.svg",
          name: "message.engineHours",
          desc: "Vehicles with the highest engine hours reading",
        },
        {
          img: "measure_energy_consumption.svg",
          name: "message.energyConsumption",
          desc: "Vehicles energy consumed aggregation from the electricity grid",
        },
        {
          img: "measure_expenses_by_type.svg",
          name: "message.expensesByType",
          desc: "Snapshot of expenses by Type",
        },
        {
          img: "measure_expenses_over_time.svg",
          name: "message.expensesOverTime",
          desc: "Expenses over time by category and total",
        },
        {
          img: "measure_vehicle_condition.svg",
          name: "message.vehiclesCondition",
          desc: "Current breakdown of inventory vehicle types (new, used, etc.)",
        },
        {
          img: "measure_days_in_inventory.svg",
          name: "message.daysInInventory",
          desc: "Identify and promote sales according to time on the lot",
        },
        {
          img: "measure_top_vehicle_locations.svg",
          name: "message.topVehicleLocations",
          desc: "The total number of connected inventory vehicles within each Geofence",
        },
      ]
    };
  },
  computed: {
    ...mapGetters(['measures']),
  },
  methods: {
    toggleMeasure(number) {
      this.$emit("toggleMeasure", number);
    },
  },
};
</script>

<style scoped lang="scss">
.customize-measures {
  width: 100%;
  max-width: 480px;
  /* height: 690px; */
  height: 560px;
}
.top-area {
  width: 100%;
  height: 79px;
  padding: 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
}
.measures-gradient-bg {
  width: 100%;
  height: 481px;
  overflow: scroll;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
}
.measures {
  display: grid;
  grid-template-rows: repeat(auto-fit, 155px);
  grid-template-columns: 1fr;
  gap: 1px;
  background-color: var(--light-grey-2);
  width: 90%;
  /* height: 100%; */
  position: relative;
  margin: 36px 5% 0 5%;
  box-sizing: border-box;
  img {
    max-width: 116px;
  }
}
.measure {
  display: grid;
  align-items: center;
  grid-template-columns: 116px 1fr 32px;
  grid-column-gap: 16px;
  background-color: white;
  width: 100%;
  min-height: 155px;
  height: 155px;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  /* hide cards too wide to be displayed */
  .measure.lg,
  .line.lg,
  .measure.xl,
  .line.xl {
    display: none;
  }
}
.measure-text {
  display: flex;
  flex-direction: column;
  /* margin-left: 16px; */
  /* margin-top: 36px; */
  color: var(--dark-grey);
  max-width: 237px;
}
.measure-text span {
  line-height: 18px;
}
.measure-text b {
  font-size: 16px;
  // line-height: 32px;
  // mar
}
.line {
  width: 100%;
  min-height: 1px;
  grid-column: span 3;
  height: 1px;
  background-color: var(--light-grey-2);
}
.exit {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.check img {
  width: 16px;
  height: 16px;
}
</style>
