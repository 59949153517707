<template>
  <div class="icon-container" :class="{'is-open': isOpen}">
    <button class="icon" @click="togglePopup">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
      <g filter="url(#a)">
        <path fill="#171C26" d="M17.899 4.17c-6.462 0-11.7 5.239-11.7 11.7 0 6.462 5.238 11.7 11.7 11.7 6.462 0 11.7-5.238 11.7-11.7 0-6.461-5.238-11.7-11.7-11.7Z"/>
      </g>
      <path fill="#fff" stroke="#fff" stroke-linecap="square" stroke-width="3.5" d="M17.899 4.17c-6.462 0-11.7 5.239-11.7 11.7 0 6.462 5.238 11.7 11.7 11.7 6.462 0 11.7-5.238 11.7-11.7 0-6.461-5.238-11.7-11.7-11.7Z"/>
      <path :fill="color1" fill-rule="evenodd" d="M27.161 15.71a9.426 9.426 0 0 0-9.158-9.422l-.268-.003v-3.05c6.788 0 12.31 5.42 12.473 12.168l.003.308h-3.05ZM17.735 3.239V6.28a9.43 9.43 0 0 0-9.43 9.43H5.263c0-6.888 5.584-12.472 12.472-12.472Z" clip-rule="evenodd"/>
      <path :fill="color2" fill-rule="evenodd" d="M17.735 28.18v-3.042a9.43 9.43 0 0 0 9.43-9.43h3.042c0 6.888-5.584 12.472-12.472 12.472ZM5.263 15.707h3.042a9.43 9.43 0 0 0 9.43 9.43v3.043c-6.888 0-12.472-5.584-12.472-12.473Z" clip-rule="evenodd"/>
      <defs>
        <filter id="a" width="35.4" height="35.4" x=".199" y=".17" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_46011"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_9_46011" result="shape"/>
        </filter>
      </defs>
    </svg>
    <div class="label">{{ label }}</div>
    </button>
    
    <div class="popup" v-if="isOpen" v-on-click-outside="closePopup">
      <button class="btn-close" @click="togglePopup"><IconClose /></button>
      <div class="event" v-for="event in data.subtypes" :key="event.id">
        <TripHistoryVideoPlayer
          v-if="event.video && showVideoPlayer"
          :src="'trip-history-videos/' + event.video"
          @close="$emit('close')"
        />
        <div class="event-expand" @click="toggleExpanded(event.id)">
          <div class="event-icon">
            <BaseIcon
              name="hardBrake"
              v-if="event.type === 1"
              class="hard-brake-svg"
            />
            <BaseIcon
              name="hardAcceleration"
              v-else-if="event.type === 2"
              class="hard-accel-svg"
            />
            <img
              src="@/assets/Video_71_.png"
              v-else-if="event.type === 6"
              class="camera-svg"
            />
            <IconSpeedingDouble :speed="event.speed" :speedLimit="event.speedLimit" v-else-if="event.type === 11" />
            <!-- <HardBrakingSVG  /> -->
            <!-- <HardAccelSVG  /> -->
            <POIIcon v-else />
          </div>
          <div class="event-info">
            <div class="event-title">{{ event.title }}</div>
            <div class="trip-address grey">{{ event.address }}</div>
            <div class="trip-time grey">{{ event.time }}</div>
          </div>
          <IconChevronDown class="icon-expand" :class="{'is-expanded': expandedId === event.id}" />
        </div>
        <div class="event-expanded" v-if="expandedId === event.id">
          <hr />
          <div
            class="launch-video"
            v-if="event.video && !showVideoPlayer"
            @click="showVideoPlayer = !showVideoPlayer"
          >
            <img src="@/assets/Video_71_.png" />
            Launch Video
          </div>
          <div class="odometer-container" v-if="event.odometer">
            <div class="odometer">
              <span
                v-for="(number, index) in event.odometer"
                :key="index"
                class="number"
                >{{
                  conversions.getMilesValue(parseInt(number), $i18n.locale)
                }}</span
              >
            </div>
            <span class="grey mi">{{ $t("message.mi") }}</span>
          </div>
          <div
            class="map-popup-text speed-section"
            v-if="event.speed && event.speedLimit"
          >
            <span
              :class="[
                'speed',
                event.speedLimit >= event.speed ? 'green' : 'red',
              ]"
              >{{ conversions.getMphValue(event.speed, $i18n.locale) }}</span
            >/<span>{{
              conversions.getMphValue(event.speedLimit, $i18n.locale) +
              " " +
              $t("message.mphLimit")
            }}</span>
          </div>
        </div> 
      </div>
    </div>
    
  </div>
</template>

<script>
import IconClose from '@/assets/icons/close-icon.svg';
import BaseIcon from '@/components/icons/BaseIcon.vue';
import POIIcon from "@/assets/poiicon.svg";
import TripHistoryVideoPlayer from "@/components/demo/TripHistoryVideoPlayer.vue";
import * as conversions from "../../utils/conversions.js";
import IconChevronDown from "@/assets/icons/chevron-down.svg";
import IconSpeedingDouble from "@/components/icons/SpeedingDoubleIcon.vue";
// import IconDownload from "@/components/icons/IconDownload.vue";
export default {
  components: {
    IconClose,
    BaseIcon,
    POIIcon,
    TripHistoryVideoPlayer,
    IconChevronDown,
    IconSpeedingDouble
  },
  props: {
    colors: {
      type: Array,
      default: () => ['#651EFF', '#FFAB40'],
    },
    label: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
      showVideoPlayer: false,
      expandedId: null,
      conversions: conversions,
    };
  },
  computed: {
    color1() {
      return this.colors[0];
    },
    color2() {
      return this.colors[1];
    },
  },
  methods: {
    togglePopup() {
      console.log('togglePopup');
      console.log(this.data);
      this.isOpen = !this.isOpen;
    },
    closePopup() {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    toggleExpanded(id) {
      this.expandedId = this.expandedId === id ? null : id;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  &.is-open {
    z-index: 20;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  button.icon {
    width: 34px;
    height: 34px;
    display: block;
    position: relative;
    border: 0;
    background-color: transparent;
    padding: 0;
  }
  svg {
    width: 100%;
    height: auto;
  }
  .label {
    position: absolute;
    font-size: 12px;
    text-align: center;
    z-index: 2;
    top: 8px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.popup {
  z-index: 20;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
  position: absolute;
  inset: auto auto 0px 0px;
  transform: translate(3px, -41.5px);
  min-width: 275px;
  left: calc(-1 * ((275px / 2) - (34px / 2)));
  padding-top: 14px;
  &::after {
    content: '';
    position: absolute;
    bottom: -7.5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 7.5px solid #ffffff;
    border-bottom: 0;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-close {
    display: block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.event {
  padding: 10px;
  .event-expand {
    display: flex;
    position: relative;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--light-grey-2);
  }
  .event-icon {
    width: 46px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    margin-right: 10px;
    .hard-brake-svg,
    .hard-accel-svg,
    .camera-svg {
      border-width: 0.35rem;
      border-style: solid;
      border-radius: 50%;
      padding: 0.4rem;
      // margin-top: 3px;
    }
    .hard-brake-svg {
      border-color: #FFA725;
    }
    .hard-accel-svg {
      border-color: rgb(186, 104, 200);
    }
    .camera-svg {
      border-color: #651EFF;
    }
  }
  .event-info {
    flex: 1;
    .event-title {
      font-size: 1.2rem;
      font-weight: 700;
    }
    .trip-address,
    .trip-time {
      font-size: 0.85rem;
      line-height: 1.3;
      color: var(--dark-grey);
    }
  }
  .icon-expand {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 3px;
    right: 4px;
    &.is-expanded {
      transform: rotate(180deg);
    }
  }
  .event-expanded {
    hr {
      border: 0;
      border-top: 1px solid var(--light-grey-2);
      margin: 9px 0;
    }
    .launch-video {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: var(--blue);
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;
      img {
        width: 20px;
        margin-top: 3px;
        // filter: invert(30%) sepia(100%) saturate(1022%) hue-rotate(180deg) brightness(95%) contrast(108%);
        filter: invert(30%) sepia(100%) saturate(433%) hue-rotate(180deg)
          brightness(95%) contrast(108%);
      }
    }
  }
}
.odometer-container {
  display: flex;
}
.odometer {
  border: 1px solid var(--light-grey);
  border-radius: 25px;
  width: fit-content;
  margin: 10px 5px 0px 0;
  display: flex;
  align-items: center;

  .number {
    border-right: 1px solid var(--light-grey);
    font-weight: bold;
    padding: 0.1rem 0.3rem;
    text-align: center;

    &:first-child {
      padding-left: 0.5rem;
    }

    &:last-child {
      padding-right: 0.5rem;
      border: 0;
    }
  }
}
.mi {
  margin-top: 10px;
}
.speed-section {
  width: 100%;
  span {
    &:first-child {
      padding-right: 3px;
    }
    &:last-child {
      padding-left: 3px;
    }
  }
}
</style>