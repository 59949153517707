<template>
  <div class="plug-and-charge">
    
    <div class="ivc-head" v-if="!isMobile">
      <div class="flex center">
        <div class="ivc-circle">
          <svg id="gfs:icon1/station" viewBox="0 0 16 16">
            <path
              d="M13.5 3.134A1 1 0 0 1 14 4v1a1 1 0 0 1-1 1h-.382v4.27a2.5 2.5 0 0 1-2.5 2.5H9v.768c0 .255-.224.462-.5.462h-6c-.276 0-.5-.207-.5-.462V2.462C2 2.207 2.224 2 2.5 2h6c.276 0 .5.207.5.462v9.307h1.118a1.5 1.5 0 0 0 1.5-1.5V6H11a1 1 0 0 1-1-1V4a1 1 0 0 1 .5-.866V2.5a.5.5 0 1 1 1 0V3h1v-.5a.5.5 0 1 1 1 0v.634zM12.618 5H13V4h-2v1H12.618zM3 5h5V3H3v2zm0 1v7h5V6H3z">
            </path>
          </svg>
        </div>
        <h1 v-if="!isMobile">{{ $t("message.plugAndCharge") }}</h1>
      </div>
      <div class="flex column center add-container">
        <div class="flex center">
          <button class="add-button">
            <Icon :icon="plusIcon" class="plus-icon" />
            <span>{{ $t("message.add") }}</span>
          </button>
        </div>
        <b>EVgo {{ $t('message.accountRequired') }}</b>
        <!-- <span class="unit"
          >{{ $t("message.vehicle") }} / {{ $t("message.month") }}</span
        > -->
      </div>
    </div>
    <div class="ivc-gradient" />
    <div class="evgo-main">
      <div class="slideshow">
        <img src="@/assets/evgoChargeCard.svg?external" />
        <b>{{ $t('message.linkAccount') }}</b>
        <!-- <p>{{ $t('message.linkAnExistingEVgoAccountToOVI') }}</p> -->
        <p>Link an existing EVgo account to OnStar Vehicle Insights or create a new one</p>
      </div>
      <div class="flex column" style="width: 100%;">
        <div class="flex" style="justify-content: space-between">
          <b>{{ $t("message.vehicles") }}</b>
          <!-- <svg
            class="vehicles-dot-dot-dot"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            />
          </svg> -->
        </div>
        <div class="flex">
          <!-- <div class="big-number-wrap flex column">
            <div class="big-number">{{ numEnrolled }}</div>
            <div class="flex center">
              <div class="dot" />
              <span class="ivc-status">{{ $t("message.enrolled") }}</span>
            </div>
          </div> -->
          <div class="big-number-wrap flex column">
            <div class="big-number">0</div>
            <div class="flex center">
              <!-- <div class="dot grey" /> -->
              <span class="ivc-status">{{ $t("message.compatible") }}</span>
            </div>
          </div>
        </div>
        <!-- <BarChart :value="percentEnrolled" :width="300" :height="6.5" rounded /> -->
      </div>
      <div class="flex column how-it-works">
        <h3 style="margin-top: 0">{{ $t("message.howDoesItWork") }}</h3>
        <p>
          Enrolling in Plug and Charge with EVgo allows your drivers to charge seamlessly at compatible EVgo charging stations.
          Simply use an existing EVgo account or create a new one and link it to your OnStar Vehicle Insights account.
        </p>
        <p>
          Drivers connect the charger at a supported EVgo station to their electric vehicle and charging starts automatically. No
          payment method is required, the cost for the charging session will automatically be billed to the EVgo account linked to
          your OnStar Vehicle Insights account. It's that easy.
        </p>
        <p>
          Fleet Managers can select which vehicles to enroll for this service, easily manage/change a vehicle's enrollment status,
          or opt-out of Plug and Charge at any time. All charging sessions are reported through OnStar Vehicle Insights.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import BarChart from "@/components/demo/BarChart.vue";
import { Icon } from '@iconify/vue2';
import plusIcon from '@iconify/icons-mdi/plus';

export default {
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  methods: {
    checkChallenge() {
      //checks if go the extra mile challenge is completed and alerts parent components
      if (this.activeChallenge === "not-buckled-not-cool") {
        this.$emit("challengeComplete", 17);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
    },
  },
  components: {
    // BarChart,
    Icon,
  },
  data() {
    return {
      radio: false,
      showVehicleSelection: false,
      numNotEnrolled: 0,
      currentSlide: 0,
      numSlides: 4,
      bingo: false,
      currentModalPage: 1,
      search: "",
      plusIcon: plusIcon,
    };
  },
  computed: {
    selectedVehicles() {
      return this.$store.state.ivcVehicles;
    },
    numEnrolled() {
      return this.dataset.vehicles.length;
    },
    percentEnrolled() {
      const total = this.numEnrolled + this.numNotEnrolled;
      return (this.numEnrolled / total) * 100;
    },
  },
};
</script>

<style scoped lang="scss">
.ivc-head {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 36px;
  box-sizing: border-box;
}
.ivc-circle {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}
.ivc-circle svg {
  width: 32px;
  height: 32px;
  fill: #606164;
}
.ivc-gradient {
  background: linear-gradient(to bottom, #f5f5f5, #ffffff 100%);
  width: 100%;
  height: 50px;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  margin-right: 8px;
  margin-bottom: 16px;
}
.dot.blue {
  background-color: var(--blue);
}
.dot.grey {
  background-color: var(--grey);
}
.ivc-status {
  font-size: 12px;
  color: #606164;
  margin-bottom: 16px;
}
.unit {
  color: var(--dark-grey);
}
.evgo-main {
  display: grid;
  grid-template-rows: 150px 420px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 72px;
}
.slideshow {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 19px;
  img {
    width: 100%;
  }
  b {
    margin-top: 16px;
  }
  p {
    margin-top: 8px;
    font-weight: 100;
    text-align: center;
  }
}
h3 {
  font-size: 19px;
}
.vehicles-dot-dot-dot {
  fill: var(--blue);
  width: 20px;
  height: 20px;
}
.big-number-wrap {
  margin-right: 22px;
}
.big-number {
  font-size: 40px;
  color: var(--light-grey);
  font-weight: 300;
  margin-left: 0px;
  margin-top: 8px;
}
.add-container {
  align-items: unset;
  text-align: center;
}
.add-button {
  background: var(--blue);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: bold;
  width: 100%;
  height: 30px;
  margin-bottom: 4px;
  .plus-icon {
    height: 16px;
    width: 16px;
  }
}

.how-it-works {
  grid-column: 2 / 3;
  margin-bottom: 64px;
  p {
    color: #606164;
    margin: 8px 0;
  }
}

.select-vehicles {
  .vehicle-selection {
    padding: 15px 0;
  }
}
@media screen and (max-width: 845px) {
  .evgo-main {
    grid-template-columns: 1fr;
    grid-template-rows: 390px auto;
    margin: 0 10%;
  }
  .slideshow {
    grid-row: 1 / 2;
  }
  .how-it-works {
    grid-column: 1 / 2;
  }
}
@media screen and (max-width: 425px) {
  .evgo-main {
    margin: 0 5%;
  }
}
</style>
