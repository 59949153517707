<template>
  <div class="content">
    <h3>Accept Terms</h3>
    <p>By selecting "Accept" you agree to the &lt;Vendor&gt; Camera Addendum to the User Terms for OnStar Vehicle Insights.</p>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="accept-terms" v-model="isAccepted">
      <label class="form-check-label" for="accept-terms">
        I accept the user terms
      </label>
    </div>
    <hr />
    <div class="btn-container">
      <button class="btn btn-minor" @click="$emit('closed')">Decline</button>
      <button class="btn btn-primary" @click="$emit('accepted')" :disabled="!isAccepted">Accept</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddCameraModal1',
  data() {
    return {
      isAccepted: false,
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.content {
  padding: 8px;
  *, ::after, ::before {
    box-sizing: border-box;
  }
  h3 {
    margin: 16px 20px 8px 20px;
    font-size: 17px;
  }
  p {
    margin: 0 20px 115px 20px;
  }
  hr {
    margin: 20px 0;
    color: inherit;
    border: 0;
    border-top: 1px solid #9EC9EA;
  }
}

.form-check {
  margin-bottom: 27px;
  margin-left: 20px;
}

.btn-container {
  display: flex;
  margin-left: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-family: $font-family-nova;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  white-space: nowrap;
  padding: 5px 18px 6px 18px;
  border-radius: 4px;
  &:first-child {
    margin-left: auto;
    margin-right: 5px;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.btn-minor {
  color: var(--dark-grey);
}

.btn-primary {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
  &:disabled {
    background-color: var(--pale-grey);
    border-color: var(--pale-grey);
    color: #969FB2;
  }
}

</style>
