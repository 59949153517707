<template>
  <form class="content" @submit.prevent="confirmed">
    <p>By clicking “Confirm”, you are requesting to cancel your camera add-on subscription. This option will remove all aftermarket cameras from your OnStar Vehicle Insights account. You will be contacted in 3-5 business days by an OnStar Vehicle Insights representative to confirm your camera add-on subscription cancellation. After cancellation, GM will end your monthly recurring charge for your camera add-on subscription from your OnStar Vehicle Insights account. In addition, after cancellation, you will no longer have access to your camera statuses or generate new camera recordings within your trip history.</p>
    <div class="form-group">
      <label for="reason">Reason for cancellation<sup>*</sup></label>
      <input type="text" class="form-control" id="reason" placeholder="Select...">
    </div>
    <div class="form-group">
      <label for="comments">Comments</label>
      <textarea class="form-control" id="comments" rows="4"></textarea>
    </div>
    <p><strong>Choose how to be contacted:</strong></p>
    <div class="checkboxes">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="" id="phone-check">
        <label class="form-check-label" for="phone-check">
          <IconPhone /> +1 (833) 266-6565
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="" id="email-check">
        <label class="form-check-label" for="email-check">
          <IconEmail /> fleetemail@handh.com
        </label>
      </div>
    </div>
    <p class="faded">You can update your contact info in the Account Details tab in the Account Settings Page</p>
    <hr />
    <div class="btn-container">
      <button class="btn btn-primary-outline" type="button" @click="$emit('closed')">Cancel</button>
      <button class="btn btn-primary" type="submit">Confirm</button>
    </div>
  </form>
</template>

<script>
import IconPhone from '@/assets/icons/phone-icon.svg';
import IconEmail from '@/assets/icons/email-icon.svg';
export default {
  name: 'CancelCameraModal1',
  components: {
    IconPhone,
    IconEmail,
  },
  data() {
    return {
      isAccepted: false,
    }
  },
  methods: {
    confirmed() {
      this.$emit('accepted');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.content {
  width: 100%;
  padding: 25px 25px 18px 25px;
  // overflow: scroll;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
  color: var(--dark);
  *, ::after, ::before {
    box-sizing: border-box;
  }
  p {
    margin-top: 0;
    margin-bottom: 9px;
    &.faded {
      color: var(--dark-grey);
    }
  }
  hr {
    margin: 18px 0;
    color: inherit;
    border: 0;
    border-top: 1px solid #9EC9EA;
  }
}

.form-group {
  margin-bottom: 14px;
  label sup {
    font-size: 15px;
    color: var(--red);
    position: relative;
    top: 3px;
  }
  input, textarea {
    width: 100%;
    padding: 6px 8px;
    font-size: 14px;
    border: 1px solid var(--light-grey-2);
    border-radius: 4px;
    margin-top: 5px;
    &::placeholder {
      color: var(--dark-grey);
    }
  }
}

.checkboxes {
  text-align: center;
}

.form-check {
  margin-bottom: 27px;
  margin-left: 8px;
  margin-right: 8px;
  .form-check-input {
    margin-right: 10px;
  }
  .form-check-label {
    svg {
      width: 12px;
      height: auto;
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-container {
  display: flex;
  margin-left: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-family: $font-family-nova;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  white-space: nowrap;
  padding: 5px 18px 6px 18px;
  border-radius: 4px;
  min-width: 120px;
  justify-content: center;
  &:first-child {
    margin-left: auto;
    margin-right: 16px;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.btn-primary-outline {
  color: var(--blue);
  border-color: var(--light-grey);
}

.btn-primary {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
  &:disabled {
    background-color: var(--pale-grey);
    border-color: var(--pale-grey);
    color: #969FB2;
  }
}
</style>