<template>
  <div
    class="bar-chart"
    :style="{ width: width + 'px', height: height + 'px' }"
  >
    <div class="bar outer" :class="{ rounded: rounded }" />
    <div
      class="bar inner"
      :style="{ width: value + '%', backgroundColor: color, borderRadius: borderRadiusPrimary }"
      :class="{ rounded: rounded }"
    />
    <div
      v-if="(secondaryValue && secondaryColor)"
      class="bar inner"
      :style="{ width: secondaryValue + '%', backgroundColor: secondaryColor, left: value + '%', borderRadius: borderRadiusSecondary }"
      :class="{ rounded: rounded }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    width: Number,
    height: Number,
    rounded: Boolean,
    color: String,
    secondaryValue: Number,
    secondaryColor: String,
  },
  computed: {
    borderRadiusPrimary() {
      if (this.secondaryValue && this.secondaryColor) {
        return '8px 0 0 8px';
      }
      return '';
    },
    borderRadiusSecondary() {
      if (this.secondaryValue && this.secondaryColor) {
        return '0 8px 8px 0';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.bar-chart {
  position: relative;
  max-width: 100%;
}
.bar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.bar.outer {
  background-color: var(--pale-grey);
}
.bar.inner {
  background-color: var(--green);
}
.rounded {
  border-radius: 8px;
}
</style>