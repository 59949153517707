<template>
  <section class="section">
    <h2>Vehicle Connections</h2>
    <div class="data">
      <img src="@/assets/img/vehicle-connections.png?external" alt="Oil Life" />
    </div>
    <ViewMore>Manage Plans</ViewMore>
  </section>
</template>

<script>
import ViewMore from '@/components/demo/myba/ViewMore.vue';

export default {
  components: {
    ViewMore
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

section.section {
  font-family: $font-family-base;
}

.data {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  img {
    width: 100%;
    max-width: 415px;
    height: auto;
  }
}
</style>