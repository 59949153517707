<template>
  <div class="Filter-Modal">
    <!-- TABS -->
    <div class="tabs">
      <div id="tab1" class="tab" :class="{ active: activeTab === 0 }" @click="activeTab = 0">
        <BaseIcon name="vehicle" />
        {{ $t('message.vehicles') }}
      </div>
      <div id="tab2" class="tab" :class="{ active: activeTab === 1 }" @click="activeTab = 1">
        <BaseIcon name="POI" />
        {{ $t('message.places') }}
      </div>
      <div id="tab3" class="tab" :class="{ active: activeTab === 2 }" @click="activeTab = 2">
        <BaseIcon name="lightning" />
        {{ $t('message.charging') }}
      </div>
    </div>
    <GuidedTourWaypoint
      :ids="waypointIds"
      target="mapsearch"
      :yOffset="20"
      @waypointOpened="handleWaypointOpened"
      @nextBtnPressed="activeTab < 2 ? activeTab += 1 : ''"
    />
    <!-- FILTER HEADING, SEARCH BAR, SEARCH AND FILTER BUTTONS -->
    <div
      :class="{
        'Filter-Modal__header': true,
        'Filter-Modal--frosted-glass': true,
        'Filter-Modal__header--filter-visible':
          $store.state.showMapFilter && filterMode,
        'Filter-Modal__header--expanded': $store.state.showMapFilterDropdown,
      }"
    >
      <!-- Original vehicle filter header -->
      <div
        class="Filter-Modal__filter-header"
        v-show="$store.state.showMapFilter && filterMode && !inChargerMode"
      >
        <button
          class="Filter-Modal__back-button"
          @click="
            filterMode = !filterMode;
            $store.commit('closeMapFilter');
            $store.commit('closeMapFilterDropdown');
          "
        >
          <BackArrowIcon />
        </button>
        <p class="Filter-Modal__title">{{ $t('message.vehicleSearchFilters') }}</p>
        <span
          class="Filter-Modal__clear"
          @click="$store.commit('clearSelectedTags')"
          >{{ $t('message.reset') }}</span
        >
      </div>
      <!-- New EV charging station filter header -->
      <div
        class="Filter-Modal__filter-header"
        v-show="$store.state.showMapFilter && filterMode && inChargerMode"
      >
        <button
          class="Filter-Modal__back-button"
          @click="
            filterMode = !filterMode;
            $store.commit('closeMapFilter');
            $store.commit('closeMapFilterDropdown');
          "
        >
          <BackArrowIcon />
        </button>
        <p class="Filter-Modal__title">Charging Station Filters</p>
        <span class="Filter-Modal__clear" @click="chargerFilterToggleKey++"
          >Reset</span
        >
      </div>
      <!-- Filter type icons -->
      <!-- <BaseIcon name="vehicle" /> -->
      <!-- <BaseIcon name="lightning" /> -->
      <input
        class="Filter-Modal__input"
        v-model="searchQuery"
        v-show="!filterMode"
        :placeholder="inputPlaceholder"
        @focus="
          $store.commit('openMapFilterDropdown');
          showFilterTypeOptions = false;
        "
        @blur="delayHideDropdown"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <div v-show="!filterMode" class="Filter-Modal__buttons">
        <SearchIcon
          id="mapsearch"
          class="Filter-Modal__icon"
          @click="$store.commit('toggleMapFilterDropdown')"
        />
        <div class="Filter-Modal__search-divider" />
        <FilterIcon
          class="Filter-Modal__icon Filter-Modal__icon--filter"
          :class="{ disabled: activeTab === 1 }"
          @click="handleFilterIconClick"
        />
      </div>
    </div>
    <!-- <div v-if="showFilterTypeOptions" class="filter-type-options-menu">
      <div
        class="filter-type-option"
        @click="
          inChargerMode = false;
          showFilterTypeOptions = false;
        "
        :class="{ selected: !inChargerMode }"
      >
        <BaseIcon name="vehicle" />
        {{ $t("message.vehicles") }}
      </div>
      <div
        class="filter-type-option"
        @click="
          inChargerMode = true;
          showFilterTypeOptions = false;
        "
        :class="{ selected: inChargerMode }"
      >
        <BaseIcon name="lightning" />
        {{ $t("message.chargingStations") }}
      </div>
    </div> -->
    <!-- VEHICLE FILTER BODY -->
    <div
      class="Filter-Modal__dropdown Filter-Modal__dropdown--visible"
      v-if="$store.state.showMapFilterDropdown && !inChargerMode && activeTab === 0"
      :class="{
        'Filter-Modal__filter': $store.state.showMapFilter,
        'Filter-Modal__dropdown--frosted-glass': !$store.state.inDealerMode,
        'Filter-Modal__dropdown--white-background': $store.state.inDealerMode,
        'vehicle-dropdown-essentials': inEssentialsMode,
      }"
    >
      <!-- Filter bodies -->
      <DemoFilters v-if="filterMode && !this.$store.state.inDealerMode" />
      <DealerFilters v-if="filterMode" />
      <!-- Search body -->
      <div v-else-if="!showCurrentCar">
        <FilterModalCarItem
          v-show="!$store.state.inDealerMode"
          v-for="(vehicle, index) of filteredCars"
          :key="index"
          :vehicle="vehicle"
          :index="index"
        />
        <p class="lot-lbl" v-if="$store.state.inDealerMode && filteredCarsMainLot.length">
          Main Lot
        </p>
        <FilterModalCarItem
          v-for="(vehicle, index) of filteredCarsMainLot"
          :key="`main-${index}`"
          :vehicle="vehicle"
          :index="index"
        />
        <p class="lot-lbl" v-if="$store.state.inDealerMode && filteredCarsRemoteLot.length">
          Remote Lot
        </p>
        <FilterModalCarItem
          v-for="(vehicle, index) of filteredCarsRemoteLot"
          :key="`remote-${index}`"
          :vehicle="vehicle"
          :index="index"
        />
        <p class="lot-lbl" v-if="$store.state.inDealerMode && filteredCarsCourtesyLot.length">
          Courtesy Lot
        </p>
        <FilterModalCarItem
          v-for="(vehicle, index) of filteredCarsCourtesyLot"
          :key="`courtesy-${index}`"
          :vehicle="vehicle"
          :index="index"
        />
      </div>
    </div>
    <!-- PLACES FILTER BODY -->
    <div
      v-if="$store.state.showMapFilterDropdown && !inChargerMode && activeTab === 1"
      class="Filter-Modal__dropdown Filter-Modal__dropdown--visible places"
      :class="{
        'Filter-Modal__filter': $store.state.showMapFilter,
        'Filter-Modal__dropdown--frosted-glass': !$store.state.inDealerMode,
        'Filter-Modal__dropdown--white-background': $store.state.inDealerMode,
      }"
    >
      <!-- <div class="place" @click="$emit('selectPlace', 0)">
        <BaseIcon name="HERESportsFacility" />
        <span class="place-lbl">
          <b>{{ places[0].name }}</b> <span>{{ places[0].name }} {{ places[0].address }}</span>
        </span>
      </div> -->
      <div class="place" @click="$emit('selectPlace', 1)">
        <BaseIcon name="HEREBusinessIndustry" />
        <span class="place-lbl">
          <b>{{ places[1].name }}</b> <span>{{ places[1].name }} {{ places[1].address }}</span>
        </span>
      </div>
      <!-- <div class="place" @click="$emit('selectPlace', 2)">
        <BaseIcon name="HEREGoingOutEnt"
        <span class="place-lbl">
          <b>{{ places[2].name }}</b> <span>{{ places[2].name }} {{ places[2].address }}</span>
        </span>
      </div> -->
    </div>
    <!-- CHARGER FILTER BODY -->
    <div
      class="Filter-Modal__dropdown Filter-Modal__dropdown--visible"
      v-if="$store.state.showMapFilterDropdown && inChargerMode && activeTab === 2"
      :class="{
        'Filter-Modal__filter': $store.state.showMapFilter,
        'Filter-Modal__dropdown--frosted-glass': !$store.state.inDealerMode,
        'Filter-Modal__dropdown--white-background': $store.state.inDealerMode,
      }"
    >
      <EVChargerFilterToggles
        v-if="$store.state.showMapFilter"
        :key="chargerFilterToggleKey"
      />
      <template v-else>
        <div v-for="(charger, index) of filteredChargers" style="padding-left: 8px" :key="'a' + index">
          <EVChargerInfo
            @click="$emit('selectCharger', charger)"
            searchResults
            :charger="charger"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BackArrowIcon from "@/assets/icon_back_arrow.svg";
import FilterIcon from "@/assets/filter-icon.svg";
import SearchIcon from "@/assets/search-icon.svg";
import DemoFilters from "@/components/demo/DemoFilters.vue";
import DealerFilters from "@/components/dealer/DealerFilters.vue";
import FilterModalCarItem from "@/components/demo/FilterModalCarItem.vue";
import places from '../../json/places.json';
import EVChargerFilterToggles from "@/components/demo/EVChargerFilterToggles.vue";
import EVChargerInfo from "@/components/demo/EVChargerInfo.vue";
import BaseIcon from "@/components/icons/BaseIcon.vue";
import GuidedTourWaypoint from "./GuidedTourWaypoint.vue";

export default {
  props: {
    dataset: Object,
  },
  components: {
    BackArrowIcon,
    DealerFilters,
    DemoFilters,
    FilterIcon,
    FilterModalCarItem,
    SearchIcon,
    EVChargerFilterToggles,
    EVChargerInfo,
    BaseIcon,
    GuidedTourWaypoint
},
  data() {
    return {
      filterMode: false, // false shows search results, true shows filter options
      tagsOpen: false, //user clicked on arrow icon next to tag filters
      filterFocus: false, //user's focus is in the tag dropdown
      showFilterTypeOptions: false, // toggle menu to switch between vehicle and charge station search/filters
      inChargerMode: false, // false shows vehicle search/filter, true shows ev charger search/filter
      chargerFilterToggleKey: 0, // incremented when reset button is clicked for charger filters
      dropdownTimeout: -1,
      places,
    };
  },
  computed: {
    showCurrentCar: {
      get() {
        return this.$store.state.showCurrentCar;
      },
      set(data) {
        if (data) {
          this.$store.commit("turnOnCurrentCarVisibility");
        } else {
          this.$store.commit("turnOffCurrentCarVisibility");
        }
      },
    },
    searchQuery: {
      get() {
        return this.$store.state.mapSearch;
      },
      set(data) {
        return this.$store.commit("setMapSearch", data);
      },
    },
    filteredCars() {
      const query = this.searchQuery.toLowerCase();
      return this.$store.getters.filteredVehicles.filter(
        (car) =>
          (!query ||
            car["Vehicle Label"].toLowerCase().indexOf(query) > -1 ||
            `${car["Model Name"]} ${car.Trim}`.toLowerCase().indexOf(query) > -1)
      );
    },
    filteredCarsMainLot() {
      const query = this.searchQuery.toLowerCase();
      return this.$store.getters.filteredVehicles.filter(
        (car) =>
          car.Lot === "Main Lot" &&
          (!query ||
            car["Vehicle Label"].toLowerCase().indexOf(query) > -1 ||
            `${car["Model Name"]} ${car.Trim}`.toLowerCase().indexOf(query) > -1)
      );
    },
    whichMap() {
      if (this.$store.state.inDealerMode) return "us";
      if (
        this.$store.state.locale === "en-ca" ||
        this.$store.state.locale === "fr-ca"
      ) {
        return "ca";
      }
      if (this.$store.state.locale === "es") {
        return "mx";
      }
      return "us";
    },
    filteredChargers() {
      const query = this.searchQuery.toLowerCase();
      // if (!query) return [];
      return this.dataset.evChargers[this.whichMap].filter(
        (charger) =>
          charger.clickable &&
          (charger.brand.toLowerCase().indexOf(query) > -1 ||
            charger.name.toLowerCase().indexOf(query) > -1 ||
            charger.location.toLowerCase().indexOf(query) > -1 ||
            charger.ports.findIndex(
              (port) => port.type.toLowerCase().indexOf(query) > -1
            ) > -1)
      );
    },
    filteredCarsRemoteLot() {
      const query = this.searchQuery.toLowerCase();
      return this.$store.getters.filteredVehicles.filter(
        (car) =>
          car.Lot === "Remote Lot" &&
          (!query ||
            car["Vehicle Label"].toLowerCase().indexOf(query) > -1 ||
            `${car["Model Name"]} ${car.Trim}`.toLowerCase().indexOf(query) > -1)
      );
    },
    filteredCarsCourtesyLot() {
      const query = this.searchQuery.toLowerCase();
      return this.$store.getters.filteredVehicles.filter(
        (car) =>
          car.Lot === "Courtesy Lot" &&
          (!query ||
            car["Vehicle Label"].toLowerCase().indexOf(query) > -1 ||
            `${car["Model Name"]} ${car.Trim}`.toLowerCase().indexOf(query) > -1)
      );
    },
    inputPlaceholder() {
      if (this.activeTab === 0) return this.$t('message.labelLicensePlateVin');
      if (this.activeTab === 1) return this.$t('message.nameAddressCategory');
      if (this.activeTab === 2) return this.$t('message.nameAddressCategory');
      return '';
    },
    activeTab: {
      get() {
        return this.$store.state.activeFilterTab;
      },
      set(val) {
        this.$store.commit('setActiveFilterTab', val);
      }
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
    waypointIds() {
      if (this.inEssentialsMode) {
        return [5, 6];
      }
      return [4, 5, 6];
    }
  },
  methods: {
    delayHideDropdown() {
      this.dropdownTimeout = setTimeout(() => {
        this.$store.commit("closeMapFilterDropdown");
        // this.searchQuery = '';
      }, 300);
    },
    delayShowDropdown() {
      this.$store.commit("openMapFilterDropdown");
      clearTimeout(this.dropdownTimeout);
    },
    handleFilterIconClick() {
      if (this.activeTab === 0) {
        this.filterMode = !this.filterMode;
        this.$store.commit('toggleMapFilter');
        this.delayShowDropdown();
      } else if (this.activeTab === 2) {
        this.filterMode = !this.filterMode;
        this.$store.commit('toggleMapFilter');
        this.delayShowDropdown();
      }
    },
    handleWaypointOpened() {
      this.$emit('waypointOpened');
      if (this.inEssentialsMode) {
        this.activeTab = 1;
      }
    },
  },
  watch: {
    activeTab() {
      this.$store.commit('closeMapFilter');
      this.$store.commit('closeMapFilterDropdown');
      this.filterMode = false;
      if (this.activeTab === 2) this.inChargerMode = true;
      else this.inChargerMode = false;
    },
  },
  beforeDestroy() {
    this.activeTab = 0;
  },
};
</script>

<style lang="scss" scoped>
@mixin frosted-glass {
  // background-color: rgba(255, 255, 255, 0.85);
  // position: relative; /* needed for bg-color to work in firefox */
  // @supports (
  //   (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  // ) {
  //   & {
  //     background-color: rgba(255, 255, 255, 0.6);
  //     -webkit-backdrop-filter: blur(3px);
  //     backdrop-filter: blur(3px);
  //   }
  // }
  background-color: #fff;
}

.Filter-Modal {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 320px;
  // z-index: 1000;
  // padding: 20px 28px;
  margin: 32px 20px 20px 32px;
  border-radius: 0.25rem;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);

  &--frosted-glass {
    @include frosted-glass;
  }
  &__header {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    border-radius: 0 0 0.5rem 0.5rem;
    font-family: ProximaNova;
    // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    margin-top: -5px;
    // transition: border-radius 0.5s;
    &--filter-visible {
      background: #05b5e4;
    }
    &--expanded {
      border-radius: 0;
      border-bottom: 1px solid #d1d6e0;
    }
    // @media screen and (max-width: 615px) {
    //   width: 75vw;
    //   margin: 24px 0 0 15px;
    // }
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 36px;
    align-items: center;
    @include frosted-glass;
    padding: 0 5px;
    border-radius: 0.5rem 0.5rem 0 0;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #76777a;
      padding-bottom: 5px;
      border-bottom: 1px solid #d4d4d8;
      cursor: pointer;

      &.active {
        color: #05b5e4;
        padding-bottom: 4px;
        border-bottom: 2px solid #05b5e4;
        svg {
          fill: #05b5e4;
        }
      }

      svg {
        height: 18px;
        fill: #76777a;
      }
    }
  }

  &__filter-header {
    display: flex;
    height: 100%; //TODO: uncomment this when done
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
  }
  &__title {
    flex-grow: 1;
    font-size: 17px;
    line-height: 17px;
    font-family: ProximaNova;
  }
  &__clear {
    opacity: 0.5;
  }

  &__search-divider {
    // height: 80%;
    min-height: 25px;
    width: 1px;
    border-top: 0;
    border-left: solid 0.5px #d1d6e0;
    /* color pulled from https://app.onstarvehicleinsights.com/# */
    margin: 0 2px;
  }
  &__input {
    font-size: 15px;
    height: auto;
    border: 0;
    outline: none;
    color: var(--grey);
    background: transparent;
    min-width: 200px;
    padding-left: 8px;
    &::placeholder {
      color: var(--grey);
    }
  }
  &__dropdown {
    // width: 100%;
    width: 330px;
    // max-width: 274px;
    max-height: 75vh;
    border-radius: 0 0 8px 8px;
    transition: opacity 0.2s;
    opacity: 0;
    overflow-y: scroll;
    // padding: 0 28px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }
    &--visible {
      opacity: 1;
    }
    &--frosted-glass {
      @include frosted-glass;
    }
    &--white-background {
      position: relative; /* needed for bg-color to work in firefox */
      background: white;
    }
    &.vehicle-dropdown-essentials > div {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__filter {
    height: auto;
    padding: 16px 28px; // side padding required to get centered
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }
  &__buttons {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__icon {
    fill: #71798a;
    height: 24px;
    width: 24px;
    margin: 0.5em;
    cursor: pointer;
    &--filter:hover {
      fill: #05b5e4;
    }
    &.disabled {
      cursor: unset;
      fill: #c6c6ca;
      &:hover {
        fill: #c6c6ca;
      }
    }
  }
  .Filter-Modal__back-button {
    margin: 0;
    padding: 0;
    margin-right: 10%;
    background: transparent;
    outline: none;
    border: 0;
    cursor: pointer;
    svg {
      fill: #fff;
      height: 20px;
      width: 20px;
      padding: 0;
      margin: 0;
    }
  }
}
// New styling for charge station filter options 12/2021
.filter-type-icon {
  width: 18px;
  max-height: 18px;
  margin: auto 8px;
  padding: 6px;
  fill: var(--blue);
  border: 1px solid var(--light-grey);
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    fill: var(--light-blue);
  }
}
.filter-type-options-menu {
  position: absolute;
  top: 28px;
  left: 36px;
  width: 180px;
  // height: 60px;
  background-color: white;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 34px 34px;
  .filter-type-option {
    padding-left: 12px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    .filter-type-icon {
      margin: 0;
      margin-top: 1px;
      padding: 0;
      border: 0;
      fill: var(--dark-grey);
    }
    &.selected {
      color: var(--blue);
      background-color: #edf9ff;
      .filter-type-icon {
        fill: var(--blue);
      }
    }
    &:hover {
      background-color: #edf9ff;
    }
  }
}
.lot-lbl {
  margin-left: 14px;
}
.Filter-Modal__dropdown.places {
  display: grid;
}
.places .place {
  display: grid;
  grid-template-columns: 21px auto;
  border-bottom: 1px solid rgb(0 28 56 / 16%);
  gap: 10px;
  padding: 10px 8px 10px 16px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: rgb(237 249 255);
  }
  svg {
    width: 21px;
    fill: rgb(72, 111, 118);
  }
  .place-lbl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: rgb(96, 97, 100);
      font-size: 12px;
    }
  }
}
</style>
