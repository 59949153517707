<template>
  <div class="in-vehicle-coaching">
        <Modal
      :x="currentModalPage === 1"
      v-show="$store.state.showModal && !showUserTerms"
      :currentModalPage="currentModalPage"
    >
      <span slot="header"> {{ $t("message.inVehicleCoaching") }}</span>
      <div slot="body">
        <!-- MODAL PAGE 1 -->
        <div class="coaching-content-container" v-show="currentModalPage === 1">
          <div class="options">
            <div class="bold heading">{{ $t("message.applyTo") }}</div>
            <div class="row">
              <svg
                v-if="radio"
                class="radio"
                id="gml:icon/radiobutton"
                viewBox="0 0 24 24"
                @click="radio = !radio"
              >
                <path
                  fill="var(--grey)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                />
                <path
                  fill="var(--blue)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z"
                />
              </svg>
              <svg
                v-else
                class="radio"
                id="gml:icon/radiobutton"
                viewBox="0 0 24 24"
                @click="radio = !radio"
              >
                <path
                  fill="var(--blue)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                />
              </svg>
              <div class="option">
                <span class="semibold">
                  {{ $t("message.allCompatibleVehicles") }}
                </span>
                <span style="color: var(--dark-grey)">
                  {{
                    $t(
                      "message.newVehiclesAddedToTheAccountAutoEnrolledInVehicleCoachingIfCompatible"
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <svg
                v-if="!radio"
                class="radio"
                id="gml:icon/radiobutton"
                viewBox="0 0 24 24"
                @click="radio = !radio"
              >
                <path
                  fill="var(--grey)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                />
                <path
                  fill="var(--blue)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z"
                />
              </svg>
              <svg
                v-else
                @click="radio = !radio"
                class="radio"
                id="gml:icon/radiobutton"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--blue)"
                  d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                />
              </svg>
              <div class="option">
                <span class="semibold">
                  {{ $t("message.selectVehiclesManually") }}
                </span>
              </div>
            </div>
          </div>
          <div class="overview">
            <span class="heading">{{ $t("message.selectedVehicles") }}</span>
            <span class="number">3</span>
            <span class="price">$20.97 + {{ $t("message.taxPerMonth") }}</span>
            <span style="color: var(--grey)"
              >{{ $t("message.firstCharge") }}:<br />12/12/2020</span
            >
          </div>
          <div class="info">
            {{
              $t(
                "message.newVehiclesAddedToTheAccountAutoEnrolledInVehicleCoachingIfCompatible"
              )
            }}
          </div>
        </div>

        <!-- MODAL PAGE 2 -->
        <div class="select-vehicles" v-show="currentModalPage === 2">
          <VehicleSelection
            :dataset="dataset"
            :selected="selectedVehicles"
            @add="$store.commit('addIVCVehicle', $event)"
            @remove="$store.commit('removeIVCVehicle', $event)"
            @mass-action="$store.commit('massActionIVCVehicle', $event)"
            @done="showVehicleSelection = false"
          />
        </div>

        <div class="buttons" v-if="currentModalPage === 1">
          <button
            :disabled="currentModalPage === 1"
            @click="currentModalPage === 1 ? null : currentModalPage--"
            :class="`pg-${currentModalPage} cancel`"
          >
            {{
              currentModalPage === 1 ? $t("message.back") : $t("message.cancel")
            }}
          </button>
          <button
            @click="
              currentModalPage !== 1 || radio
                ? $store.commit('toggleModal')
                : currentModalPage++
            "
            :class="`pg-${currentModalPage}`"
          >
            {{
              currentModalPage === 1
                ? $t("message.next")
                : $t("message.saveSelection")
            }}
          </button>
        </div>
      </div>
    </Modal>

    <Modal v-if="showUserTerms" class="ivc" x>
      <span slot="header"> {{ $t("message.inVehicleCoaching") }}</span>
      <p slot="body">
        By adding a payment method and connecting vehicles, you authorize OnStar Vehicle
        Insights to automatically bill your payment method at the current standard monthly rates (plus
        applicable taxes and fees) for each Connected Vehicle after your Trial ends. The amount and
        frequency of each recurring payment is based upon the number of Connected Vehicle. Click on the
        "Subscriptions" tab to see your cost, billing date, Trial duration and Connected Vehicle count. You
        may cancel at any time by calling <span style="color: var(--blue)">(833) 266-6565</span>.
      </p>
    </Modal>

    <div class="ivc-head" v-if="!isMobile">
      <div class="flex center">
        <div class="ivc-circle">
          <svg id="gfs:icon1/ivc-lg" viewBox="0 0 24 24">
            <path
              fill="#011537"
              d="M10.54 4c.38.1.66.2.82.26l.17.08a8.24 8.24 0 011.92 1.34 6.67 6.67 0 011.66 2.35c.1.25.17.51.24.8l.12.59.17 1 .04.2.07.14c.08.16.21.4.39.7l.35.6.85 1.4a.4.4 0 01-.26.61l-.08.01h-1.22v2.38c-.03.3-.18.57-.4.77l-.1.07a.38.38 0 01-.15.06l-.08.01h-1.77c-.32 0-.47.09-.56.23a.47.47 0 00-.06.17v.1l-.33 2.71-.8-.1.33-2.65c0-.18.04-.4.17-.63.2-.36.57-.58 1.07-.62h1.8l.03-.05.04-.06.01-.05v-2.74c0-.2.14-.36.32-.4h.98l-.29-.5-.24-.4c-.42-.7-.7-1.2-.82-1.5l-.05-.22a7.14 7.14 0 01-.06-.26l-.19-1.1-.02-.07a5.83 5.83 0 00-.24-.9 5.86 5.86 0 00-1.46-2.07 7.5 7.5 0 00-1.8-1.24L11.05 5a6.48 6.48 0 00-4.7-.07l-.33.13c-2.22 1.02-3.3 3.08-3.45 5.77v.52a8.61 8.61 0 001.2 3.48l.3.52c.26.47.77 1.33.89 1.53.18.33.33.68.45 1.07.19.58.31 1.2.38 1.82l.03.34.03.4v.09h-.8v-.18a9.56 9.56 0 00-.4-2.22 5.24 5.24 0 00-.4-.93l-1-1.73c-.9-1.58-1.39-2.86-1.48-4.13a5.22 5.22 0 010-.63c.15-2.85 1.31-5.12 3.66-6.32l.32-.15a7.17 7.17 0 014.79-.32z"
            ></path>
            <path
              fill="#1D6091"
              d="M20.55 12.18a6.1 6.1 0 01.23 8.36l-.18.2-.57-.57a5.3 5.3 0 00.12-7.24l-.17-.19.57-.56zm-.92.88c1.81 1.8 1.9 4.7.23 6.6l-.16.18-.58-.56a4.03 4.03 0 00.1-5.5l-.15-.15.56-.57zm-.9.88a3.57 3.57 0 01.21 4.85l-.13.15-.58-.55c1-1.05 1-2.68.06-3.74l-.13-.14.57-.57z"
            ></path>
          </svg>
        </div>
        <h1 v-if="!isMobile">{{ $t("message.inVehicleCoaching") }}</h1>
      </div>
      <div class="flex column center add-container">
        <div class="flex center">
          <button class="add-button" @click="$store.commit('toggleModal')">
            <Icon :icon="plusIcon" class="plus-icon" />
            <span>{{ $t("message.add") }}</span>
          </button>
        </div>
        <b>{{ $i18n.locale === "fr-ca" ? "6,99$" : "$6.99" }}</b>
        <span class="unit"
          >{{ $t("message.vehicle") }} / {{ $t("message.month") }}</span
        >
      </div>
    </div>
    <div class="ivc-gradient" />
    <div class="ivc-main">
      <div class="slideshow">
        <svg
          class="prev-btn"
          @click="
            currentSlide > 0 ? currentSlide-- : (currentSlide = numSlides - 1)
          "
          id="gml:icon1/carousel-prev"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
          ></path>
        </svg>
        <IVCCarousel :currentSlide="currentSlide" />

        <svg
          class="next-btn"
          @click="
            currentSlide < numSlides - 1 ? currentSlide++ : (currentSlide = 0)
          "
          id="gml:icon1/carousel-next"
          viewBox="0 0 24 24"
        >
          <path
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          ></path>
        </svg>
        <div class="dots flex center">
          <div
            class="dot"
            v-for="i in numSlides"
            :class="currentSlide === i - 1 ? 'blue' : 'grey'"
            @click="currentSlide = i - 1"
            :key="i"
          />
        </div>
      </div>
      <div class="flex column">
        <div class="flex" style="justify-content: space-between">
          <b>{{ $t("message.vehicles") }}</b>
          <svg
            class="vehicles-dot-dot-dot"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            />
          </svg>
        </div>
        <div class="flex">
          <div class="big-number-wrap flex column">
            <div class="big-number">{{ numEnrolled }}</div>
            <div class="flex center">
              <div class="dot" />
              <span class="ivc-status">{{ $t("message.enrolled") }}</span>
            </div>
          </div>
          <div class="big-number-wrap flex column">
            <div class="big-number">{{ numNotEnrolled }}</div>
            <div class="flex center">
              <div class="dot grey" />
              <span class="ivc-status">{{ $t("message.notEnrolled") }}</span>
            </div>
          </div>
        </div>
        <BarChart :value="percentEnrolled" :width="300" :height="6.5" rounded />
      </div>
      <div class="flex column">
        <h3 style="margin-top: 0">{{ $t("message.helpYourDrivers") }}</h3>
        <div class="ivc-vox-grid">
          <!-- Hard Brake -->
          <AudioPlayButton src="hardBrake-EN-US.mp3" />
          <svg id="gfs:icon1/i-hard-brake" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M12.2 4.4a7.6 7.6 0 110 15.2 7.6 7.6 0 010-15.2zm7.18 1.23a9.57 9.57 0 01-.04 12.8l-.9-.8a8.36 8.36 0 00.04-11.2l.9-.8zM5.07 5.58l.9.8a8.36 8.36 0 00-.03 11.23l-.89.8a9.56 9.56 0 01.02-12.84zm7.13.02a6.4 6.4 0 100 12.8 6.4 6.4 0 000-12.8zm0 8.9a.83.83 0 110 1.67.83.83 0 010-1.67zm.63-6.67v5h-1.26v-5h1.25z"
            ></path>
          </svg>
          <span v-html="b($t('message.hardBrakeNotifies'))" />

          <div class="line" />
          <!-- Hard Acceleration -->
          <AudioPlayButton src="hardAcceleration-EN-US.mp3" />
          <svg id="gfs:icon1/i-hard-acceleration" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M21 3.52l.1.1v1.07l-.1.1-6.4-.03-5.11 5.2v6.55h1.17l.02-.1a3.3 3.3 0 012.8-2.58l.2-.01h.18a3.3 3.3 0 013.2 2.59l.01.1H21l.1.1v1.08l-.1.1h-3.93l-.02.1a3.3 3.3 0 01-2.82 2.57l-.19.02h-.18a3.28 3.28 0 01-3.18-2.6l-.02-.1H8.34l-.1-.1V16.5H3.87l-.1-.1v-.94l.1-.1h4.37v-3.28H2.06l-.1-.1v-.94l.1-.1h6.18l.02-1.49 2.32-2.37H5.8l-.1-.1v-.94l.1-.1 5.85.03 2.43-2.44H21zm-7.14 11.57c-1.1 0-2.01.92-2.01 2.06 0 1.13.9 2.06 2 2.06 1.13 0 2.03-.92 2.03-2.06 0-1.14-.9-2.06-2.02-2.06z"
            ></path>
          </svg>
          <span v-html="b($t('message.hardAccelerationNotifies'))" />

          <div class="line" />
          <!-- Seatbelt Unbuckled -->
          <AudioPlayButton
            src="seatbelt-EN-US.mp3"
            @checkChallenge="checkChallenge($event)"
          />
          <svg id="gfs:icon1/i-seatbelt-unbuckled" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M7.29 11.4l5.66 5.65a.6.6 0 010 .85l-2.83 2.83a.6.6 0 01-.85 0l-.86-.87-2.77 2.77-.85-.85 2.4-2.4-1.97-1.98-2.4 2.4-.86-.85 2.77-2.76-1.12-1.12a.6.6 0 010-.85l2.83-2.83a.6.6 0 01.85 0zm-.42 1.26l-1.98 1.98 4.8 4.81 1.98-1.98-4.8-4.8zm11.78-10.4l.85.85-3.11 3.11 1.98 1.98 3.11-3.1.85.84-3.17 3.17.84.84c.2.21.23.54.07.77l-.07.08-2.81 2.81c-.1.1-.23.18-.39.2h-.1l-2.4-.15-1.38 1.39a.6.6 0 01-.76.07l-.08-.07-2.83-2.83a.6.6 0 01-.07-.77l.07-.08 1.38-1.38-.12-2.26a.6.6 0 01.09-.53l.06-.07L13.5 4.3a.6.6 0 01.76-.07l.09.07 1.14 1.14 3.17-3.18zM11.8 9.1l.05 1.09a.6.6 0 01-.1.38l-.07.07-1.15 1.15 1.98 1.98 1.15-1.14a.6.6 0 01.36-.18h.1l1.1.07-3.42-3.42zm.88 1.83l.76.76-1.03 1.03-.76-.77 1.02-1.02zm1.25-5.36l-1.98 1.98 4.8 4.8 1.98-1.97-4.8-4.81zM8.07 4a.6.6 0 01.64.58l.39 4.15-1.24.1-.27-2.73-4.65 5.16-.08-.1a1.23 1.23 0 010-1.64l3.81-4.24-2.74.01-.03-1.24z"
            ></path>
          </svg>
          <span v-html="b($t('message.seatbeltNotifies'))" />

          <div class="line" />
          <!-- Fixed Speed -->
          <AudioPlayButton src="fixedSpeed-EN-US.mp3" />
          <svg id="gfs:icon1/i-fixed-speed" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M12.2 11.67c.2 0 .37.17.36.38l.84 6.4a2.5 2.5 0 11-2.66-.1l.16-.09.9-6.22c.01-.2.18-.37.4-.37zm2.99-4.64a9.8 9.8 0 015.01 14.61l-.18.29h-4.56c.16-.4.25-.82.27-1.25h3.6a8.54 8.54 0 10-14.65 0h3.6c.01.43.1.85.26 1.25H3.98l-.18-.29a9.8 9.8 0 0111.39-14.6zM12 19.22a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM10.52 2.39l3.11.05-1.6 2.66-1.51-2.71z"
            ></path>
          </svg>
          <span v-html="b($t('message.fixedSpeedLimitNotifies'))" />

          <div class="line" />
          <!-- Road Speed -->
          <AudioPlayButton src="roadSpeed-EN-US.mp3" />
          <svg id="gfs:icon1/i-road-speed" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M8.18 4.5l-3.71 16H3l3.72-16h1.46zm9.4 0l3.7 16h-1.46l-3.72-16h1.47zm-4.65 12.8v2.95H11.5V17.3h1.42zm0-5.9v2.95H11.5V11.4h1.42zm0-5.9v2.95H11.5V5.5h1.42z"
            ></path>
          </svg>
          <span v-html="b($t('message.roadSpeedLimitNotifies'))" />

          <div class="line" />
        </div>
        <span class="more-info">For more information view In-Vehicle Coaching <a @click="showUserTerms = true">User Terms</a>.</span>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/demo/BarChart.vue";
import AudioPlayButton from "@/components/demo/AudioPlayButton.vue";
import IVCCarousel from "@/components/demo/IVCCarousel.vue";
import Modal from "@/components/demo/Modal.vue";
import VehicleSelection from "@/components/demo/VehicleSelection.vue";
import { Icon } from '@iconify/vue2';
import plusIcon from '@iconify/icons-mdi/plus';

export default {
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  methods: {
    checkChallenge() {
      //checks if go the extra mile challenge is completed and alerts parent components
      if (this.activeChallenge === "not-buckled-not-cool") {
        this.$emit("challengeComplete", 17);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
    },
    b(str) {
      let formattedString = str;
      const keywords = ['Hard Brake', 'Hard Acceleration', 'Seatbelt is Unbuckled', 'Fixed Speed', 'Road Speed Limit'];

      keywords.forEach(keyword => {
        const regex = new RegExp(keyword, 'gi');
        formattedString = formattedString.replace(regex, `<b>${keyword}</b>`);
      });

      return formattedString;
    },
  },
  components: {
    BarChart,
    AudioPlayButton,
    IVCCarousel,
    Modal,
    VehicleSelection,
    Icon,
  },
  data() {
    return {
      radio: false,
      showVehicleSelection: false,
      numNotEnrolled: 0,
      currentSlide: 0,
      numSlides: 4,
      bingo: false,
      currentModalPage: 1,
      showUserTerms: false,
      search: "",
      plusIcon: plusIcon,
    };
  },
  computed: {
    selectedVehicles() {
      return this.$store.state.ivcVehicles;
    },
    numEnrolled() {
      return this.dataset.vehicles.length;
    },
    percentEnrolled() {
      const total = this.numEnrolled + this.numNotEnrolled;
      return (this.numEnrolled / total) * 100;
    },
    showModal() {
      return this.$store.state.showModal;
    },
  },
  watch: {
    showUserTerms() {
      if (this.showUserTerms) {
        this.$store.commit('toggleModal');
      }
    },
    showModal() {
      if (this.showUserTerms && !this.showModal) {
        this.showUserTerms = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ivc-head {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 36px;
  box-sizing: border-box;
}
.ivc-circle {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}
.ivc-circle svg {
  width: 32px;
  height: 32px;
}
.ivc-gradient {
  background: linear-gradient(to bottom, #f5f5f5, #ffffff 100%);
  width: 100%;
  height: 50px;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  margin-right: 8px;
  margin-bottom: 16px;
}
.dot.blue {
  background-color: var(--blue);
}
.dot.grey {
  background-color: var(--grey);
}
.ivc-status {
  font-size: 12px;
  color: var(--dark);
  margin-bottom: 16px;
}
.unit {
  color: var(--dark-grey);
}
.ivc-main {
  display: grid;
  // grid-template-rows: 180px 420px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 72px;
}
.slideshow {
  width: 100%;
  height: 100%;
  grid-row: 1 / 3;
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-template-rows: 1fr 40px;
  grid-template-areas:
    "prev carousel next"
    "dots dots dots";
}
.slideshow .prev-btn,
.slideshow .next-btn {
  fill: var(--blue);
  width: 20px;
  height: 20px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
}
.slideshow .prev-btn {
  grid-area: prev;
}
.slideshow .next-btn {
  grid-area: next;
}
.slideshow .dots {
  grid-area: dots;
  padding-top: 16px;
}
.slideshow .dots .dot {
  cursor: pointer;
}
h3 {
  font-size: 19px;
}
.vehicles-dot-dot-dot {
  fill: var(--blue);
  width: 20px;
  height: 20px;
}
.big-number-wrap {
  margin-right: 22px;
}
.big-number {
  font-size: 40px;
  color: var(--dark);
  font-weight: 300;
  margin-left: 4px;
  margin-top: 8px;
}
.ivc-vox-grid {
  display: grid;
  grid-template-columns: 32px 32px 1fr;
  grid-template-rows: repeat(5, 1fr 1px) 1px;
  gap: 12px;
  align-items: center;
  color: #606164;
}
.ivc-vox-grid svg {
  width: 32px;
  height: 32px;
  fill: var(--dark-grey);
}
.ivc-vox-grid .line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
  grid-column: 1 / 4;
}
.add-container {
  align-items: unset;
  text-align: center;
}

.add-button {
  background: var(--blue);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: bold;
  width: 100%;
  height: 30px;
  margin-bottom: 4px;

  .plus-icon {
    height: 16px;
    width: 16px;
  }
}
.coaching-content-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .options {
    max-width: 55%;
    .heading {
      margin-bottom: 20px;
    }
    .row {
      display: flex;
      svg {
        height: 18px;
        width: 18px;
        min-height: 18px;
        max-height: 18px;
        min-width: 18px;
        max-width: 18px;

        margin-right: 2px;
      }
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .semibold {
        font-weight: 600;
      }
    }
  }
  .overview {
    width: 35%;
    padding: 50px 10px 20px 10px;
    border: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      margin: 5px 0;
      font-weight: bold;
    }
    .heading {
      font-size: 1.6rem;
      text-align: center;
    }
    .number {
      border-bottom: 1px solid var(--grey);
      width: fit-content;
      padding-bottom: 10px;
      font-size: 3.5rem;
    }
    .price {
      font-size: 2rem;
      font-weight: normal;
    }
  }
  .info {
    width: 100%;
    margin: 60px 0 20px 0;
    color: var(--grey);
  }
}
.select-vehicles-container {
  width: 90%;
  padding: 0 5%;
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    .tab {
      width: 50%;
      text-align: center;
      font-weight: bold;
      color: var(--blue);
    }
    .compatible {
      border-bottom: 1px solid var(--blue);
    }
  }
  .selection {
    width: 20%;
  }
  .search-and-upload-container {
    display: flex;
    .search-container {
      width: 30%;
      border-right: 1px solid var(--light-grey);
      margin-right: 10px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      .input {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border: 1px solid var(--light-grey);
        padding: 0 5px;
        border-radius: 0.25rem;
        max-height: 25px;
        input {
          border: 0;
          width: 100%;
        }
      }
      .icon {
        width: 24px;
        height: 24px;
      }
    }

    button {
      max-height: 35px;
      min-height: 25px;
      font-weight: bold;
      border-radius: 0.25rem;
      width: fit-content;
      color: var(--blue);
      background: #fff;
      border: 1px solid var(--light-grey);
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
  }
}
.table-container {
  .table-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    color: var(--light-grey);
    margin-top: 20px;
    .selection {
      color: var(--blue);
      display: flex;
      justify-content: flex-end;
      width: 30%;
      .select-all {
        border-right: 1px solid var(--light-grey);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
}
.buttons {
  border-top: 1px solid var(--grey);
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  button {
    font-weight: bold;
    border: 0;
    border-radius: 0.25rem;

    &.pg-1 {
      color: var(--blue);
      background: #fff;
      margin-right: 10px;
    }
    &.pg-2 {
      background: var(--blue);
      color: #fff;
    }
    &.pg-1:disabled {
      color: var(--grey);
      background: #fff;
    }
    &.pg-2:disabled {
      border: 1px solid var(--grey);
    }
    &.cancel {
      width: 10%;
      color: var(--blue);
      margin-right: 10px;
      background: #fff;
      border: 1px solid var(--grey);
    }
  }
}

.more-info {
  font-size: 15px;
  color: #606164;
  a {
    color: var(--blue);
    font-weight: 600;
    text-decoration: none;
  }
}

.select-vehicles {
  .vehicle-selection {
    padding: 15px 0;
  }
}
@media screen and (max-width: 845px),
  (max-width: 1120px) and (min-width: 970px) {
  .ivc-main {
    grid-template-columns: 1fr;
    grid-template-rows: auto 480px auto;
    margin: 0 10%;
  }
  .slideshow {
    grid-row: 2 / 3;
  }
}
@media screen and (max-width: 425px) {
  .ivc-main {
    margin: 0 5%;
  }
}

</style>
