<template>
  <div class="location-marker-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="40"
      height="46"
      viewBox="0 0 40 46"
    >
      <defs>
        <filter
          id="664txb58fa"
          width="212.5%"
          height="189.9%"
          x="-56.3%"
          y="-31.7%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="4"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3.5"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter2"
          />
          <feOffset
            dy="4"
            in="shadowSpreadOuter2"
            result="shadowOffsetOuter2"
          />
          <feGaussianBlur
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
            stdDeviation="3.5"
          />
          <feComposite
            in="shadowBlurOuter2"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter2"
          />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <path
          id="hj7yeffvsb"
          d="M12 0C5.4 0 0 5.09 0 11.354c0 2.48.867 4.829 2.4 6.787l9.067 11.615c.2.26.666.326.933.13l.133-.13L21.6 18.14c1.533-1.958 2.4-4.307 2.4-6.787C24 5.09 18.6 0 12 0z"
        />
      </defs>
      <g fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g>
          <g>
            <g>
              <g
                transform="translate(-124 -301) translate(-1 -1) translate(55.4 -225.8) translate(77.6 531.8)"
              >
                <use
                  fill="#000"
                  filter="url(#664txb58fa)"
                  xlink:href="#hj7yeffvsb"
                />
                <path
                  fill="var(--blue)"
                  stroke="#fff"
                  d="M12-.5c3.437 0 6.56 1.328 8.824 3.47 2.27 2.148 3.676 5.114 3.676 8.384 0 2.592-.903 5.048-2.506 7.094h0l-9.098 11.656c-.305.267-.642.373-.94.373-.376 0-.695-.168-.883-.414h0L2.006 18.45C.403 16.402-.5 13.946-.5 11.354c0-3.27 1.406-6.236 3.676-8.384C5.44.828 8.563-.5 12-.5h0z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.location-marker-icon {
  position: absolute;
  top: -30px;
  left: -18px;
  z-index: 1000;
}
</style>
