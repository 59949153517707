<template>
  <div class="driver-details">
        <h1 v-if="!isMobile">{{ $t("message." + camelize(obj["Name"])) }}</h1>
    <div v-else style="margin-top: 16px" />
    <div class="sections">
      <div
        class="section-btn"
        :class="{ selected: page === 1 }"
        @click="page = 1"
      >
        {{ $t("message.overview") }}
      </div>
      <div
        v-if="!$store.state.inDealerMode"
        class="section-btn"
        :class="{ selected: page === 2, disabled: !canSwitchToTripHistory }"
        @click="switchToTripHistory"
      >
        {{ $t("message.tripHistory") }}
      </div>
      <div
        class="section-btn"
        :class="{ selected: page === 3 }"
        @click="page = 3"
      >
        {{ $t("message.timeline") }}
      </div>
    </div>
    <div class="gradient-divider" :style="page === 2 ? 'z-index: 15;' : ''" />
    <ModalView
      v-if="showAssignmentModal"
      @hide="showAssignmentModal = false"
      :multiplier="multiplier"
    >
      <AssignVehicleDriver
        header="Assign Vehicle to Driver"
        :vehicles="dataset.vehicles"
        :multiplier="multiplier"
        :numIncomplete="numIncomplete"
        :timeLeft="timeLeft"
        :activeChallenge="activeChallenge"
        @hide="showAssignmentModal = false"
        @assign-vehicle="handleAssignment"
        @pauseTimer="pauseTimer()"
        @challengeComplete="challengeComplete($event)"
        :shown="sidebarVisible"
      />
    </ModalView>

    <!-- Overview page -->
    <div class="flex w-80 center overview-container" v-if="page === 1">
      <div class="detail-wrapper">
        <div class="flex info-wrap driver-detail-overview column">
          <div class="info-grid">
            <span> {{ $t("message.email") }}</span>
            <b class="blue">{{
              $t("message." + camelize(obj["Name"] + "Email"))
            }}</b>
            <span> {{ $t("message.phone") }}</span>
            <b class="blue">{{ obj["Phone"] }}</b>
            <span> {{ $t("message.address") }}</span>
            <b>{{ $t("message.fullAddress") }}</b>
            <span>{{ $t("message.timeZone") }}</span>
            <b>{{ $t("message.detroit") }}</b>
            <span>{{ $t("message.role") }}</span>
            <b>{{ $t("message.driver") }}</b>
            <span>{{ $t('message.assignedVehicle') }}</span>
            <b class="blue">{{ obj["Vehicle"] }}</b>
          </div>
          <div class="info-grid">
            <span>{{ $t("message.fleetName") }}</span>
            <b>{{ obj["Fleet"] }}</b>
            <span>{{ $t("message.language") }}</span>
            <b>{{ $t("message.userLanguage") }}</b>
            <span>{{ $t("message.dateFormat") }}</span>
            <b>dd/mm/yy</b>
            <span>{{ $t("message.timeFormat") }}</span>
            <b>{{ $t("message.12HourClock") }}</b>
            <span>{{ $t("message.units") }}</span>
            <b>{{ $t("message.metricSystem") }}</b>
          </div>
          <div class="button-grid flex">
            <div>{{ $t("message.edit") }}</div>
            <div>{{ $t("message.changePassword") }}</div>
          </div>
        </div>
      </div>
      <div class="profile-picture-wrap">
        <div class="circle">{{ initials }}</div>
        <b>{{ $t(`message.${camelize(obj["Name"])}`) }}</b>
        <span>{{ $t("message.joined") }} {{ formattedPriorDate }}</span>
      </div>
    </div>

    <div class="overview-lower-area" v-if="page === 1">
      <div class="border-line" />
      <div class="chart-wrap">
        <div class="title-area">
          <h4 class="has-subtitle">{{ $t('message.driverScore') }}</h4>
          <span class="subtitle">{{ $t('message.lastTripEnded') }}: {{ formattedCurrentDate }}</span>
          <div class="timerange-buttons">
            <span
              class="small semibold clickable"
              @click="graphToView = 7; checkChallenge();"
              :class="{ underlined: graphToView === 7 }"
            >
              {{ $t('message.last7Days') }}
            </span>
            <span class="small semibold">&nbsp;|&nbsp;</span>
            <span class="small semibold clickable" @click="graphToView = 30" :class="{ underlined: graphToView === 30 }">
              {{ $t('message.last30Days') }}
            </span>
          </div>
        </div>
        <h1 :style="{ color: scoreColor }">
          {{ obj.details.score[graphToView + "Day"] }}
        </h1>
        <DriverScoreGraph :obj="obj" :graphToView="graphToView" />
      </div>
    </div>

    <!-- Trip History page -->
    <div
      class="section"
      ref="triphistsec"
      :style="
        isMobile
          ? 'height: calc(100vh - 178px);'
          : 'height: calc(100vh - 250px);'
      "
      v-if="page === 2"
    >
      <TripHistory
        fromDriver
        :mainSidebarVisible="sidebarVisible"
        :isMobile="isMobile"
        :isTablet="isTablet"
        :trips="trips"
        :summary="obj.details.tripHistorySummary[$t('tripHistoryKey')]"
      />
    </div>

    <!-- Notifications page -->
    <div class="section" v-else-if="page === 3">
      <NotificationsListSearchable :notifications="notifications" />
    </div>
  </div>
</template>

<script>
import DriverScoreGraph from '@/components/demo/DriverScoreGraph.vue';
import TripHistory from "@/components/demo/TripHistory.vue";
import NotificationsListSearchable from "@/components/demo/NotificationsListSearchable.vue";
import ModalView from "@/components/demo/ModalView.vue";
import AssignVehicleDriver from "@/components/demo/AssignVehicleDriver.vue";
import moment from "moment";
import Vue from "vue";
import { camelize } from "../../utils/camelize.js";

export default {
  props: {
    id: String,
    dataset: Object,
    isMobile: Boolean,
    isTablet: Boolean,
    sidebarVisible: Boolean,
    numIncomplete: Number,
    challenges: Array,
    multiplier: Number,
  },
  data() {
    return {
      page: 1,
      graphToView: 30,
      showAssignmentModal: false,
      activeChallenge: "",
      bingo: false,
      startTime: Number,
      camelize: camelize,
    };
  },
  components: {
    DriverScoreGraph,
    TripHistory,
    NotificationsListSearchable,
    ModalView,
    AssignVehicleDriver,
  },
  computed: {
    index() {
      return parseInt(this.id);
    },
    obj() {
      // return this.dataset.drivers[this.index];
      if (this.$store.state.inDealerMode) {
        return this.$store.state.dataset.drivers.find((d) => d.id === this.id);
      } else {
        return this.$store.state.dataset.drivers.find((s) => s.id === this.id);
      }
    },
    initials() {
      let first;
      let second;
      const name = this.$t(`message.${camelize(this.obj["Name"])}`);
      first = name.charAt(0);
      second = name.split(" ")[1].charAt(0);
      return first + second;
    },
    scoreColor() {
      if (this.obj.details.score[this.graphToView + "Day"] > 89)
        return "var(--green)";
      else if (this.obj.details.score[this.graphToView + "Day"] < 80)
        return "var(--red)";
      return "var(--orange)";
    },
    formattedCurrentDate() {
      let obj = moment();
      return obj.format("MM/DD/YYYY");
    },
    formattedPriorDate() {
      let obj = moment().subtract(7, "days");
      obj.hour(10);
      obj.minute(18);
      return obj.format("MMM D, hh:mm A");
    },
    trips() {
      let arr = [];
      const driver = camelize(this.obj["Name"]);
      for (let i = 0; i < this.dataset.tripHistory.length; i++) {
        const obj = this.dataset.tripHistory[i];
        if (obj.driver === driver) arr.push(obj);
      }
      return arr;
    },
    notifications() {
      let arr = [];
      const driver = this.obj["Name"];
      for (let i = 0; i < this.dataset.notifications.length; i++) {
        const obj = this.dataset.notifications[i];
        if (obj.driver === driver) arr.push(obj);
      }
      return arr;
    },
    timeLeft() {
      return this.$parent.timeLeft;
    },
    canSwitchToTripHistory() {
      // Temporarily disable trip history for MX region
      // Set enable/disable state in translations file
      return this.$t("allowTripHistory") === "TRUE";
    }
  },
  methods: {
    selected() {
      //checks if turn-by-turn-turner challenge is completed and alerts parent components
      if (
        this.activeChallenge === "turn-by-turn-turner" &&
        this.$route.path === "/2/detail/3"
      ) {
        this.$emit("challengeComplete", 16);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
    },
    goToVehicle(vehicleName) {
      const filteredVehicleArr = this.dataset.vehicles.filter(
        (vehicle) => vehicle["Vehicle Label"] === vehicleName
      );
      const index = this.dataset.vehicles.indexOf(filteredVehicleArr[0]);
      if (index !== -1) {
        this.$router.push("/1/detail/" + index);
      }
    },
    releaseOrAssignVehicle() {
      if (this.obj["Vehicle"] === "") {
        this.showAssignmentModal = true;
      } else {
        Vue.set(this.dataset.drivers[this.index], "Vehicle", "");
        this.$forceUpdate();
      }
    },
    handleAssignment(vehicle) {
      Vue.set(
        this.dataset.drivers[this.index],
        "Vehicle",
        vehicle["Vehicle Label"]
      );
      this.$forceUpdate();
    },
    checkChallenge() {
      if (
        this.activeChallenge === "trips-dont-lie" &&
        this.$route.path === "/2/detail/0"
      ) {
        setTimeout(() => {
          this.bingo = true;
          this.$emit("challengeComplete", 7);
          this.$emit("pauseTimer");
        }, 1000);
      }
    },
    challengeComplete(chal) {
      this.$emit("challengeComplete", chal);
    },
    pauseTimer() {
      this.$emit("pauseTimer");
    },
    switchToTripHistory() {
      if (this.canSwitchToTripHistory) {
        this.page = 2;
        this.selected();
      }
    }
  },
  mounted() {
    this.$route.params.title = this.obj["Name"];
  },
  created() {
    if (this.$attrs.activeChallenge) {
      this.activeChallenge = this.$attrs.activeChallenge;
    }
    this.startTime = this.timeLeft;
  },
  watch: {
    numIncomplete: function () {
      if (this.numIncomplete === 0) {
        this.$emit("noMoreChallenges");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.driver-details,
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .info-grid {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 8px;
    margin-bottom: 16px;
    span {
      text-transform: capitalize;
    }
    b {
      font-weight: normal;
      &.blue {
        font-weight: 600;
      }
    }
    @media screen and (max-width: 1112px) {
      width: 100%;
      grid-template-columns: 35% auto;
    }
  }
}
.overview-container {
  .detail-wrapper {
    width: 70%;
  }
  @media screen and (max-width: 1112px) {
    flex-direction: column-reverse;
    .detail-wrapper {
      width: 100%;
    }
  }
}
.profile-picture-wrap {
  width: 218px;
  height: 130px;
  min-height: 130px;
  padding: 16px;
  background-color: var(--pale-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
  @media screen and (max-width: 1112px) {
    margin-top: 24px;
    margin-bottom: 48px;
  }
}
.profile-picture-wrap b {
  font-weight: 700;
  font-size: 15px;
  line-height: 12px;
  margin-top: 11px;
  color: var(--dark);
}
.profile-picture-wrap span {
  margin-top: 2px;
  font-size: 13px;
  color: var(--dark-grey);
}
.circle {
  background-color: #6f166d;
  width: 88px;
  height: 85px;
  min-height: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 34px;
  line-height: unset;
  font-weight: 700;
  padding-top: 3px;
}

.overview-lower-area {
  margin: 32px auto; 
  width: 80%;
  .border-line {
    width: 100%;
    margin-bottom: 16px;
  }
}

img.notifications {
  max-width: 360px;
  max-height: 187px;
  margin-top: 24px;
}
.button-grid.vert {
  grid-template: 30px 30px / 140px;
}
.blue {
  color: var(--blue);
}
.chart-wrap {
  // padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
}
.chart-wrap .title-area {
  width: 100%;
}
.chart-wrap svg {
  min-height: 300px;
}
.chart-wrap h1 {
  /* margin: 16px 0 16px 64px; */
  width: 513px;
  /* padding-left: 60px; */
  color: var(--orange);
}
@media screen and (max-width: 1112px) {
  .overview-container {
    width: 100%;
  }
  .driver-detail-overview {
    flex-direction: column;
    width: 100%;
  }
  .info-grid {
    margin: 0;
  }
  .button-grid.vert {
    margin: 16px 0 32px 0;
    grid-template-columns: 100%;
  }
  .chart-wrap h1,
  .chart-wrap img {
    width: 100vw;
  }
}

.chart-wrap .title-area .timerange-buttons {
  color: var(--blue);
  padding: 8px 0 16px 16px;
}
.underlined {
  border-bottom: 1px solid var(--blue);
}

.w-80 {
  width: 80%;
}

// .center {
// margin: 0 auto;
// }
</style>
