<template>
  <div class="performance" :class="{'is-essentials': inEssentialsMode}">
    <div style="margin-top: 24px;" />
    <div class="top-bar">
      <!-- Fleet dropdown -->
      <span v-if="!$store.state.inDealerMode">{{ $t('message.fleet') }}</span>
      <DropDown
        v-if="!$store.state.inDealerMode"
        :options="options.map((o) => o.text)"
        :value="selectedFleet.text"
        @input="selectedFleet = options.find((o) => o.text === $event)"
        class="dropdown"
      />
    </div>

    <div class="gradient-divider" />
    
    <DashboardArea
      class="dashboard-measures"
      :dataset="dataset" 
      :measuresToDisplay="measuresToDisplay"
    >
      <template v-slot:grid-top>
        <!-- Search Area -->
        <b class="welcome">{{ $t('message.welcome') }}, Juan Torres!</b>
        <div class="search-area">
          <h4>{{ $t('message.searchForVehicle') }}</h4>
          <input 
            id="search-for-vehicles-box"
            class="search-box"
            :placeholder="$t('message.labelMakeAndModelVin')"
            v-model="searchQuery"
            @keyup.enter="search"
            type="text"
          >
        </div>
      </template>
      <template v-slot:grid-bottom>
        <div class="fleet-overview" v-if="!inEssentialsMode">
          <div class="flex" style="justify-content: space-between">
            <span class="title">{{ $t('message.fleetOverview') }}</span>
            <span class="blue" @click="$router.push('/0')">{{ $t('message.viewMap') }}</span>
          </div>
          <img src="@/assets/home-map.png" />
        </div>
      </template>
    </DashboardArea>
    <!-- Fleet Overview (Home tab only) -->
    <GuidedTourWaypoint :id="3" target="search-for-vehicles-box" :yOffset="16" />
    <BaseModal class="modal-upgrade" :show="isModalOpen" @closed="isModalOpen = false">
      <template v-slot:header>
        <div class="modal-header">
          <button class="btn-close" @click="isModalOpen = false"><IconClose /></button>
        </div>
      </template>
      <div class="modal-content">
        <div class="modal-body">
          <div class="icon-container">
            <IconUpgradeModal />
          </div>
          <h1>Upgrade to Maximize Your Business's Capabilities</h1>
          <p>Customers who upgrade to the Basic Plan will gain access to valuable vehicle features like:</p>
          <div class="features-list">
            <ul>
              <li>Vehicle Location and Trip History</li>
              <li>Driver Behavior Data</li>
              <li>Fleet Performance Metrics and Insights</li>
              <li>Geofences and more!</li>
            </ul>
          </div>
          
          <div class="btn-container">
            <button class="btn btn-primary">Learn More</button>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DashboardArea from '@/components/demo/DashboardArea.vue';
import DropDown from '../../components/demo/DropDown.vue';
import GuidedTourWaypoint from '../../components/demo/GuidedTourWaypoint.vue';
import BaseModal from '@/components/BaseModal.vue';
import IconClose from '@/assets/icons/close-icon.svg';
import IconUpgradeModal from '@/assets/icons/upgrade-modal-icon.svg';

export default {
  components: { 
    DashboardArea, 
    DropDown, 
    GuidedTourWaypoint,
    BaseModal,
    IconClose,
    IconUpgradeModal
  },
  data() {
    return {
      lots: { 1: 'Main Lot', 2: 'Remote Lot', 3: 'Courtesy Lot' },
      measuresToDisplay: [1, 2, 3, 5, 11],
      showCustomizeModal: false,
      fleetDropdownOpen: false,
      searchQuery: '',
      isModalOpen: false,
    };
  },
  methods: {
    handleToggleMeasure(number) {
      const index = this.measuresToDisplay.indexOf(number);
      if (index !== -1) {
        // toggle off
        this.measuresToDisplay.splice(index, 1);
      } else {
        // toggle on
        this.measuresToDisplay.push(number);
      }
    },
    challengeComplete(chal) {
      this.$emit('challengeComplete', chal);
    },
    pauseTimer() {
      this.$emit('pauseTimer');
    },
    noMoreChallenges() {
      this.$emit('noMoreChallenges');
    },
    search(e) {
      e.preventDefault();
      this.$router.push(`/1/?q=${this.searchQuery}`);
    }
  },
  mounted() {
    this.$route.params.title = 'Home';
    if (this.activeChallenge === 'monitor-what-matters') {
      this.measuresToDisplay = [];
    }
    if (this.inEssentialsMode) {
      this.isModalOpen = true;
    }
  },
  props: {
    dataset: Object,
    isMobile: Boolean,
    challenges: Array,
    activeChallenge: String,
    numIncomplete: Number,
    timeLeft: Number,
  },
  computed: {
    options() {
      return this.$store.inDealerMode ? this.lots : this.$store.state.fleets;
    },
    selectedFleet: {
      get() {
        return this.$store.state.selectedFleet;
      },
      set(value) {
        this.$store.commit('setSelectedFleet', value);
      },
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
  created() {
    this.$store.commit("setMode", 1);
    if (this.$route.meta.inEssentialsMode) {
      this.$store.commit("setEssenialsMode", true);
    }
    /* if (this.$route.meta.inEssentialsMode || this.$store.state.inEssentialsMode) {
      this.measuresToDisplay = [1, 2, 5, 11];
    } */
  },
};
</script>

<style lang="scss" scoped>
.performance {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dropdown {
  margin-left: 12px;
}

.search-area {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 3fr 7fr;
  align-items: center;
  justify-content: flex-start;
  /* width: 80%; */
  gap: 16px;
  border: 1px solid #001c3829;
  padding: 16px 24px;
  box-sizing: border-box;
  /* margin: 16px 0 -16px 0; */
}

.welcome {
  grid-column: 1 / -1;
}

.search-area h4 {
  margin: 0;
}
.search-area input {
  margin: 0;
  width: unset;
  max-width: unset;
}

.dashboard-measures {
  width: max-content;
  max-width: 80%;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.no-measures {
  padding-top: 120px;
}

.no-measures img {
  margin-bottom: 12px;
}

.no-measures .action-button.major {
  width: 133px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-top: 36px;
}

.top-bar {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 980px) {
  .customize-btn {
    margin-bottom: 20px;
  }

  .gradient-divider {
    height: 25px;
    min-height: 25px;
  }
}

.fleet-overview {
  border: 1px solid var(--light-grey-2);
  padding: 16px 24px;
  width: 404px;
  min-height: 280px;
  /* margin: 10px auto 32px 10%; */
}
.fleet-overview .title {
  color: #606164;
  text-transform: uppercase;
  font-weight: 600;
}
.fleet-overview .blue {
  color: var(--blue);
  font-weight: 600;
  cursor: pointer;
}
.fleet-overview img {
  width: 100%;
  margin-top: 16px;
  height: 350px;
  object-fit: cover;
}

@media screen and (max-width: 980px) {
  /* This is where the dashboard grid becomes flex */
  .welcome {
    width: calc(90% + 12px);
  }
  .search-area {
    width: calc(90% + 12px);
  }
  .fleet-overview {
    width: calc(90% - 38px);
  }
}

@media screen and (max-width: 1437px) {
  /* Temporary fix for Fleet Overview overflowing on dense packing mode */
  .fleet-overview {
    grid-column: 1 / -1;
  }
}

.modal-upgrade {
  width: 100%;
  max-width: 600px;
  margin-bottom: 32px;
  top: 600px;
  left: auto;
  right: auto;
  bottom: auto;
  position: absolute;
  overflow: visible;
  height: auto;
  &.overlay {
    background-color: transparent;
    backdrop-filter: none;
  }
  ::v-deep .content.type--modal {
    margin-top: 0;
    margin-bottom: 32px;
  }
  
  .modal-header {
    position: relative;
    padding-bottom: 0;
    height: 17px;
    z-index: 1;
    .btn-close{
      margin-left: auto;
      border: 0;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      svg {
        width: 16px;
      }
    }
  } 
  .modal-content {
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    .icon-container {
      max-width: 115px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 13px;
    }
    h1 {
      font-size: 19px;
      margin: 0;
      text-align: center;
      font-weight: 600;
      line-height: 1.21;
      margin-bottom: 4px;
      min-height: 27px;
    }
    p {
      text-align: center;
      min-height: 47px;
      max-width: 387px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.26;
      margin-top: 0;
      margin-bottom: 2px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      max-width: 463px;
      li {
        position: relative;
        // list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23808"><path d="M18 4c-4-1-6 3-6 3s-2-4-6-3-4 6-2 8l8 8 8-8c2-2 2-7-2-8z"></path></svg>');
        display: flex;
        margin-left: 26px;
        color: var(--dark-grey);
        margin-bottom: 4px;
        min-width: 142px;
        @media screen and (min-width: 980px) {
          white-space: nowrap;
          margin-bottom: 10px;
          align-items: center;
        }
        &::before{
          content: '';
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxOCAxOSI+CiAgPHBhdGggZmlsbD0iIzcxNzk4QSIgZD0ibTguNTQzIDEyLjI4NC4wMDctLjAwN2EuNTA5LjUwOSAwIDAgMCAuMDIzLS4wMjJsMy41Ny00LjAyYS40OTUuNDk1IDAgMCAwLS4wMDktLjcuNDk1LjQ5NSAwIDAgMC0uNy0uMDA3bC0zLjI3IDMuNjktMS4zMTUtMS4wODJhLjQ5NS40OTUgMCAwIDAtLjcuMDA4LjQ5NS40OTUgMCAwIDAtLjAwOC43bDEuNzc5IDEuNDc4Yy4xOTEuMTkuNDIuMTY2LjYxNS0uMDNsLjAwOC0uMDA4WiIvPgogIDxwYXRoIGZpbGw9IiM3MTc5OEEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTkgMTUuODg3YTYgNiAwIDEgMSAwLTEyIDYgNiAwIDAgMSAwIDEyWm0wLS43NWE1LjI1IDUuMjUgMCAxIDAgMC0xMC41IDUuMjUgNS4yNSAwIDAgMCAwIDEwLjVaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+');
          height: 18px;
          width: 18px;
          display: block;
          position: absolute;
          left: -26px;
        }
        &:nth-child(2n) {
          @media screen and (min-width: 980px) {
            margin-left: auto;
          }
        }
      }
    }
    .btn-container {
      text-align: center;
      .btn-primary {
        background-color: var(--blue);
        border: 1px solid var(--blue);
        outline: none;
        font-family: var(--font-family-base);
        font-weight: 600;
        font-size: 13px;
        line-height: 1.38;
        color: var(--white);
        padding: 6px 12px;
        border-radius: 4px;
      }
    }
  }
}
</style>
