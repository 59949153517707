<template>
  <div class="Map-Buttons" id="map-button">
    <button class="Map-Buttons__button Map-Buttons__button--circle">
      <MapLayersIcon style="cursor: pointer;" @click="$emit('toggleMapLayers')" />
    </button>
    <button class="Map-Buttons__button Map-Buttons__button--circle">
      <MapViewIcon @click="alert = !alert" />
      <div class="Map-Buttons__alert" v-if="alert">
        <p><strong>Feature not active for demo</strong></p>
      </div>
    </button>
    <button class="Map-Buttons__button" id="map-buttons-plus">
      <MapPlusIcon />
    </button>
    <button class="Map-Buttons__button" style="cursor: pointer;" @click="$emit('zoomOut')">
      <MapMinusIcon />
    </button>
    <GuidedTourWaypoint v-if="inEssentialsMode" :id="999" target="map-button" :xOffset="0" :yOffset="-75" :zIndex="100" :copyOverride="'Click here to see charger locations.'" />
  </div>
</template>

<script>
import MapPlusIcon from "@/assets/map_plus.svg";
import MapMinusIcon from "@/assets/map_minus.svg";
import MapViewIcon from "@/assets/map-view.svg";
import MapLayersIcon from "@/assets/icons-misc-map-layers-24-px.svg";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";
export default {
  data() {
    return {
      alert: false,
    };
  },
  computed: {
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
  components: {
    MapPlusIcon,
    MapMinusIcon,
    MapLayersIcon,
    MapViewIcon,
    GuidedTourWaypoint
  },
};
</script>

<style lang="scss" scoped>
.Map-Buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 10px;
  &__button {
    background: white;
    border: 0;
    width: 40px;
    height: 40px;
    margin: 2px;
    -webkit-box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 0.5);
    -moz-box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 0.5);
    box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 0.5);
  }

  &__button--circle {
    border-radius: 50%;
  }

  &__alert {
    position: absolute;
    left: -178px;
    top: 65px;
    background: white;
    border-radius: 0.25rem;
    padding: 5px;
    p {
      margin: 0;
    }
  }
}
</style>
