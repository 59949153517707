<template>
  <div class="page">
    <h1>
      {{ `${vehicle.Make || ""} ${vehicle["Model Name"]} ${vehicle.Trim || ""}` }}
    </h1>
    <header class="status-header">
      <div class="search-container">
        <input placeholder="Event, Driver" type="text" class="search-box">
      </div>
    </header>
    <div class="page-body">
      <div class="table-header">
        <div class="camera-vehicles">{{events.length}} results</div>
        <button class="btn-download"><DownloadIcon /></button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Trip Start Date and Time</th>
              <th>Event</th>
              <th>Category</th>
              <th>Driver</th>
              <th>Launch Video</th>
              <th>Duration</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vehicleEvent in events" :key="vehicleEvent.id" @click="goToVehicle(vehicleEvent)">
              <td>{{ tripDate }} {{ vehicleEvent.data.time }}</td>
              <td class="icon-and-label"><div><div class="event-icon"><BaseIcon :class="`icon-${vehicleEvent.icon}`" :name="vehicleEvent.icon" /></div> {{ vehicleEvent.label }}</div></td>
              <td class="category"><div><BaseIcon name="miscTrip" /> Vehicle</div></td>
              <td>{{ $t("message." + vehicleEvent.driver) }}</td>
              <td>--</td>
              <td>{{ vehicleEvent.data.idleTime ? vehicleEvent.data.idleTime : '--' }}</td>
              <td>{{ vehicleEvent.data.address ? vehicleEvent.data.address : '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <BasePagination :totalItems="60" />
    </div>
  </div>
</template>

<script>
import * as markers from "@/utils/markers.js";
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import BaseIcon from '@/components/icons/BaseIcon.vue';
import BasePagination from '@/components/BasePagination.vue';
export default {
  components: {
    DownloadIcon,
    BaseIcon,
    BasePagination,
  },
  props: {
    id: String,
    dataset: Object,
  },
  data() {
    return {
      markers: markers,
    };
  },
  computed: {
    dt() {
      // create date object for 7 days ago
      return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    },
    tripDate() {
      const month = this.dt.getMonth() + 1;
      const date = this.dt.getDate();
      const year = this.dt.getFullYear();
      return `${month}/${date}/${year}`;
    },
    vehicle() {
      return this.$store.state.dataset.vehicles.find((v) => v.id === this.id);
    },
    trips() {
      let arr = [];
      const vehicle = this.vehicle["Vehicle Label"];
      for (let i = 0; i < this.dataset.tripHistory.length; i++) {
        const obj = this.dataset.tripHistory[i];
        if (!this.$store.state.inDealerMode) {
          if (obj.vehicle === vehicle) arr.push(obj);
        } else {
          if (obj.vehicleID === this.vehicle.id) arr.push(obj);
        }
      }
      return arr;
    },
    events() {
      let events = [];
      // loop through all the trips
      for (let i = 0; i < this.trips.length; i++) {
        const trip = this.trips[i];
        // Build events based on trip type
        const dots = trip.map.us.dots;
        for (let j = 0; j < dots.length; j++) {
          const dot = dots[j];
          // Type 0 = Start point
          // Type 3 = End point
          // Type 4 = Trip snapshot
          // Type 5 = Turn or something
          // Type 7 = Orange donut
          // Type 8 = Purple donut
          // Type 9 = MultiIcon / Stacked event
          if (dot.type !== 0 && dot.type !== 3 && dot.type !== 4 && dot.type !== 5 && dot.type !== 7 && dot.type !== 8 && dot.type !== 9) {
            const event = markers.getMarker(dot);
            event.vehicleId = this.id;
            event.tripIndex = i;
            event.driver = trip.driver;
            if (!dot.data) {
              event.data = {};
            }
            events.push(event);
          }
          if (dot.type === 9) {
            for (let k = 0; k < dot.data.subtypes.length; k++) {
              const event = markers.getMarker(dot.data.subtypes[k]);
              event.data = dot.data;
              event.vehicleId = this.id;
              event.tripIndex = i;
              event.driver = trip.driver;
              events.push(event);
            }
          }
        }
      }
      return events;
    },
  },
  methods: {
    goToVehicle(vehicleEvent) {
      console.log(vehicleEvent);
      this.$router.push(`/1/detail/${vehicleEvent.vehicleId}?trip=${vehicleEvent.tripIndex}`);
    },
  },
  mounted() {
    /* console.log(this.vehicle);
    console.log(this.trips);
    console.log(this.events); */
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  *, *:before, *:after {
    box-sizing: border-box;
  }
}
.status-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .search-container {
    width: 100%;
    .search-box {
      max-width: 525px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3IiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuOTkyMTkgNi45OTk5M0MxLjk5MjE5IDkuNTk4MyA0LjA5ODU4IDExLjcwNDcgNi42OTY5NSAxMS43MDQ3QzcuODI0NjMgMTEuNzA0NyA4Ljg1OTY0IDExLjMwNzkgOS42NzAwMiAxMC42NDY0TDEzLjcyODYgMTQuNzA1QzE0LjEwMDUgMTQuMzMzMSAxNC4xMDA1IDEzLjczMDEgMTMuNzI4NiAxMy4zNTgxTDEwLjM0MzUgOS45NzNDMTEuMDA1IDkuMTYyNjIgMTEuNDAxNyA4LjEyNzYgMTEuNDAxNyA2Ljk5OTkzQzExLjQwMTcgNC40MDE1NiA5LjI5NTMyIDIuMjk1MTcgNi42OTY5NSAyLjI5NTE3QzQuMDk4NTggMi4yOTUxNyAxLjk5MjE5IDQuNDAxNTYgMS45OTIxOSA2Ljk5OTkzWk02LjY5Njk1IDEwLjc1MjNDOC43NjkzMyAxMC43NTIzIDEwLjQ0OTMgOS4wNzIzMSAxMC40NDkzIDYuOTk5OTNDMTAuNDQ5MyA0LjkyNzU1IDguNzY5MzMgMy4yNDc1NSA2LjY5Njk1IDMuMjQ3NTVDNC42MjQ1NyAzLjI0NzU1IDIuOTQ0NTcgNC45Mjc1NSAyLjk0NDU3IDYuOTk5OTNDMi45NDQ1NyA5LjA3MjMxIDQuNjI0NTcgMTAuNzUyMyA2LjY5Njk1IDEwLjc1MjNaIiBmaWxsPSIjNzE3OThBIi8+Cjwvc3ZnPg==");
      background-position: right 6px center;
      background-repeat: no-repeat;
    }
  }
}
.page-body {
  width: 100%;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
  padding-top: 50px;
}
.table-header {
  display: flex;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  .camera-vehicles {
    color: var(--dark-grey);
    font-size: 14px;
  }
  .btn-download {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: auto;
    svg {
      width: 24px;
      fill: var(--blue);
    }
  }
  .btn-columns {
    width: 20px;
    height: 20px;
    fill: var(--blue);
    margin-top: 3px;
    margin-left: 8px;
    margin-right: 8px;
  }
}
.table-responsive {
  overflow: scroll;
  max-height: 485px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}
table.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #C1C8D6;
  thead {
    background: #EDF9FF;
    th {
      padding: 12px 8px;
      font-size: 12px;
      font-weight: 600;
      color: #71798A;
      text-align: left;
      border-bottom: 1px solid #C1C8D6;
      white-space: nowrap;
      &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 8px;
        font-size: 15px;
        color: #333;
        white-space: nowrap;
        border-bottom: 1px solid #C1C8D6;
        &:first-child {
          padding-left: 16px;
          color: #0372c8;
          &:hover {
            color: #52b2df;
          }
        }
        &:last-child {
          padding-right: 16px;
        }
        &.icon-and-label > div {
          display: flex;
          height: 100%;
          align-items: center;
          vertical-align: middle;
        }
        &.category > div {
          display: flex;
          height: 100%;
          align-items: center;
          vertical-align: middle;
          > svg {
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }
        a {
          color: #171C26;
          text-decoration: none;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
}
.camera-status {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 50%;
  margin-right: 8px;
  &.assigned {
    background-color: var(--green);
  }
  &.unassigned {
    background-color: #FFAB40;
  }
  &.lastSeen {
    background-color: var(--red);
  }
}
.base-pagination {
  width: 340px;
  margin: 14px auto;
}
.event-icon {
  width: 25px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  margin-right: 10px;
  .hard-brake-svg,
  .hard-accel-svg,
  > svg {
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    padding: 3px;
    // margin-top: 3px;
  }
  .icon-hardBrake, .icon-hardAcceleration {
    border-color: #FFA725;
  }
  .icon-video {
    border-color: #651EFF;
  }
  .icon-adasSystem {
    border-color: var(--red);
  }
}
</style>