import { render, staticRenderFns } from "./VehicleDetailsScreen.vue?vue&type=template&id=1063e1b6&scoped=true&"
import script from "./VehicleDetailsScreen.vue?vue&type=script&lang=js&"
export * from "./VehicleDetailsScreen.vue?vue&type=script&lang=js&"
import style0 from "./VehicleDetailsScreen.vue?vue&type=style&index=0&id=1063e1b6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1063e1b6",
  null
  
)

export default component.exports