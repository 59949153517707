<template>
  <div class="adas-insights">
    <div class="top-bar">
      <div class="icon-circle">
        <BaseIcon name="ADAS" />
      </div>
      <h1>Advanced Driver Assistance System Insights</h1>
    </div>
    <div class="top-bar-shadow" />
    <div class="content-grid">
      <div class="lhs">
        <img src="@/assets/adas-card-image.svg?external" />
        <h2>Analyze the ADAS on/off status</h2>
        <p>
          Advanced Driver Assistance System (ADAS) helps reduce the risk of
          collisions with features such as Forward Collison Alert and Lane
          Departure Warning. It also alerts fleet managers to when drivers turn
          these important safety features off.
        </p>
      </div>
      <div class="vehicles-box">
        <b>Vehicles</b>
        <div class="big-zero">0</div>
        <div class="caption">Compatible</div>
      </div>
      <div class="details-box">
        <h2>How does it work?</h2>
        <p>
          When the ignition is off, after a trip is completed, data is collected
          from each vehicle on the status of Lane Departure Warnings and Forward
          Collision Alerts*.<br />
          Reports are generated to help fleet managers identify drivers most in
          need of coaching.
        </p>
      </div>
    </div>
    <div class="ribbon">Coming Soon!</div>
  </div>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  width: 100%;
  background-color: #f4f3f7;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .icon-circle {
    width: 54px;
    height: 54px;
    background-color: #fff;
    border: 1px solid rgba(0, 28, 56, 0.239);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 34px;
    }
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.top-bar-shadow {
  background: linear-gradient(
    to bottom,
    rgb(244, 243, 247),
    rgb(255, 255, 255) 100%
  );
  width: 100%;
  height: 50px;
}
.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 1.5rem;
  margin: 0 72px;
  .lhs {
    grid-row: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 354px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    h2 {
      font-weight: 700;
      margin-top: 32px;
      margin-bottom: 8px;
    }
    p {
      margin: 0;
      font-size: 19px;
      line-height: 24px;
      font-weight: 300;
      text-align: center;
    }
  }
}
.vehicles-box {
  padding: 24px;
  .big-zero {
    margin-top: 16px;
    font-size: 40px;
    font-weight: 300;
    color: #c6c6ca;
  }
  .caption {
    font-size: 12px;
    color: #606164;
  }
}
.details-box {
  padding: 24px;
  color: #606164;
  h2 {
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 24px;
  }
}
.ribbon {
  background-color: #0072c8;
  width: 15em;
  position: absolute;
  right: -60px;
  top: 30px;
  transform-origin: top left;
  transform: rotate(32deg);
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 4px 0;
  box-shadow: 0 1px 5px 0 rgba(26, 28, 30, 0.2),
    0 2px 2px 0 rgba(26, 28, 30, 0.14), 0 3px 1px -2px rgba(26, 28, 30, 0.12);
}
</style>
