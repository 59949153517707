<template>
  <div class="days-grid">
    <div class="day" v-for="(day, i) of days" :class="{ selected: selectedDays[i] }" @click="$emit('toggleDay', i)" :key="i">
      {{ day }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedDays: Array[Boolean],
  },
  data() {
    return {
      days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    };
  },
}
</script>

<style lang="scss" scoped>
.days-grid {
  display: grid;
  width: 272px;
  height: 30px;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  grid-template-columns: repeat(7, 38px);
  gap: 1px;
  margin-bottom: 40px;
}

.day {
  width: 100%;
  height: 100%;
  background-color: white;
  color: var(--grey);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.5s;
}

.day.selected {
  color: var(--blue);
}

.day:hover {
  color: #05b5e4;
}
</style>