<template>
  <div class="geofence-selection">
    <h3>{{ $t("message.geofenceBoundary") }}</h3>
    <div class="gs-main">
      <div class="flex">
        <div class="dropdown-toggle" @click="showDropdown = !showDropdown" :class="{ open: showDropdown }">
          {{ dropdownLbl }}
          <DownCaret class="dropdown-arrow" />
        </div>
        <div class="dropdown-menu" v-if="showDropdown">
          <div class="bold">{{ $t("message.automatic") }}</div>
          <div
            class="item"
            v-for="(option, i) of automaticOptions"
            @click="selectMap(i + 1)"
            :key="i"
          >
            {{ $t("message." + camelize(option)) }}
          </div>
          <div class="line" />
          <div class="bold">{{ $t("message.customize") }}</div>
          <div
            class="item"
            v-for="(option, i) of customizeOptions"
            @click="selectMap(i + 6)"
            :key="'a' + i"
          >
            {{ $t("message." + camelize(option)) }}
          </div>
        </div>
        <!-- <input class="search" placeholder="Search for location to set map focus" /> -->
        <div v-if="!hideSearch" class="search">
          {{ $t("message.searchForLocation") }}
        </div>
      </div>
      <div class="map-container">
        <img v-if="mapId === 0" src="@/assets/full_map.png" />
        <img v-else-if="mapId === 1" src="@/assets/city-detroit.png" />
        <img v-else-if="mapId === 2" src="@/assets/county-wayne.png" />
        <img v-else-if="mapId === 3" src="@/assets/state-mi.png" />
        <img v-else-if="mapId === 4" src="@/assets/country-usa.png" />
        <img v-else-if="mapId === 5" src="@/assets/zip-midtown.png" />
        <img v-else-if="mapId === 6" src="@/assets/radius.png" />
        <img v-else-if="mapId === 7" src="@/assets/region-med-ctr.png" />
      </div>
    </div>
    <div class="bottom-btns">
      <div class="action-button" @click="$emit('done')">
        {{ $t("message.cancel") }}
      </div>
      <div class="action-button major" @click="handleClick">
        {{ $t("message.save") }}
      </div>
    </div>
  </div>
</template>

<script>
import { camelize } from "../../utils/camelize.js";
import DownCaret from "@/assets/icons/down_caret.svg";

export default {
  components: {
    DownCaret,
  },
  props: {
    initMapId: Number,
    hideSearch: Boolean,
  },
  data() {
    return {
      mapId: 0,
      automaticOptions: ["City", "County", "State", "Country", "Zip Code"],
      customizeOptions: ["Radius", "Polygon"],
      showDropdown: false,
      camelize: camelize,
    };
  },
  computed: {
    dropdownLbl() {
      if (this.mapId === 0) return this.$t("message.selectBoundaryType");
      if (this.mapId < 6) return this.automaticOptions[this.mapId - 1];
      return this.customizeOptions[this.mapId - 6];
    },
  },
  methods: {
    selectMap(mapId) {
      this.showDropdown = false;
      this.mapId = mapId;
    },
    handleClick() {
      this.$emit("save", this.mapId);
      this.$emit("done");
    },
  },
  mounted() {
    this.mapId = this.initMapId;
  },
};
</script>

<style scoped>
.geofence-selection {
  width: 100%;
  max-width: 1000px;
  position: relative;
}
h3 {
  font-size: 19px;
  margin: 16px 24px;
}
.gs-main {
  width: 100%;
  height: 510px;
  padding: 24px;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #eaf1f9, #ffffff 20px);
}
.dropdown-toggle {
  width: 273px;
  height: 30px;
  white-space: nowrap;
  margin: 20px;
  margin-left: 0;
  padding-left: 12px;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  font-weight: 600;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
}
.dropdown-arrow {
  margin: 0 12px;
  width: 12px;
  fill: var(--blue);
  transition: transform 0.5s;
}
.dropdown-toggle.open .dropdown-arrow {
  transform: rotate(180deg);
}
.dropdown-toggle:hover {
  border-color: #05b5e4;
  color: #05b5e4;
}
.dropdown-menu {
  position: absolute;
  top: 115px;
  left: 25px;
  width: 212px;
  height: 304px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 30px) 17px repeat(3, 30px);
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
}
.dropdown-menu .item {
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown-menu .item:hover {
  background-color: #eaf1f9;
}
.dropdown-menu .bold {
  padding-left: 16px;
  font-weight: bold;
  font-size: 14.5px;
}
.dropdown-menu .line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
}
.search {
  margin: 20px 0 20px 6px;
  padding: 4px 12px;
  box-sizing: border-box;
  width: 100%;
  /* height: 27px; */
  height: 32px;
  color: var(--grey);
  appearance: none;
  border: 1px solid var(--light-grey-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* .search::placeholder {
  font-family: ProximaNova;
  font-size: 15px;
  color: var(--grey);
} */
.map-container {
  width: 100%;
  max-width: 952px;
  height: 390px;
}
.map-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bottom-btns {
  width: 100%;
  height: 63px;
  margin-top: 25px;
  border-top: 1px solid var(--light-grey-2);
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.action-button {
  margin-left: 8px;
  height: 30px;
  min-width: 120px;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}
.action-button svg {
  width: 18px;
  height: 18px;
  fill: var(--blue);
  margin-right: 8px;
}
.action-button.major {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-left: 16px;
}
</style>
