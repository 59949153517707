<template>
  <div class="myba">
    <Topbar/>
    <main class="main">
      <Sidebar :hideSidebar="hideSidebar" @toggleSidebar="toggleSidebar"/>
      <div class="content">
        <h1>Welcome, Katie Smith</h1>
        <div class="box">
          <div class="columns">
            <VehicleAvailability />
            <OilLife />
            <FieldActions />
          </div>
        </div>
        <DistanceDriven />
        <section class="section alert">
          <div class="alert-left">
            <div class="alert-title">Unlock the latest premium features.</div>
            <div class="alert-text">Get the most out of your OnStar experience, including features like vehicle location, driver behavior, and&nbsp;more.&nbsp;</div>
          </div>
          <div class="alert-right">
            <button class="btn btn-outline-white">Unlock Features <IconArrowRight /></button>
          </div>
        </section>
        <div class="columns">
          <Orders />
          <VehicleConnections />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Topbar from '@/components/demo/myba/Topbar.vue';
import Sidebar from '@/components/demo/myba/Sidebar.vue';
import VehicleAvailability from '@/components/demo/myba/VehicleAvailability.vue';
import OilLife from '@/components/demo/myba/OilLife.vue';
import FieldActions from '@/components/demo/myba/FieldActions.vue';
import DistanceDriven from '@/components/demo/myba/DistanceDriven.vue';
import Orders from '@/components/demo/myba/Orders.vue';
import VehicleConnections from '@/components/demo/myba/VehicleConnections.vue';
import IconArrowRight from '@/components/icons/myba/IconArrowRight.vue';

export default {
  data() {
    return {
      searchQuery: "",
      hideSidebar: false,
    };
  },
  components: {
    Sidebar, Topbar,
    VehicleAvailability,
    OilLife,
    FieldActions,
    DistanceDriven,
    IconArrowRight,
    Orders,
    VehicleConnections
},
  methods: {
    toggleSidebar() {
      this.hideSidebar = !this.hideSidebar;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.myba {
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.item-title {
  font-family: Overpass;
  font-size: 17.5px;
  line-height: 21px;
  color: #3D3935;
}
.description {
  font-family: Overpass;
  font-size: 11.5px;
  line-height: 17.8px;
  color: #75787A;
  letter-spacing: 0.06px;
  margin-top: 12px;
  &.center {
    text-align: center;
  }
  &.blue {
    margin-top: 0 !important;
    color: #00478C;
  }
}
.myba {
  background-color: #ECEBEF;
  // width: 100vw;
  min-height: 100vh;

  .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    // height: calc(100% - 50px);

    .content {
      padding: 20px;
      flex-grow: 1;
      h1 {
        color: $pacific-blue;
        font-size: 20px;
        line-height: 1.5;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 12px;
      }
      .columns {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-gap: 2px;
      }
      section.section {
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        margin-bottom: 2px;
        &.alert {
          background-color: $blue;
          color: $white;
          flex-direction: row;
          align-items: center;
        }
        ::v-deep h2 {
          color: $blues-blue-dark;
          font-size: 18px;
          line-height: 1.5;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .alert {
        .alert-title {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 600;
          margin-bottom: 8px;
        }
        .alert-text {
          font-size: 14px;
          line-height: 1.57;
        }
      }
    }
    .content-container {
      // width: calc(100% - 180px);
      height: 100%;
      transition: all 0.25s ease-in;
      padding-top: 50px;
      margin-left: 180px;
      &.hideSidebar {
        width: 100%;
        margin-left: 0;
      }
      .content {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        .item {
          width: 90%;
          min-width: 290px;
          max-width: 464px;
          padding: 14px 12px;
          background-color: #FAFAFA;
          border-radius: 6px;
          box-shadow: 1px 1px 1px #0000001A;
          margin: 6px;
          &.bottom {
            height: 360px;
          }
          .header {
            display: flex;
            justify-content: flex-end;
            .item-title {
              margin-right: auto;
            }
            .second {
              margin-right: 10px;
            }
          }
          .order-number {
            margin-top: 10px;
            position: relative;
            width: 145px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid grey;
            .svg {
              position: relative;
              transform: scale(1.1);
              top: 5px;
              left: 8px;
            }
            .text {
              padding: 0 5px;
              font-size: 9px;
              position: absolute;
              background-color: white;
              transform: translateY(-50%);
              top: 0;
              left: 10px;
            }
          }
          .button {
            width: fit-content;
            border: 2px solid #0072CE;
            border-radius: 25px;
            color: #0072CE;
            font-size: 12px;
            padding: 7px 27px;
            margin: 30px auto 5px auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-family: $font-family-base;
  background: transparent;
  white-space: nowrap;
  svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
}
.btn-outline-white {
  color: $white;
  border-color: $white;
  border-width: 2px;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  &:hover {
    background-color: rgba($white, 0.1);
  }
}
</style>