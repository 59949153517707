<template>
  <div class="car-diag-grid">
    <span class="front left wheel">{{ frontLeftPSI }}</span>
    <span class="front right wheel">{{ frontRightPSI }}</span>
    <span class="rear left wheel">{{ rearLeftPSI }}</span>
    <span class="rear right wheel">{{ rearRightPSI }}</span>
    <svg
      width="76"
      height="142"
      viewBox="0 0 76 142"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#00000000" fill-rule="evenodd" stroke-width="1.28">
        <path
          id="carBody"
          stroke="var(--pale-grey)"
          d="M67.008 49.789l-.02-6.171.329-6.332c2.573-16.69 0-21.008-.658-22.186-.656-1.179-.656-3.436-2.902-5.743C61.512 7.05 53.134 3.319 51.545 2.73c-1.587-.589-.986-.294-2.574-.589-.547-.098-2.026-.343-3.723-.589a51.638 51.638 0 0 0-15.06 0c-1.697.246-3.176.491-3.723.59-1.589.294-.986 0-2.575.588-1.587.589-9.966 4.32-12.211 6.627-2.245 2.307-2.245 4.614-2.902 5.743-.658 1.128-3.231 5.448-.658 22.186l.329 6.332-.007 6.244C1.687 52.296 1 55.426 1 56.723c0 .05.055.099.055.147.11.197.384.295.602.197l6.78-2.43-.044 40.961s-.602 3.534-.876 8c-.274 4.467-.548 9.867-.055 13.842.11.737 2.191 2.405 2.191 2.553.166 1.192.9 7.076 2.154 11.11l.474 2.29c.384 1.67 1.807 2.994 3.614 3.436l6.09 1.437c10.179 3.67 21.55 3.645 31.714-.073l-.237.109 6.243-1.473c1.807-.442 3.23-1.767 3.614-3.436l.712-3.436c-.034.054-.07.106-.103.159 1.09-3.947 1.74-9.025 1.855-10.123a.572.572 0 0 1 .118-.146l-.008.146c0-.148 2.081-1.816 2.19-2.553.931-7.656-.657-20.468-.876-21.743l-.01.186c-.028-.185-.045-.285-.045-.285l-.13-41.061c.04.029.084.055.13.076l6.791 2.503c.219.098.493 0 .602-.196 0-.05.055-.098.055-.148 0-1.359-.647-4.54-7.592-6.983"
        />
        <path
          id="frontRightWheel"
          :stroke="
            recommendedFront - frontRightPSI > 10
              ? 'var(--red)'
              : 'var(--green)'
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M58.693 29.272c.077-2.958.655-4.415 1.123-5.118.314-.466.885-.754 1.495-.754h5.813c.61 0 1.18.288 1.494.754.469.703 1.046 2.166 1.123 5.118.045 1.495.064 3.341.058 5.482.006 2.147-.013 3.993-.058 5.488-.077 2.952-.654 4.409-1.123 5.118-.308.466-.885.754-1.494.754H61.31c-.61 0-1.181-.288-1.495-.754-.468-.703-1.046-2.166-1.123-5.118a169.377 169.377 0 0 1-.058-5.488c-.007-2.14.013-3.987.058-5.482"
        />
        <path
          id="rearRightWheel"
          :stroke="
            recommendedRear - rearRightPSI > 10 ? 'var(--red)' : 'var(--green)'
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M58.693 102.872c.077-2.959.655-4.415 1.123-5.118.314-.466.885-.754 1.495-.754h5.813c.61 0 1.18.288 1.494.754.469.703 1.046 2.166 1.123 5.118.045 1.495.064 3.341.058 5.482.006 2.147-.013 3.993-.058 5.488-.077 2.952-.654 4.409-1.123 5.118-.308.466-.885.754-1.494.754H61.31c-.61 0-1.181-.288-1.495-.754-.468-.703-1.046-2.166-1.123-5.118a169.377 169.377 0 0 1-.058-5.488c-.007-2.14.013-3.987.058-5.482"
        />
        <path
          id="rearLeftWheel"
          :stroke="
            recommendedRear - rearLeftPSI > 10 ? 'var(--red)' : 'var(--green)'
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5.893 102.872c.077-2.959.655-4.415 1.123-5.118C7.33 97.288 7.9 97 8.51 97h5.813c.61 0 1.18.288 1.494.754.469.703 1.046 2.166 1.123 5.118.045 1.495.064 3.341.058 5.482.006 2.147-.013 3.993-.058 5.488-.077 2.952-.654 4.409-1.123 5.118-.308.466-.885.754-1.494.754H8.51c-.61 0-1.181-.288-1.495-.754-.468-.703-1.046-2.166-1.123-5.118a169.377 169.377 0 0 1-.058-5.488c-.007-2.14.013-3.987.058-5.482"
        />
        <path
          id="frontLeftWheel"
          :stroke="
            recommendedFront - frontLeftPSI > 10 ? 'var(--red)' : 'var(--green)'
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5.893 29.272c.077-2.958.655-4.415 1.123-5.118.314-.466.885-.754 1.495-.754h5.813c.61 0 1.18.288 1.494.754.469.703 1.046 2.166 1.123 5.118.045 1.495.064 3.341.058 5.482.006 2.147-.013 3.993-.058 5.488-.077 2.952-.654 4.409-1.123 5.118-.308.466-.885.754-1.494.754H8.51c-.61 0-1.181-.288-1.495-.754-.468-.703-1.046-2.166-1.123-5.118a169.377 169.377 0 0 1-.058-5.488c-.007-2.14.013-3.987.058-5.482"
        />
      </g>
    </svg>
    <div class="psi-info">
      <span>{{$t('message.recommendedPSI')}}</span>
      <span>{{$t('message.front')}} {{ recommendedFront }} | {{$t('message.rear')}} {{ recommendedRear }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    frontLeftPSI: Number,
    frontRightPSI: Number,
    rearLeftPSI: Number,
    rearRightPSI: Number,
    recommendedFront: Number,
    recommendedRear: Number,
  },
};
</script>

<style scoped>
.car-diag-grid {
  display: grid;
  grid-template: 66px 76px 42px / 17px 75px 17px;
  grid-row-gap: 4px;
  align-items: center;
  color: var(--dark-grey);
}
.wheel {
  font-weight: 600;
}
.front.wheel {
  grid-row: 1 / 2;
}
.rear.wheel {
  grid-row: 2 / 3;
}
.left.wheel {
  grid-column: 1 / 2;
}
.right.wheel {
  grid-column: 3 / 4;
}
.car-diag-grid svg {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  align-self: flex-start;
  justify-self: center;
}
.psi-info {
  margin-top: 8px;
  grid-column: 1 / 4;
  padding: 0;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>