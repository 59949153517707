<template>
  <div class="ivc-circle small" @click="handleClick">
    <BaseIcon :name="playing ? 'pause' : 'play'" class="icon" />
    <audio @ended="playing = false" :src="'ivc-sounds/' + src" ref="audio" />
  </div>
</template>

<script>
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  components: {
    BaseIcon
},
  props: {
    src: String,
  },
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    handleClick() {
      this.playing = !this.playing;
      if (this.playing) {
        this.$refs.audio.play();
        this.$emit("checkChallenge", 17);
      } else {
        this.$refs.audio.pause();
      }
    },
  },
};
</script>

<style scoped>
.ivc-circle {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
  cursor: pointer;
}
.ivc-circle.small {
  width: 28px;
  height: 28px;
}
.ivc-circle.small .icon {
  width: 16px;
  height: 16px;
  fill: var(--blue);
}
audio {
  display: none;
}
</style>
