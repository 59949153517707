<template>
  <!-- It is a gearshift. The letters stand for “PARK”, “REVERSE”, “NEUTRAL”, “DRIVE” AND “LOW”! -->
  <div class="prndl">
    <span v-for="option of options" :class="{selected: option === current}" :key="option">
      {{ option }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    current: String,
  },
  data() {
    return {
      options: ['P', 'R', 'N', 'D'],
    };
  },
}
</script>

<style lang="scss" scoped>
.prndl {
  display: flex;
  color: #c6c6ca;
  font-weight: 600;
  font-size: 12px;
  height: 18px;
  gap: 6px;
}

.prndl span {
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prndl .selected {
  width: 18px;
  background-color: #969fb3;
  color: #fff;
  border-radius: 50%;
}
</style>