<template>
  <div class="Dealer-Filters" v-show="true">
    <section
      class="Dealer-Filters__section"
      v-for="(section, index) in dropDownSections"
      :key="index"
    >
      <template v-if="index < moreThresholdIndex || moreFiltersPopup">
        <!-- section label -->
        <div class="Dealer-Filters__label">
          {{ $t("message." + camelize(section)) }}
        </div>
        <!-- input and currently selected items -->
        <div
          class="Dealer-Filters__select"
          :class="{ 'Dealer-Filters__select--focus': filterFocus === index }"
          v-if="isDropdown(section)"
        >
          <input
            @click="
              open = -1;
              filterFocus = -1;
            "
            @focus="filterFocus = index"
            @blur="filterFocus = -1"
            :placeholder="
              !$store.state.inDealerMode ? 'All Vehicles' : `Select ${section}`
            "
            v-if="$store.state.dealerMode.selected[`${section}`] === 0"
          />
          <div
            v-else
            class="Dealer-Filters__selected-tags"
            :class="{
              'Dealer-Filters__selected-tags--focus': filterFocus === index,
            }"
          >
            <Tag
              v-for="tag in $store.state.dealerMode.selected[`${section}`]"
              :key="tag"
              :tag="{ text: tag, section }"
              rounded
              x
            />
          </div>
          <div
            class="Dealer-Filters__picker-part"
            :class="{ 'Dealer-Filters__picker-part--focus': open === index }"
            @click="
              filterFocus = filterFocus === -1 ? index : -1;
              open = open === -1 ? index : -1;
            "
          >
            <ArrowDownIcon />
          </div>
        </div>

        <div
          class="Dealer-Filters__options"
          v-if="open === index && isDropdown(section)"
        >
          <Tag
            v-for="tag in $store.state.dealerMode[`${section} Options`]"
            :key="tag.value"
            :tag="{ text: tag, section }"
            :x="false"
            :checked="
              $store.state.dealerMode.selected[`${section}`].indexOf(tag) !== -1
            "
          />
        </div>

        <div class="toggle-wrap" v-else-if="section === 'Vehicle Health'">
          <BaseIcon name="diagnostics" class="diagnostics-icon" />
          <span>{{ $t("message.hasHealthIssues") }}</span>
          <ToggleButton
            :value="false"
            :color="toggle.color"
            :switch-color="toggle.switchColor"
            :margin="toggle.margin"
            :height="toggle.height"
            :width="toggle.width"
          />
        </div>

        <div class="slider-wrap" v-else-if="section === 'Days in Inventory'">
          <div class="bound">{{ minDaysInventory }}</div>
          <MultiRangeSlider
            baseClassName="multi-range-slider"
            :min="0"
            :max="90"
            :step="10"
            :ruler="false"
            :label="false"
            :minValue="minDaysInventory"
            :maxValue="maxDaysInventory"
            @input="updateDaysInventory"
          />
          <div class="bound">{{ maxDaysInventory }}</div>
        </div>

        <!-- <div v-else-if="index >= 5" class="Dealer-Filters__options--buttons">
          <Tag
            v-for="(tag, index) in $store.state.dealerMode[`${section} Options`]"
            :key="index"
            :tag="{ text: tag, section }"
            :color="determineColor(tag)"
            :x="false"
            rounded
            contained
          />
        </div> -->
      </template>
    </section>
    <div
      class="popup-holder"
      v-if="moreThresholdIndex < dropDownSections.length"
    >
      <button
        @click="
          moreFiltersPopup = !moreFiltersPopup;
          filterFocus = -1;
          open = -1;
        "
        class="Dealer-Filters__button"
      >
        {{
          moreFiltersPopup
            ? $t("message.lessFilters")
            : $t("message.moreFilters")
        }}
      </button>
      <!-- <div class="popup" v-show="moreFiltersPopup">
        <p>Model Year</p>
        <p>Engine</p>
        <p>Drivetrain</p>
        <p>Body Style</p>
      </div> -->
    </div>
    <!-- <button
      class="Dealer-Filters__button Dealer-Filters__button--main"
      @click="filter"
    >
      Apply
    </button> -->
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import ArrowDownIcon from "@/assets/arrow_down.svg";
import Tag from "@/components/demo/Tag.vue";
import { camelize } from "../../utils/camelize";
import MultiRangeSlider from "multi-range-slider-vue";
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  components: {
    ToggleButton,
    ArrowDownIcon,
    Tag,
    MultiRangeSlider,
    BaseIcon
},
  data() {
    return {
      camelize: camelize,
      toggle: {
        color: {
          checked: "rgba(0, 113, 200, 0.3)",
          unchecked: "rgba(151, 161, 180, 0.2)",
        },
        switchColor: {
          checked: "#0071c8",
          unchecked: "#969FB3",
        },
        margin: -3,
        height: 12,
        width: 34,
      },
      fleetSections: [
        "Geofence",
        "Make",
        "Model",
        "Year",
        "Condition",
        "Fleets",
        "Vehicle Health",
        // 'Engine',
      ],
      fleetMoreThresholdIndex: 7,
      dealerSections: [
        "Geofence",
        "Make",
        "Model",
        "Year",
        "Condition",
        "Fleets",
        "Trim",
        "Exterior Color",
        "Interior",
        "Days in Inventory",
        "Vehicle Health",
        "Body Style",
        "Engine",
        "Drivetrain",
      ],
      dealerMoreThresholdIndex: 11,
      filterFocus: -1, //toggles the focus from one dropdown to the next
      open: -1, // toggles the dropdowns depending on which one
      moreFiltersPopup: false,
      minDaysInventory: 0,
      maxDaysInventory: 90,
    };
  },
  computed: {
    dropDownSections() {
      if (this.$store.state.inDealerMode) return this.dealerSections;
      return this.fleetSections;
    },
    moreThresholdIndex() {
      if (this.$store.state.inDealerMode) return this.dealerMoreThresholdIndex;
      return this.fleetMoreThresholdIndex;
    },
  },
  methods: {
    filter() {
      this.$store.commit("setFilteringState", true);
      this.$store.commit("setSelectedTags");
      if (this.$store.state.inDealerMode) {
        if (this.$store.state.dealerMode.selected.Geofence.length === 1) {
          this.$store.commit(
            "setShowView",
            this.$store.state.dealerMode.selected.Geofence[0]
          );
        }
      }
    },
    determineColor(tag) {
      switch (tag) {
        case "Black":
          return "var(--black)";
        case "Green":
          return "var(--green)";
        case "Red":
          return "var(--red)";
        case "Silver":
          return "var(--dark-grey)";
        case "White":
          return "#fff";
        case "Yellow":
          return "var(--yellow)";
        default:
          break;
      }
    },
    isDropdown(section) {
      if (section === "Vehicle Health") return false;
      if (section === "Days in Inventory") return false;
      return true;
    },
    updateDaysInventory(obj) {
      this.minDaysInventory = obj.minValue;
      this.maxDaysInventory = obj.maxValue;
    },
  },
};
</script>

<style lang="scss">
.Dealer-Filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &__section {
    width: 100%;
  }

  &__label {
    width: 100%;
    margin-bottom: 3px;
    color: #76777a;
  }
  &__select {
    display: flex;
    width: 100%;
    height: 30px;
    border-right: 0;
  }
  &__select input,
  &__selected-tags {
    background: white;
    display: flex;
    align-items: center;
    flex-grow: 1;
    border: 0;
    outline: 0;
    color: #171c26;
    padding-left: 0.625rem;
    touch-action: none;
    user-select: text;
    appearance: none;
    font-family: ProximaNova;
    font-size: 15px;
    border: 1px solid var(--light-grey-2);
    border-right: 0;
    border-radius: 4px 0 0 4px;
    overflow-x: scroll;
    //hide scroll bars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &::placeholder {
      color: #969fb3;
    }
    &:focus {
      caret-color: #05b5e4;
    }
  }

  &__picker-part {
    width: 30px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey-2);
    border-radius: 0 4px 4px 0;
    background: #fff;
    .diagnostics-icon {
      width: 24px;
      height: 24px;
      transform: rotate(0deg);
      transition: transform 200ms ease;
      fill: #71798a;
    }
    &--focus {
      border: 1px solid #05b5e4;
      border-left: 0;
      border-radius: 0 0.25rem 0.25rem 0;
      .diagnostics-icon {
        fill: #05b5e4;
        transform: rotate(-180deg);
        transition: transform 200ms ease;
      }
    }
  }
  .Dealer-Filters {
    &__selected-tags--focus,
    &__select--focus input {
      border-right: 0;
      border: 1px solid #05b5e4;
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
  &__options {
    width: 100%;
    max-width: 305px;
    margin-top: 2px;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
    padding: 0.625rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__options--buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 6px;
  }
  &__button {
    background: none;
    // border: 1px solid var(--pale-grey);
    border: none;
    border-radius: 0.25rem;
    color: var(--blue);
    font-weight: bold;
    margin: 7px 0;
    padding: 8px 0 0 0;
    cursor: pointer;
    &--main {
      background: var(--blue);
      width: 100%;
      color: white;
      border: 0;
      padding: 5px 0;
    }
  }
  .popup {
    font-size: 12px;
    display: block;
    padding: 0;
    background: #fff;
    z-index: 5000;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    position: absolute;
    border-radius: 4px;
    box-shadow: rgba(23, 28, 38, 0.2) 0px 1px 5px 0px,
      rgba(23, 28, 38, 0.14) 0px 2px 2px 0px,
      rgba(23, 28, 38, 0.12) 0px 3px 1px -2px;
    bottom: 25px;
    white-space: nowrap;

    &-holder {
      position: relative;
    }
    > p {
      font-size: 12px;
      padding: 10px;
      margin: 0;
    }
    > p:hover {
      background: #eaf6f9;
    }
  }
  .toggle-wrap {
    display: grid;
    grid-template-columns: 18px 1fr 32px;
    align-items: center;
    gap: 8px;
    color: #606164;
    margin-bottom: 6px;
    .diagnostics-icon {
      width: 18px;
      fill: #9d9da1;
    }
  }
  .slider-wrap {
    margin-bottom: 9px;
    display: grid;
    grid-template-columns: 20px 1fr 20px;
    align-items: center;
    gap: 6px;
  }
}
.multi-range-slider {
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 2px 2px 0 2px;
  width: 96%;
  .bar-left,
  .bar-right {
    box-shadow: none;
    background-color: rgba(151, 161, 180, 0.2);
    height: 5px;
    padding: 0;
  }
  .bar-inner {
    background-color: var(--blue);
    border: none;
    box-shadow: none;
    height: 5px;
  }
  .thumb::before {
    width: 11px;
    height: 11px;
    margin: -3px;
    box-shadow: none;
    border: none;
    background-color: var(--blue);
  }
  .caption {
    display: none !important;
  }
}
</style>
