<template>
  <div class="checkbox-wrap">
    <div
      v-if="checked"
      class="checkbox checked"
      @click="$emit('toggle')"
    >
      <svg viewBox="0 0 24 24" :class="{clickable: !disabled}">
        <path
          fill="var(--blue)"
          d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
        ></path>
      </svg>
    </div>
    <div
      v-else
      class="checkbox unchecked"
      @click="$emit('toggle')"
    >
      <svg id="gml:icon/checkbox-unchecked" viewBox="0 0 24 24" :class="{clickable: !disabled}">
        <path
          :fill="uncheckedFill || 'var(--grey)'"
          d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: Boolean,
    disabled: Boolean,
    uncheckedFill: String,
  },
}
</script>

<style lang="scss" scoped>
div.checkbox > svg {
  width: 18px;
  height: 18px;
  line-height: 10px;
  margin-bottom: -3.5px;
  // cursor: auto;
}
.clickable {
  cursor: pointer;
}
</style>