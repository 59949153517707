<template>
  <div class="page">
    <h1>Distance Driven</h1>
    <div class="page-body">
      <div class="table-header">
        <div class="camera-vehicles">{{vehicles.length}} results &nbsp; | &nbsp;</div>
        <MeasureDaysSelect v-model="economyDays" />
        <button class="btn-download"><DownloadIcon /></button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Vehicle Label</th>
              <th>Make and Modal</th>
              <th>Vin</th>
              <th class="sticky">Distance Driven</th>
              <th class="sticky">Previous Period</th>
              <th class="sticky">Change</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vehicle in vehicles" :key="vehicle.id" @click="goToVehicle(vehicle)">
              <td>{{ vehicle['Vehicle Label'] }}</td>
              <td>{{ vehicle['Model Year'] }} {{ vehicle['Model Name'] }}</td>
              <td>{{ vehicle['VIN'] }}</td>
              <td class="sticky">
                <template v-if="vehicle.distanceDriven">{{ vehicle.distanceDriven }} <small>mi</small></template>
                <template v-else>--</template>
              </td>
              <td class="sticky">
                <template v-if="vehicle.previousDistanceDriven">{{ vehicle.previousDistanceDriven }} <small>mi</small></template>
                <template v-else>--</template>
              </td>
              <td class="sticky">
                <template v-if="vehicle.change && vehicle.change !== '--'">
                  <img v-if="vehicle.change >= 0" src="@/assets/img/up-icon.png" />
                  <img v-else src="@/assets/img/down-icon.png" />
                  {{ vehicle.change }}%
                </template>
                <template v-else>--</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as conversions from "../../utils/conversions.js";
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import MeasureDaysSelect from "@/components/demo/MeasureDaysSelect.vue";
export default {
  components: {
    DownloadIcon,
    MeasureDaysSelect,
  },
  data() {
    return {
      economyDays: 7,
      conversions: conversions,
      vehicleData: [
        {
          distanceDriven: 267,
          previousDistanceDriven: 156.2,
          change: 70.9,
          id: 'v-0',
          isClickable: true,
        },
        {
          distanceDriven: 266.9,
          previousDistanceDriven: 541.4,
          change: -50.7,
          id: 'v-1',
          isClickable: true,
        },
        {
          distanceDriven: 186.9,
          previousDistanceDriven: 279,
          change: -33,
          id: 'v-2',
          isClickable: true,
        },
        {
          distanceDriven: 131.7,
          previousDistanceDriven: 202.7,
          change: -35,
          id: 'v-3',
          isClickable: true,
        },
        {
          distanceDriven: 63.3,
          previousDistanceDriven: 127.3,
          change: -50.3,
          id: 'v-4',
          isClickable: true,
        },
        {
          distanceDriven: 7.2,
          previousDistanceDriven: 22.5,
          change: -68.1,
          id: 'v-5',
        },
      ]
    };
  },
  computed: {
    vehicles() {
      const vehicleDataMap = this.vehicleData.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle;
        return acc;
      }, {});

      return this.$store.state.dataset.vehicles.slice(0, 10).map(vehicle => {
        return {
          ...vehicle,
          ...vehicleDataMap[vehicle.id]
        };
      });
    },
  },
  methods: {
    goToVehicle(vehicle) {
      if (vehicle.isClickable) {
        this.$router.push(`/1/detail/${vehicle.id}`);
      }
    },
    addNumberedClasses() {
      const rows = this.$el.querySelectorAll('table.table tr');
      rows.forEach((row) => {
        const stickyElements = row.querySelectorAll('.sticky');
        stickyElements.forEach((element, elementIndex) => {
          element.classList.add(`sticky-${elementIndex + 1}`);
        });
      });
    },
    buildStickyColumns() {
      const rows = this.$el.querySelectorAll('table.table tr');
      rows.forEach(row => {
        const stickyElements = Array.from(row.querySelectorAll('.sticky')).reverse();
        let distanceFromRight = 0;
        stickyElements.forEach(element => {
          const elementWidth = element.offsetWidth;
          element.style.setProperty('--distance-from-right', `${distanceFromRight}px`);
          distanceFromRight += elementWidth;
        });
      });
    },
    handleResize() {
      this.buildStickyColumns();
    }
  },
  mounted() {
    console.log(this.vehicles);
    this.addNumberedClasses();
    this.buildStickyColumns();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  *, *:before, *:after {
    box-sizing: border-box;
  }
}
.page-body {
  width: 100%;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
  padding-top: 50px;
}
.table-header {
  display: flex;
  align-items: center;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  .camera-vehicles {
    color: var(--dark-grey);
    font-size: 14px;
  }
  .btn-download {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: auto;
    svg {
      width: 24px;
      fill: var(--blue);
    }
  }
  .btn-columns {
    width: 20px;
    height: 20px;
    fill: var(--blue);
    margin-top: 3px;
    margin-left: 8px;
    margin-right: 8px;
  }
}
.table-responsive {
  overflow: scroll;
  max-height: 485px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #C1C8D6;
}
table.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  thead {
    background: #EDF9FF;
    th {
      padding: 12px 8px;
      font-size: 12px;
      font-weight: 600;
      color: #71798A;
      text-align: left;
      border-bottom: 1px solid #C1C8D6;
      white-space: nowrap;
      &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 12px;
        font-size: 15px;
        color: #333;
        white-space: nowrap;
        border-bottom: 1px solid #C1C8D6;
        &:first-child {
          padding-left: 16px;
          // color: #0372c8;
          // &:hover {
          //   color: #52b2df;
          // }
        }
        &:last-child {
          padding-right: 16px;
        }
        &.icon-and-label > div {
          display: flex;
          height: 100%;
          align-items: center;
          vertical-align: middle;
        }
        &.category > div {
          display: flex;
          height: 100%;
          align-items: center;
          vertical-align: middle;
          > svg {
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }
        a {
          color: #171C26;
          text-decoration: none;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
  img {
    margin-right: 8px;
  }
}
</style>