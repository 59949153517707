<template>
  <div class="trip-history" :style="{ height: mapHeight + 'px' }">
    <div ref="ths" id="ths" class="sidebar" :class="{ hidden: !tripSelectionVisible }">
      <div class="top flex center anchor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          class="arrow-icon"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="var(--blue)">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M22.537 11.866L16 18.403l-6.538-6.536c-.957-.956-2.507-.956-3.465 0l9.998 9.997 10.002-9.998c-.955-.955-2.505-.955-3.46 0z"
                        transform="translate(-89 -532) translate(24 133) translate(-24 391) translate(47 8) translate(42) rotate(90 15.997 16)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="date-container">
          <span class="date" @click="$store.commit('toggleCalendarPopup')">
            {{ tripDateList[0] }}</span
          >
          <svg
            id="gfs:icon/i-schedule"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.775 19.108l-.883-.883 1.483-1.483v-1.967h1.25v2.5l-1.85 1.833zm-3.333 1.85h-9.4V3.875h4.166V2.833h1.25v1.042h7.084V2.833h1.25v1.042h4.166v8.575a5.767 5.767 0 0 0-1.25-.833V9.175c-.2.076-.411.116-.625.117H4.917c-.214-.001-.426-.04-.625-.117v10.533h7.316c.225.452.508.872.842 1.25h-.008zM4.292 7.417c.004.343.281.62.625.625h14.166a.633.633 0 0 0 .625-.625V5.125h-2.916v1.042h-1.25V5.125H8.458v1.042h-1.25V5.125H4.292v2.292zM21.792 17A4.792 4.792 0 1 0 17 21.792 4.8 4.8 0 0 0 21.792 17zm-1.25 0A3.542 3.542 0 1 1 17 13.458 3.55 3.55 0 0 1 20.542 17z"
            />
          </svg>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          class="arrow-icon"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="var(--light-grey)">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M10.89 22.535c-1.008 1.009-1.008 2.644 0 3.653h.187l9.999-9.997V16L11.079 6h-.19c-.484.484-.756 1.14-.756 1.825 0 .684.272 1.34.756 1.824l6.443 6.442-6.44 6.444h-.001z"
                        transform="translate(-254 -532) translate(24 133) translate(-24 391) translate(47 8) translate(207)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <!-- <svg
          class="history-info-btn"
          id="gfs:icon1/info-filled"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 2c5.519 0 10 4.481 10 10s-4.481 10-10 10S2 17.519 2 12 6.481 2 12 2zm.83 8.33h-1.66v7h1.66v-7zM12.84 7h-1.68l.01 1.7h1.67V7z"
          ></path>
        </svg>
        <div
          class="history-info"
          :style="{
            left: !mainSidebarVisible ? '150px;' : '',
            top: isMobile ? '229px' : '',
          }"
        >
          {{ $t("message.eachTripIsScoredBasedOnVariousEventsDetected") }}
        </div> -->

        <div class="calendar popup" v-show="$store.state.showCalendarPopup">
          <div
            class="top-bar"
            @click="
              calendarPopupView
                ? (calendarPopupView = 0)
                : (calendarPopupView = 1)
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              class="arrow-icon"
            >
              <g fill="none" fill-rule="evenodd">
                <g fill="#C1C8D6">
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            d="M22.537 11.866L16 18.403l-6.538-6.536c-.957-.956-2.507-.956-3.465 0l9.998 9.997 10.002-9.998c-.955-.955-2.505-.955-3.46 0z"
                            transform="translate(-89 -532) translate(24 133) translate(-24 391) translate(47 8) translate(42) rotate(90 15.997 16)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span>{{ dt.getFullYear() }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="24"
              id="gml:icon/dropdown-arrow"
              viewBox="0 0 24 24"
            >
              <path fill="#71798a" d="M7,10L12,15L17,10H7Z"></path>
            </svg>
          </div>
          <div
            class="month-grid grid"
            :class="{ hide: calendarPopupView === 1 }"
            @click="calendarPopupView = 1"
          >
            <div
              class="month"
              v-for="(month, index) in months"
              :key="index"
              :class="{ active: index === dt.getMonth() }"
            >
              {{ month }}
            </div>
          </div>
          <div class="day-grid grid" :class="{ hide: calendarPopupView !== 1 }">
            <div
              class="day-label"
              v-for="(label, index) in daysOfWeek"
              :key="`dl-${index}`"
              @click="calendarPopupView = 0"
            >
              {{ label }}
            </div>
            <div
              class="day"
              v-for="(day, index) in days"
              :key="`d-${index}`"
              @click="calendarPopupView = 0"
              :class="{
                active: day === dt.getDate(),
                today: day === new Date().getDate(),
                black: day >= dt.getDate() - 7 && day <= dt.getDate() - 1,
              }"
            >
              {{ day }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="item summary"
        @click="currentTripIndex = -1"
        :class="{ selected: currentTripIndex === -1 }"
      >
        <div class="summary-lbl">{{ $t('message.dailySummary') }}</div>
        <svg id="gfs:icon1/i-driver-score" viewBox="0 0 24 24">
          <path
            d="M21.733 12.9A9.792 9.792 0 1 0 3.8 19.342l.342.525 2.8-1.825a2.317 2.317 0 0 0 .891-2.667 4.375 4.375 0 0 1 3.042-5.617 4.417 4.417 0 0 1 3.8.834 4.325 4.325 0 0 1 1.667 3.441c.01.451-.049.9-.175 1.334a2.308 2.308 0 0 0 .9 2.675l2.791 1.791.342-.525a9.742 9.742 0 0 0 1.533-6.408zM6.375 14c.001.603.097 1.202.283 1.775.16.452-.004.954-.4 1.225L4.5 18.167a8.508 8.508 0 0 1 1.042-9.742l2.05 2.058C6.8 11.484 6.372 12.723 6.375 14zM12 8.375c-.472 0-.942.06-1.4.175A5.458 5.458 0 0 0 8.483 9.6L6.417 7.533a8.525 8.525 0 0 1 11.166 0l-2.075 2.075h-.041A5.567 5.567 0 0 0 12 8.375zm7.5 9.792L17.742 17a1.067 1.067 0 0 1-.4-1.233c.187-.57.282-1.167.283-1.767a5.567 5.567 0 0 0-1.233-3.508l2.066-2.067a8.458 8.458 0 0 1 2.034 4.608 8.542 8.542 0 0 1-.992 5.134zM13.1 14.5l-.767-3.608a.383.383 0 0 0-.7 0l-.716 3.6a2.292 2.292 0 1 0 2.183 0v.008zM12 17.542a1.042 1.042 0 1 1 0-2.084 1.042 1.042 0 0 1 0 2.084z"
          ></path>
        </svg>
        <span>
          {{ $t('message.avgScore') }}
          <span class="space" />
          <span
            class="bold red"
            :class="{
              orange: summaryStats.score > 69,
              green: summaryStats.score > 89,
            }"
            >{{ Math.ceil(summaryStats.score) }}</span
          >
        </span>
        <svg id="gfs:icon1/tripduration" viewBox="0 0 24 24">
          <path
            fill-rule="evenodd"
            d="M11.375 6.804h1.25v4.8L9.392 13.98l-.725-1.008 2.708-2V6.804zm8.75-1.667v8.334c0 .981-.46 1.906-1.242 2.5L12 21.154l-6.883-5.183c-.782-.594-1.241-1.519-1.242-2.5V5.138c0-1.266 1.026-2.292 2.292-2.292h11.666c1.266 0 2.292 1.026 2.292 2.292zm-1.25 0c0-.575-.466-1.041-1.042-1.041H6.167c-.576 0-1.042.466-1.042 1.042v8.333c.002.588.276 1.141.742 1.5L12 19.587l6.133-4.641c.466-.359.74-.912.742-1.5V5.137z"
          ></path>
        </svg>
        <span>
          {{ $t('message.trips') }}
          <span class="space" />
          {{ summaryStats.trips }}
        </span>
        <svg id="gfs:icon1/warning" viewBox="0 0 24 24">
          <path
            fill-rule="evenodd"
            d="M12.47 3.27a1 1 0 01.42.42l8.3 15.85a1 1 0 01-.88 1.46H3.67a1 1 0 01-.89-1.47L11.12 3.7a1 1 0 011.35-.42zM12 5.23L4.5 19.5h14.98L12 5.23zm0 10.97a.9.9 0 110 1.8.9.9 0 010-1.8zm.68-6.85v5.4h-1.35v-5.4h1.35z"
          ></path>
        </svg>
        <span>
          {{ $t('message.events') }}
          <span class="space" />
          {{ summaryStats.events }}
        </span>
        <img src="@/assets/Video_71_.png" style="width: 16px;" />
        <span>
          {{ $t('message.recordings') }}
          <span class="space" />
          {{ summaryStats.recordings }}
        </span>
      </div>
      <div
        class="item"
        v-for="(trip, index) of trips"
        @click="currentTripIndex = index"
        :key="index"
        :class="{ selected: currentTripIndex === index }"
      >
        <div class="a-to-b flex column center">
          <svg class="icon-A" width="24" height="30" viewBox="0 0 24 30" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <path class="pin" fill="#00518F" d="M12 0C5.4 0 0 5.09 0 11.354c0 2.48.867 4.829 2.4 6.787l9.067 11.615c.2.26.666.326.933.13l.133-.13L21.6 18.14c1.533-1.958 2.4-4.307 2.4-6.787C24 5.09 18.6 0 12 0z" transform="translate(-300 -357) translate(248 198) translate(26 135) translate(26 24)"></path>
              <text fill="#FFF" text-anchor="middle" font-family="ProximaNova-Bold, Proxima Nova" font-size="16" font-weight="bold" transform="translate(-300 -357) translate(248 198) translate(26 135) translate(26 24)" style="font-family: ProximaNova">
                <tspan x="12" y="17">{{index + 1}}</tspan>
              </text>
            </g>
          </svg>
          <div class="dashed-line" />
          <svg class="icon-B" height="12" width="12" viewBox="0 0 12 12">
            <circle fill="#05B5E4" cx="6" cy="6" r="6"></circle>
          </svg>
        </div>
        <div class="flex column" style="justify-content: center">
          {{ trip.map[$t("tripHistoryKey")].startLocation }}
          <span style="color: var(--grey); font-size: 13px">07:47 AM</span>
        </div>
        <div class="flex column" style="justify-content: center">
          {{
            trip.map[$t("tripHistoryKey")].endLocation ||
            trip["Trip End location"]
          }}
          <span style="color: var(--grey); font-size: 13px">08:34 AM</span>
        </div>
        <div class="trip-score-area">
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: var(--light-grey-2);
            "
          />
          <div style="margin: 14px 0; display: flex">
            <div class="flex center" v-if="fromVehicle">
              <svg
                id="gfs:icon/i-driver"
                width="18px"
                height="18px"
                style="margin: 0 6px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--dark-grey)"
                  d="M18.948 14.154c-.78-1.562-2.286-2.529-3.918-2.515h-1.186c1.44-.762 2.357-2.352 2.362-4.097v-.964C16.206 4.05 14.325 2 12.004 2c-2.32 0-4.201 2.05-4.201 4.578v.964c.005 1.745.921 3.335 2.362 4.097H8.979c-1.59-.016-3.067.9-3.865 2.4L2 20.458l2.716 1.118c.026-.508.098-1.011.212-1.504l-1.053-.385 2.327-4.82.062-.115c.555-1.06 1.595-1.707 2.715-1.687h6.05c1.144-.022 2.205.65 2.752 1.745l2.353 4.819-1.053.443c.11.48.177.971.203 1.465L22 20.42l-3.052-6.265zM9.13 7.542v-.964c0-1.73 1.287-3.132 2.874-3.132 1.588 0 2.875 1.402 2.875 3.132v.964c0 1.73-1.287 3.133-2.875 3.133-1.587 0-2.874-1.403-2.874-3.133zM17.975 22h-1.327c0-2.795-2.079-5.06-4.644-5.06-2.564 0-4.644 2.265-4.644 5.06H6.034c0-3.593 2.673-6.506 5.97-6.506 3.298 0 5.971 2.913 5.971 6.506z"
                />
              </svg>
              <span class="semibold" style="color: var(--dark-grey)">{{
                $t("message." + trip.driver)
              }}</span>
            </div>
            <div class="flex center" v-else-if="fromDriver">
              <svg
                id="gfs:icon/i-vehicle"
                width="18px"
                height="18px"
                style="margin: 0 6px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--dark-grey)"
                  d="M21.167 8.875h-1.359a1.042 1.042 0 0 1-.966-.667l-1.667-4.333H6.833L5.167 8.217c-.157.396-.54.657-.967.658H2.833v1.25h1.342l-1.133 1.6v8.4h4.583v-3.333h8.75v3.333h4.583v-8.4l-1.133-1.6h1.342v-1.25zM7.683 5.125h8.634l1.358 3.542c.027.077.06.153.1.225H6.225c.039-.073.072-.148.1-.225l1.358-3.542zm-1.308 13.75H4.292v-2.342c.322.167.679.255 1.041.259h1.042v2.083zm11.25 0v-2.083h1.042c.362-.004.72-.092 1.041-.259v2.342h-2.083zm1.042-3.333H5.333A1.042 1.042 0 0 1 4.292 14.5v-2.375l1.1-1.558c.193-.27.501-.434.833-.442h11.533c.332.008.64.172.834.442l1.1 1.558V14.5c0 .569-.457 1.033-1.025 1.042zM5.333 12.208h3.334v1.25H5.333v-1.25zm10 0h3.334v1.25h-3.334v-1.25z"
                />
              </svg>
              <span class="semibold" style="color: var(--dark-grey)">{{
                trip.vehicle
              }}</span>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; margin-bottom: 14px;">
            <div style="display: flex; justify-content: space-between">
              <svg
                id="gfs:icon/i-driver-score"
                viewBox="0 0 24 24"
                width="18px"
                height="18px"
                style="margin: 0 6px"
              >
                <path
                  d="M21.733 12.9A9.792 9.792 0 1 0 3.8 19.342l.342.525 2.8-1.825a2.317 2.317 0 0 0 .891-2.667 4.375 4.375 0 0 1 3.042-5.617 4.417 4.417 0 0 1 3.8.834 4.325 4.325 0 0 1 1.667 3.441c.01.451-.049.9-.175 1.334a2.308 2.308 0 0 0 .9 2.675l2.791 1.791.342-.525a9.742 9.742 0 0 0 1.533-6.408zM6.375 14c.001.603.097 1.202.283 1.775.16.452-.004.954-.4 1.225L4.5 18.167a8.508 8.508 0 0 1 1.042-9.742l2.05 2.058C6.8 11.484 6.372 12.723 6.375 14zM12 8.375c-.472 0-.942.06-1.4.175A5.458 5.458 0 0 0 8.483 9.6L6.417 7.533a8.525 8.525 0 0 1 11.166 0l-2.075 2.075h-.041A5.567 5.567 0 0 0 12 8.375zm7.5 9.792L17.742 17a1.067 1.067 0 0 1-.4-1.233c.187-.57.282-1.167.283-1.767a5.567 5.567 0 0 0-1.233-3.508l2.066-2.067a8.458 8.458 0 0 1 2.034 4.608 8.542 8.542 0 0 1-.992 5.134zM13.1 14.5l-.767-3.608a.383.383 0 0 0-.7 0l-.716 3.6a2.292 2.292 0 1 0 2.183 0v.008zM12 17.542a1.042 1.042 0 1 1 0-2.084 1.042 1.042 0 0 1 0 2.084z"
                />
              </svg>
              <span class="bold" style="margin-right: 6px">{{
                $t("message.tripScore")
              }}</span>
              <span
                :id="`trip-score-${index}`"
                class="bold red"
                :class="{ orange: trip.score > 69, green: trip.score > 89 }"
                >{{ trip.score }}</span
              >
            </div>
            <InfoHoverIcon
              msg="The trip score is based only on the vehicle detected hard brake, hard acceleration, and speed over 80 mph calculated as a percent of trip distance and not correlated to configured speeding events such as max speed, road speed, and geofence. Because speed over 80 mph is a percent of the entire trip there are no linkable points on the trip map. The camera detected hard brake and hard acceleration are not included in the trip score."
              clearIcon
            />
          </div>
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; justify-content: space-between">
              <svg id="gfs:icon1/warning" width="18px" height="18px" style="margin: 0 6px" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12.47 3.27a1 1 0 01.42.42l8.3 15.85a1 1 0 01-.88 1.46H3.67a1 1 0 01-.89-1.47L11.12 3.7a1 1 0 011.35-.42zM12 5.23L4.5 19.5h14.98L12 5.23zm0 10.97a.9.9 0 110 1.8.9.9 0 010-1.8zm.68-6.85v5.4h-1.35v-5.4h1.35z"></path>
              </svg>
              <span class="bold" style="margin-right: 6px">{{ $t("message.totalTripEvents") }} ({{ numTripEvents(trip) }})</span>
            </div>
            <svg
              id="gml:icon/expander-down"
              :style="
                breakdownsShown.indexOf(index) !== -1
                  ? 'transform: rotate(180deg);'
                  : ''
              "
              viewBox="0 0 24 24"
              width="19px"
              height="19px"
              @click="showHideBreakdown(trip, index)"
            >
              <path
                fill="black"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
              />
            </svg>
          </div>
          <div
            class="other-events-container"
            v-if="breakdownsShown.indexOf(index) !== -1"
          >
            <svg id="gfs:icon/donut-marker" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                fill="rgb(255, 167, 37)"
                d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
              />
            </svg>
            <span class="bold" style="margin-right: 6px">
              {{ $t("message.scoredEvents") }} ({{ numScoredEvents(trip) }})
            </span>
          </div>
          <div class="breakdown" v-if="breakdownsShown.indexOf(index) !== -1">
            <div class="breakdown-item">
              <svg id="gfs:icon1/hard-brake" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12.2 4.4a7.6 7.6 0 110 15.2 7.6 7.6 0 010-15.2zm7.18 1.23a9.57 9.57 0 01-.04 12.8l-.9-.8a8.36 8.36 0 00.04-11.2l.9-.8zM5.07 5.58l.9.8a8.36 8.36 0 00-.03 11.23l-.89.8a9.56 9.56 0 01.02-12.84zm7.13.02a6.4 6.4 0 100 12.8 6.4 6.4 0 000-12.8zm0 8.9a.83.83 0 110 1.67.83.83 0 010-1.67zm.63-6.67v5h-1.26v-5h1.25z"></path>
              </svg>
              <span>{{ $t("message.hardBrake") }}</span>
              <span class="deduction">{{
                trip.hardBreaking
                  ? "(-" + trip.hardBreaking.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{
                trip.hardBreaking ? trip.hardBreaking.times : "0"
              }}</span>
            </div>
            <div class="breakdown-item">
              <svg id="gfs:icon1/hard-acceleration" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M21 3.52l.1.1v1.07l-.1.1-6.4-.03-5.11 5.2v6.55h1.17l.02-.1a3.3 3.3 0 012.8-2.58l.2-.01h.18a3.3 3.3 0 013.2 2.59l.01.1H21l.1.1v1.08l-.1.1h-3.93l-.02.1a3.3 3.3 0 01-2.82 2.57l-.19.02h-.18a3.28 3.28 0 01-3.18-2.6l-.02-.1H8.34l-.1-.1V16.5H3.87l-.1-.1v-.94l.1-.1h4.37v-3.28H2.06l-.1-.1v-.94l.1-.1h6.18l.02-1.49 2.32-2.37H5.8l-.1-.1v-.94l.1-.1 5.85.03 2.43-2.44H21zm-7.14 11.57c-1.1 0-2.01.92-2.01 2.06 0 1.13.9 2.06 2 2.06 1.13 0 2.03-.92 2.03-2.06 0-1.14-.9-2.06-2.02-2.06z"></path>
              </svg>
              <span>{{ $t("message.hardAcceleration") }}</span>
              <span class="deduction">{{
                trip.hardAcceleration
                  ? "(-" + trip.hardAcceleration.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{
                trip.hardAcceleration ? trip.hardAcceleration.times : "0"
              }}</span>
            </div>
            <div class="breakdown-item">
              <svg id="gfs:icon1/fixed-speed" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12.2 11.67c.2 0 .37.17.36.38l.84 6.4a2.5 2.5 0 11-2.66-.1l.16-.09.9-6.22c.01-.2.18-.37.4-.37zm2.99-4.64a9.8 9.8 0 015.01 14.61l-.18.29h-4.56c.16-.4.25-.82.27-1.25h3.6a8.54 8.54 0 10-14.65 0h3.6c.01.43.1.85.26 1.25H3.98l-.18-.29a9.8 9.8 0 0111.39-14.6zM12 19.22a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM10.52 2.39l3.11.05-1.6 2.66-1.51-2.71z"></path>
              </svg>
              <span>{{ $t("message.speedOver80mph") }}</span>
              <span class="deduction">{{
                trip.speedOver80
                  ? "(-" + trip.speedOver80.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count"
                >{{ trip.speedOver80 ? trip.speedOver80.percent : "0" }}%</span
              >
            </div>
          </div>
          <div
            :id="`history-events-${index}`"
            class="other-events-container"
            v-if="breakdownsShown.indexOf(index) !== -1"
          >
            <svg id="gfs:icon/donut-marker" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                fill="rgb(95, 30, 236)"
                d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
              />
            </svg>
            <span class="bold" style="margin-right: 6px">
              {{ $t("message.cameraEvents") }} ({{ numCameraEvents(trip) }})
            </span>
          </div>
          <div class="breakdown" v-if="breakdownsShown.indexOf(index) !== -1">
            <div class="breakdown-item">
              <img src="@/assets/Video_71_.png" style="width: 20px; padding: 0 6px 0 0; filter: contrast(0.3);" />
              <span>{{ $t("message.foodAndDrink") }}</span>
              <span class="deduction">{{
                trip.camera && trip.camera.foodAndDrink.deduction
                  ? "(-" + trip.camera.foodAndDrink.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{ trip.camera ? trip.camera.foodAndDrink.times : "0" }}</span>
            </div>
            <div class="breakdown-item">
              <img src="@/assets/Video_71_.png" style="width: 20px; padding: 0 6px 0 0; filter: contrast(0.3);" />
              <span>{{ $t("message.cellphoneUse") }}</span>
              <span class="deduction">{{
                trip.camera && trip.camera.cellPhoneUse.deduction
                  ? "(-" + trip.camera.cellPhoneUse.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{ trip.camera ? trip.camera.cellPhoneUse.times : "0" }}</span>
            </div>
            <div class="breakdown-item">
              <img src="@/assets/Video_71_.png" style="width: 20px; padding: 0 6px 0 0; filter: contrast(0.3);" />
              <span>{{ $t("message.hardBrake") }}</span>
              <span class="deduction">{{
                trip.camera && trip.camera.hardBrake.deduction
                  ? "(-" + trip.camera.hardBrake.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{ trip.camera ? trip.camera.hardBrake.times : "0" }}</span>
            </div>
            <div class="breakdown-item">
              <img src="@/assets/Video_71_.png" style="width: 20px; padding: 0 6px 0 0; filter: contrast(0.3);" />
              <span>{{ $t("message.hardAcceleration") }}</span>
              <span class="deduction">{{
                trip.camera && trip.camera.hardAcceleration.deduction
                  ? "(-" + trip.camera.hardAcceleration.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{ trip.camera ? trip.camera.hardAcceleration.times : "0" }}</span>
            </div>
          </div>
          <!-- ADAS Events -->
          <!-- <div
            class="other-events-container"
            v-if="breakdownsShown.indexOf(index) !== -1"
          >
            <DonutIcon color="#FF4F72" />
            <span class="bold" style="margin-right: 6px">
              ADAS Events ({{ numAdasEvents(trip) }})
            </span>
          </div>
          <div class="breakdown" v-if="breakdownsShown.indexOf(index) !== -1">
            <div class="breakdown-item">
              <SidebarAdasIcon />
              <span>Forward Collision Alert</span>
              <span></span>
              <span class="count">{{ trip.adas && trip.adas.collision ? trip.adas.collision.times : "0" }}</span>
            </div>
            <div class="breakdown-item">
              <SidebarAdasIcon />
              <span>Forward Automatic Braking</span>
              <span></span>
              <span class="count">{{ trip.adas && trip.adas.breaking ? trip.adas.breaking.times : "0" }}</span>
            </div>
          </div> -->
          <!-- Other Events -->
          <div
            class="other-events-container"
            v-if="breakdownsShown.indexOf(index) !== -1"
          >
            <DonutIcon color="#171C26" />
            <span class="bold" style="margin-right: 6px">
              Additional Events ({{ numOtherEvents(trip) }})
            </span>
          </div>
          <div class="breakdown" v-if="breakdownsShown.indexOf(index) !== -1">
            <!--  -->
            <div class="breakdown-item">
              <SidebarIdlingIcon />
              <span>Idling</span>
              <span></span>
              <span class="count">{{ trip.other && trip.other.excessiveIdling ? trip.other.excessiveIdling.times : "0" }}</span>
            </div>
            <div class="breakdown-item">
              <SidebarFixedSpeedIcon />
              <span>Fixed Speed</span>
              <span></span>
              <span class="count">{{ trip.other && trip.other.fixedSpeed ? trip.other.fixedSpeed.times : "0" }}</span>
            </div>
          </div>
          <div class="breakdown" v-if="breakdownsShown.indexOf(index) !== -1">
            <!-- <div class="breakdown-item">
              <svg id="gfs:icon1/donut-marker" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  fill="rgb(0, 172, 192)"
                  d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
                ></path>
              </svg>
              <span>{{ $t("message.seatbeltUnbuckled") }}</span>
              <span class="deduction"></span>
              <span class="count">--</span>
            </div>
            <div class="breakdown-item">
              <svg id="gfs:icon/donut-marker" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  fill="rgb(192, 202, 51)"
                  d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
                />
              </svg>
              <span>{{ $t("message.speeding") }}</span>
              <span class="deduction">{{
                trip.speedOver80
                  ? "(-" + trip.speedOver80.deduction + " pts)"
                  : ""
              }}</span>
              <span class="count">{{ trip.speedOver80 ? "1" : "0" }}</span>
            </div> -->
            <div class="flex" style="margin-top: 12px">
              <div
                class="flex center"
                style="margin-right: 20px"
                :style="trip.business ? 'color: var(--blue);' : ''"
              >
                <svg
                  class="radio"
                  id="gml:icon/radiobutton"
                  @click="
                    trip.business = true;
                    $forceUpdate();
                  "
                  viewBox="0 0 24 24"
                >
                  <path
                    v-if="trip.business !== true"
                    fill="var(--grey)"
                    d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                  <path
                    v-else
                    fill="var(--blue)"
                    d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z"
                  />
                </svg>
                {{ $t("message.business") }}
              </div>
              <div
                class="flex center"
                :style="!trip.business ? 'color: var(--blue);' : ''"
              >
                <svg
                  class="radio"
                  id="gml:icon/radiobutton"
                  @click="
                    trip.business = false;
                    $forceUpdate();
                  "
                  viewBox="0 0 24 24"
                >
                  <path
                    v-if="trip.business !== false"
                    fill="var(--grey)"
                    d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                  <path
                    v-else
                    fill="var(--blue)"
                    d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z"
                  />
                </svg>
                {{ $t("message.personal") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GuidedTourWaypoint
      :id="9"
      target="trip-score-0"
      :xOffset="100"
      :yOffset="80"
      :initRotation="180"
      :customScrollRef="$refs.ths"
      forceYReverse
    />
    <GuidedTourWaypoint
      :id="10"
      target="gml:icon/expander-down"
      :xOffset="67"
      :yOffset="32"
      :initRotation="270"
      :customScrollRef="$refs.ths"
    />

    <div
      id="map"
      class="map"
      :class="{ shifted: !tripSelectionVisible, mapOverflowY }"
      :style="{
        'background-image': `url(${require(`../../assets/trip-history/${
          summary[`img${currentTripMap.mapSize}`]
        }`)})`,
        'background-position': currentTripMap.offsetX
          ? `calc(50% - ${currentTripMap.offsetX}px) calc(50% - ${currentTripMap.offsetY}px)`
          : '',
        'background-size': currentTripMap.size
          ? currentTripMap.size + 'px'
          : '',
        width: mapWidth + 'px',
        height: mapHeight + 'px',
      }"
      @click.self="isMobile ? (tripSelectionVisible = false) : ''"
    >
      <!-- EDITOR (LOCAL ONLY) -->
      <div v-if="isLocalEnv && editMode" class="add-pt-btn">
        <div class="flex check-area">
          <input type="checkbox" v-model="editMode" />Edit mode
        </div>
        <select v-model.number="localpt.type">
          <option value="0">Start</option>
          <option value="1">Hard Brake</option>
          <option value="2">Hard Accel</option>
          <option value="3">End</option>
          <option value="4">Breadcrumb</option>
          <option value="5">Invisible</option>
          <option value="6">Camera</option>
          <option value="7">Orange Donut</option>
          <option value="8">Purple Donut</option>
        </select>
        <input
          class="x"
          v-model.number="localpt.x"
          type="number"
          placeholder="x"
        />
        <input
          class="y"
          v-model.number="localpt.y"
          type="number"
          placeholder="y"
        />
        <button @click="addPoint">+ Marker</button>
        <button @click="copyPoints">Copy Dots</button>
        <button @click="deletePoint">DELETE point</button>
        <!-- <button @click="addIDs">Add IDs</button> -->
        <input
          :disabled="currentTripMap.dots.indexOf(localpt) !== 0"
          v-model.number="localptId"
          type="number"
          :placeholder="currentTripMap.dots.indexOf(localpt) === 0 ? 'id-seed' : 'id'"
          style="color: purple;"
        />
        <input
          v-if="localpt.type === 0"
          v-model.number="localpt.num"
          type="number"
          placeholder="number"
        />
        <div class="check-area">(local only)</div>
      </div>
      <div v-else-if="isLocalEnv" class="add-pt-btn">
        <div class="flex check-area">
          <input type="checkbox" v-model="editMode" />Edit mode
        </div>
        <div class="check-area">(local only)</div>
      </div>

      <!-- TRIP STATS -->
      <div class="trip-stats">
        <span class="text">{{ $t("message.distance") }}</span>
        <span class="bold">
          {{
            conversions.getMilesValue(currentStats.distance, $i18n.locale) +
            $t("message.mi")
          }}</span
        >
        <span class="text">{{ $t("message.fuelEconomy") }}</span>
        <span class="bold">{{
          currentStats.fuelEconomy
            ? conversions.getMpgValue(currentStats.fuelEconomy, $i18n.locale) +
              $t("message.mpg")
            : "N/A"
        }}</span>
        <span class="text">{{ $t("message.totalTime") }}</span>
        <span class="bold">{{ currentStats.time }}</span>
        <span class="text">{{ $t("message.idleTime") }}</span>
        <span class="bold">{{
          currentStats.idle ? currentStats.idle : "N/A"
        }}</span>
      </div>

      <!-- LINES -->
      <div
        v-for="(dot, i) of currentTripMap.dots"
        class="line"
        :style="{
          display: i === 0 || currentTripIndex === -1 ? 'none' : '',
          left: lineProps(i).left,
          top: lineProps(i).top,
          width: lineProps(i).width,
          transform: lineProps(i).angle,
        }"
        :key="'a' + i"
      />

      <!-- MARKERS (DOTS) -->
      <div
        v-for="dot of currentTripMap.dots"
        :class="['dot', whichDot === dot.id ? 'active-dot' : '', `dot-${dot.type}`]"
        :key="dot.id"
        :style="{
          left: `calc(50% + ${dot.x}px)`,
          top: `calc(50% + ${dot.y}px)`,
          minWidth: (isLocalEnv && editMode) ? '4px' : '',
          minHeight: (isLocalEnv && editMode) ? '4px' : '',
          border: (!isLocalEnv || !editMode)
            ? ''
            : localpt === dot
            ? '1px solid red'
            : '1px solid black',
        }"
        @mouseenter="(isLocalEnv && editMode) ? (localpt = dot) : ''"
      >
        <!-- HARD BRAKE -->
        <div
          class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <HardBrakingSVG v-if="dot.type === 1" style="width: 36px; height: 36px;" />
        </div>

        <!-- <div
          class="hard-brake-popup map-popup"
          :style="{
            left: `${mapWidth / 2 + dot.x}px`,
            top: `${mapHeight / 2 + dot.y}px`,
          }"
          v-if="dot.type === 1 && showMapPopup === 1 && whichDot === dot.id"
        >
          <div class="icon-container">
            <HardBrakingSVG />
          </div>
          <p class="map-popup-text">
            <span class="bold heading">{{ $t("message.hardBrake") }}</span
            ><span class="grey">08:12 AM</span>
          </p>
        </div> -->

        <!-- HARD ACCEL -->
        <div
          class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <HardAccelSVG v-if="dot.type === 2" style="width: 36px; height: 36px;" />
        </div>
        <!-- <div
          class="hard-accel-popup map-popup"
          v-if="dot.type === 2 && showMapPopup === 2 && whichDot === dot.id"
        >
          <div class="icon-container">
            <HardAccelSVG />
          </div>
          <p class="map-popup-text">
            <span class="bold heading">{{
              $t("message.hardAcceleration")
            }}</span
            ><span class="grey">08:25 AM</span>
          </p>
        </div> -->

        <!-- CAMERA: Type 6 -->
        <div
          class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <img src="@/assets/Video_71_.png" v-if="dot.type === 6" class="camera-icon" />
        </div>

        <!-- BREADCRUMB -->
        <!-- does not need container, since the component has one -->
        <div class="icon-container clickable" @click="handleMapPopups(dot, 'click')">
          <Breadcrumb
            v-if="dot.type === 4"
            :expanded="
              whichDot === dot.id &&
              showMapPopup === 4 &&
              breadcrumbExpanded &&
              trips[currentTripIndex].activeBreadcrumb
                ? true
                : false
            "
            :dot="dot"
          />
        </div>

        <svg v-if="dot.type === 7" class="donut-for-main" :class="`donut-${dot.type}`" id="gfs:icon/donut-marker" viewBox="0 0 24 24">
          <path
            fill-rule="evenodd"
            fill="rgb(255, 167, 37)"
            d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
          />
        </svg>

        <svg v-if="dot.type === 8" class="donut-for-main" id="gfs:icon/donut-marker" viewBox="0 0 24 24">
          <path
            fill-rule="evenodd"
            fill="rgb(95, 30, 236)"
            d="M12,0C18.623,0 24,5.377 24,12C24,18.623 18.623,24 12,24C5.377,24 0,18.623 0,12C0,5.377 5.377,0 12,0ZM12,6C15.311,6 18,8.689 18,12C18,15.311 15.311,18 12,18C8.689,18 6,15.311 6,12C6,8.689 8.689,6 12,6Z"
          />
        </svg>

        <!-- Type 9 = MultiIcon -->
        <MultiIcon
          v-if="dot.type === 9"
          :colors="dot.subtypeColors"
          :label="dot.subtypes.length"
          :data="dot.data" />

        <!-- Type 10 = Idling -->
        <div
          class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <IconIdling v-if="dot.type === 10" style="width: 36px; height: 36px;" />
        </div>

        <!-- Type 11 = Speeding -->
        <div class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <IconSpeeding v-if="dot.type === 11" style="width: 36px; height: 36px;" />
        </div>

         <!-- Type 12 = Fixed Speed -->
         <div class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <IconFixedSpeed v-if="dot.type === 12" style="width: 36px; height: 36px;" />
        </div>

        <!-- Type 13 = ADAS -->
        <div class="icon-container clickable"
          @click="handleMapPopups(dot, 'click')"
        >
          <IconAdas v-if="dot.type === 13" style="width: 36px; height: 36px;" />
        </div>

        <BreadcrumbPopup
          @close="handleMapPopups(dot, 'click')"
          v-if="whichDot === dot.id && (showMapPopup === 4 || showMapPopup === 1 || showMapPopup === 2 || showMapPopup === 6 || showMapPopup === 10 || showMapPopup === 11 || showMapPopup === 12 || showMapPopup === 13)"
          :dot="dot"
        />

        <!-- END -->
        <!-- <EndSVG v-if="dot.type === 3" /> -->
        <!-- <Breadcrumb v-if="dot.type === 3" :dot="dot" /> -->

        <!-- START -->
        <div class="icon-container">
          <Breadcrumb v-if="dot.type === 0" :dot="dot" start />
          <StartMarker
            v-if="dot.type === 0"
            :num="dot.num ? dot.num : currentTripIndex + 1"
          />
        </div>
      </div>
      <div
        class="sidebar-toggle"
        @click="tripSelectionVisible = !tripSelectionVisible"
      >
        <svg
          id="gml:icon/expander-down"
          :class="{ open: tripSelectionVisible }"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path
            d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import HardBrakingSVG from "@/assets/trip-history/markers/hardbrake.svg";
import HardAccelSVG from "@/assets/trip-history/markers/hardaccel.svg";
import StartMarker from "./StartMarker.vue";
// import EndSVG from "@/assets/trip-history/markers/b.svg";
import Breadcrumb from "./Breadcrumb.vue";
import * as conversions from "../../utils/conversions.js";
import BreadcrumbPopup from "./BreadcrumbPopup.vue";
import InfoHoverIcon from "./InfoHoverIcon.vue";
import { v4 as uuid } from 'uuid';
import GuidedTourWaypoint from "./GuidedTourWaypoint.vue";
import MultiIcon from "@/components/icons/MultiIcon.vue";
import IconIdling from "@/assets/trip-history/markers/idling.svg";
import IconSpeeding from "@/assets/trip-history/markers/speeding_road.svg";
import IconFixedSpeed from "@/assets/trip-history/markers/speeding_fixed.svg";
import IconAdas from "@/assets/trip-history/markers/adas.svg";
import DonutIcon from "@/components/icons/DonutIcon.vue";
import SidebarIdlingIcon from "@/components/icons/IdlingIcon.vue";
import SidebarFixedSpeedIcon from "@/components/icons/FixedSpeedIcon.vue";
// import SidebarAdasIcon from "@/components/icons/AdasSystemIcon.vue";

export default {
  components: {
    HardBrakingSVG,
    HardAccelSVG,
    StartMarker,
    // EndSVG,
    Breadcrumb,
    BreadcrumbPopup,
    InfoHoverIcon,
    GuidedTourWaypoint,
    MultiIcon,
    IconIdling,
    IconSpeeding,
    IconFixedSpeed,
    IconAdas,
    DonutIcon,
    SidebarIdlingIcon,
    SidebarFixedSpeedIcon,
    // SidebarAdasIcon,
},
  props: {
    mainSidebarVisible: Boolean,
    isMobile: Boolean,
    isTablet: Boolean,
    trips: Array[Object],
    fromVehicle: Boolean,
    fromDriver: Boolean,
    vehicleLabel: String,
    summary: Object,
    // triphistory: Array[Object]
  },
  data() {
    return {
      mapOverflowY: false,
      breadcrumbExpanded: false,
      whichDot: null,
      showMapPopup: null,
      mapWidth: 0,
      mapHeight: 0,
      currentTripIndex: -1,
      breakdownsShown: [],
      tripSelectionVisible: true,
      calendarPopupView: 0, // 0 to view the year, 1 for the month
      conversions: conversions,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      daysOfWeek: ["S", "M", "T", "W", "T", "F", "S"],
      editMode: false,
      localpt: {
        type: 4,
        x: 0,
        y: 0,
        num: undefined,
      },
      localptSeedId: undefined,
    };
  },
  computed: {
    days() {
      let dates = ["", "", "", ""]; //pushes start date over to Thursday instead of Sunday
      for (let i = 1; i <= 31; i++) {
        dates.push(i);
      }
      return dates;
    },
    dt() {
      // create date object for 7 days ago
      return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    },
    tripDateList() {
      const month = this.dt.getMonth() + 1;
      const date = this.dt.getDate();
      const year = this.dt.getFullYear();
      return [`${month}/${date}/${year}`];
    },
    currentTrip() {
      if (this.currentTripIndex == -1) return this.summary;
      return this.trips[this.currentTripIndex];
    },
    currentTripMap() {
      if (this.currentTripIndex == -1) return this.currentTrip;
      return this.currentTrip.map[this.$t("tripHistoryKey")];
    },
    summaryStats() {
      const obj = {};
      obj.score = 0;
      obj.trips = 0;
      obj.events = 0;
      obj.distance = 0;
      obj.fuelEconomy = 0;
      obj.totalMin = 0;
      obj.totalSec = 0;
      obj.idleMin = 0;
      obj.idleSec = 0;
      obj.recordings = 0;
      for (let i = 0; i < this.trips.length; i += 1) {
        obj.score += this.trips[i].score || 0;
        obj.trips += 1;
        if (this.trips[i].hardAcceleration?.times)
          obj.events += this.trips[i].hardAcceleration.times;
        if (this.trips[i].hardBreaking?.times)
          obj.events += this.trips[i].hardBreaking.times;
        if (this.trips[i].speedOver80) obj.events += 1;
        obj.distance += this.trips[i].distance || 0;
        obj.fuelEconomy += this.trips[i].fuelEconomy || 0;
        obj.totalSec +=
          (parseInt(this.trips[i].time?.match(/([0-9]*)min/)?.[1]) || 0) * 60;
        obj.totalSec +=
          parseInt(this.trips[i].time?.match(/([0-9]*)s/)?.[1]) || 0;
        obj.idleSec +=
          (parseInt(this.trips[i].idle?.match(/([0-9]*)min/)?.[1]) || 0) * 60;
        obj.idleSec +=
          parseInt(this.trips[i].idle?.match(/([0-9]*)s/)?.[1]) || 0;
        obj.recordings += this.trips[i].camera?.foodAndDrink?.times || 0;
        obj.recordings += this.trips[i].camera?.cellPhoneUse?.times || 0;
        obj.recordings += this.trips[i].camera?.hardBrake?.times || 0;
        obj.recordings += this.trips[i].camera?.hardAcceleration?.times || 0;
      }
      obj.score /= obj.trips;
      obj.fuelEconomy /= obj.trips;
      const totalTime = {
        h: Math.floor(obj.totalSec / 3600),
        m: Math.floor((obj.totalSec % 3600) / 60),
        s: Math.floor(obj.totalSec % 60),
      };
      obj.time =
        (totalTime.h ? totalTime.h + "hr " : "") +
        (totalTime.m ? totalTime.m + "min " : "") +
        (totalTime.s ? totalTime.s + "s" : "");
      const idleTime = {
        h: Math.floor(obj.idleSec / 3600),
        m: Math.floor((obj.idleSec % 3600) / 60),
        s: Math.floor(obj.idleSec % 60),
      };
      obj.idle =
        (idleTime.h ? idleTime.h + "hr " : "") +
        (idleTime.m ? idleTime.m + "min " : "") +
        (idleTime.s ? idleTime.s + "s" : "");
      return obj;
    },
    currentStats() {
      if (this.currentTripIndex == -1) return this.summaryStats;
      return this.trips[this.currentTripIndex];
    },
    isLocalEnv() {
      return process.env.VUE_APP_LOCAL;
    },
    localptId: {
      get() {
        let i = this.currentTripMap.dots.indexOf(this.localpt);
        if (i === 0) {
          return this.localptSeedId;
        } else if (i > 0 && this.localptSeedId) {
          return this.localptSeedId + i;
        } else if (this.localpt.id) {
          return this.localpt.id;
        } else {
          return undefined;
        }
      },
      set(val) {
        this.localptSeedId = val;
      },
    },
  },
  methods: {
    handleMapPopups(dot, action) {
      //toggle overflow-y:hidden when clicking breadcrumbs near the top.
      this.mapOverflowY = false; //toggles off when switching to other popups
      if (dot.id === 54) {
        this.mapOverflowY = !this.mapOverflowY;
      }
      // give dot a random id if it doesn't have one
      // this ensures whichDot will have a value that corresponds to only this dot
      if (!dot.id) {
        dot.id = uuid();
      }

      //toggle popups
      if (action !== "mouseleave") {
        //if clicking, toggle popup
        if (action === "click") {
          this.breadcrumbExpanded = !this.breadcrumbExpanded;
          this.whichDot = this.whichDot !== null ? null : dot.id;
          this.showMapPopup = this.showMapPopup !== null ? null : dot.type;
        } else {
          //if mouse entering, activate popup
          this.breadcrumbExpanded = false;
          this.whichDot = dot.id;
          this.showMapPopup = dot.type;
        }
      } else {
        //close everything on mouse leave
        this.breadcrumbExpanded = false;
        this.whichDot = null;
        this.showMapPopup = null;
      }
    },
    showHideBreakdown(trip, index) {
      const shownArrIndex = this.breakdownsShown.indexOf(index);
      if (shownArrIndex === -1) {
        this.breakdownsShown.push(index);
      } else {
        this.breakdownsShown.splice(shownArrIndex, 1);
      }
    },
    setMapSize() {
      // if (window.innerWidth > 1024) {

      // } else {
      //   // If smaller than 1024 width, map stops rescaling in CSS
      //   // To combat this, set a "min width" for the javascript as well
      //   this.mapWidth = 430;
      // }

      if (
        this.mainSidebarVisible &&
        this.tripSelectionVisible &&
        !this.isTablet
      ) {
        // Give space for main sidebar AND trip selection
        this.mapWidth = window.innerWidth - 595;
      } else if (this.mainSidebarVisible && !this.isTablet) {
        // Give space for main sidebar only
        this.mapWidth = window.innerWidth - 275;
      } else if (this.tripSelectionVisible) {
        // Give space for trip selection only
        this.mapWidth = window.innerWidth - 320;
      } else {
        // No space needs to be given up
        this.mapWidth = window.innerWidth;
      }

      if (this.isMobile) {
        this.mapHeight = window.innerHeight - 178;
      } else {
        this.mapHeight = window.innerHeight - 250;
      }
      this.$parent.$refs.triphistsec.style.height = this.mapHeight + "px";
    },
    lineProps(i) {
      const obj = {};
      if (!i) {
        obj.width = "";
        obj.angle = "";
        obj.top = "";
        obj.left = "";
        return obj;
      }
      const dotTypes = [
        {
          // Start marker
          width: 0,
          height: 0,
        },
        {
          // Hard brake
          width: 0,
          height: 0,
        },
        {
          // Hard acceleration
          width: -8,
          height: -8,
        },
        {
          // End point
          width: -8,
          height: -8,
        },
        {
          // Breadcrumb
          width: 16,
          height: 16,
        },
        {
          // Invisible Breadcrumb
          width: 16,
          height: 16,
        },
        {
          // Camera
          width: 0,
          height: 0,
        },
        {
          // Orange Donut
          width: 4,
          height: 9,
        },
        {
          // Purple Donut
          width: 4,
          height: 9,
        },
        {
          // Multi-colored Donut
          width: -8,
          height: -8,
        },
        {
          // Excessive Idling
          width: -8,
          height: -8,
        },
        {
          // Speeding
          width: -8,
          height: -8,
        },
        {
          // Fixed Speed
          width: -8,
          height: -8,
        },
        {
          // ADAS
          width: -8,
          height: -8,
        },
      ];
      const dot1 = this.currentTripMap.dots[i];
      const dot2 = this.currentTripMap.dots[i - 1];
      const x1 = dot1.x - dotTypes[dot1.type].width / 2;
      const y1 = dot1.y - dotTypes[dot1.type].height / 2;
      const x2 = dot2.x - dotTypes[dot2.type].width / 2;
      const y2 = dot2.y - dotTypes[dot2.type].height / 2;
      // console.log(`Point ${i}: (${x1}, ${y1})`);
      obj.top = `calc(50% + ${y1}px)`;
      obj.left = `calc(50% + ${x1}px)`;
      obj.width = `${Math.sqrt(
        (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)
      )}px`;
      // obj.width = "4px";
      obj.angle = `rotate(${
        Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI)
      }deg)`;
      return obj;
    },
    addPoint() {
      let i = this.currentTripMap.dots.indexOf(this.localpt);
      this.currentTripMap.dots.splice(i, 0, {
        type: this.localpt.type,
        x: this.localpt.x,
        y: this.localpt.y,
        num: this.localpt.num,
      });
      this.localpt = this.currentTripMap.dots[i + 1];
    },
    copyPoints() {
      const origSeed = this.localptSeedId;
      if (!this.currentTripMap.dots[0].id && this.localptSeedId) {
        for (let i = 0; i < this.currentTripMap.dots.length; i += 1) {
          this.currentTripMap.dots[i].id = this.localptSeedId;
          this.localptSeedId += 1;
        }
      }
      navigator.clipboard.writeText(JSON.stringify(this.currentTripMap.dots));
      this.localptSeedId = origSeed;
    },
    deletePoint() {
      let i = this.currentTripMap.dots.indexOf(this.localpt);
      this.localpt = this.currentTripMap.dots[i - 1];
      this.currentTripMap.dots.splice(i, 1);
    },
    // addIDs() {
    //   let curId = 2001;
    //   for (let i = 0; i < this.triphistory.length; i += 1) {
    //     if (this.triphistory[i].map && this.triphistory[i].map.mx) {
    //       for (let j = 0; j < this.triphistory[i].map.mx.dots.length; j += 1) {
    //         this.triphistory[i].map.mx.dots[j].id = curId;
    //         curId += 1;
    //       }
    //     } 
    //   }
    //   navigator.clipboard.writeText(JSON.stringify(this.triphistory));
    // },
    numScoredEvents(trip) {
      let numEvents = 0;
      numEvents += trip?.hardAcceleration?.times || 0;
      numEvents += trip?.hardBreaking?.times || 0;
      numEvents += trip?.speedOver80?.times || 0;
      return numEvents;
    },
    numCameraEvents(trip) {
      let numEvents = 0;
      numEvents += trip?.camera?.cellPhoneUse?.times || 0;
      numEvents += trip?.camera?.foodAndDrink?.times || 0;
      numEvents += trip?.camera?.hardAcceleration?.times || 0;
      numEvents += trip?.camera?.hardBrake?.times || 0;
      return numEvents;
    },
    numAdasEvents(trip) {
      return trip?.adas?.breaking?.times || 0;
    },
    numOtherEvents(trip) {
      let numEvents = 0;
      numEvents += trip?.other?.fixedSpeed?.times || 0;
      numEvents += trip?.other?.excessiveIdling?.times || 0;
      return numEvents;
    },
    numTripEvents(trip) {
      return this.numScoredEvents(trip) + this.numCameraEvents(trip) + this.numAdasEvents(trip) + this.numOtherEvents(trip);
    },
  },
  watch: {
    mainSidebarVisible() {
      if (!this.isMobile) this.setMapSize();
    },
    tripSelectionVisible() {
      this.setMapSize();
    },
    currentTripIndex() {
      if (this.isMobile) this.tripSelectionVisible = false;
    },
  },

  created() {
    window.addEventListener("resize", this.setMapSize);
  },
  mounted() {
    this.setMapSize();
    console.log(this.$i18n.locale);
    // Check if 'trip' variable is in the URL
    if (this.$route.query.trip) {
      this.currentTripIndex = parseInt(this.$route.query.trip);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.setMapSize);
  },
};
</script>

<style lang="scss" scoped>
.trip-history {
  position: relative;
  width: 100%;
  height: 100%;
}
.trip-history .sidebar {
  min-width: 350px;
  width: 350px;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  transition: transform 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.trip-history .sidebar.hidden {
  transform: translateX(-320px);
}
.trip-history .sidebar .top {
  color: var(--blue);
  fill: var(--blue);
  margin: 16px 0;
  min-height: 15px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 80%;
  padding: 0 10%;
  .date-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
  }
  .arrow-icon {
    &.first-child {
      flex-grow: 1;
    }
    padding: 0;
    margin: 0;

    height: 20px;
    width: 20px;
  }
}
.trip-history .sidebar .top .date {
  font-weight: 600;
  fill: unset;
}
.trip-history .map {
  // background-image: url("../../assets/trip-history/old/1.png");
  background-size: none;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 320px;
  position: absolute;
  overflow: hidden;

  &.mapOverflowY {
    overflow-y: visible;
  }
}
.trip-history .map.shifted {
  left: 0;
}
.trip-history .map .sidebar-toggle {
  position: absolute;
  top: 0;
  left: 28px;
  width: 24px;
  height: 48px;
  border: 1px solid var(--light-grey-2);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  @media screen and (max-width: 1112px) {
    left: 18px;
  }
}
.trip-history .map .sidebar-toggle svg {
  transform: rotate(270deg);
  fill: var(--dark-grey);
}
.trip-history .map .sidebar-toggle svg.open {
  transform: rotate(90deg);
}
.trip-history .item {
  width: calc(85% - 40px);
  background-color: var(--white);
  outline: solid 1px var(--light-grey-2);
  outline-offset: -1px;
  display: grid;
  grid-template-rows: 56px 56px auto;
  grid-template-columns: 24px 223px;
  grid-gap: 20px 10px;
  padding: 20px;
  font-size: 15px;
  margin-bottom: 24px;
  cursor: pointer;
}
.trip-history .item.summary {
  padding: 10px 20px;
  grid-template-rows: 19px 20px 20px;
  grid-template-columns: 15px auto 15px auto 15px auto;
  align-items: center;
  gap: 10px 4px;
  .summary-lbl {
    grid-column: 1 / -1;
  }
  svg {
    width: 15px;
    height: 15px;
    color: var(--dark);
  }
  span {
    font-size: 12px;
    font-weight: bold;
  }
  .space {
    padding-left: 2px;
  }
}
.trip-history .item.selected {
  background-color: #e6f1fa;
}
.a-to-b {
  grid-row: 1 / 3;
  justify-content: unset;
  .icon-A {
    margin-top: 13px;
    margin-bottom: 2px;
  }
  .dashed-line {
    width: 0px;
    height: 48px;
    border-right: 2px dashed var(--light-grey-2);
  }
  .icon-B {
    margin-top: 2px;
  }
}
.trip-score-area {
  grid-column: 1 / 3;
}
.trip-stats {
  width: 450px;
  height: 42px;
  top: 18px;
  left: 64px;
  padding: 16px 32px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-rows: 13px 15px;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  grid-auto-flow: column;
  position: absolute;
  z-index: 8;

  .text {
    font-size: 13px;
    line-height: 13px;
    color: var(--dark-grey);
  }
  .bold {
    font-size: 15px;
    line-height: 15px;
  }
  @media screen and (max-width: 1112px) {
    width: 65vw;
    left: 48px;
    grid-gap: 8px;
    height: 51px;
    grid-template-rows: auto auto;
    .text {
      font-size: 13px;
      line-height: 10px;
      color: var(--dark-grey);
    }
    .bold {
      font-size: 15px;
      line-height: 15px;
    }
  }
}

.red {
  color: var(--red);
}
.orange {
  color: var(--orange);
}
.green {
  color: var(--green);
}

.breakdown {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, auto);
  grid-gap: 8px;
  grid-auto-flow: row;
  width: 100%;
  font-size: 13px;
  color: var(--dark-grey);
  margin: 6px 0;
  padding: 0 6px 0 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
.breakdown-item {
  display: grid;
  grid-template-columns: 20px 124px 57px 25px;
  grid-template-rows: auto;
  grid-gap: 10px 8px;
  align-items: center;
  font-size: 12px;
}
.breakdown-item svg {
  width: 100%;
  fill: #76777a;
}
.breakdown-item .deduction {
  color: var(--red);
  text-align: right;
}
.breakdown-item .count {
  color: var(--black);
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}
.radio {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.line {
  // width: 50px;
  height: 6px;
  border-radius: 8px;
  background-color: #20b6e2;
  position: absolute;
  // top: 428px;
  // left: 441px;
  // margin: -13px 0 0 -23px;
  transform-origin: left;
  border-left: 1px solid #20b6e2;
  border-right: 1px solid #20b6e2;
}
.dot {
  position: absolute;
  top: 0;
  left: 0;
  margin: -12px 0 0 -12px;
  width: fit-content;
  height: auto;
  // z-index: 0;
  padding: 0;
  & svg {
    width: 30px;
    height: 30px;
    font-family: ProximaNova;
  }
}
.active-dot {
  z-index: 20;
}
.other-events-container {
  height: 24px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  svg {
    // height: 18px;
    // width: 18px;
    width: 12px;
    height: 13px;
    margin: 0 6px;
  }
}
.history-info-btn {
  width: 18px;
  height: 18px;
  fill: var(--grey);
  position: absolute;
  right: 24px;
  top: 3px;
  cursor: pointer;
}
.history-info {
  display: none;
}
.history-info-btn:hover ~ .history-info {
  display: unset;
  width: 280px;
  height: 110px;
  padding: 10px 12px;
  box-sizing: border-box;
  position: fixed;
  top: 301px;
  left: 430px;
  background-color: #e6eff8;
  color: var(--dark-grey);
  font-size: 13px;
  line-height: 15px;
  box-shadow: 0 0 6px 0 var(--light-grey);
}

/* calendar popup */

.anchor {
  position: relative;
}
.calendar.popup {
  background: #fff;
  width: 66%;
  border-radius: 0.25rem;
  z-index: 1000;
  position: absolute;
  left: 32%;
  top: 25px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(110, 110, 110, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(110, 110, 110, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(110, 110, 110, 0.5);
  .top-bar {
    height: 35px;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-weight: bold;
    width: 90%;
    .arrow-icon {
      margin-left: 8%;
    }
    span {
      flex-grow: 1;
      text-align: right;
      max-width: 50%;
    }
  }
  .hide {
    display: none;
  }
  .black {
    color: #000;
  }
}
.grid {
  display: grid;
  width: 90%;
  height: 60%;
  margin: 0 auto;
  color: var(--grey);
  &.month-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding-top: 20px;
    padding-bottom: 40px;
    height: 150px;
    .active {
      background-color: var(--blue);
      border-radius: 25rem;
      color: #fff;
    }
    .month {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  &.day-grid {
    text-align: center;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    padding-top: 20px;
    padding-bottom: 40px;

    .active {
      background-color: var(--blue);
      color: #fff;
    }
    .day {
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2.5px;
    }
    .today {
      border: 1px solid var(--grey);
    }
  }
}

.add-pt-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 12px;
  border: 4px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    width: 80px;
    height: 24px;
    font-size: 28px;
  }
  input.x::-webkit-outer-spin-button,
  input.x::-webkit-inner-spin-button {
    transform: rotate(90deg);
  }
  input.y::-webkit-outer-spin-button,
  input.y::-webkit-inner-spin-button {
    transform: rotate(180deg);
  }
  .check-area {
    background-color: white;
    font-weight: bold;
    margin: 8px;
    input {
      width: 16px;
      height: 16px;
    }
  }
}
.camera-icon {
  box-sizing: border-box;
  width: 26px;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
  box-shadow: inset 0 0 0px 3px #5f1eec, 0px 0px 3px #000;
  border: 1px solid #fff;
  margin: -1px 0 0 -1px;
}

.donut-for-main {
  width: 16px !important;
  height: 16px !important;
  background-color: white;
  border-radius: 50%;
}
</style>
