<template>
  <div
    :class="[
      'Car-Label',
      `Car-Label--${horizontalPosition}`,
      `Car-Label--${verticalPosition}`,
    ]"
    :style="{ transform: `rotate(${rotation * -1}deg)` }"
  >
    <div class="Car-Label__gear">
      <span class="Car-Label__gear__letter">D</span>
    </div>
    <p class="Car-Label__name">{{ vehicleLabel }}</p>
  </div>
</template>

<script>
export default {
  props: {
    vehicleLabel: {
      type: String,
      required: true,
    },
    rotation: {
      type: Number,
      required: true,
    },
    horizontalPosition: {
      type: String, //options are 'right' and 'left' to push in that direction
      required: true,
    },
    verticalPosition: {
      type: String, // used to the push the label for the terrain upward
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.Car-Label {
  position: absolute;

  transform-origin: left top; // actual rotate is applied inline
  width: 99px;
  height: 24px;
  margin: 29px 32px 40px 4px;
  padding: 2px 11px 2px 2px;
  border-radius: 25px;
  border: solid 1px var(--light-grey);
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--left { // for cars slanted like / 
    top: -36px;
    left: -8px;
  }
  &--right { // for cars slanted like \ (pushes label to right)
    top: -26px;
    left: 48px;
  }
  &--top {
    top: 41px;
    left: -12px;
  }

  &::before { // this is the / line that connects the label to the car
    content: '';
    width: 1px;
    height: 23px;
    margin: 1px 2px 27px 14px;
    background: var(--blue);
    position: absolute;
    top: 5px;
    left: -23px;
    transform: rotate(50deg);
  }

  &__gear {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    margin-right: 3px;
    background-color: var(--blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__gear__letter {
    width: 9px;
    height: 20px;
    font-family: ProximaNova;
    font-size: 13.3px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: var(--white);
  }

  &__name {
    flex-grow: 1;
    height: 19px;
    margin: 0 0 1px;
    font-family: ProximaNova;
    font-size: 14px;
    line-height: 1.36;
    color: var(--dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
