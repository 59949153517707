import Vue from 'vue';
import VueI18n from 'vue-i18n';

import App from './App.vue';
// import './registerServiceWorker'
import router from './router';
import store from './store';
import 'scroll-behavior-polyfill';
import translations from './json/translations.json';

Vue.config.productionTip = false;

const messages = translations;

// let locale = 'en';
// const urlSegments = window.location.hostname.split('.');
// for (const segment of urlSegments) {
//   if (['es', 'en', 'en-ca', 'fr-ca'].includes(segment)) {
//     locale = segment;
//   }
// }
let locale = store.state.locale;

const i18n = new VueI18n({
	locale,
	messages,
});

export {i18n};

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
