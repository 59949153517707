<template>
  <div class="map-layer-panel" :class="{'is-essentials': inEssentialsMode}">
    <template>
      <div class="top-bar">
        <b>Map Type</b>
        <img src="@/assets/clear.svg?external" @click="$emit('done')" />
      </div>
      <div class="map-types">
        <div class="map-type selected">
          <img src="@/assets/map-types/roadmap.svg?external" />
          <span>Road</span>
        </div>
        <div class="map-type">
          <img src="@/assets/map-types/hybrid.svg?external" />
          <span>Satellite</span>
        </div>
        <div class="map-type">
          <img src="@/assets/map-types/traffic.svg?external" />
          <span>Traffic</span>
        </div>
        <div class="map-type">
          <img src="@/assets/map-types/terrain.svg?external" />
          <span>Terrain</span>
        </div>
        <div class="map-type">
          <img src="@/assets/map-types/dark.svg?external" />
          <span>Dark</span>
        </div>
      </div>
      <div class="divider-line" />
    </template>
    
    <div class="top-bar">
      <b>Map Layers</b>
    </div>
    <div class="map-layers">
      <template>
        <CheckBox :class="{ 'disabled': inEssentialsMode }" disabled />
        <BaseIcon :class="{ 'disabled': inEssentialsMode }" name="geofence" />
        <span  :class="{ 'disabled': inEssentialsMode }">Geofences</span>
      </template>
      <template>
        <CheckBox disabled />
        <BaseIcon name="tag" />
        <span>Vehicle Labels</span>
      </template>
      <template>
        <CheckBox :checked="showChargers" @toggle="$emit('toggleChargers', !showChargers)" />
        <BaseIcon name="lightning" :class="{selected: showChargers}" />
        <span :class="{selected: showChargers}">Charging Stations</span>
      </template>
    </div>
  </div>
</template>

<script>
import CheckBox from '@/components/demo/CheckBox.vue';
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  props: {
    showChargers: Boolean,
  },
  components: {
    CheckBox,
    BaseIcon,
  },
  data() {
    return {
    };
  },
  computed: {
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  }
}
</script>

<style lang="scss" scoped>
.map-layer-panel {
  width: 242px;
  height: 367px;
  position: fixed;
  bottom: 145px;
  right: 74px;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgb(23 28 38 / 20%), 0 2px 2px 0 rgb(23 28 38 / 14%), 0 3px 1px -2px rgb(23 28 38 / 12%);
  padding: 16px;
  box-sizing: border-box;
  &.is-essentials {
    height: auto;
  }
}
.top-bar {
  display: flex;
  justify-content: space-between;
  b {
    color: var(--dark);
    font-size: 16px;
    font-weight: 700;
  }
  img {
    width: 10px;
    cursor: pointer;
    // padding: 0 2px;
  }
}
.map-types {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 80px);
  align-items: center;
  justify-items: center;
  margin: 6px -12px;
  .map-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 40px;
    }
    &.selected img {
      border: 2px solid var(--blue);
      border-radius: 4px;
    }
  }
}
.divider-line {
  margin: 8px 0 12px 0;
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
}
.map-layers {
  margin: 16px auto;
  width: 92%;
  display: grid;
  grid-template-columns: 18px 18px 1fr;
  align-items: center;
  gap: 8px;
  svg {
    width: 18px;
    fill: var(--grey);
    &.selected {
      fill: var(--blue);
    }
  }
  span {
    margin-left: 8px;
    &.selected {
      color: var(--blue);
    }
  }
  .disabled {
    cursor: default;
    opacity: .5;
  }
}
</style>