<template>
  <transition :name="transitionName">
    <div v-if="show" class="g-modal overlay" :class="`type--${type}`" @click.self="closeFromMask">
      <section
        class="content"
        :class="[`type--${type}`, type === 'drawer' ? drawerDirection : null]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="content-header" id="modalTitle" v-if="hasHeaderSlot">
          <slot name="header" v-bind="{ close }"></slot>
        </header>
        <div class="content-body" id="modalDescription">
          <slot v-bind="{ close }"></slot>
        </div>
        <footer class="content-footer" v-if="hasFooterSlot">
          footer
          <slot v-bind="{ close }" name="footer"></slot>
        </footer>
      </section>
    </div>
  </transition>
</template>

<script>
// QUESTION: Should drawer and modal be part of the same component? Should I have something more abstract. like and overlay component and a card that can go in it? then I style from there? They do share show hide logic but transitions and stuff are sort of brand specific.
// TODO figure out a system for creating new transitions
/**
 * @version 0.0.1
 */
export default {
  name: 'GModal',
  model: {
    prop: 'show',
    event: 'update:show',
  },
  props: {
    /** disable closing by clicking on overlay outside of modal */
    disableClosingOnMask: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    disableScrolling: {
      type: Boolean,
      default: false,
    },
    drawerDirection: {
      type: String,
      default: 'from-left',
    },
    type: {
      type: String,
      default: 'modal',
      validator(value) {
        return ['modal', 'drawer'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$scopedSlots.header;
    },
    hasFooterSlot() {
      return !!this.$scopedSlots.footer;
    },
    transitionName() {
      if (this.$mq === 'sm') return 'modal-fade';
      if (this.type === 'modal') return 'modal-fade';
      if (this.type === 'drawer') return `modal-slide-${this.drawerDirection}`;
      return 'modal-fade';
    },
  },
  methods: {
    closeFromMask() {
      if (this.disableClosingOnMask) return;
      this.close();
    },
    close() {
      // this.$store.dispatch('setIsBaseModalOpen', false);
      this.$emit('closed', false);
      this.$emit('update:show', false);
    },
  },
  watch: {
    show(open) {
      console.log('show', open, this.disableScrolling);
      // this.$store.dispatch('setIsBaseModalOpen', open);
      function disableScroll() {
        document.body.style.overflow = 'hidden';
      }

      function enableScroll() {
        document.body.style.overflow = 'auto';
      }

      if (this.disableScrolling) {
        if (open) {
          disableScroll();
        } else {
          enableScroll();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-slide-from-left-enter,
.modal-slide-from-left-leave-to {
  opacity: 0;

  .content {
    transform: translateX(-100%);
  }
}
.modal-slide-from-right-enter,
.modal-slide-from-right-leave-to {
  opacity: 0;

  .content {
    transform: translateX(100%);
  }
}
.modal-slide-from-left-enter-to,
.modal-slide-from-left-leave,
.modal-slide-from-right-enter-to,
.modal-slide-from-right-leave {
  opacity: 1;
  .content {
    transform: translateX(0);
  }
}
.modal-slide-from-left-enter-active,
.modal-slide-from-left-leave-active,
.modal-slide-from-right-enter-active,
.modal-slide-from-right-leave-active {
  transition: opacity 0.5s ease;

  .content {
    transition: transform 0.5s ease;
  }
}
.g-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  overflow-y: scroll;
  &.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    // background-color: rgba(33, 33, 33, 0.25);
    backdrop-filter: blur(3px) contrast(0.75);
  }

  .content {
    box-sizing: border-box;

    &.type--modal {
      margin: auto;
      width: 100%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20);
      border-radius: 4px;
      // margin-bottom: 32px;
    }

    &.type--drawer {
      width: 100%;
      max-width: 420px;
      height: 100%;

      &.from-left {
        margin: 0 auto 0 0;
      }
      &.from-right {
        margin: 0 0 0 auto;
      }
    }
    .content-header {
      display: flex;
    }
    .content-body {
      position: relative;
    }
    .content-footer {
      display: flex;
      margin-top: auto;
    }
  }
}
</style>
