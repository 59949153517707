<template>
  <nav class="topbar">
    <div class="logo-container">
      <LogoEnvolve class="envolve-logo"/>
    </div>
    <ul class="nav-left">
      <li class="nav-item"><a href="#">Why GM Envolve</a></li>
      <li class="nav-item"><a href="#">Solutions</a></li>
      <li class="nav-item"><a href="#">Fleet</a></li>
    </ul>
    <div class="user">
      MW
    </div>
  </nav>
</template>

<script>
import LogoEnvolve from '../../icons/myba/LogoEnvolve.vue';
// import MagnifyGlass from '../../icons/myba/MagnifyGlassIcon.vue';
// import MybaIcon from '../../icons/myba/MybaIcon.vue';

export default {
  components: {
    // MagnifyGlass, MybaIcon,
    LogoEnvolve
},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.topbar {
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
.topbar {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: $topbar-height;
  background-color: $poseidon-blue; 
  padding: 10px 24px;
  .logo-container {
    width: 105px;
    svg {
      display: block;
    }
  }
  .nav-left {
    list-style: none;
    display: flex;
    margin-left: 24px;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    .nav-item {
      margin-left: 40px;
      a {
        font-weight: bold;
        color: $white;
        text-decoration: none;
      }
    }
  }
  .user {
    margin-left: auto;
    margin-right: 12px;
    border: 3px solid $white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 10px;
    line-height: 1.4;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      right: -15px;
      background-color: $danger;
      border-radius: 50%;
      height: 8px;
      width: 8px;
    }
  }
  .label-container {
    display: flex;
    margin-bottom: 10px;
    margin-left: 30px;
    .label {
      margin-right: 10px;
      font-family: Overpass;
      font-weight: bold;
      color: white;
      font-size: 9.5px;
      line-height: 11.4px;
    }
  }
}
</style>