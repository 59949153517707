<template>
  <div class="content">
    <div class="icon-container">
      <IconCircleCheck />
    </div>
    <h3>You're all set!</h3>
    <p>Your cameras are on their way!
      Once they are shipped you will be able to see their serial numbers on the ‘Cameras’ tab in the Account Settings Page.</p>
    <hr />
    <div class="btn-container">
      <button class="btn btn-primary" @click="$emit('accepted')">Back</button>
    </div>
  </div>
</template>

<script>
import IconCircleCheck from '@/assets/icons/circle-check-icon2.svg';
export default {
  name: 'AddCameraModal1',
  components: {
    IconCircleCheck,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.content {
  padding: 8px;
  text-align: center;
  *, ::after, ::before {
    box-sizing: border-box;
  }
  h3 {
    margin: 0 auto 20px auto;
    font-size: 17px;
  }
  p {
    max-width: 400px;
    margin: 0 auto 105px auto;
  }
  hr {
    margin: 20px 0;
    color: inherit;
    border: 0;
    border-top: 1px solid #9EC9EA;
  }
}

.icon-container {
  width: 60px;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  svg {
    width: 100%;
    height: auto;
    fill: var(--blue);
  }
}

.form-check {
  margin-bottom: 27px;
  margin-left: 20px;
}

.btn-container {
  display: flex;
  margin-left: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-family: $font-family-nova;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  white-space: nowrap;
  padding: 5px 18px 6px 18px;
  border-radius: 4px;
  &:first-child {
    margin-left: auto;
    margin-right: 5px;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.btn-minor {
  color: var(--dark-grey);
}

</style>
