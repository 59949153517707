<template>
  <div class="info-hover-icon" :class="{ clear: clearIcon }">
    <BaseIcon
      name="info"
      v-if="clearIcon"
      ref="icon"
      class="history-info-btn clear"
      @mouseover="placeInfoBox"
      @mouseout="hovering = false"
    />
    <BaseIcon
      name="infoFilled"
      v-else
      ref="icon"
      class="history-info-btn"
      @mouseover="placeInfoBox"
      @mouseout="hovering = false"
    />
    <Teleport to="body">
      <div
        class="history-info"
        ref="info"
        :style="{ visibility: hovering ? 'visible' : 'hidden' }"
      >
        {{ msg }}
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from "vue2-teleport";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    Teleport,
    BaseIcon,
  },
  props: {
    msg: String,
    clearIcon: Boolean,
  },
  data() {
    return {
      hovering: false,
    };
  },
  methods: {
    placeInfoBox() {
      this.hovering = true;
      const iconPos = this.$refs.icon.$el.getBoundingClientRect();
      const infoPos = this.$refs.info.getBoundingClientRect();
      this.$refs.info.style.position = "fixed";
      this.$refs.info.style.top = `${iconPos.top - infoPos.height - 6}px`;
      this.$refs.info.style.bottom = "unset";
      this.$refs.info.style.left = `${
        iconPos.left - infoPos.width / 2 + iconPos.width / 2
      }px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-hover-icon {
  position: relative;
  width: 18px;
  height: 18px;
}
.history-info-btn {
  width: 18px;
  height: 18px;
  fill: var(--grey);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &.clear {
    width: 20px;
    height: 20px;
  }
}
.history-info {
  display: unset;
  width: 280px;
  // height: 110px;
  padding: 10px 12px;
  box-sizing: border-box;
  position: absolute;
  bottom: 25px;
  left: -110px;
  background-color: #e6eff8;
  color: var(--dark-grey);
  font-size: 13px;
  line-height: 15px;
  box-shadow: 0 0 6px 0 var(--light-grey);
  z-index: 100000;
}
</style>
