<template>
  <div class="tag-list flex">
    <div
      v-for="tag in tagArray"
      :class="[
        tag === '' ? 'flex' : 'tag-item flex',
        iconOnly ? 'icon-only' : null,
      ]"
      :key="tag"
    >
      <div class="bullet" v-show="tags">
        <svg
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <circle id="c" cx="13" cy="13" r="13" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g stroke-linecap="square" transform="translate(10 10)">
              <use :fill="determineColor(tag)" xlink:href="#c" />
            </g>
          </g>
        </svg>
      </div>
      <p v-if="!iconOnly" class="tag-text">
        {{
          ["EV", "SUV"].includes(tag)
            ? $t("message." + tag)
            : tag !== ""
            ? $t("message." + camelize(tag))
            : ""
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { camelize } from "../../utils/camelize.js";

export default {
  data() {
    return {
      camelize: camelize,
    };
  },
  props: {
    tags: {
      type: String,
    },
    iconOnly: {
      type: Boolean,
    },
  },
  computed: {
    tagArray() {
      let arr = this.tags.split(" ");
      return arr;
    },
  },
  methods: {
    determineColor(tag) {
      switch (tag) {
        // fall-through: https://stackoverflow.com/questions/6513585/test-for-multiple-cases-in-a-switch-like-an-or
        case "Demo":
        case "Services":
          return "#024C52";
        case "Inventory":
        case "Car":
          return "#FF6D00";
        case "EV":
          return "#EE5450";
        case "SUV":
          return "#BA68C8";
        case "Truck":
          return "#3B8E3F";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tag-list {
  justify-content: space-between;
  flex-wrap: wrap;
  // margin-left: 2px;
}
.tag-item {
  align-items: center;
  // background: var(--pale-grey);
  border: 1px solid rgba(0, 28, 56, 0.15);
  border-radius: 0.75rem;
  font-size: 0.75rem;
  max-height: 20px;
  margin: 2px;
  margin-left: 0;
  padding: 0 9px 0 7px;
  gap: 4px;
  color: #606164;
  .bullet {
    height: 1.25rem;
    width: 1.25rem;
    margin-bottom: 1.5px;
    margin-left: -4px
  }
}
.icon-only {
  background: none;
  // margin-right: 8px;
  padding: 0;
  border: 0;
}
</style>
