<template>
  <div class="performance-camera-statuses">
    <div class="card-top">
      <span class="title">Camera Status</span>
      <RouterLink to="/4/camera-statuses" class="more">More</RouterLink>
      <span class="dots"><img src="@/assets/more_horiz.svg?external" /></span>
    </div>
    <div class="card-body">
      <div gml-template="GridPanel" class="grid" style="grid-template-columns: 1fr; gap: 0.5rem;">
      <div gml-template="GridPanel" class="grid" style="grid-template-columns: 1fr 1fr 1fr 1fr; gap: 0rem 0.5rem;">
        <div gml-template="GridPanel" class="grid" style="grid-template-columns: auto;">
          <div gml-template="Title" class="title title-section title-tight">2</div>
          <div gml-template="Tag" class="tag has-bullet" gml-pressable="short">
            <span class="bullet" style="--tag-bullet-color: #1cd8aa;"></span>
            <span class="text">Last seen 4 days</span>
          </div>
        </div>
        <div gml-template="GridPanel" class="grid" style="grid-template-columns: auto;">
          <div gml-template="Title" class="title title-section title-tight">0</div>
          <div gml-template="Tag" class="tag has-bullet" gml-pressable="short">
            <span class="bullet" style="--tag-bullet-color: #ffab40;"></span>
            <span class="text">Last seen 7 days</span>
          </div>
        </div>
        <div gml-template="GridPanel" class="grid" style="grid-template-columns: auto;">
          <div gml-template="Title" class="title title-section title-tight">2</div>
          <div gml-template="Tag" class="tag has-bullet" gml-pressable="short">
            <span class="bullet" style="--tag-bullet-color: #ff4f72;"></span>
            <span class="text">Last seen 7+ days</span>
          </div>
        </div>
        <div gml-template="GridPanel" class="grid" style="grid-template-columns: auto;">
          <div gml-template="Title" class="title title-section title-tight">0</div>
          <div gml-template="Tag" class="tag has-bullet" gml-pressable="short">
            <span class="bullet" style="--tag-bullet-color: #e6e8ed;"></span>
            <span class="text">Unassigned</span>
          </div>
        </div>
      </div>
      <div gml-part="contentBox" gml-template="SectionsBar" class="grid sections-bar" style="grid-template-columns: 1fr;">
        <div gml-template="ProgressBar" class="progress-bar thick round-corners last-section determinate" gml-state="determinate" style="--progressbar-thumb-color: #e6e8ed; grid-area: 1 / 1 / 1 / 1;">
          <div gml-part="thumb" class="progress-bar__thumb" style="width: 100%;"></div>
        </div>
        <div gml-template="ProgressBar" class="progress-bar thick round-corners determinate" gml-state="determinate" style="--progressbar-thumb-color: #ff4f72; grid-area: 1 / 1 / 1 / 1;">
          <div gml-part="thumb" class="progress-bar__thumb" style="width: 100%;"></div>
        </div>
        <div gml-template="ProgressBar" class="progress-bar thick round-corners determinate" gml-state="determinate" style="--progressbar-thumb-color: #ffab40; grid-area: 1 / 1 / 1 / 1;">
          <div gml-part="thumb" class="progress-bar__thumb" style="width: 50%;"></div>
        </div>
        <div gml-template="ProgressBar" class="progress-bar thick round-corners determinate" gml-state="determinate" style="--progressbar-thumb-color: #1cd8aa; grid-area: 1 / 1 / 1 / 1;">
          <div gml-part="thumb" class="progress-bar__thumb" style="width: 50%;"></div>
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script scoped>
export default {
  name: 'PerformanceCameraStatuses',
};
</script>

<style lang="scss" scoped>
.performance-camera-statuses {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  *, *:before, *:after {
    box-sizing: border-box;
  }
}

.card-top {
  display: flex;
  justify-content: space-between;
  .title,
  .dots {
    font-family: ProximaNova;
    font-weight: 700;
    color: var(--dark);
  }
  .title {
    text-transform: uppercase;
  }
  .dots {
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .dots img {
    width: 13px;
    height: 13px;
  }
  .more {
    margin-left: auto;
    margin-right: 8px;
    color: var(--blue);
    font-weight: bold;
    text-decoration: none;
  }
}
.card-top-subtitle {
  width: fit-content;
  margin-top: 6px;
  /* margin-bottom: -26px; */
  color: var(--blue);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.card-top-subtitle.selected {
  border-bottom: 1px solid var(--blue);
}

.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .grid {
    display: grid;
    justify-items: stretch;
    align-items: stretch;
    justify-content: start;
    align-content: start;
    --overflow-arrow-width: 1.5rem;
    --overflow-arrow-fill: var(--screen-bg);
  }
  .grid-p-m-c {
    align-content: center;
    justify-content: center;
  }
  .grid-i-m-s {
    align-items: center;
    justify-items: stretch;
  }
  .card-content {
      flex: 1;
      min-height: 14rem;
  }
  .title {
    color: var(--title-color);
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  .tag {
    font: var(--small-font);
    color: var(--tag-text-color);
    background: var(--tag-fill-color);
    border: solid 0 var(--tag-stroke-color);
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    border-radius: 0.25rem;
    max-width: var(--tag-max-width);
    margin: var(--tag-spacing);
    padding: var(--tag-padding);
    gap: 6px;
    align-items: baseline;
    > .bullet {
      background: var(--tag-bullet-color);
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .sections-bar {
    --progressbar-track-color: transparent;
    .progress-bar {
      position: relative;
      direction: ltr;
      display: block;
      height: var(--progressbar-height);
      border-radius: var(--progressbar-height);
      background-color: var(--progressbar-track-color);
      background-clip: padding-box;
      overflow: hidden;
      border-radius: 0.5rem;
      height: 0.45rem;
      > div {
        height: 100%;
        will-change: transform;
        background-color: var(--progressbar-thumb-color);
        transition: width .3s linear;
      }
    }
    .progress-bar.last-section {
      --progressbar-track-color: var(--pale-grey);
    }
  }
}

</style>