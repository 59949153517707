<template>
  <div class="Demo-Filters" v-show="true">
    <!-- <div class="Demo-Filters__label">{{ $t("message.tags") }}</div>
    <div
      class="Demo-Filters__select"
      :class="{ 'Demo-Filters__select--focus': filterFocus }"
    >
      <input
        @click="
          tagsOpen = false;
          filterFocus = !filterFocus;
        "
        @focus="filterFocus = true"
        @blur="filterFocus = false"
        :placeholder="$t('message.allVehicles')"
        v-if="selectedTags.length === 0"
      />
      <div
        v-else
        class="Demo-Filters__selected-tags"
        :class="{ 'Demo-Filters__selected-tags--focus': filterFocus }"
      >
        <Tag
          v-for="tag in selectedTags"
          :key="tag"
          :tag="{ text: tag, color: determineColor(tag) }"
          rounded
          x
        />
      </div>
      <div
        class="Demo-Filters__picker-part"
        :class="{ 'Demo-Filters__picker-part--focus': tagsOpen }"
        @click="
          filterFocus = !filterFocus;
          tagsOpen = !tagsOpen;
        "
      >
        <ArrowDownIcon />
      </div>
    </div>

    <div class="Demo-Filters__options" v-if="tagsOpen">
      <Tag
        v-for="tag in $store.state.tags"
        :key="tag.value"
        :tag="tag"
        :x="false"
        rounded
      />
    </div> -->
  </div>
</template>

<script>
// import ArrowDownIcon from '@/assets/arrow_down.svg';
// import Tag from '@/components/demo/Tag.vue';
export default {
  // components: {
  //   ArrowDownIcon,
  //   Tag,
  // },
  data() {
    return {
      searchQuery: '',
      filterFocus: false, //user's focus is in the tag dropdown
      tagsOpen: false, // shows dropdown for tags
    };
  },
  computed: {
    selectedTags() {
      return this.$store.state.selectedTags.filter((tag) => {
        return tag.includes(this.searchQuery);
      });
    },
  },
  methods: {
    determineColor(tag) {
      switch (tag) {
        case 'Services':
          return '#024C52';
        case 'Car':
          return '#FF6D00';
        case 'EV':
          return '#EE5450';
        case 'SUV':
          return '#BA68C8';
        case 'Truck':
          return '#3B8E3F';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss">
.Demo-Filters {
  display: flex;
  flex-wrap: wrap;
  //   padding: 20px 28px;

  &__label {
    width: 100%;
    // margin-bottom: 5px;
    color: #969fb3;
  }
  &__select {
    display: flex;
    width: 100%;
    border-right: 0;
    padding: 5px 0;
    /* if the 15px is too low, the child input pokes out the right side */
  }
  &__select input,
  &__selected-tags {
    background: white;
    display: flex;
    align-items: center;
    flex-grow: 1;
    border: 0;
    outline: 0;
    color: #171c26;
    padding-left: 0.625rem;
    touch-action: none;
    user-select: text;
    appearance: none;
    font-family: ProximaNova;
    font-size: 15px;
    border: 1px solid var(--light-grey-2);
    &::placeholder {
      color: #969fb3;
    }
    &:focus {
      caret-color: #05b5e4;
    }
  }

  &__picker-part {
    width: 30px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey-2);
    background: #fff;
    svg {
      width: 24px;
      height: 24px;
      transform: rotate(0deg);
      transition: transform 200ms ease;
      fill: #71798a;
    }
    &--focus {
      border: 1px solid #05b5e4;
      border-left: 0;
      border-radius: 0 0.25rem 0.25rem 0;
      svg {
        fill: #05b5e4;
        transform: rotate(-180deg);
        transition: transform 200ms ease;
      }
    }
  }
  .Demo-Filters {
    &__selected-tags--focus,
    &__select--focus input {
      border-right: 0;
      border: 1px solid #05b5e4;
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
  &__options {
    width: 100%;
    max-width: 305px;
    margin-top: 2px;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
    padding: 0.625rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
