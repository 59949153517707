<template>
  <div class="all-notifications">
    <h1 v-if="!isMobile">Notifications Center</h1>
    <div v-else style="margin-top: 16px" />
    <div class="search-wrap flex center">
      <input
        class="search-box"
        :placeholder="$t('message.search')"
        v-model="searchQuery"
        type="text"
      />
      <svg
        id="gfs:icon/i-settings"
        @click="$router.push({ name: 'notification-settings' })"
        viewBox="0 0 24 24"
      >
        <path
          fill="var(--blue)"
          d="M19.392 12.833a7.108 7.108 0 0 0 0-1.666c-.043-.324.072-.65.308-.875l1.058-1.025-2-3.475-1.425.375A1.05 1.05 0 0 1 16.425 6a7.383 7.383 0 0 0-1.467-.833 1.067 1.067 0 0 1-.6-.667L14 3.042h-4L9.642 4.5c-.084.312-.305.57-.6.7-.52.22-1.012.5-1.467.833a1.05 1.05 0 0 1-.908.134l-1.425-.384-2 3.475L4.3 10.283c.239.228.354.557.308.884a7.108 7.108 0 0 0 0 1.666c.043.324-.072.65-.308.875l-1.058 1.034 2 3.475 1.425-.384a1.05 1.05 0 0 1 .908.167c.456.333.948.612 1.467.833.296.126.517.381.6.692L10 20.958h4l.358-1.458c.084-.312.305-.57.6-.7a7 7 0 0 0 1.467-.833 1.05 1.05 0 0 1 .908-.134l1.425.409 2-3.475-1.058-1.025a1.025 1.025 0 0 1-.308-.909zm-1.242-1.541c.026.232.04.466.042.7a6.292 6.292 0 0 1-.042.691 2.308 2.308 0 0 0 .675 1.925l.367.35-1.034 1.775-.483-.133a2.267 2.267 0 0 0-2 .367 5.942 5.942 0 0 1-1.217.708 2.275 2.275 0 0 0-1.308 1.542l-.125.483h-2.05l-.125-.483a2.275 2.275 0 0 0-1.308-1.542A5.942 5.942 0 0 1 8.325 17a2.267 2.267 0 0 0-2-.367l-.483.134-1.034-1.8.367-.35A2.3 2.3 0 0 0 5.85 12.7a6.575 6.575 0 0 1 0-.7 6.292 6.292 0 0 1 0-.692 2.308 2.308 0 0 0-.675-1.925l-.367-.35 1.034-1.775.483.134a2.267 2.267 0 0 0 2-.392c.377-.282.785-.52 1.217-.708A2.275 2.275 0 0 0 10.85 4.75l.125-.483h2.05l.125.483a2.275 2.275 0 0 0 1.35 1.567c.416.183.81.412 1.175.683a2.267 2.267 0 0 0 2 .367l.483-.134 1.034 1.775-.367.35a2.3 2.3 0 0 0-.675 1.942v-.008zM12 8.042a3.775 3.775 0 1 0-.017 7.55A3.775 3.775 0 0 0 12 8.042zm0 6.291a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"
        />
      </svg>
    </div>
    <div class="gradient-divider" />
    <NotificationsListSearchable
      class="notifications"
      useExternalSearch
      :externalSearchQuery="searchQuery"
      :notifications="dataset.notifications"
    />
    <GuidedTourWaypoint
      :id="2"
      target="gfs:icon/i-settings"
      :yOffset="30"
    />
  </div>
</template>

<script>
import NotificationsListSearchable from "@/components/demo/NotificationsListSearchable.vue";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";

export default {
  data() {
    return {
      searchQuery: "",
    };
  },
  components: {
    NotificationsListSearchable,
    GuidedTourWaypoint
},
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
};
</script>

<style scoped>
.all-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  text-align: center;
}
.notifications {
  width: 80%;
}
.search-wrap {
  margin-bottom: 20px;
  width: 100%;
}
.search-wrap svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.search-box {
  margin: 0;
  margin-right: 12px;
  width: 100%;
}
</style>
