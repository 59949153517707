var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vehicles"},[(!_vm.isMobile)?_c('h1',[_vm._v(_vm._s(_vm.$t('message.vehicles')))]):_c('div',{staticStyle:{"margin-top":"24px"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"search-box",attrs:{"placeholder":_vm.$store.state.inDealerMode ? _vm.$t('message.labelMakeModelStockNumberVin') : _vm.$t('message.labelMakeModelVin'),"type":"text"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('div',{staticClass:"gradient-divider"}),_c('DataTable',{attrs:{"id":"vehicles-table","data":_vm.filteredData,"columnsToShow":_vm.columnsToShow,"tags":_vm.tags,"upperIconsToShow":_vm.dataTableIconsOrder},on:{"row-clicked":_vm.handleRowClick},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var obj = ref.obj;
return [_c('img',{staticClass:"car-img",attrs:{"src":require('@/assets/' + obj.Image + '?external')}})]}},{key:"tags",fn:function(ref){
var obj = ref.obj;
return [_c('TagsList',{attrs:{"tags":obj.Tags}})]}},{key:"status",fn:function(ref){
var obj = ref.obj;
return [_c('span',{staticClass:"data status"},[_c('div',{staticClass:"connected-dot"}),_vm._v(" "+_vm._s(_vm.$t("message." + _vm.camelize(obj.Status)))+" ")])]}},{key:"health",fn:function(ref){
var obj = ref.obj;
return [(obj.details && obj.details.diagnostics)?_c('HealthIcons',{attrs:{"diagnostics":obj.details.diagnostics}}):_vm._e()]}},{key:"deliveryDate",fn:function(ref){
var obj = ref.obj;
return [_c('span',{staticClass:"data"},[_vm._v(_vm._s(obj.details.deliveryDate))])]}}])}),_c('GuidedTourWaypoint',{attrs:{"id":20,"target":"vehicles-table","xOffset":-20,"yOffset":-60}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }