<template>
  <div class="data-table">
    <div class="table-wrap" v-if="headings.length">
      <div class="table">
        <!-- table header -->
        <div
          class="head-cell"
          v-for="(heading, index) of headings"
          :key="'a' + index"
        >
          <b>{{ $t("message." + camelize(heading)) }}</b>
        </div>
        <div class="head-cell">
          <!-- this is just to make sure the background extends across the whole table to match the comps -->
        </div>
      </div>
      <div
        class="table"
        v-for="(obj, index) of data"
        @click="$emit('row-clicked', index)"
        :key="'A' + index"
      >
        <div class="body-cell">{{ obj["invoiceDate"] }}</div>
        <div class="body-cell">{{ obj["amount"] }}</div>
        <div class="body-cell">
          <div class="icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              class="download icon"
            >
              <path
                fill="var(--blue)"
                fill-rule="evenodd"
                d="M8.5 9.778l2.462-2.624a.475.475 0 0 1 .692 0 .539.539 0 0 1 0 .737l-2.926 3.117a1 1 0 0 1-1.458 0L4.346 7.892a.54.54 0 0 1 .022-.76.475.475 0 0 1 .671.022L7.5 9.776V3.5a.5.5 0 0 1 1 0v6.278zM2 13.5a.5.5 0 0 1 .5-.5h11a.5.5 0 1 1 0 1h-11a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
        </div>
        <div class="body-cell">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              class="green-check icon"
            >
              <g fill="#1DD8AA">
                <circle cx="28" cy="28" r="28" />
                <path
                  fill="#fff"
                  d="M22.815 37.691a1.208 1.208 0 0 1-.06-.055l-9.389-9.457a1.25 1.25 0 1 1 1.768-1.768l8.529 8.58 17.209-16.64a1.25 1.25 0 0 1 1.736 1.798l-18.1 17.502a1.25 1.25 0 0 1-1.693.04z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="body-cell">
          <!-- this makes the lines extend across the table -->
        </div>
      </div>
    </div>
    <div class="pagination-buttons">
      <div class="first page-button">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fill-rule="evenodd">
            <g fill="#C1C8D6">
              <g>
                <g>
                  <g>
                    <path
                      d="M23.594 5.907c1.008 1.01 1.008 2.644 0 3.653l-6.442 6.443 6.442 6.443c1.008 1.008 1.008 2.641 0 3.65h-.188l-9.998-10.003v-.189l9.998-9.997zm-13.44.898l.153.109v18.45l-.153.108H7.793l-.153-.109V6.913l.153-.108h2.361z"
                      transform="translate(-47 -532) translate(24 133) translate(-24 391) translate(47 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="prev page-button">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fill-rule="evenodd">
            <g fill="#C1C8D6">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M22.537 11.866L16 18.403l-6.538-6.536c-.957-.956-2.507-.956-3.465 0l9.998 9.997 10.002-9.998c-.955-.955-2.505-.955-3.46 0z"
                        transform="translate(-89 -532) translate(24 133) translate(-24 391) translate(47 8) translate(42) rotate(90 15.997 16)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <span class="page-number">1/2</span>
      <div class="next page-button">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" fill-rule="evenodd">
            <g fill="var(--blue)" opacity=".8">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M8.584 5.907l9.998 10.002v.189l-9.998 9.997h-.188c-1.008-1.01-1.008-2.644 0-3.653l6.442-6.443-6.442-6.443c-1.008-1.008-1.008-2.642 0-3.65h.188zm15.613.623l.153.109v18.45l-.153.108h-2.361l-.153-.109V6.638l.153-.108h2.361z"
                        transform="translate(-296 -532) translate(24 133) translate(-24 391) translate(47 8) translate(249)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="last page-button">
        <svg width="24" height="24" viewBox="0 8 24 24">
          <g fill="none" fill-rule="evenodd">
            <g fill="var(--blue)" opacity=".8">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M22.537 11.866L16 18.403l-6.538-6.536c-.957-.956-2.507-.956-3.465 0l9.998 9.997 10.002-9.998c-.955-.955-2.505-.955-3.46 0z"
                        transform="translate(-89 -532) translate(24 133) translate(-24 391) translate(47 8) translate(42) rotate(90 15.997 16)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { camelize } from "../../utils/camelize.js";

export default {
  props: {
    data: Array[Object],
    templateColumns: String,
    noClick: Boolean,
  },
  data() {
    return {
      camelize: camelize,
    };
  },
  computed: {
    headings() {
      let arr = [];
      if (this.data) {
        arr = Object.keys(this.data[0]);
        return arr;
      }

      return arr; // this sets the initial state in data
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-top: 10px;
  padding-bottom: 40px;
}

.table-wrap {
  width: 80%;
  border: 1px solid var(--light-grey-2);
  overflow: scroll;
  z-index: 1;
  margin: 0 auto;

  // hide scroll bars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  //chrome
  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);

  @media screen and (max-width: 1112px) {
    width: 125%;
    grid-template-columns: repeat(5, 20%);
  }
}

.table .head-cell,
.table .body-cell {
  &:first-child {
    padding-left: 5%;
  }
}
.table .head-cell {
  height: 39px;
  border-bottom: 1px solid var(--light-grey-2);
  background-color: #eaf6f9;
  display: flex;
  display: flex;
  align-items: center;
  color: var(--dark-grey);
  font-size: 13px;
  @media screen and (max-width: 1112px) {
    &:last-child {
      display: none;
    }
  }
}

b {
  font-weight: 600;
}

.table .body-cell {
  height: 39px;
  font-size: 12px;
  border-bottom: 1px solid var(--light-grey-2);
  display: flex;
  align-items: center;
  word-break: break-all;
  cursor: pointer;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  .icon {
    height: 15px;
    width: 15px;
  }
  @media screen and (max-width: 1112px) {
    &:last-child {
      display: none;
    }
  }
}

.pagination-buttons {
  margin: 0 auto;
  width: 35%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-button {
    margin: 0;
    padding: 0;
    max-height: 24px;

    &.last {
      transform: rotate(180deg);
    }
  }
  .page-number {
    max-height: 24px;
    padding: 0;
    margin-top: 2px;
    color: var(--dark-grey);
  }

  @media screen and (max-width: 1112px) {
    width: 80%;
  }
}
</style>
