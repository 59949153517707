<template>
  <div class="breadcrumb-container" :class="{ start: start }">
    <div v-if="!expanded" class="breadcrumb-icon"></div>
    <div v-else class="active-breadcrumb">
      <div class="big-breadcrumb-icon"></div>
      <LocationMarker />
    </div>
  </div>
</template>

<script>
import LocationMarker from './LocationMarker.vue';
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    start: Boolean,
  },
  components: {
    LocationMarker,
  },
};
</script>

<style lang="scss" scoped>
$side: 0.75rem;
.breadcrumb-container {
  width: fit-content;
  &.start {
    transform: translate(9px, 10px);
    .breadcrumb-icon {
      border-color: #1a518f;
    }
  }
}

.breadcrumb-icon {
  box-sizing: border-box;
  border: calc($side / 4) solid #05b5e4;
  border-radius: 50%;
  height: $side;
  width: $side;
  background: white;
}

.active-breadcrumb {
  height: $side * 2;
  position: relative;
  top: 0px;
}

.big-breadcrumb-icon {
  position: relative;
  top: -9px;
  left: -13px;
  $side: 2rem;
  box-sizing: border-box;
  border: calc($side / 11) solid var(--blue);

  border-radius: 50%;
  height: $side;
  width: $side;
  background: white;
}
</style>
