import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
const { filterAndSortArray } = require("multiple-array-filter");
import initialData from "@/data.js";
import translations from "../json/translations.json";
import camera from './modules/camera';
import measure from './modules/measure';

import VueI18n from "vue-i18n";

const messages = translations;

let locale = "en";
const urlSegments = window.location.hostname.split(".");
for (const segment of urlSegments) {
  if (["es", "en", "en-ca", "fr-ca"].includes(segment)) {
    locale = segment;
  }
}
if (process.env.VUE_APP_LOCAL && localStorage.getItem("locale")) {
  locale = localStorage.getItem("locale");
}

const i18n = new VueI18n({
  locale,
  messages,
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['camera', 'measure'], // For development only, remove before production
    }),
  ],
  state: {
    showCameraComingSoonStuff: true,
    locale: locale,
    isFiltering: false,
    dataset: initialData,
    showView: "Overlay Map",
    mapSearch: "",
    dealerMode: {
      selected: {
        Geofence: [],
        Make: [],
        Model: [],
        Year: [],
        Condition: [],
        Fleets: [],
        Trim: [],
        "Vehicle Diagnostics": [],
        "Exterior Color": [],
        Interior: [],
        "Days in Inventory": [],
        "Body Style": [],
        Engine: [],
        Drivetrain: [],
      },
      "Geofence Options": ["Main Lot", "Remote Lot", "Courtesy Lot"],
      "Make Options": ["Chevrolet"],
      "Model Options": [
        "Blazer",
        "Bolt",
        "Camaro",
        "Corvette",
        "Equinox",
        "Malibu",
        "Silverado 1500",
        "Sonic",
        "Spark",
        "Tahoe",
        "Trailblazer",
        "Trax",
      ],
      "Year Options": ["2023"],
      "Condition Options": ["New"],
      "Fleets Options": ["Fleet One", "Fleet Two"],
      "Trim Options": ["1LS", "1LT", "1SS", "L", "LS", "LT", "LTZ", "RS"],
      "Vehicle Diagnostics Options": [
        "Low Tire Pressure",
        "Low Fuel",
        "Low Oil",
        "Low Battery",
      ],
      "Exterior Color Options": [
        "Black",
        "Green",
        "Red",
        "Silver",
        "White",
        "Yellow",
      ],
      "Interior Options": ["Black", "White"],
      "Days in Inventory Options": ["0-45", "46-90", "90+"],
      "Body Style Options": [],
      "Engine Options": ["threeHundredVoltElectric", "Gasoline"],
      "Drivetrain Options": [],
    },
    currentCar: null,
    currentCarIndex: -1,
    showCurrentCar: false,
    inDemoMode: localStorage.getItem("inDemoMode") ? true : false,
    inGameMode: localStorage.getItem("inGameMode") ? true : false,
    inDealerMode: localStorage.getItem("inDealerMode") ? true : false,
    inEssentialsMode: localStorage.getItem("inEssentialsMode") === "true" ? true : false,
    tags: [
      {
        text: i18n.t("message.services"),
        value: "Services",
        color: "#024C52",
        colorName: "blue",
      },
      {
        text: i18n.t("message.car"),
        value: "Car",
        color: "#FF6D00",
        colorName: "orange",
      },
      {
        text: i18n.t("message.EV"),
        value: "EV",
        color: "#EE5450",
        colorName: "red",
      },
      {
        text: i18n.t("message.SUV"),
        value: "SUV",
        color: "#BA68C8",
        colorName: "purple",
      },
      {
        text: i18n.t("message.truck"),
        value: "Truck",
        color: "#3B8E3F",
        colorName: "green",
      },
    ],
    selectedTags: [],
    billingHistory: [
      {
        ["invoiceDate"]: "Oct 7",
        ["amount"]: "$0.00",
        ["download"]: true,
        ["paid"]: true,
      },
      {
        ["invoiceDate"]: "Oct 7",
        ["amount"]: "$0.00",
        ["download"]: true,
        ["paid"]: true,
      },
    ],
    showMapFilter: false, //user has clicked the filter icon
    showMapFilterDropdown: false,
    showModal: false,
    showBillingModal: false,
    showCalendarPopup: false,
    selectedFleet: { text: i18n.t("message.allFleets"), value: "All Fleets" },
    // fleet options
    fleets: [
      { text: i18n.t("message.allFleets"), value: "All Fleets" },
      { text: i18n.t("message.fleetOne"), value: "Fleet One" },
      { text: i18n.t("message.fleetTwo"), value: "Fleet Two" },
    ],
    // make sure this is greater than any existing ids below
    currentUniqueId: 0,
    // set sample data below
    fleetActivitySettings: [
      {
        name: "Vehicle Check In / Check Out",
        desc: "getNotifiedWhenCheckedInOut",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: false,
      },
      {
        name: "Vehicle Added / Removed",
        desc: "Get notified when vehicles are added or removed",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "New Member",
        desc: "getNotifiedWhenJoinsFleet",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      // {
      //   name: "New Fleet Member",
      //   desc: "getNotifiedWhenManagerDriverJoinsFleet",
      //   inApp: true,
      //   email: true,
      //   showInFleet: false,
      //   showInDealer: true,
      // },
      {
        name: "User Removed",
        desc: "getNotifiedWhenRemoved",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      // {
      //   name: "Manager / Driver Removed from Fleet",
      //   desc: "getNotifiedWhenManagerDriverRemoved",
      //   inApp: true,
      //   email: true,
      //   showInFleet: false,
      //   showInDealer: true,
      // },
      {
        name: "Account Details Changed",
        desc: "getNotifiedAccountDetails",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Account Billing Address Changed",
        desc: "getNotifiedBillingAddressChanged",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Automatic expense created for fueling and charging events",
        desc: "Drivers identified for fueling and charging sessions will be notified",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: false,
      },
      {
        name: "New Vehicle Enrollment to OnStar",
        desc: "Get notified when your new vehicles are enrolled to OnStar and are thus eligible for OnStar Vehicle Insights",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: false,
      },
    ],
    subscriptionActivitySettings: [
      {
        name: "subscriptionUpdates",
        desc: "notifiesPaymentMethodAdded",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Payment Method Expiration",
        desc: "notifiesPaymentMethodExpiration",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Payment Confirmation",
        desc: "notifiesTransactionCompleted",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Payment Failure",
        desc: "notifiesTransactionFails",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
      {
        name: "Invoice Ready",
        desc: "notifiesInvoiceCreated",
        inApp: true,
        email: true,
        showInFleet: true,
        showInDealer: true,
      },
    ],
    diagnosticNotifications: [],
    geofenceNotifications: [
      {
        name: "Wayne County",
        allVehicles: true,
        selectedVehicles: [],
        sendToActive: false,
        allManagers: true,
        onlyMe: false,
        selectedManagers: [],
        speedConditions: { active: "fixed", fixed: 80, road: 5 },
        maintenanceConditions: {
          mileage: { enabled: true, recurrance: 8000, start: 0 },
          time: {
            enabled: true,
            recurrance: { amount: 6, unit: "months" },
            start: { date: "2023-12-06", time: "11:51:17" },
          },
        },
        geofence: {
          days: [false, true, true, true, true, true, false],
          time: { allDay: true, start: "08:00", end: "20:00" },
          trigger: { enter: false, exit: true },
          mapId: 2,
        },
        lastTripRecorded: 4,
        drivingLimit: {
          number: 9,
          unit: "hours",
          per: "week",
          startOfWeek: "Monday",
        },
        toggles: {
          brake: false,
          accel: false,
          seatbelt: false,
          fixedSpeed: false,
          roadSpeed: false,
        },
        id: 0,
        inApp: true,
        email: false,
        lastUpdated: new Date(),
      },
      {
        name: "Midtown",
        allVehicles: false,
        selectedVehicles: ["Bolt EV 82"],
        sendToActive: false,
        allManagers: true,
        onlyMe: false,
        selectedManagers: [],
        speedConditions: { active: "fixed", fixed: 80, road: 5 },
        maintenanceConditions: {
          mileage: { enabled: true, recurrance: 8000, start: 0 },
          time: {
            enabled: true,
            recurrance: { amount: 6, unit: "months" },
            start: { date: "2023-12-06", time: "11:51:53" },
          },
        },
        geofence: {
          days: [true, true, true, true, true, true, true],
          time: { allDay: true, start: "08:00", end: "20:00" },
          trigger: { enter: true, exit: true },
          mapId: 5,
        },
        lastTripRecorded: 4,
        drivingLimit: {
          number: 9,
          unit: "hours",
          per: "week",
          startOfWeek: "Monday",
        },
        toggles: {
          brake: false,
          accel: false,
          seatbelt: false,
          fixedSpeed: false,
          roadSpeed: false,
        },
        id: 1,
        inApp: true,
        email: false,
        lastUpdated: new Date(),
      },
    ],
    eventThresholdNotification: {
      fixed: 80,
      road: 5,
      excessive: 15,
      oil: 10,
    },
    driverBehaviorNotifications: [],
    maintenanceReminderNotifications: [],
    locationMaskingNotifications: [],
    vehicleUtilizationNotifications: [],
    offHoursNotifications: [],
    drivingLimitNotifications: [],
    selectedType: 4, // keep current tab in notification settings stored in memory
    visibleSection: [true, true, true], // if on mobile, toggle between showing and hiding the right pane
    notiSettingsDetails: false, // in notification settings
    ivcShowAll: true, // IVC fleet settings vehicle selection data
    ivcVehicles: [],
    activeFilterTab: 0, // 0 = vehicles, 1 = places, 2 = charging
    sidebarVisible: true,
    demoSessionId: '',
    activeWaypoint: -1,
    closedWaypoints: [],
  },
  mutations: {
    setMapSearch(state, payload) {
      state.mapSearch = payload;
    },
    setShowView(state, payload) {
      state.showView = payload;
    },
    turnOnCurrentCarVisibility(state) {
      state.showCurrentCar = true;
    },
    turnOffCurrentCarVisibility(state) {
      state.showCurrentCar = false;
    },
    toggleCurrentCarVisibility(state) {
      state.showCurrentCar = !state.showCurrentCar;
    },
    setCurrentCar(state, vehicle) {
      state.currentCar = vehicle;
    },
    setCurrentCarIndex(state, index) {
      state.currentCarIndex = index;
    },
    toggleMapFilter(state) {
      state.showMapFilter = !state.showMapFilter;
    },
    openMapFilter(state) {
      state.showMapFilter = true;
    },
    closeMapFilter(state) {
      state.showMapFilter = false;
    },
    toggleMapFilterDropdown(state) {
      state.showMapFilterDropdown = !state.showMapFilterDropdown;
    },
    openMapFilterDropdown(state) {
      state.showMapFilterDropdown = true;
    },
    closeMapFilterDropdown(state) {
      state.showMapFilterDropdown = false;
    },
    setMode(state, modeToSet = 0) {
      // 1 demo
      // 2 game
      // 3 dealer
      let modes = ["inDemoMode", "inGameMode", "inDealerMode"];
      let currentMode = state.inDealerMode ? 3 : state.inGameMode ? 2 : 1;

      if (modeToSet && modeToSet !== currentMode) {
        localStorage.setItem(modes[modeToSet - 1], true);
        state[modes[modeToSet - 1]] = true;
        //reset all the other ones
        modes
          .filter((modeFromList) => modeFromList !== modes[modeToSet - 1])
          .forEach((modeToTurnOff) => {
            localStorage.setItem(modeToTurnOff, "");
            state[modeToTurnOff] = false;
          });
      }
    },
    setSelectedTags(state, payload = null) {
      let text, section;
      if (payload) {
        text = payload.text;
        section = payload.section; //text refers to the tag label and section refers to what type of tag it is (i.e. geofence)

        let { dealerMode, selectedTags } = state;
        let selection = section ? dealerMode.selected[section] : selectedTags;

        // if the tag has already been selected
        if (selection.includes(text)) {
          let spliceIndex = selection.indexOf(text);
          // remove the tag
          if (!section) {
            selectedTags.splice(spliceIndex, 1);
          } else {
            dealerMode.selected[section].splice(spliceIndex, 1);
          }
        } else {
          // if the tag has not already been selected, add it
          if (!section) {
            selectedTags.push(text);
          } else {
            dealerMode.selected[section] = [
              text,
              ...dealerMode.selected[section],
            ];
          }
        }
      }
    },
    clearSelectedTags(state) {
      if (state.dealerMode) {
        Object.keys(state.dealerMode.selected).forEach((key) => {
          state.dealerMode.selected[key] = [];
        });
      }
    },
    toggleCalendarPopup(state) {
      state.showCalendarPopup = !state.showCalendarPopup;
    },
    openBillingModal(state) {
      state.showBillingModal = true;
    },
    toggleModal(state) {
      state.showModal = !state.showModal;
    },
    setSelectedFleet(state, payload) {
      state.selectedFleet = payload;
    },
    newDiagnosticNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.diagnosticNotifications.push(payload);
      state.currentUniqueId++;
    },
    newMaintenanceReminderNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.maintenanceReminderNotifications.push(payload);
      state.currentUniqueId++;
    },
    newDriverBehaviorNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.driverBehaviorNotifications.push(payload);
      state.currentUniqueId++;
    },
    newGeofenceNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.geofenceNotifications.push(payload);
      state.currentUniqueId++;
    },
    newLocationMaskingNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.locationMaskingNotifications.push(payload);
      state.currentUniqueId++;
    },
    newVehicleUtilitizationNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.vehicleUtilizationNotifications.push(payload);
      state.currentUniqueId++;
    },
    newOffHoursNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.offHoursNotifications.push(payload);
      state.currentUniqueId++;
    },
    newDrivingLimitNotification(state, payload) {
      payload.id = state.currentUniqueId;
      payload.inApp = true;
      payload.email = false;
      payload.lastUpdated = new Date();
      state.drivingLimitNotifications.push(payload);
      state.currentUniqueId++;
    },
    updateDiagnosticNotification(state, payload) {
      const index = state.diagnosticNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.diagnosticNotifications[index] = payload;
    },
    updateMaintenanceReminderNotification(state, payload) {
      const index = state.maintenanceReminderNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.maintenanceReminderNotifications[index] = payload;
    },
    updateDriverBehaviorNotification(state, payload) {
      const index = state.driverBehaviorNotification.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.driverBehaviorNotification[index] = payload;
    },
    updateGeofenceNotification(state, payload) {
      const index = state.geofenceNotification.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.geofenceNotification[index] = payload;
    },
    updateLocationMaskingNotification(state, payload) {
      const index = state.locationMaskingNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.locationMaskingNotifications[index] = payload;
    },
    updateVehicleUtilizationNotification(state, payload) {
      const index = state.vehicleUtilizationNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.vehicleUtilizationNotifications[index] = payload;
    },
    updateOffHoursNotification(state, payload) {
      const index = state.offHoursNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.offHoursNotifications[index] = payload;
    },
    updateDrivingLimitNotification(state, payload) {
      const index = state.drivingLimitNotifications.findIndex(
        (item) => item.id === payload.id
      );
      payload.lastUpdated = new Date();
      state.drivingLimitNotifications[index] = payload;
    },
    setSelectedType(state, payload) {
      state.selectedType = payload;
    },
    deleteDiagnosticNotification(state, payload) {
      const index = state.diagnosticNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.diagnosticNotifications.splice(index, 1);
    },
    deleteMaintenanceReminderNotification(state, payload) {
      const index = state.maintenanceReminderNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.maintenanceReminderNotifications.splice(index, 1);
    },
    deleteDriverBehaviorNotification(state, payload) {
      const index = state.driverBehaviorNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.driverBehaviorNotifications.splice(index, 1);
    },
    deleteGeofenceNotification(state, payload) {
      const index = state.geofenceNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.geofenceNotifications.splice(index, 1);
    },
    deleteLocationMaskingNotification(state, payload) {
      const index = state.locationMaskingNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.locationMaskingNotifications.splice(index, 1);
    },
    deleteVehicleUtilizationNotification(state, payload) {
      const index = state.vehicleUtilizationNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.vehicleUtilizationNotifications.splice(index, 1);
    },
    deleteOffHoursNotification(state, payload) {
      const index = state.offHoursNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.offHoursNotifications.splice(index, 1);
    },
    deleteDrivingLimitNotification(state, payload) {
      const index = state.drivingLimitNotifications.findIndex(
        (item) => item.id === payload.id
      );
      state.drivingLimitNotifications.splice(index, 1);
    },
    setIVCShowAll(state, payload) {
      state.ivcShowAll = payload;
    },
    addIVCVehicle(state, payload) {
      state.ivcVehicles.push(payload);
    },
    removeIVCVehicle(state, payload) {
      const i = state.ivcVehicles.indexOf(payload);
      state.ivcVehicles.splice(i, 1);
    },
    massActionIVCVehicle(state, payload) {
      state.ivcVehicles = Array.from(payload);
    },
    setNotiSettingsDetails(state, payload) {
      state.notiSettingsDetails = payload;
    },
    setFilteringState(state, isFiltering) {
      state.isFiltering = isFiltering;
    },
    changeLocale(state, payload) {
      state.locale = payload;
    },
    setActiveFilterTab(state, payload) {
      state.activeFilterTab = payload;
    },
    setSidebarVisible(state, payload) {
      state.sidebarVisible = payload;
    },
    setDemoSessionId(state, payload) {
      state.demoSessionId = payload;
    },
    setActiveWaypoint(state, payload) {
      state.activeWaypoint = payload;
    },
    addClosedWaypoint(state, payload) {
      if (state.closedWaypoints.indexOf(payload) === -1) {
        state.closedWaypoints.push(payload);
      }
    },
    setEssenialsMode(state, payload) {
      state.inEssentialsMode = payload;
      localStorage.setItem("inEssentialsMode", payload);
    }
  },
  actions: {},
  modules: {
    camera,
    measure,
  },
  getters: {
    datasetWithoutExtraCars(state) {
      return state.dataset.vehicles.filter((row) => row.details);
    },
    filteredVehicles(state, getters) {
      let { datasetWithoutExtraCars } = getters;
      let data = state.inDemoMode
        ? datasetWithoutExtraCars
        : state.dataset.dealerModeVehicles;
      let filters = [];
      let thisIsWorthDoing = state.inDealerMode
        ? state.dealerMode.selected.Geofence?.length ||
          state.dealerMode.selected.Make?.length ||
          state.dealerMode.selected["Model Name"]?.length ||
          state.dealerMode.selected.Trim?.length ||
          state.dealerMode.selected["Vehicle Diagnostics"]?.length ||
          state.dealerMode.selected.Color?.length ||
          state.dealerMode.selected["Days in Inventory"]?.length ||
          state.mapSearch
        : state.selectedTags;

      if (state.isFiltering) {
        if (thisIsWorthDoing) {
          if (state.inDealerMode) {
            filters = Object.entries(state.dealerMode.selected).map(
              (categoryAndSelectedOptions) => {
                let [category, selectedOptions] = categoryAndSelectedOptions;

                return {
                  field: category === "Geofence" ? "Lot" : category,
                  search: selectedOptions,
                };
              }
            );
          } else {
            filters = state.selectedTags.map((tag) => {
              return {
                field: "Tags",
                search: tag,
              };
            });
          }

          let sort = state.inDealerMode ? "id" : "Vehicle Label";

          let filtered = filterAndSortArray(data, filters, sort);
          return filtered;
        } else {
          //no changes in tags, not worth executing
          state.isFiltering = false;
          return data;
        }
      } else {
        //not filtering
        state.isFiltering = false;

        return data;
      }
    },
  },
});
