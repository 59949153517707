<template>
  <div class="container">
    <div
      :class="{ 'remote-modal': !unlocking, 'unlock-mode': unlocking }"
      v-if="!bingo && timeLeft !== 0"
    >
      <div v-if="unlocking" class="flex column">
        <div class="top-area">
          <span class="title">{{ $t("message.unlockDoors") }}</span>
        </div>
        <div class="password-prompt flex column">
          <p>{{ $t("message.pleaseEnterLoginPassword") }}</p>
          <span style="color: var(--dark-grey)"
            >{{ $t("message.password") }}
            <span style="color: var(--red)">*</span></span
          >
          <input class="search-box" type="password" value="whatever" />
        </div>
        <div class="password-submit-area">
          <div class="action-button" @click="$emit('hide')">
            {{ $t("message.cancel") }}
          </div>
          <div class="action-button major" @click="cueLoader('unlock')">
            {{ $t("message.apply") }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="top-area">
          <span class="title">{{ $t("message.remoteCommands") }}</span>
          <svg
            id="gml:icon/dialog-close"
            viewBox="0 0 24 24"
            style="margin-right: 7px"
            @click="$emit('hide')"
          >
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </div>
        <div class="control-grid">
          <div class="ctrl-subtitle">{{ $t("message.doorLocks") }}</div>
          <div class="button" @click="cueLoader('lock')">
            <div class="icon-wrap">
              <svg id="gfs:icon1/door-lock" viewBox="0 0 24 24" width="100%">
                <path
                  d="M5.79 9.975V6.42A3.42 3.42 0 0 1 9.21 3h5.58a3.42 3.42 0 0 1 3.42 3.42v3.6H21v9A2.025 2.025 0 0 1 18.975 21H5.025A2.025 2.025 0 0 1 3 18.975v-9h2.79zm1.26 0h9.9V6.42a2.151 2.151 0 0 0-2.16-2.16H9.21a2.151 2.151 0 0 0-2.16 2.16v3.555zm-1.26 1.26H4.26v7.74c0 .422.343.765.765.765h13.95a.765.765 0 0 0 .765-.765v-7.74H5.79zm6.21 1.53c1.118 0 2.025.907 2.025 2.025v1.395a2.025 2.025 0 0 1-4.05 0V14.79c0-1.118.907-2.025 2.025-2.025zm0 1.26a.765.765 0 0 0-.765.765v1.395a.765.765 0 1 0 1.53 0V14.79a.765.765 0 0 0-.765-.765z"
                ></path>
              </svg>
              <!-- <svg id="gfs:icon/door-lock" viewBox="0 0 24 24">
              <path
                fill-rule="nonzero"
                d="M7.75 9.703V5.08c0-.567.448-1.027 1-1.027h7c.552 0 1 .46 1 1.027v4.622h1V5.08c0-1.134-.895-2.054-2-2.054h-7c-1.105 0-2 .92-2 2.054v4.622h1zm1 0h7V5.08h-7v4.622zm-3 0V5.08C5.75 3.38 7.093 2 8.75 2h7c1.657 0 3 1.38 3 3.081v4.622H21v9.243C21 20.08 20.105 21 19 21H5c-1.105 0-2-.92-2-2.054V9.703h2.75zM4 10.73v8.216c0 .567.448 1.027 1 1.027h14c.552 0 1-.46 1-1.027V10.73H4zm10 4.02v2.5c0 .966-.895 1.75-2 1.75s-2-.784-2-1.75v-2.5c0-.966.895-1.75 2-1.75s2 .784 2 1.75zm-1.143 0c0-.414-.384-.75-.857-.75s-.857.336-.857.75v2.5c0 .414.384.75.857.75s.857-.336.857-.75v-2.5z"
              />
            </svg> -->
            </div>
            <span>{{ $t("message.lock") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.lock === true,
                done: loadFinished.lock === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.lock === true">
              <img src="@/assets/icon_success.png" />
              {{ $t("message.unlockDoorsSentSuccessfully") }}
            </div>
          </div>
          <div class="button" @click="unlocking = true">
            <div class="icon-wrap">
              <svg id="gfs:icon1/door-unlock" viewBox="0 0 24 24">
                <path
                  d="M5.457 10.324l-.724-2a3.324 3.324 0 0 1 2.029-4.267L11.867 2.2a3.324 3.324 0 0 1 4.219 1.99l.847 2.343-1.143.41-.857-2.333a2.105 2.105 0 0 0-2.695-1.267L7.133 5.248a2.105 2.105 0 0 0-1.257 2.657l.886 2.419h13.524v8.762a1.971 1.971 0 0 1-1.972 1.962H4.734a1.971 1.971 0 0 1-1.972-1.962v-8.762h2.695zM3.99 19.086a.743.743 0 0 0 .743.743h13.581c.41 0 .743-.333.743-.743v-7.562H3.99v7.562zm7.534-6.048c1.089 0 1.971.883 1.971 1.972v1.352a1.971 1.971 0 0 1-3.943 0V15.01c0-1.09.883-1.972 1.972-1.972zm0 1.22a.743.743 0 0 0-.743.752v1.352a.743.743 0 0 0 1.486 0V15.01a.743.743 0 0 0-.743-.753z"
                ></path>
              </svg>
              <!-- <svg id="gfs:icon/door-unlock" viewBox="0 0 24 24">
              <path
                fill-rule="nonzero"
                d="M8.75 10H21v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9h2.75V5a3 3 0 0 1 3-3h7a3 3 0 0 1 3 3v3h-3V5h-7v5zm-1 0V5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v2h1V5a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v5h1zm1 1H4v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8H8.75zm5 3.5V17a1.75 1.75 0 0 1-3.5 0v-2.5a1.75 1.75 0 0 1 3.5 0zm-1 0a.75.75 0 1 0-1.5 0V17a.75.75 0 1 0 1.5 0v-2.5z"
              />
            </svg> -->
            </div>
            <span>{{ $t("message.unlock") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.unlock === true,
                done: loadFinished.unlock === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.unlock === true">
              <img src="@/assets/icon_success.png" />
              {{ $t("message.lockDoorsSentSuccessfully") }}
            </div>
          </div>
          <div class="line" />
          <div class="ctrl-subtitle">
            Engine
          </div>
          <div class="button" @click="cueLoader('engineStart')">
            <div class="icon-wrap">
              <IconEngineStart />
            </div>
            <span>{{ $t("message.start") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.engineStart === true,
                done: loadFinished.engineStart === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.engineStart === true">
              <img src="@/assets/icon_success.png" />
              Engine start sent successfully
            </div>
          </div>
          <div class="button" @click="cueLoader('engineStop')">
            <div class="icon-wrap">
              <IconEngineStop />
            </div>
            <span>{{ $t("message.stop") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.engineStop === true,
                done: loadFinished.engineStop === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.engineStop === true">
              <img src="@/assets/icon_success.png" />
              Engine stop sent successfully
            </div>
          </div>
          <div class="line" />
          <div class="ctrl-subtitle">
            {{ $t("message.hornAndLights") }}
          </div>
          <div class="button" @click="cueLoader('panicOn')">
            <div class="icon-wrap">
              <svg id="gfs:icon1/start-honk" viewBox="0 0 24 24">
                <path
                  d="M20.229 7.086l-.048.18-.762.41h-.171a8.086 8.086 0 1 0-.096 7.953l.181-.048.743.438.048.171a9.21 9.21 0 1 1 .105-9.057v-.047zm1.638 4.076l.133.133v.867l-.133.124h-2.59l-.134-.124v-.867l.133-.133h2.59zm-2.724 1.8h.18l2.172 1.286v.171l-.438.752h-.171l-2.124-1.295-.048-.171.429-.743zm1.78-4.762h.172l.438.743v.18l-2.2 1.287-.19-.048-.429-.743.048-.18L20.924 8.2zm-6.485-1.581C12.676 9.371 9.514 10.829 5 10.99l-.143-.142c.034-.299.088-.595.162-.886l.114-.105c4.153-.18 6.962-1.524 8.467-4.028l.229-.277h1.98l.124.134V17.79l-.123.134-2.086-.067-.124-.21c-1.505-2.504-4.314-3.809-8.467-4.028l-.114-.095a7.219 7.219 0 0 1-.162-.886L5 12.476c4.514.162 7.676 1.62 9.324 4.314h.495V6.667l-.38-.048z"
                ></path>
              </svg>
              <!-- <svg id="gfs:icon/start-honk" viewBox="0 0 24 24">
              <path
                fill-rule="nonzero"
                d="M7.664 3.735l.414.751a8.139 8.139 0 0 0-4.22 7.137A8.138 8.138 0 0 0 12 19.765a8.139 8.139 0 0 0 8.143-8.142 8.137 8.137 0 0 0-4.056-7.043l.431-.741A8.994 8.994 0 0 1 21 11.623a8.996 8.996 0 0 1-9 9 8.995 8.995 0 0 1-9-9 8.996 8.996 0 0 1 4.664-7.888zM11.773 2h.857v2.571h-.857V2zm1.714 2.577l1.274-2.148.738.437-1.275 2.148-.737-.437zM8.773 2.866l.737-.437 1.274 2.148-.737.437-1.274-2.148zm2.57 15.863a7.258 7.258 0 0 1-.864-.156c-.183-4.168-1.541-6.982-4.057-8.491l-.208-.125V8h12v1.957l-.208.125c-2.515 1.509-3.873 4.323-4.056 8.491-.283.07-.572.122-.864.156.157-4.427 1.575-7.527 4.271-9.253v-.619H7.071v.619c2.697 1.726 4.115 4.826 4.271 9.253z"
              />
            </svg> -->
            </div>
            <span>{{ $t("message.start") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.panicOn === true,
                done: loadFinished.panicOn === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.panicOn === true">
              <img src="@/assets/icon_success.png" />
              {{ $t("message.hornAndLightsOnSentSuccessfully") }}
            </div>
          </div>
          <div class="button" @click="cueLoader('panicOff')">
            <div class="icon-wrap">
              <svg id="gfs:icon1/stop-honk" viewBox="0 0 24 24">
                <path
                  d="M20.267 7.076l-.048.181-.762.42-.18-.058a8.105 8.105 0 0 0-15.239 3.905 8.105 8.105 0 0 0 15.114 4.066l.172-.047.752.438.048.171a9.229 9.229 0 1 1 .105-9.076h.038zm-5.81-.466C12.695 9.37 9.524 10.829 5 10.99l-.143-.142c.035-.302.089-.601.162-.896l.124-.095c4.162-.18 6.971-1.533 8.476-4.038l.238-.276h1.972l.133.133V17.81l-.133.123-2.086-.057-.124-.21c-1.505-2.514-4.314-3.809-8.476-4.037l-.124-.105a6.905 6.905 0 0 1-.162-.886L5 12.476c4.524.153 7.695 1.62 9.352 4.314h.496V6.657l-.39-.047zm5.638 6.028l-1.133 1.133h-.181l-.59-.61v-.18l1.133-1.133-1.134-1.134v-.143l.61-.61h.181l1.114 1.087 1.134-1.134h.19l.581.657v.19l-1.133 1.134L22 12.981v.18l-.61.61h-.19l-1.105-1.133z"
                ></path>
              </svg>
              <!-- <svg id="gfs:icon/stop-honk" viewBox="0 0 24 24">
              <path
                fill-rule="nonzero"
                d="M7.664 3.735l.414.751a8.139 8.139 0 0 0-4.22 7.137A8.138 8.138 0 0 0 12 19.765a8.139 8.139 0 0 0 8.143-8.142 8.137 8.137 0 0 0-4.056-7.043l.431-.741A8.994 8.994 0 0 1 21 11.623a8.996 8.996 0 0 1-9 9 8.995 8.995 0 0 1-9-9 8.996 8.996 0 0 1 4.664-7.888zm3.678 14.994a7.258 7.258 0 0 1-.863-.156c-.183-4.168-1.541-6.982-4.057-8.491l-.208-.125V8h12v1.957l-.208.125c-2.515 1.509-3.873 4.323-4.056 8.491-.283.07-.572.122-.864.156.157-4.427 1.575-7.527 4.271-9.253v-.619H7.071v.619c2.697 1.726 4.115 4.826 4.271 9.253zm1.582-14.91l1.213 1.211-.607.607-1.212-1.213-1.212 1.213-.606-.607 1.212-1.212L10.5 2.606 11.106 2l1.212 1.212L13.53 2l.607.606-1.213 1.212z"
              />
            </svg> -->
            </div>
            <span>{{ $t("message.stop") }}</span>
            <div
              class="circle-loader"
              :class="{
                loading: loading.panicOff === true,
                done: loadFinished.panicOff === true,
              }"
            />
            <div class="done-popup" v-if="loadFinished.panicOff === true">
              <img src="@/assets/icon_success.png" />
              {{ $t("message.hornAndLightsOffSentSuccessfully") }}
            </div>
          </div>
          <div class="line" />
          <div class="learn-more-area flex center" @click="$emit('learn-more')">
            <svg
              class="i-icn"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
              />
            </svg>
            <span>{{ $t("message.learnMore") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconEngineStart from '@/assets/icons/engine-start-icon.svg';
import IconEngineStop from '@/assets/icons/engine-stop-icon.svg';
export default {
  components: {
    IconEngineStart,
    IconEngineStop,
  },
  data() {
    return {
      unlocking: false,
      loading: {
        lock: false,
        unlock: false,
        engineStart: false,
        engineStop: false,
        panicOn: false,
        panicOff: false,
      },
      loadFinished: {
        lock: false,
        unlock: false,
        engineStart: false,
        engineStop: false,
        panicOn: false,
        panicOff: false,
      },
      bingo: false,
    };
  },
  props: ["activeChallenge", "numIncomplete", "timeLeft", "multiplier"],
  methods: {
    cueLoader(itemToLoad) {
      this.unlocking = false;
      this.loading[itemToLoad] = true;
      setTimeout(() => {
        this.loading[itemToLoad] = false;
        this.loadFinished[itemToLoad] = true;

        if (
          this.activeChallenge === "unlock-productivity" &&
          (this.$route.path === "/1/detail/0" ||
            this.$attrs.vehicle === "Silverado LD 121")
        ) {
          this.$emit("challengeComplete", 2);
          this.bingo = true;
          this.$emit("bingo");
        } else
          setTimeout(() => {
            this.loadFinished[itemToLoad] = false;
          }, 2000);
      }, 2000);
    },
  },
  watch: {
    timeLeft: function () {
      if (this.timeLeft <= 0) {
        this.bingo = false;
        this.$emit("hide");
      }
    },
    numIncomplete: function () {
      if (this.numIncomplete === 0) {
        this.$emit("noMoreChallenges");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-area {
  width: 272px;
  height: 32px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 21px;
  color: var(--dark);
  font-weight: 700;
}
.top-area svg {
  width: 20px;
  height: 20px;
  fill: var(--dark);
  cursor: pointer;
}
.control-grid {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-template-rows: repeat(2, 28px 106px 1px);
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 0;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  /* margin: 24px 56px 0 56px; */
}
.button {
  width: 100%;
  /* margin: 0 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.icon-wrap {
  width: 82px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 50%;
  border: 1px solid var(--light-grey);
  /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2); */
}
.icon-wrap svg {
  width: 44px;
  height: 44px;
  fill: var(--dark-grey);
}
.button:hover .icon-wrap {
  border-color: #05b5e4;
}
.button:hover .icon-wrap svg {
  fill: #05b5e4;
}
.button span {
  font-size: 15px;
  color: var(--dark);
  line-height: 15px;
  margin-top: 7px;
}
.remote-modal {
  width: 320px;
  padding-bottom: 30px;
  // Remove height
  // height: 504px;
}
.unlock-mode {
  width: 400px;
  height: 400px;
}
.password-prompt {
  padding: 20px 26px 89px 26px;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 30px);
}
.search-box {
  width: unset;
  max-width: unset;
}
.password-submit-area {
  border-top: 1px solid #b3cfe6;
  padding: 16px 24px;
  width: 352px;
  height: 47px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.action-button {
  width: 103px;
  height: 28px;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}
.action-button.major {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-left: 16px;
}
.circle-loader {
  position: absolute;
  top: -3px;
  /* left: 50%;
  transform: translateX(-50%); */
  margin: 0 auto;
  border: 2px solid var(--white);
  border-radius: 50%;
  width: 84px;
  height: 84px;
  opacity: 0;
}
.circle-loader.loading {
  opacity: 1;
  border-top: 2px solid var(--dark-grey);
  animation: spin 0.9s cubic-bezier(0.75, -0.01, 0.44, 0.7) infinite;
}
.circle-loader.done {
  opacity: 1;
  background-color: var(--white);
  background-image: url("../../assets/check.svg?external");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.done-popup {
  position: fixed;
  top: 20px;
  /* left: 98.75px; */
  left: calc(50vw - 412px);
  background-image: url("../../assets/notification_bar_success.png");
  width: 824px;
  height: 70px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 10px;
}
@media screen and (max-width: 820px) {
  .done-popup {
    width: calc(100% - 8px);
    left: 0;
    margin-right: 8px;
  }
}
.done-popup img {
  margin-left: 32px;
  margin-right: 8px;
  margin-bottom: 1px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ctrl-subtitle {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.017em;
  color: var(--dark);
  grid-column: 1 / 3;
  justify-self: flex-start;
  align-self: flex-start;
}
.line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
  grid-column: 1 / 3;
}
.learn-more-area {
  grid-column: 1 / 3;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-grey);
}
.learn-more-area:hover {
  color: #05b5e4;
}
.i-icn {
  width: 18px;
  height: 18px;
  fill: var(--dark-grey);
  margin-right: 8px;
}
.learn-more-area:hover .i-icn {
  fill: #05b5e4;
}
@media screen and (max-width: 980px) {
  .password-prompt {
    padding: 0px 5px 69px 5px;
    /* padding: 0px; */
    background: linear-gradient(to bottom, #eaf6f9, var(--white) 30px);
    width: 82%;

    align-self: stretch;
  }
  .password-submit-area {
    width: 80%;
    padding: 0;
    margin: 0 5px 0 10px;
  }
}
</style>
