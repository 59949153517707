<template>
  <div
    class="sidebar"
    :class="{ hidden: !shown, 'mode-dealer': $store.state.inDealerMode }"
    id="sidebar"
  >
    <div class="flex upper-section" @click="unReady()">
      <svg
        class="collapse-btn"
        @click="
          $store.state.inGameMode && !isMobile ? () => {} : $emit('toggle')
        "
        id="gml:icon/shell-menu"
        viewBox="0 0 24 24"
      >
        <path fill="white" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
      </svg>
      <img
        v-if="$i18n.locale !== 'fr-ca'"
        class="logo"
        @click="reset"
        src="@/assets/logo-twolines-right-white.svg?external?external"
      />
      <img
        v-if="$i18n.locale === 'fr-ca'"
        class="logo"
        @click="reset"
        src="@/assets/logo-twolines-right-white-fr.svg?external?external"
      />
    </div>

    <div class="flex menu" @click="unReady()">
      <!-- Button -->
      <div
        v-for="item of menuItems"
        v-show="showMenuItemInEnvironment(item) && (!hasParent(item) || isParentExpanded(item))"
        @click="setActive(item)"
        @mouseenter="item.hovering = true"
        @mouseleave="item.hovering = false"
        class="flex item"
        :class="{
          active: isActive(item),
          activebg: (!hasChildren(item) && isActive(item)) || isExpanded(item) || isParentExpanded(item),
          submenu: hasChildren(item),
        }"
        :key="item.name"
      >
        <div class="active-bar" v-if="isActive(item)" />
        <SideBarIcon :iconId="item.id" :color="iconColor(item)" />
        {{ $t(item.translationKey ? item.translationKey : item.name) }}
        <svg
          class="arrow"
          v-if="hasChildren(item)"
          :class="{ active: isExpanded(item) }"
          id="gml:icon1/expander-right"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
        </svg>
      </div>
    </div>
    <div class="cta">
      <div class="cta-links">
        <a href="/welcome" style="text-transform: uppercase">{{
          $store.state.inDealerMode ? $t('message.dealer') : $t('message.demo')
        }}</a>
      </div>
      <button
        class="startTrialButton"
        v-if="!$store.state.inDealerMode"
        @click="goToGMSite"
      >
        {{ $t("message.startYourFreeTrial") }}
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import SideBarIcon from "./SideBarIcon.vue";
import { addLocalRecord } from "@/utils/localMetrics.js";
import { Capacitor } from '@capacitor/core';

const axios = require("axios").default;

export default {
  props: {
    shown: Boolean,
    isMobile: Boolean,
    currentScreen: Number,
    numIncomplete: Number,
    points: Number,
    halfPts: Boolean,
    challengesSkipped: {},
    multiplier: Number,
  },
  methods: {
    flashNotActive() {
      this.notActive = true;
      setTimeout(() => {
        this.notActive = false;
      }, 2000);
    },
    challengeGo() {
      this.go = true;
      this.makeSideBarScrollable();
      this.startTimer();
      let thumbnail = document.getElementsByClassName("bigThumbnail");
      thumbnail = thumbnail[0];
      thumbnail.style.top = "419px";
      thumbnail.style.left = "0";
      thumbnail.style.width = "100%";
      thumbnail.style.height = "182px";
      thumbnail.style.backgroundSize = "cover";
      thumbnail.style.transform = "translate(0%, 0%)";
    },
    makeSideBarScrollable() {
      document.querySelector(".bigThumbnail").classList.remove("hide");
      setTimeout(() => {
        document.querySelector(".sidebar").classList.add("scrollable");

        //hide text until challenge modal is in sidebar
        document.querySelector(".non-question-answer").classList.remove("hide");
        document
          .querySelector(".sidebar-challenge-button")
          .classList.remove("hide");
      }, 500);
      //class is automatically removed between challenges due to rerender.
    },
    skipQuestion() {
      this.$emit("skipped", this.challenge);
      this.$router.push("/challenges");
    },
    // BEGIN Menu Item Methods
    iconColor(item) {
      if (this.isActive(item)) return "var(--light-blue)"
      if (item.hovering) return "var(--white)";
      return "var(--grey)";
    },
    showMenuItemInEnvironment(item) {
      if (this.$store.state.inDealerMode) {
        if (item.dealer) return true;
        return false;
      }
      if (item.fleet) return true;
      return false;
    },
    isActive(item) {
      const isThisPageActive = this.activePageId === item.id
      if (this.hasChildren(item) && !this.isExpanded(item)) {
        return this.isChildActive(item);
      }
      return isThisPageActive;
    },
    hasParent(item) {
      return item.parentId !== undefined;
    },
    hasChildren(item) {
      return item.showChildren !== undefined;
    },
    isExpanded(item) {
      return item.showChildren;
    },
    isParentExpanded(item) {
      if (this.hasParent(item)) {
        return this.menuItems.find((p) => item.parentId === p.id && this.showMenuItemInEnvironment(p))?.showChildren;
      }
      return false;
    },
    isChildActive(item) {
      return this.menuItems.find((c) => c.id === this.activePageId && c.parentId === item.id);
    },
    trackInsight(action, value, callback) {
      if (process.env.VUE_APP_INSIGHTS_URL && this.activationId) {
        let synced = false;
        axios
          .post(process.env.VUE_APP_INSIGHTS_URL, {
            activationId: this.activationId,
            sessionIdentifier: this.demoSessionId,
            action,
            value,
            createdAt: new Date(),
            updatedAt: new Date(),
          })
          .then(() => {
            synced = true;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(async () => {
            if (Capacitor.isNativePlatform()) {
              await addLocalRecord(this.activationId, this.demoSessionId, action, value, synced);
            }
            if (callback) callback();
          });
      } else if (process.env.VUE_APP_INSIGHTS_URL) {
        console.error('Metrics not tracked - no activation ID specified')
        if (callback) callback();
      } else {
        console.warn('Metrics not tracked in this environment');
        if (callback) callback();
      }
    },
    setActive(item) {
      // Expand/collapse menu, if applicable
      if (this.hasChildren(item)) {
        item.showChildren = !item.showChildren;
        return;
      }
      // Otherwise, continue to change active page
      let itemId = item.id;
      if (item.id === 0 && this.$store.state.inDealerMode) {
        itemId = "dealer";
        this.$store.commit("setShowView", "Overlay Map");
      }
      if (item.id === 6) {
        itemId = "home";
      }
        
      const trackingOn = true;
      if (trackingOn) {
        this.trackInsight(item.name);
      }
      if (this.$route.path !== "/" + itemId) this.$router.push("/" + itemId);
      this.$emit('hide-notifications');
      // mode gets wiped going to the pages for some reason
      this.$store.state.inDealerMode
        ? this.$store.commit("setMode", 3)
        : this.$store.state.inGameMode
        ? this.$store.commit("setMode", 2)
        : this.$store.commit("setMode", 1);

      // hide sidebar after clicking button in nav if on mobile
      if (this.isMobile) this.$emit("toggle");
    },
    // END Menu Item Methods
    reset() {
      const trackingOn = true;
      // post demo session and duration
      let startTime = new Date(this.demoSessionStartTime);
      let endTime = new Date();
      let secondsTaken = Number((endTime - startTime) / 1000);

      if (trackingOn) {
        this.trackInsight("Reset", secondsTaken.toString(10), () => {
          if (!this.$store.state.inDealerMode) {
            this.$router.push(this.fleetRoute);
          } else if (this.$route.path !== "/") {
            this.$router.push("/welcome");
          }
          // hard refresh to ensure all state (vuex and otherwise) is reset
          this.$router.go();
          location.reload(true);
        });
      }
    },
    readyToPlay() {
      this.showVideo = false;
      this.ready = true;
    },
    addPts(pts) {
      if (this.timeTaken < 10) {
        pts *= 2;
      } else if (this.timeTaken < 20) {
        pts *= 1.8;
      } else if (this.timeTaken < 30) {
        pts *= 1.6;
      }

      if (this.halfPts) {
        pts = pts / 2;
      }
      this.$emit("ptsAdded", pts);
      this.$emit("answerResult", this.challenge);
    },
    answer(answer) {
      this.$emit("answer", answer);
    },
    answered(result) {
      this.ready = false;
      this.isAnswered = true;
      this.$emit("answerResult", this.challenge);
      this.answerResult = result;
    },
    unReady() {
      if (this.ready || this.showVideo) {
        this.startTimer(false);
      }
      this.ready = false;
      this.showVideo = false;
    },
    pauseTimer() {
      this.$emit("pauseTimer");
    },
    startTimer() {
      this.$emit("startTimer", false);
    },
    noMoreChallenges() {
      this.$emit("noMoreChallenges");
    },
    goToDemo() {
      this.challenge = "";
      this.challengeDescription = "";
      (this.answers = []), this.$emit("resetGame");
    },
    showVideoModal() {
      this.$emit("usedHelp");
      this.showVideo = true;
      this.ready = false;
    },
    hideAnswerModal() {
      this.ready = false;
      this.$emit("startTimer", false);
    },
    hideVideoModal() {
      this.showVideo = false;
      this.$emit("startTimer", false);
    },
    goToGMSite() {
      window.open(
        "https://app.onstarvehicleinsights.com/?path=Session:NewAccount#",
        "_blank"
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  data() {
    return {
      notActive: false,
      challenge: "",
      challengeDescription: "",
      ready: false,
      questionAnswer: false,
      answers: [],
      correctAnswerIndex: Number,
      isAnswered: false,
      answerResult: Boolean,
      showVideo: false,
      startTime: Number,
      demoSessionStartTime: String,
      // eslint-disable-next-line vue/no-dupe-keys
      go: false,
      menuItems: [
        {
          id: 6,
          name: "Home",
          translationKey: "message.home",
          fleet: true,
          dealer: false,
          hovering: false,
        },
        {
          id: 0,
          name: "Map",
          translationKey: "message.map",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 11,
          name: "Fleet Management",
          translationKey: "message.fleetManagement",
          showChildren: false,
          fleet: true,
          dealer: false,
          hovering: false,
        },
        {
          id: 11,
          name: "Inventory Management",
          translationKey: "message.inventoryManagement",
          showChildren: this.$store.state.inDealerMode ? true : false,
          fleet: false,
          dealer: true,
          hovering: false,
        },
        {
          id: 1,
          parentId: 11,
          name: "Vehicles",
          translationKey: "message.vehicles",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 4,
          parentId: 11,
          name: "Performance",
          translationKey: "message.performance",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 5,
          parentId: 11,
          name: "Reports",
          translationKey: "message.reports",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 3,
          parentId: 11,
          name: "Expenses",
          translationKey: "message.expenses",
          fleet: true,
          dealer: false,
          hovering: false,
        },
        {
          id: 9,
          parentId: 11,
          name: "Fleets",
          translationKey: "message.fleets",
          fleet: true,
          dealer: false,
          hovering: false,
        },
        {
          id: 12,
          name: "Account Management",
          translationKey: "message.accountManagement",
          showChildren: false,
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 2,
          parentId: 12,
          name: "Users",
          translationKey: "message.users",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 7,
          parentId: 12,
          name: "Settings",
          translationKey: "message.settings",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 13,
          name: "Finance & Billing",
          translationKey: "message.financeAndBilling",
          showChildren: false,
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 10,
          parentId: 13,
          name: "Subscriptions",
          translationKey: "message.subscriptions",
          fleet: true,
          dealer: true,
          hovering: false,
        },
        {
          id: 14,
          name: "Business Solutions",
          translationKey: "message.businessSolutions",
          showChildren: false,
          fleet: true,
          dealer: false,
          hovering: false,
        },
        {
          id: 8,
          parentId: 14,
          name: "Add-on Solutions",
          translationKey: "message.addOnSolutions",
          fleet: true,
          dealer: false,
          hovering: false,
        },
        // {
        //   id: 8,
        //   name: "In-Vehicle Coaching",
        //   translationKey: "message.inVehicleCoaching",
        //   fleet: true,
        //   dealer: false,
        //   hovering: false,
        // },
      ],
      activationIds: {
        Fleet: {
          en: process.env.VUE_APP_INSIGHTS_ACTIVATION_FLEET_EN_US,
          es: process.env.VUE_APP_INSIGHTS_ACTIVATION_FLEET_ES_MX,
          "fr-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_FLEET_FR_CA,
          "en-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_FLEET_EN_CA,
          ipad: process.env.VUE_APP_INSIGHTS_ACTIVATION_FLEET_IPAD,
        },
        Dealer: {
          en: process.env.VUE_APP_INSIGHTS_ACTIVATION_DEALER_EN_US,
          es: process.env.VUE_APP_INSIGHTS_ACTIVATION_DEALER_ES_MX,
          "fr-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_DEALER_FR_CA,
          "en-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_DEALER_EN_CA,
          ipad: process.env.VUE_APP_INSIGHTS_ACTIVATION_DEALER_IPAD,
        },
      },
    };
  },
  created() {
    this.demoSessionId = uuid();
    this.demoSessionStartTime = new Date().getTime();
  },
  mounted() {
    const trackingOn = true;
    if (trackingOn) {
      this.trackInsight(this.$store.state.inDealerMode ? 'Vehicles' : 'Home');
    }
    // If current page is in a submenu, expand that submenu
    const currentMenuItem = this.menuItems.find((c) => c.id === this.activePageId);
    if (currentMenuItem.parentId) {
      this.menuItems.find((p) => p.id === currentMenuItem.parentId).showChildren = true;
    }
    // Listen for experience reset event
    this.$parent.$on("resetexp", () => {
      this.reset();
    });
  },
  components: {
    SideBarIcon
},
  computed: {
    skipped() {
      const findChallenge = (challenge) => challenge.name === this.challenge;
      const x = this.challengesSkipped.findIndex(findChallenge);
      return this.challengesSkipped[x].skipped;
    },
    timeLeft() {
      return this.$parent.timeLeft;
    },
    timeTaken() {
      return this.startTime - this.timeLeft;
    },
    mode() {
      if (this.$store.state.inDealerMode) return "Dealer";
      return "Fleet"
    },
    activePageId() {
      // Path can be of the form /0, /10, /1/detail, etc.
      const pathArr = this.$route.path.split('/');
      // pathArr[1] is the page id
      const numFromPath = parseInt(pathArr[1], 10);
      // Handle /dealer (this is the map page in dealer mode)
      // Also handles other incorrect routes (defaults to map page)
      if (isNaN(numFromPath)) {
        if (this.$store.state.inDealerMode) return 0;
        return 6;
      } 
      // Otherwise, return the page id
      return numFromPath;
    },
    activationId() {
      if (!this.mode) return undefined;
      if (!this.$store.state.locale) return undefined;
      if (!this.activationIds[this.mode]) return undefined;
      if (Capacitor.isNativePlatform()) {
        if (!this.activationIds[this.mode]['ipad']) return undefined;
        return this.activationIds[this.mode]['ipad'];
      }
      if (!this.activationIds[this.mode][this.$store.state.locale]) return undefined;
      return this.activationIds[this.mode][this.$store.state.locale];
    },
    demoSessionId: {
      get() {
        return this.$store.state.demoSessionId;
      },
      set(val) {
        this.$store.commit('setDemoSessionId', val);
      },
    },
    showGuidedTour() {
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === true) return true;
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === 'true') return true;
      return false;
    },
    fleetRoute() {
      if (this.showGuidedTour) return '/tour';
      return '/welcome';
    },
  },
  watch: {
    ready() {
      if (this.ready) {
        this.pauseTimer();
      }
    },
    showVideo() {
      if (this.showVideo) {
        this.pauseTimer();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  --menu-bg: #073151;
  --menu-bg-dark: #0e1b34;
  --menu-bg-light: #045ca0;
  width: 275px;
  height: 100%;
  background-image: linear-gradient(
    189deg,
    var(--menu-bg),
    var(--menu-bg-light)
  );
  position: fixed;
  z-index: 25;
  touch-action: none;
  transition: transform 0.25s;
}
.startTrialButton {
  width: 98%;
}
.sidebar.hidden {
  min-width: unset;
  transform: translateX(-275px);
  /* left: -275px; */
}
.collapse-btn {
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 16px;
  cursor: pointer;
}
.flex {
  display: flex;
}
.upper-section {
  align-items: center;
  justify-content: space-between;
}
.menu {
  flex-direction: column;
}
.item {
  padding: 8px 18px;
  font-size: 15px;
  font-weight: 600;
  color: var(--grey);
  align-items: center;
  cursor: pointer;
  position: relative;
}
.item:hover {
  color: var(--white);
}
.item.active {
  color: var(--light-blue);
}
.item:hover {
  background-color: #69cdff1f;
}
.item.submenu:hover {
  background-color: unset;
}
.item.activebg,
.item.activebg.submenu:hover {
  background-color: rgb(0, 28, 56);
}
.item.activebg:hover {
  background-color: rgb(11, 51, 78);
}
.item svg {
  margin-right: 16px;
  margin-left: 1px;
  margin-bottom: 1px;
}
.item svg.arrow {
  position: absolute;
  top: 9px;
  right: 18px;
  margin: 0;
  transform: rotate(90deg);
  transition: transform 0.125s cubic-bezier(0.4, 0.0, 1, 1);
  path {
    fill: var(--grey);
  }
  &.active {
    transform: rotate(270deg);
  }
}
.logo {
  height: 36px;
  margin-right: 20px;
  cursor: pointer;
}
.active-bar {
  background-color: var(--light-blue);
  width: 3px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 0;
}
.reset {
  margin-left: 19px;
  padding: 17px 0;
  color: var(--white);
  cursor: pointer;
  z-index: 1000000;
}

/* Game Mode Styles */

.challenge-thumbnail {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 182px;
  background-size: cover;
  transition: 3s linear ease-in-out;
}
.invisible {
  opacity: 0;
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 182px;
  background-size: cover;
  transition: 3s linear ease-in-out;
}

.bigThumbnailInvisible {
  position: relative;
  margin-top: 130px;
  width: 100%;
  height: 182px;
  background-size: cover;
  transition: 3s linear ease-in-out;
}

.sidebar-challenge {
  background: #ffffff;
  // height: 100%;
}
.sidebar-challenge-description {
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 0 40px;
  width: 195px;
  text-align: center;
}
.taller {
  height: 240px !important;
}
.non-question-answer {
  height: 125px;
  padding: 0 40px;
  width: 195px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &.hide {
    visibility: hidden;
  }
}
.sidebar-challenge-button {
  width: 275px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &.hide {
    visibility: hidden;
  }
}
.sidebar-challenge-button img {
  width: 80%;
  cursor: pointer;
}
.disabled {
  pointer-events: none;
}
.smallerText {
  font-size: 13px;
}
.cta {
  width: 100%;
  height: 125px;
  color: white;
  position: absolute;
  bottom: 0;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  .cta-links {
    // width: 50%;
    display: flex;
    justify-content: space-around;
  }
  a {
    color: unset;
    text-decoration: unset;
  }
}
.mode-dealer .cta .cta-links {
  margin-top: auto;
  margin-bottom: 20px;
}
button {
  font-size: 13px;
  font-family: "Gotham Book";
  padding-top: 2px;
  display: block;
  width: 180px;
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 23px;
  color: #236292;
  margin-top: 16px;
  cursor: pointer;
  text-transform: uppercase;
}
.bigThumbnailOverlay {
  z-index: 15;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(33, 33, 33, 0.5);
}
.overlay-content {
  // top: 30vh;

  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .bigThumbnail.main {
    min-width: 40%;
    max-width: 90%;
    min-height: 30vh;
    margin-top: 20px;
  }
  .overlay-description {
    margin: 20px 0;
    width: fit-content;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.bigThumbnail {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &.side {
    transition: 1s;
    transition-timing-function: linear;
    position: absolute;
    width: 626px;
    height: 412px;
    top: 35%;
    left: 200%;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
  &.hide {
    display: none;
  }
}
.bigThumbnailOverlay .overlay-description {
  color: #fff;
  font-family: GothamBold;
  // position: relative;
  // top: 70%;
  // left: 50%;
  // transform: translate(-50%, -25%);
  // width: 626px;
  font-size: 20px;
}

.overlay-description button {
  /* challenge modal button */
  background: none;
  border: none;
  // position: absolute;
  // top: 100px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  cursor: pointer;
  height: 100px;
}

/* hamburger menu scroll */
@media screen and (max-width: 700px) {
  .sidebar {
    // overflow-y: scroll;
    touch-action: pan-y;
  }
  .cta {
    position: unset;
    margin-top: 16px;
  }
  .upper-section {
    position: sticky;
    top: 0;
    background-color: #073151;
    z-index: 30;
  }
  .bigThumbnail {
    max-height: 50vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    top: 36%;
  }
  .bigThumbnailOverlay .overlay-description {
    top: 62%;
    width: 80%;
  }
  .bigThumbnailOverlay .overlay-description button {
    top: 150px;
  }
}

.fullscreen.sidebar-hidden {
  left: 100%;
}
.bigThumbnailOverlay.sidebar-hidden {
  left: 100%;
}
.bigThumbnail.sidebar-hidden {
  left: calc(100% + 50vw);
}

.scrollable {
  overflow-y: auto;
}

/* mobile/tablet challenge modal fixes 2 */
@media screen and (max-width: 700px) {
  .bigThumbnail {
    top: 45vh;
    width: 98vw;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .bigThumbnailOverlay .overlay-description {
    top: 65%;
    width: 80%;
  }
}
/* mobile responsive challenge modal */
@media screen and (max-width: 700px) {
  .non-question-answer {
    height: 80px;
  }
  /* Chrome 29+ */
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    /* .bigThumbnailOverlay.sidebar-hidden {
      position: fixed;
      left: 65vw;
      width: 110vw;
    } */
    .bigThumbnail.sidebar-hidden {
      top: 45vh;
      /* left: 116.5vw; */
      width: 98vw;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .overlay-description.sidebar-hidden {
      top: 65%;
      width: 80%;
    }

    .overlay-description button.sidebar-hidden {
      width: 100%;
      height: fit-content;
      border-radius: 0;
      top: 150px;
    }
  }
  /* Safari 10.1+*/
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      /* .bigThumbnailOverlay.sidebar-hidden {
        position: fixed;
        left: 60vw;
        width: 110vw;
      } */
      .bigThumbnail.sidebar-hidden {
        top: 45vh;
        /* left: 116.5vw; */
        width: 98vw;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .overlay-description.sidebar-hidden {
        top: 65%;
        width: 75%;
      }

      .overlay-description button.sidebar-hidden {
        width: 100%;
        height: fit-content;
        border-radius: 0;
        top: 150px;
      }
    }
  }
}
</style>
