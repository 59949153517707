<template>
  <div class="basic-measure flex">
    <h1 v-if="label === 'mi' && $i18n.locale !== 'en'">
      <span :class="{ gray: number === 0 }">{{
        conversions.getMilesValue(number, $i18n.locale)
      }}</span>
      <span v-if="minorNumber" class="gray">/{{ minorNumber }}</span>
    </h1>
    <h1 v-else-if="label === 'gal' && $i18n.locale !== 'en'">
      <span :class="{ gray: number === 0 }">{{
        conversions.getGalValue(number, $i18n.locale)
      }}</span>
      <span v-if="minorNumber" class="gray">/{{ minorNumber }}</span>
    </h1>
    <h1 v-else>
      <span :class="{ gray: number === 0 }">{{ number }}</span>
      <span v-if="minorNumber" class="darkgray">/{{ minorNumber }}</span>
    </h1>
    <span class="label" v-if="label === 'mi'">{{
      $i18n.locale !== "en" ? $t("message.km") : $t("message.mi")
    }}</span>
    <span class="label" v-else-if="label === 'gal'">{{
      $i18n.locale !== "en" ? $t("message.l") : $t("message.gal")
    }}</span>
    <span class="label" v-else>{{ label }}</span>
    <BaseIcon
      name="diagnostics"
      v-if="diagnosticIcon"
      width="40px"
      height="40px"
      class="card-icon"
    />
    <BaseIcon
      name="vehicleLg"
      v-if="vehicleIcon"
      width="40px"
      height="40px"
      class="card-icon"
    />
    <BaseIcon
      name="fuel"
      v-if="fuelIcon"
      width="40px"
      height="40px"
      class="card-icon"
    />
    <BaseIcon
      name="checkIn"
      v-if="checkInIcon"
      width="40px"
      height="40px"
      class="card-icon"
    />
    <BaseIcon
      name="recall"
      v-if="recallIcon"
      width="40px"
      height="40px"
      class="card-icon"
    />
  </div>
</template>

<script>
import * as conversions from "../../utils/conversions.js";
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  data() {
    return {
      conversions: conversions,
    };
  },
  props: {
    number: Number,
    minorNumber: Number,
    label: String,
    diagnosticIcon: Boolean,
    vehicleIcon: Boolean,
    fuelIcon: Boolean,
    checkInIcon: Boolean,
    recallIcon: Boolean,
  },
  components: {
    BaseIcon
  },
};
</script>

<style scoped>
.basic-measure {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.darkgray {
  color: #9d9da1;
}
.gray {
  color: #c6c6ca;
}
h1 {
  margin: 0;
  margin-top: 40px;
  color: #0072c8;
}
.card-icon {
  margin-top: 32px;
  fill: #9d9da1;
}
.label {
  display: block;
  margin-top: 6px;
  color: #606164;
}
</style>
