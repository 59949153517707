<template>
  <div class="trip-events-widget">
    <div class="card-top">
      <span class="title">Fleet Trip Events</span>
      <span class="dots">
        <img src="@/assets/more_horiz.svg?external" />
      </span>
    </div>
    <MeasureDaysSelect v-model="economyDays" />
    <div class="flex">
      <div class="wide-stat">
        <h3>Top Trip Event Count</h3>
        <div class="body vertical">
          <div
            class="leaderboard-grid fuel-economy"
            v-for="(vehicle, index) of top3Trips"
            :key="'E' + index"
          >
            <img
              :src="require('@/assets/' + vehicle['Image'] + '?external')"
            />
            <div class="flex column">
              <span
                style="font-size: 16px; font-weight: 600; line-height: 18px"
                >{{ vehicle["Vehicle Label"] }}</span
              >
              <span
                style="font-size: 12px; color: var(--grey); line-height: 16px"
                >{{ vehicle["Model Name"] }}</span
              >
            </div>
            <div class="trip-count">
              <RouterLink :to="`/4/events/${vehicle.id}`">{{ vehicle.trips}}</RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div class="wide-stat-divider" />
      <div class="wide-stat">
        <h3>{{ $t("message.lowestPerformingVehicles") }}</h3>
        <div class="body vertical">
          <div
            class="leaderboard-grid fuel-economy"
            v-for="(vehicle, index) of bottom3Trips"
            :key="'E' + index"
          >
            <img
              :src="require('@/assets/' + vehicle['Image'] + '?external')"
            />
            <div class="flex column">
              <span style="font-size: 16px; font-weight: 600">{{
                vehicle["Vehicle Label"]
              }}</span>
              <span style="font-size: 12px; color: var(--grey)">{{
                vehicle["Model Name"]
              }}</span>
            </div>
            <div class="trip-count">
              <RouterLink :to="`/4/events/${vehicle.id}`">{{ vehicle.trips}}</RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeasureDaysSelect from "@/components/demo/MeasureDaysSelect.vue";
import * as conversions from "../../utils/conversions.js";
export default {
  components: {
    MeasureDaysSelect,
  },
  props: {
    topVehicles: {
      type: Array,
      default: () => [],
    },
    bottomVehicles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      economyDays: 7,
      conversions: conversions,
      vehicleData: [
        {
          "Vehicle Label": "Silverado",
          "Model Name": "Silverado 1500",
          "Model Year": "2024",
          "Image": "vehicle-imgs/avatar_truck.svg",
          trips: 111,
          id: 'v-0',
        },
        {
          "Vehicle Label": "Malibu 102",
          "Model Name": "Silverado 1500",
          "Model Year": "2024",
          "Image": "vehicle-imgs/avatar_sedan.svg",
          trips: 56,
          id: 'v-1',
        },
        {
          "Vehicle Label": "Terrain 101",
          "Model Name": "GMC Terrain",
          "Model Year": "2024",
          "Image": "vehicle-imgs/avatar_suv.svg",
          trips: 50,
          id: 'v-2',
        },
        {
          "Vehicle Label": "Bolt EV 82",
          "Model Name": "Chevrolet Bolt EV",
          "Model Year": "2023",
          "Image": "vehicle-imgs/avatar_electric.svg",
          trips: 11,
          id: 'v-3',
        },
        {
          "Vehicle Label": "Enclave 98",
          "Model Name": "Buick Enclave",
          "Model Year": "2023",
          "Image": "vehicle-imgs/avatar_suv.svg",
          trips: 13,
          id: 'v-4',
        },
        {
          "Vehicle Label": "Silverado HD 122",
          "Model Name": "Silverado HD",
          "Model Year": "2024",
          "Image": "vehicle-imgs/avatar_truck.svg",
          trips: 40,
          id: 'v-0',
        },
      ]
    };
  },
  computed: {
    top3Trips() {
      return [...this.vehicleData]
        .sort((a, b) => b.trips - a.trips)
        .slice(0, 3);
    },
    bottom3Trips() {
      return [...this.vehicleData]
        .sort((a, b) => a.trips - b.trips)
        .slice(0, 3);
    }
  }
}
</script>

<style lang="scss" scoped>
.trip-events-widget {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  *, *:before, *:after {
    box-sizing: border-box;
  }
  .card-top {
    display: flex;
    justify-content: space-between;
    .title,
    .dots {
      font-family: ProximaNova;
      font-weight: 700;
      color: var(--dark);
    }
    .title {
      text-transform: uppercase;
    }
    .dots {
      line-height: 16px;
      display: flex;
      align-items: center;
    }
    .dots img {
      width: 13px;
      height: 13px;
    }
  }
}
.wide-stat {
  width: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.wide-stat-divider {
  margin: 0 18px;
  width: 1px;
  border-left: solid 0.5px var(--light-grey-2);
  margin-top: 50px;
}
.leaderboard-grid {
  display: grid;
  grid-template-columns: 36px auto 32px;
  grid-gap: 0 8px;
  margin: 6px 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &.fuel-economy {
    grid-template-columns: 80px auto 75px;
  }
  img {
    width: 120%;
    justify-self: center;
  }
}
.body {
  height: 200px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.trip-count {
  font-size: 20px;
  font-weight: 800;
  text-align: right;
  margin-right: 12px;
  color: #0372c8;
  &:hover {
    color: #52b2df;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  a {
    color: #0372c8;
    text-decoration: none;
    &:hover {
      color: #52b2df;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}
</style>