<template>
  <img
    :src="require('@/assets/' + obj.details.score[graphToView + 'DayChart'])"
  />
</template>

<script>
export default {
  props: {
    obj: Object, // the relevant driver object
    graphToView: Number, // 7 or 30 day graph, number is 7 or 30
  },
};
</script>

<style scoped>
img {
  width: 800px;
  max-width: 100%;
  margin-top: -80px;
  z-index: -1;
}
@media screen and (max-width: 1112px) {
  img {
    margin-top: -40px;
  }
}
</style>
