<template>
  <div class="notifications-list">
    <template v-for="(notification, index) of data">
      <div v-if="!notification.trip && !notification.cameraOn" class="item" :class="{ extrapadding: compact }" :key="index">
        <BaseIcon name="camera" v-if="notification.cameraOn || notification.cameraOff" class="item-icon camera" />
        <svg v-else id="gfs:icon/i-driver-speeding-lg" class="item-icon" viewBox="0 0 24 24">
          <path
            d="M22 14.17a10 10 0 0 1-1.63 5.48l-.15.24h-5.87a2.79 2.79 0 0 0 .65-1.05h4.67a9.1 9.1 0 0 0 1.29-4.21h-1.59v-1.05h1.55a9 9 0 0 0-2.07-5.19l-1.06 1.06-.74-.74 1.08-1.09a8.88 8.88 0 0 0-5.6-2.44v1.56h-1.06V5.18a8.88 8.88 0 0 0-5.6 2.44L7 8.71l-.74.74-1.11-1.06a9 9 0 0 0-2.07 5.19h1.55v1.05H3.07a9.1 9.1 0 0 0 1.29 4.21H9c.127.391.338.75.62 1.05H3.78l-.15-.24A10 10 0 1 1 22 14.17zm-6-2.3l.86-.64A5.57 5.57 0 0 0 12 8.48h-.5l.1 1.07c.133-.01.267-.01.4 0a4.5 4.5 0 0 1 4 2.32zm-1.88 5.64a2.4 2.4 0 0 1 0 .28A2.11 2.11 0 1 1 12 15.68c.169.003.337.026.5.07l5.19-3.85c.43-.28.81.11.51.53l-4.08 5.08zm-1 .28A1.05 1.05 0 1 0 12 18.84a1 1 0 0 0 1.05-1.05h.07z" />
        </svg>
        <span v-if="notification.speeding">
          <span class="blue">{{ notification.driver }}</span>
          {{ $t("message.speedingAbove") }}
          {{ notification.speeding.limit }} mph, {{ $t("message.for") }}
          {{ notification.speeding.percent }}% {{ $t("message.ofTrip") }}
        </span>
        <span v-else-if="notification.tire">
          {{ $t("message.lowTirePressureOn") }}
          <span class="blue">{{ notification.vehicle }}</span>
        </span>
        <span v-else-if="notification.oil">
          {{ $t("message.lowOilLifeOn") }}
          <span class="blue">{{ notification.vehicle }}</span>
        </span>
        <span v-else-if="notification.cameraOn">
          Camera was powered on on <span class="blue">{{ notification.vehicle }}</span>.
        </span>
        <span v-else-if="notification.cameraOff">
          Camera Lytx 12345678, assigned to <span class="blue">{{ notification.vehicle }}</span>, has been powered off recently. See the vehicle’s <span class="blue">timeline</span> for more information.
        </span>
        <span class="time">{{ generatedTimes[index] }}</span>
        <img v-if="!compact" class="item-menu" src="@/assets/more_horiz.svg?external" />
        <div class="item-menu" v-else />
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon
  },
  props: {
    data: Array[Object],
    compact: Boolean,
  },
  computed: {
    generatedTimes() {
      let arr = [];
      for (let i = 0; i < this.data.length; i++) {
        const time = moment();
        time.subtract(30 * (i + 1), "minutes");
        const timeStr = time.format("MMM D, hh:mm A");
        arr.push(timeStr);
      }
      return arr;
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-rows: auto 14px;
  grid-template-columns: 50px auto 35px;
  grid-gap: 5px;
  padding-bottom: 34px;
  border-bottom: 1px solid var(--light-grey-2);
  margin-top: 14px;
  &.extrapadding {
    padding-left: 16px;
  }
}
.item-icon {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  width: 35px;
  height: 35px;
  fill: var(--dark-grey);
  justify-self: center;
  margin-top: 3px;
}

.item-menu {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
  width: 13px;
  height: 13px;
  margin-top: 5px;
}

.blue {
  color: var(--blue);
}

.time {
  color: var(--grey);
  font-size: 13px;
}

@media screen and (max-width: 1112px) {

  .item {
    grid-template-rows: auto 14px;
    padding-bottom: 14px;
  }
}
</style>