<template>
  <div class="profile-menu">
    <div class="upper">
      <b>Juan Torres</b>
      <span>juan.torres@osvi-industries.com</span>
      <button>View Profile</button>
      <svg class="profile-btn" @click="$emit('done')" id="gfs:icon1/i-session-lg" viewBox="0 0 24 24">
        <g transform="scale(.6)">
          <path
            d="M23.6 31.91l-.92-2.32-1.57.63 1 2.51a14.15 14.15 0 0 1-12.23 0l1-2.51-1.57-.63-.91 2.32a14.5 14.5 0 0 1-3.05-2.57l1.31-2.44a4.14 4.14 0 0 1 3.65-2h1.08L16 30.38l4.61-5.53h1.08c1.53-.02 2.95.8 3.68 2.15l1.28 2.37c-.88.99-1.89 1.85-3 2.57l-.05-.03zm-5.18-7.06L16 27.75l-2.41-2.9h4.83zm-6.63-6.92v-1.31a4.33 4.33 0 0 1 4.21-4.4 4.33 4.33 0 0 1 4.21 4.4v1.31a4.34 4.34 0 0 1-4.21 4.4 4.34 4.34 0 0 1-4.21-4.4zM16 5.48c7.86 0 14.32 6.46 14.32 14.31 0 2.9-.89 5.73-2.53 8.12l-.93-1.74a5.82 5.82 0 0 0-5.17-3H19a6.13 6.13 0 0 0 2.91-5.24v-1.31A6.03 6.03 0 0 0 16 10.54a6.03 6.03 0 0 0-5.89 6.08v1.31A6.13 6.13 0 0 0 13 23.17h-2.69a5.82 5.82 0 0 0-5.12 2.91l-1 1.82a14.2 14.2 0 0 1-2.53-8.1A14.4 14.4 0 0 1 16 5.48zm0-1.68c-8.78 0-16 7.22-16 16 0 8.78 7.22 16 16 16 8.78 0 16-7.22 16-16 0-8.78-7.22-16-16-16z"
          ></path>
          <circle cx="32" cy="27.8" r="8"></circle>
          <circle cx="32" cy="27.8" r="6.5" fill="#fff"></circle>
          <path
            fill="#0072c8"
            d="M28.63 25.55h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37zm0 2.25h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37zm0 2.25h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="divider" />
    <div class="lower">
      <svg id="gfs:icon1/info" viewBox="0 0 24 24">
        <path
          d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
        ></path>
      </svg>
      <span>About</span>
      <svg id="gfs:icon1/help" viewBox="0 0 24 24">
        <path
          d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
        ></path>
      </svg>
      <span>FAQ</span>
      <svg id="gfs:icon1/support" viewBox="0 0 24 24">
        <path
          d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
        ></path>
      </svg>
      <span>Support</span>
      <svg id="gfs:icon1/logout" viewBox="0 0 24 24">
        <path
          d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13"
        ></path>
      </svg>
      <span>Sign out</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.profile-menu {
  position: fixed;
  top: 0;
  right: 4px;
  width: 290px;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(26 28 30 / 20%),0 2px 2px 0 rgba(26 28 30 / 14%),0 3px 1px -2px rgba(26 28 30 / 12%);
  border-radius: 0 0 4px 4px;
  span {
    color: #606164;
  }
  .upper {
    margin: 8px 18px;
    display: grid;
    grid-template-rows: 22px 38px 30px;
    grid-template-columns: 1fr 40px;
    grid-auto-flow: column;
    b {
      font-size: 16px;
    }
    button {
      background-color: var(--blue);
      justify-self: left;
      padding: 0 10px;
      color: white;
      border: none;
      border-radius: 4px;
      font-weight: bold;
      margin-left: 8px;
    }
  }
  .divider {
    width: 90%;
    margin: 16px auto 10px auto;
    border-top: 0.5px solid rgb(0 28 56 / 16%);
  }
  .lower {
    margin: 6px 24px;
    display: grid;
    grid-template-rows: repeat(4, 30px);
    grid-template-columns: 18px 1fr;
    align-items: center;
    gap: 0 10px;
    span {
      line-height: 20px;
    }
  }
  svg {
    fill: #76777a;
  }
}
.profile-btn {
  cursor: pointer;
  &:hover {
    background-color: rgb(105 205 255 / 12%);
  }
}
</style>
