<template>
  <div>
    One moment please...
  </div>
</template>

<script>
  export default {
    mounted() {
      // const devQAPrompt = 'You will be redirected to fill out a form. After this form is complete, you will be redirected to onstarvehicleinsightsdemo.com';
      if (this.$i18n.locale === 'en' && window.location.hostname === 'onstarvehicleinsightsdemo.com') {
        window.location.replace('https://gmusweb.capture.intouchinsight.com/ovi2020/index.html');
      } else {
        // this.$router.replace('/myba');
        this.$router.replace('/welcome');
      }
    }
  }
</script>