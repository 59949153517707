<template>
  <div>
    <ModalForm
      :header="$t('message.createFleetAccount')"
      :fields="modalFields"
      button1="Cancel"
      button2="OK"
      :maxColumns="2"
    />
    <header>
      <h1>{{ $t("message.fleets") }}</h1>

      <div class="input">
        <input
          required
          type="search"
          id="search"
          v-model="search"
          :placeholder="$t('message.searchByNameOrEmail')"
        />
        <svg
          id="gml:icon1/field-search"
          class="search icon"
          viewBox="0 0 24 24"
        >
          <path
            d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
          ></path>
        </svg>
      </div>
    </header>
    <div class="gradient-divider"></div>
    <div class="fleets">
      <div class="top-bar">
        <p>2 {{ $t("message.results") }}</p>
        <div class="flex">
          <div class="icon arrow">
            <svg
              id="icon_back_arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="_-g-Combined_Shape"
                data-name="-g-Combined Shape"
                d="M.217,9.1a.825.825,0,0,1,0-1.114L7.674,0l1.1,1.114L2.584,7.747H18.4a1.6,1.6,0,0,1,1.6,1.6H2.581L8.774,15.98l-1.1,1.113Z"
                transform="translate(2 3.453)"
                stroke="#000"
                stroke-miterlimit="10"
                stroke-width="0.3"
              />
            </svg>
          </div>
          <div class="icon add" @click="$store.commit('toggleModal')">
            <svg id="gfs:icon1/i-add-solid" viewBox="0 0 24 24">
              <path
                d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm.872 3.136h-1.744v6.992H4c0 .963.78 1.744 1.744 1.744h5.384v6.984h1.744v-6.984H20a1.752 1.752 0 0 0-1.744-1.744h-5.384V4.136z">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <ul>
        <!-- <li class="fleet new">
          <button @click="$store.commit('toggleModal')">
            + {{ $t("message.createNewFleet") }}
          </button>
        </li> -->
        <li v-for="fleet in fleets" :key="fleet.id" class="fleet">
          <div class="fleet-info title">
            <div class="flex center">
              <div class="pfp">
                {{ fleet.name.split(" ")[0][0] }}{{ fleet.name.split(" ")[1][0] }}
              </div>
              <h2>{{ $t("message." + camelize(fleet.name)) }}</h2>
            </div>
          </div>
          <div class="fleet-info">
            <div class="icon">
              <svg id="gfs:icon1/phone" viewBox="0 0 24 24">
                <path
                  fill="var(--dark-grey)"
                  d="M7.5 3a1 1 0 011 1c0 1.25.2 2.45.57 3.57a1 1 0 01-.16.92l-.09.1-2.63 2.6a15.13 15.13 0 006.25 6.4l.35.18 2.62-2.59a1.02 1.02 0 011.02-.24c1.12.37 2.33.57 3.57.57a1 1 0 011 .88V20a1 1 0 01-1 1A17 17 0 013 4a1 1 0 01.88-1H7.5zm8.67 13.11l-2.43 2.4.04.02c1.82.78 3.81 1.23 5.9 1.27h.12v-3.1h-.25c-1.05-.04-2.09-.2-3.12-.5l-.26-.09zM7.3 4.2H4.2v.11c.04 2 .45 3.9 1.16 5.63l.1.27L7.9 7.82l-.1-.3c-.28-.99-.44-2.02-.48-3.07V4.2z"
                ></path>
              </svg>
            </div>
            <a>{{ fleet.phone }}</a>
          </div>
          <div class="fleet-info">
            <div class="icon">
              <svg id="gfs:icon1/i-envelope" viewBox="0 0 24 24">
                <path
                  fill="var(--dark-grey)"
                  d="M21.6 5.4v10.7a2.6 2.6 0 01-2.6 2.6H5a2.6 2.6 0 01-2.6-2.6V5.4h19.2zm-1.935 4.772l-.18.088L12 13.646 4.516 10.26a3.596 3.596 0 01-.916-.597V16.1a1.4 1.4 0 001.265 1.394l.135.006h14a1.4 1.4 0 001.4-1.4l.001-6.437c-.22.198-.467.37-.736.51zm.735-3.573H3.6v.38a2.4 2.4 0 001.253 2.11l.158.077L12 12.33l6.99-3.163a2.4 2.4 0 001.404-2.01l.006-.176v-.381z"
                ></path>
              </svg>
            </div>
            <a>{{ fleet.email }}</a>
          </div>
          <div class="fleet-info">
            <div class="icon id">
              <span>{{ $t("message.id") }}</span>
            </div>
            <p>{{ fleet.id }}</p>
          </div>
          <div class="fleet-info">
            <p class="created">{{ fleet.created }}</p>
          </div>
          <div class="stats">
            <div class="stat">
              <span class="stat-count">
                {{ fleet.vehicles }}
                <svg id="gfs:icon1/i-vehicle" viewBox="0 0 24 24">
                  <path
                    fill="var(--blue)"
                    d="M21.167 8.875h-1.359a1.042 1.042 0 0 1-.966-.667l-1.667-4.333H6.833L5.167 8.217c-.157.396-.54.657-.967.658H2.833v1.25h1.342l-1.133 1.6v8.4h4.583v-3.333h8.75v3.333h4.583v-8.4l-1.133-1.6h1.342v-1.25zM7.683 5.125h8.634l1.358 3.542c.027.077.06.153.1.225H6.225c.039-.073.072-.148.1-.225l1.358-3.542zm-1.308 13.75H4.292v-2.342c.322.167.679.255 1.041.259h1.042v2.083zm11.25 0v-2.083h1.042c.362-.004.72-.092 1.041-.259v2.342h-2.083zm1.042-3.333H5.333A1.042 1.042 0 0 1 4.292 14.5v-2.375l1.1-1.558c.193-.27.501-.434.833-.442h11.533c.332.008.64.172.834.442l1.1 1.558V14.5c0 .569-.457 1.033-1.025 1.042zM5.333 12.208h3.334v1.25H5.333v-1.25zm10 0h3.334v1.25h-3.334v-1.25z"
                  ></path>
                </svg>
              </span>
            </div>
            <div class="stat">
              <span class="stat-count">
                {{ fleet.drivers }}
                <svg id="gfs:icon1/i-driver" viewBox="0 0 24 24">
                  <path
                    fill="var(--blue)"
                    d="M18.948 14.154c-.78-1.562-2.286-2.529-3.918-2.515h-1.186c1.44-.762 2.357-2.352 2.362-4.097v-.964C16.206 4.05 14.325 2 12.004 2c-2.32 0-4.201 2.05-4.201 4.578v.964c.005 1.745.921 3.335 2.362 4.097H8.979c-1.59-.016-3.067.9-3.865 2.4L2 20.458l2.716 1.118c.026-.508.098-1.011.212-1.504l-1.053-.385 2.327-4.82.062-.115c.555-1.06 1.595-1.707 2.715-1.687h6.05c1.144-.022 2.205.65 2.752 1.745l2.353 4.819-1.053.443c.11.48.177.971.203 1.465L22 20.42l-3.052-6.265zM9.13 7.542v-.964c0-1.73 1.287-3.132 2.874-3.132 1.588 0 2.875 1.402 2.875 3.132v.964c0 1.73-1.287 3.133-2.875 3.133-1.587 0-2.874-1.403-2.874-3.133zM17.975 22h-1.327c0-2.795-2.079-5.06-4.644-5.06-2.564 0-4.644 2.265-4.644 5.06H6.034c0-3.593 2.673-6.506 5.97-6.506 3.298 0 5.971 2.913 5.971 6.506z"
                  ></path>
                </svg>
              </span>
            </div>
            <div class="stat">
              <span class="stat-count">
                {{ fleet.managers }}
                <svg id="gfs:icon1/i-manager" viewBox="0 0 24 24">
                  <path
                    fill="var(--blue)"
                    d="M14.283 20.125H22L18.875 13.8a4.283 4.283 0 0 0-3.85-2.425h-1.292a3.95 3.95 0 0 0 2.225-3.542V7a3.958 3.958 0 1 0-7.916 0v.833a3.95 3.95 0 0 0 2.225 3.542H9.008A4.283 4.283 0 0 0 5.2 13.708L2 20.125h7.717L12 22l2.283-1.875zM9.292 7.833V7a2.708 2.708 0 1 1 5.416 0v.833a2.708 2.708 0 1 1-5.416 0zm8.433 6.517l2.225 4.525h-5.358l-.725-4.267 1.058-1.983h.067a3.033 3.033 0 0 1 2.733 1.725zm-8.333 4.525H4.05l2.208-4.492.05-.091a3.042 3.042 0 0 1 2.7-1.667h.067l1.058 1.983-.741 4.267zm1.083-6.25h3.017l-.95 1.767.833 4.85L12 20.333l-1.383-1.125.833-4.85-.975-1.733z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <button>{{ $t("message.editInfo") }}</button>
        </li>
      </ul>
    </div>
    <GuidedTourWaypoint :id="13" bottomCircle />
  </div>
</template>

<script>
// import Modal from "../../components/demo/Modal.vue";
// import ModalForm from "../../components/demo/ModalForm.vue";
import ModalForm from "../../components/demo/ModalForm.vue";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";
import { camelize } from "../../utils/camelize.js";

export default {
  components: {
    // Modal,
    // ModalForm,
    ModalForm,
    GuidedTourWaypoint,
},
  data() {
    return {
      camelize: camelize,
      search: "",
      fleets: [
        {
          name: "Fleet One",
          phone: "+1 (555) 555-5566",
          email: "juan.torres@osvi-industries.com",
          id: 1000006,
          created: this.$t("message.createdOn") + " 12/10/2020, 01:48 PM",
          vehicles: 3,
          drivers: 5,
          managers: 0,
        },
        {
          name: "Fleet Two",
          phone: "+1 (555) 555-5568",
          email: "christine.davis@osvi-industries.com",
          id: 1000007,
          created: this.$t("message.createdOn") + " 12/10/2020, 01:48 PM",
          vehicles: 0,
          drivers: 0,
          managers: 1,
        },
      ],
      modalFields: [
        {
          label: 'Name',
          type: 'text',
          required: true,
        },
        {
          label: 'Phone',
          type: 'tel',
        },
        {
          label: 'Business Email',
          type: 'email',
          placeholder: 'Enter Email Address',
        },
        {
          label: 'Business Address',
          type: 'search',
          placeholder: 'Search...',
          required: true,
        },
        {
          label: 'Language',
          type: 'language-dropdown'
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  h1 {
    text-align: center;
    @media screen and (max-width: 1112px) {
      display: none;
    }
  }
}
.input {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--pale-grey);
  height: 30px;
  margin-bottom: 30px;

  input {
    border: 0;
    flex-grow: 1;
  }
  @media screen and (max-width: 1112px) {
    width: 70%;
    margin-top: 20px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--grey);
  &.id {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    margin: 0;
    padding: 0;
    color: var(--dark-grey);
  }
  &.arrow {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    svg {
      width: 18px;
      height: 18px;
      fill: var(--blue);
    }
  }
  &.add {
    margin-left: 16px;
    width: 20px;
    height: 20px;
    svg {
      fill: var(--blue);
    }
  }
}
.fleets {
  width: 80%;
  margin: 0 auto;
}
.top-bar {
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    color: var(--grey);
  }
}
ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
  @media screen and (max-width: 1112px) {
    overflow-y: scroll;
  }

  .fleet {
    // background: #f5f8fd;
    display: flex;
    flex-direction: column;
    width: 25%;
    min-width: fit-content;
    // height: 300px;
    padding: 16px 20px 20px 20px;
    border: 1px solid var(--light-grey);
    margin-right: 2%;
    margin-bottom: 10px;
    .pfp {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--blue);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin-left: -4px;
      margin-right: 4px;
    }

    h2 {
      padding: 0;
      margin: 0;
      margin-left: 8px;
      font-size: 16px;
      font-weight: bold;
    }

    button {
      background: #fff;
      color: var(--blue);
      width: 80%;
      border-radius: 0.25rem;
      border: 1px solid var(--light-grey);
      align-self: center;
      padding: 5px;
      font-weight: bold;
      margin: 10px 0;
    }
    @media screen and (max-width: 1112px) {
      width: 100%;
      height: 220px;
    }
  }
  .fleet.new {
    background: #eaf0fb;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fleet-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 2px 0;
    gap: 16px;
    svg {
      margin-right: 5%;
    }
    a {
      color: var(--blue);
      text-decoration: none;
      margin: 0;
    }
    p {
      margin: 0;
      color: #000;
    }
    &.title {
      padding-bottom: 10px;
      // gap: unset;
    }
    .created {
      margin: 0;
      color: var(--dark-grey);
      font-size: 12px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 16px auto;
    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .stat-count {
      color: var(--blue);
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
</style>
