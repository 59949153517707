<template>
  <div class="ivc-fleet-settings flex column">
    <div class="flex" style="margin-top: -12px">
      <div class="big-number-wrap flex column">
        <div class="big-number">{{ numEnrolled }}</div>
        <div class="flex center">
          <div class="dot" />
          <span class="ivc-status">{{ $t("message.enrolled") }}</span>
        </div>
      </div>
      <div class="big-number-wrap flex column">
        <div class="big-number">{{ numNotEnrolled }}</div>
        <div class="flex center">
          <div class="dot grey" />
          <span class="ivc-status">{{ $t("message.notEnrolled") }}</span>
        </div>
      </div>
    </div>
    <BarChart
      class="ivc-bar"
      :value="percentEnrolled"
      :width="300"
      :height="6.5"
      rounded
    />
    <b>{{ $t("message.vehicles") }}</b>
    <div class="tiny-txt">
      {{ $t("message.defineWhichVehiclesYouWoulLikeToReceive") }}
    </div>
    <div class="flex column">
      <div
        class="flex"
        style="margin-bottom: 4px; cursor: pointer"
        @click="
          allVehicles = true;
          $store.commit('massActionIVCVehicle', []);
          $forceUpdate();
        "
      >
        <RadioButton :value="allVehicles !== false" style="align-self: center" />
        {{ $t("message.allVehicles") }}
      </div>
      <div
        class="flex"
        style="cursor: pointer"
        @click="
          allVehicles = false;
          showVehicleSelection = true;
          $forceUpdate();
        "
      >
        <RadioButton :value="allVehicles !== true" style="align-self: center" />
        {{ $t("message.selectVehiclesManually") }}
        {{ allVehicles === false ? ` (${selectedVehicles.length})` : "" }}
        <b
          v-if="allVehicles === false"
          @click="showVehicleSelection = true"
          class="edit-btn"
          >{{ $t("message.edit") }}</b
        >
      </div>
    </div>
    <ModalView v-if="showVehicleSelection" @hide="showVehicleSelection = false">
      <VehicleSelection
        :dataset="dataset"
        :selected="selectedVehicles"
        @add="$store.commit('addIVCVehicle', $event)"
        @remove="$store.commit('removeIVCVehicle', $event)"
        @mass-action="$store.commit('massActionIVCVehicle', $event)"
        @done="showVehicleSelection = false"
      />
    </ModalView>
    <div class="line" style="margin: 36px 0" />
    <b>{{ $t("message.conditions") }}</b>
    <div class="tiny-txt">
      {{ $t("message.defineWhichEventInVehicleCoaching") }}
    </div>
    <div class="ivc-vox-grid">
      <!-- Hard Brake -->
      <AudioPlayButton src="hardBrake-EN-US.mp3" />
      <!-- <BaseIcon name="hardBrake" /> -->
      <span>{{ $t("message.notifyDriverWhenHardBrakeDetected") }}</span>

      <div class="line" />
      <!-- Hard Acceleration -->
      <AudioPlayButton src="hardAcceleration-EN-US.mp3" />
      <!-- <BaseIcon name="hardAcceleration" /> -->
      <span>{{ $t("message.notifyDriverWhenHardAccelerationDetected") }}</span>

      <div class="line" />
      <!-- Seatbelt Unbuckled -->
      <AudioPlayButton src="seatbelt-EN-US.mp3" />
      <!-- <BaseIcon name="seatbeltUnbuckled" /> -->
      <span>{{ $t("message.notifyDriverWhenSeatbeltUnbuckled") }}</span>

      <div class="line" />
      <!-- Fixed Speed -->
      <AudioPlayButton src="fixedSpeed-EN-US.mp3" />
      <!-- <BaseIcon name="fixedSpeed" /> -->
      <span>{{
        $t("message.notifyDriverWhenVehicleExceedsFixedSpeedLimit")
      }}</span>

      <div class="line" />
      <!-- Road Speed -->
      <AudioPlayButton src="roadSpeed-EN-US.mp3" />
      <!-- <BaseIcon name="roadSpeed" /> -->
      <span
        >{{ $t("message.notifyDriverWhenVehicleExceedsPostedSpeedLimit")
        }}<br /><span style="color: var(--dark-grey)"
          >* {{ $t("message.whereAvailable") }}</span
        ></span
      >

      <div class="line" />
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/demo/BarChart.vue";
import AudioPlayButton from "@/components/demo/AudioPlayButton.vue";
import ModalView from "@/components/demo/ModalView.vue";
import VehicleSelection from "@/components/demo/VehicleSelection.vue";
import RadioButton from "./RadioButton.vue";

export default {
  props: {
    dataset: Object,
  },
  components: {
    BarChart,
    AudioPlayButton,
    ModalView,
    VehicleSelection,
    RadioButton
},
  data() {
    return {
      numNotEnrolled: 0,
      currentSlide: 0,
      numSlides: 4,
      showVehicleSelection: false,
    };
  },
  computed: {
    numEnrolled() {
      return this.dataset.vehicles.length;
    },
    percentEnrolled() {
      const total = this.numEnrolled + this.numNotEnrolled;
      return (this.numEnrolled / total) * 100;
    },
    allVehicles: {
      get() {
        return this.$store.state.ivcShowAll;
      },
      set(val) {
        this.$store.commit("setIVCShowAll", val);
      },
    },
    selectedVehicles() {
      return this.$store.state.ivcVehicles;
    },
  },
};
</script>

<style scoped>
/* .ivc-fleet-settings {
  margin-left: 20px;
} */
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  margin-right: 8px;
  margin-bottom: 16px;
}
.dot.blue {
  background-color: var(--blue);
}
.dot.grey {
  background-color: var(--grey);
}
.ivc-status {
  font-size: 12px;
  color: var(--dark);
  margin-bottom: 16px;
}
.unit {
  color: var(--dark-grey);
}
.big-number-wrap {
  margin-right: 22px;
}
.big-number {
  font-size: 40px;
  color: var(--dark);
  font-weight: 300;
  margin-left: 4px;
  margin-top: 8px;
}
.ivc-bar {
  margin-bottom: 32px;
}
.ivc-vox-grid {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: repeat(5, 1fr 1px) 1px;
  gap: 12px;
  align-items: center;
}
.ivc-vox-grid svg {
  width: 32px;
  height: 32px;
  fill: var(--dark-grey);
}
.line {
  width: 100%;
  height: 1px;
  background-color: var(--light-grey-2);
  grid-column: 1 / 4;
}
.tiny-txt {
  font-size: 12px;
  color: var(--dark-grey);
  margin-bottom: 16px;
}
svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.edit-btn {
  font-weight: 600;
  color: var(--blue);
  margin-left: 8px;
  cursor: pointer;
}
</style>
