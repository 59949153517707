<template>
  <div class="camera-status-container">
    <header class="status-header">
      <div class="search-container">
        <input placeholder="VIN/Label/Camera/Vendor" type="text" class="search-box">
      </div>
      <div class="camera-vehicles">12 vehicles found</div>
      <button class="btn-download"><DownloadIcon /></button>
    </header>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>VIN</th>
            <th>Label</th>
            <th>Vendor</th>
            <th>Camera</th>
            <th>Status</th>
            <th>Last Update</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="camera in cameras" :key="camera.id">
            <td><!-- <RouterLink :to="`/1/detail/${camera.id}`">{{ camera['VIN'] }}</RouterLink> -->{{ camera['VIN'] }}</td>
            <td>{{ camera['Vehicle Label'] }}</td>
            <td>Lytx</td>
            <td>123456789101112</td>
            <td><span class="camera-status" :class="camera.cameraStatusClass"></span>{{ camera.cameraStatus }}</td>
            <td>03/01/2022</td>
          </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :totalItems="60" />

    <hr />
    <div class="btn-container">
      <button class="btn btn-primary" @click="$emit('closed')">Close</button>
    </div>
  </div>
</template>

<script>
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import BasePagination from '@/components/BasePagination.vue';
export default {
  components: {
    DownloadIcon,
    BasePagination,
  },
  data() {
    return {
      otherCameras: [
        {
          id: 1,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 2,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 3,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 4,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 5,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 6,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 7,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 8,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 9,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 10,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Ordered',
          cameraStatusClass: 'ordered',
        },
        {
          id: 11,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Ordered',
          cameraStatusClass: 'ordered',
        },
        {
          id: 12,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Ordered',
          cameraStatusClass: 'ordered',
        },
      ]
    };
  },
  computed: {
    cameras() {
      let data = JSON.parse(JSON.stringify(this.$store.state.dataset.vehicles));
      data.forEach((vehicle) => {
        vehicle.cameraStatus = 'Assigned';
        vehicle.cameraStatusClass = 'assigned';
      })
      data = data.filter((vehicle) => vehicle.details);
      data = data.concat(this.otherCameras);
      return data;
    }
  },
  mounted() {
    // console.log(this.cameras);
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';
.camera-status-container {
  width: 100%;
  min-height: 481px;
  // overflow: scroll;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
  padding: 23px 28px;
  hr {
    margin: 14px 0 20px 0;
    color: inherit;
    border: 0;
    border-top: 1px solid #9EC9EA;
  }
}
.status-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .search-container {
    width: 100%;
    .search-box {
      max-width: 260px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3IiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuOTkyMTkgNi45OTk5M0MxLjk5MjE5IDkuNTk4MyA0LjA5ODU4IDExLjcwNDcgNi42OTY5NSAxMS43MDQ3QzcuODI0NjMgMTEuNzA0NyA4Ljg1OTY0IDExLjMwNzkgOS42NzAwMiAxMC42NDY0TDEzLjcyODYgMTQuNzA1QzE0LjEwMDUgMTQuMzMzMSAxNC4xMDA1IDEzLjczMDEgMTMuNzI4NiAxMy4zNTgxTDEwLjM0MzUgOS45NzNDMTEuMDA1IDkuMTYyNjIgMTEuNDAxNyA4LjEyNzYgMTEuNDAxNyA2Ljk5OTkzQzExLjQwMTcgNC40MDE1NiA5LjI5NTMyIDIuMjk1MTcgNi42OTY5NSAyLjI5NTE3QzQuMDk4NTggMi4yOTUxNyAxLjk5MjE5IDQuNDAxNTYgMS45OTIxOSA2Ljk5OTkzWk02LjY5Njk1IDEwLjc1MjNDOC43NjkzMyAxMC43NTIzIDEwLjQ0OTMgOS4wNzIzMSAxMC40NDkzIDYuOTk5OTNDMTAuNDQ5MyA0LjkyNzU1IDguNzY5MzMgMy4yNDc1NSA2LjY5Njk1IDMuMjQ3NTVDNC42MjQ1NyAzLjI0NzU1IDIuOTQ0NTcgNC45Mjc1NSAyLjk0NDU3IDYuOTk5OTNDMi45NDQ1NyA5LjA3MjMxIDQuNjI0NTcgMTAuNzUyMyA2LjY5Njk1IDEwLjc1MjNaIiBmaWxsPSIjNzE3OThBIi8+Cjwvc3ZnPg==");
      background-position: right 6px center;
      background-repeat: no-repeat;
    }
  }
  .camera-vehicles {
    color: var(--dark-grey);
    font-size: 14px;
  }
  .btn-download {
    padding: 0;
    background-color: transparent;
    border: 0;
    svg {
      width: 24px;
      fill: var(--blue);
    }
  }
}
.table-responsive {
  overflow: scroll;
  max-height: 310px;
}
table.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #C1C8D6;
  thead {
    background: #EDF9FF;
    th {
      padding: 12px 8px;
      font-size: 12px;
      font-weight: 600;
      color: #71798A;
      text-align: left;
      border-bottom: 1px solid #C1C8D6;
      &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 8px;
        font-size: 15px;
        color: #333;
        white-space: nowrap;
        border-bottom: 1px solid #C1C8D6;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
        a {
          color: #171C26;
          text-decoration: none;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
}
.camera-status {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 50%;
  margin-right: 8px;
  &.assigned {
    background-color: var(--green);
  }
  &.unassigned {
    background-color: #FFAB40;
  }
  &.ordered {
    background-color: #969FB2;
  }
}
.base-pagination {
  width: 340px;
  margin: 14px auto;
}

.btn-container {
  display: flex;
  margin-left: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid transparent;
  font-family: $font-family-nova;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  white-space: nowrap;
  padding: 5px 18px 6px 18px;
  border-radius: 4px;
  &:first-child {
    margin-left: auto;
    margin-right: 5px;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
}

.btn-primary {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
  min-width: 100px;
  &:disabled {
    background-color: var(--pale-grey);
    border-color: var(--pale-grey);
    color: #969FB2;
  }
}
</style>