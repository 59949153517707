<template>
  <transition name="alert-fade">
    <div class="alert" :class="`alert-${type}`" v-if="show">
      <IconAlertInfo v-if="type === 'info'" class="icon" />
      <IconAlertSuccess v-if="type === 'success'" class="icon" />
      <IconAlertDanger v-if="type === 'danger'" class="icon" />
      <div class="content-container">
        <slot></slot>
      </div>
      <div class="learn-more-btn-container">
        <button v-if="isDismissible" type="button" class="btn-close" aria-label="Close" @click="close"><IconClose /></button>
        <button v-if="hasLearnMore" type="button" class="btn-learn-more">Learn More</button>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '@/assets/icons/close-icon.svg';
import IconAlertInfo from '@/assets/icons/alert-info-icon.svg';
import IconAlertSuccess from '@/assets/icons/alert-success-icon.svg';
import IconAlertDanger from '@/assets/icons/alert-danger-icon.svg';
export default {
  name: 'BaseAlert',
  components: {
    IconClose,
    IconAlertInfo,
    IconAlertSuccess,
    IconAlertDanger,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'success', 'warning', 'danger'].indexOf(value) !== -1;
      },
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    hasLearnMore: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('closed', false);
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-fade-enter,
.alert-fade-leave-active {
  opacity: 0;
}
.alert-fade-enter-active,
.alert-fade-leave-active {
  transition: opacity 0.5s ease;
}
.alert {
  position: relative;
  padding: 50px 20px 40px 20px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  max-width: 774px;
  @media screen and (min-width: 980px) {
    margin-top: 8px;
    padding: 17px 20px;
  }
  &.alert-info {
    background-color: var(--clear-blue);
    border-top: 5px solid var(--blue);
    @media screen and (min-width: 980px) {
      border-top: 0;
      border-left: 5px solid var(--blue);
    }
  }
  &.alert-danger {
    background-color: #FAE8EB;
    color: var(--dark);
    align-items: center;
  }
  &.alert-success {
    background-color: #E3F6F1;
    color: var(--dark);
    align-items: center;
  }
  .icon {
    width: 48px;
    display: block;
    margin-right: 15px;
  }
  .learn-more-btn-container {
    margin-left: auto;
    @media screen and (min-width: 980px) {
      display: flex;
      align-items: center;
    }
    .btn-learn-more {
      border: 0;
      background-color: transparent;
      font-family: var(--font-family-base);
      font-size: 17px;
      font-weight: 600;
      color: var(--blue);
      display: block;
      order: 0;
      @media screen and (min-width: 980px) {
        margin-right: 12px;
        padding-left: 18px;
        padding-right: 18px;
      }
    }
  }
  .btn-close {
    display: block;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    order: 1;
    svg {
      width: 24px;
    }
  }
}
</style>
