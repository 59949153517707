<template>
  <component :is="iconComponent" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iconComponent: null,
    }
  },
  computed: {
    nameWithCap() {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
  },
  methods: {
    loadIconComponent() {
      // Dynamically import the icon component based on the prop value
      // console.log(this.nameWithCap);
      const iconComponent = () => import(`@/components/icons/${this.nameWithCap}Icon.vue`);
      this.iconComponent = iconComponent;
    },
  },
  watch: {
    nameWithCap: {
      immediate: true,
      handler() {
        this.loadIconComponent();
      },
    },
  },
};
</script>
