<template>
  <!-- :to="customScrollRef ? `#${customScrollRef.id}` : 'body'" -->
  <Teleport to="body">
    <div
      class="guided-tour-waypoint"
      ref="waypoint"
      :class="{ hide: !visible || hidden, csr: customScrollRef, bottomCircle, sidebarVisible, active }"
      :style="{ zIndex }"
      v-show="!$store.state.inDealerMode && showGuidedTour && $store.state.closedWaypoints.indexOf(realId) === -1"
    >
      <div
        ref="icon"
        class="icon"
        :class="{ active, yreverse, bottomCircle, reverseRotation: initRotation }"
        @click="setActive"
      >
        <template v-if="bottomCircle">
          <img v-show="active" src="@/assets/Hotspot-Circle-No-Plus.svg?external" />
          <img v-show="!active" src="@/assets/Hotspot-Circle.svg?external" />
        </template>
        <img v-else src="@/assets/Hotspot.svg?external" />
      </div>
      <div
        ref="text"
        class="text"
        :class="{ hide: !active, xreverse, yreverse, multipleIds: ids && ids.length }"
      >
        <template v-if="ids && ids.length">
          <span v-html="$t(`waypoints[${ids[idIndex] - 1}]`).replace(/_([^_]+)_/g, `<span class='underline'>$1</span>`)" />
          <svg v-if="idIndex + 1 < ids.length" id="gfs:icon1/arrow-right" @click="nextId" viewBox="0 0 24 24">
            <path
              d="M8.035 16.934a1.938 1.938 0 0 0 0 2.74h.14l7.499-7.498v-.142L8.176 4.533h-.142a1.935 1.935 0 0 0 0 2.737l4.832 4.831-4.83 4.833z"
            ></path>
          </svg>
        </template>
        <span v-else-if="!copyOverride">{{ $t(`waypoints[${id - 1}]`) }} {{ copyOverride }}</span>
        <span v-else v-html="copyOverride" />
        <div class="dotted-line" :class="{ hide: !active, yreverse }" />
      </div>
    </div>
  </Teleport>
</template>

<script>
import Teleport from "vue2-teleport";
import { addLocalRecord } from "@/utils/localMetrics.js";
import { Capacitor } from '@capacitor/core';

const axios = require("axios").default;

export default {
  components: {
    Teleport,
  },
  props: {
    id: Number,
    ids: Array[Number],
    target: String,
    xOffset: Number,
    yOffset: Number,
    initRotation: Number,
    customScrollRef: HTMLElement,
    hidden: Boolean,
    zIndex: Number,
    bottomCircle: Boolean,
    forceYReverse: Boolean,
    copyOverride: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      visible: true,
      xreverse: false,
      yreverse: false,
      idIndex: 0,
      activationIds: {
        en: process.env.VUE_APP_INSIGHTS_ACTIVATION_TOUR_EN_US,
        es: process.env.VUE_APP_INSIGHTS_ACTIVATION_TOUR_ES_MX,
        "fr-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_TOUR_FR_CA,
        "en-ca": process.env.VUE_APP_INSIGHTS_ACTIVATION_TOUR_EN_CA,
        ipad: process.env.VUE_APP_INSIGHTS_ACTIVATION_TOUR_IPAD,
      },
      timestamp: null,
    };
  },
  computed: {
    showGuidedTour() {
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === true) return true;
      if (process.env.VUE_APP_SHOW_GUIDED_TOUR === 'true') return true;
      return false;
    },
    sidebarVisible() {
      return this.$store.state.sidebarVisible;
    },
    activationId() {
      if (Capacitor.isNativePlatform()) {
        if (!this.activationIds['ipad']) return undefined;
        return this.activationIds['ipad'];
      }
      if (!this.$store.state.locale) return undefined;
      if (!this.activationIds[this.$store.state.locale]) return undefined;
      return this.activationIds[this.$store.state.locale];
    },
    demoSessionId() {
      return this.$store.state.demoSessionId;
    },
    realId() {
      return this.id || this.ids[this.idIndex];
    },
    active: {
      get() {
        return this.$store.state.activeWaypoint === this.realId;
      },
      set(isTrue) {
        if (isTrue) this.$store.commit('setActiveWaypoint', this.realId);
        else this.$store.commit('setActiveWaypoint', -1);
      },
    },
    activeId() {
      return this.$store.state.activeWaypoint;
    }
  },
  methods: {
    setActive() {
      if (this.active) {
        // hide entire waypoint on second click
        this.hide();
      } else {
        // show text on first click
        this.$refs.icon.style.rotate = "";
        this.active = true;
        this.trackInsight(`${this.realId}-open`);
        this.timestamp = new Date().getTime();
        this.$emit('waypointOpened');
      }
    },
    setPositions() {
      if (this.bottomCircle) {
        this.yreverse = true;
      } else {
        const tgtPos = document
          .getElementById(this.target)
          ?.getBoundingClientRect();
        const iconPos = this.$refs.icon?.getBoundingClientRect();
        if (!tgtPos || !iconPos) return;
        this.$refs.waypoint.style.top = `${
          tgtPos.top + iconPos.height - (this.yOffset || 0)
        }px`;
        this.$refs.waypoint.style.left = `${
          tgtPos.left - iconPos.width + (this.xOffset || 0)
        }px`;
        const text = this.$refs.text;
        const textPos = text?.getBoundingClientRect();
        if (!textPos) return;
        if (!this.xreverse) this.xreverse = textPos.x + text.scrollWidth > window.innerWidth;
        // if (!this.yReverse)
        if (!this.yreverse) this.yreverse = this.forceYReverse || (textPos.y + text.scrollHeight > window.innerHeight);
      }
    },
    handleResize() {
      setTimeout(() => {
        this.setPositions();
      }, 100);
    },
    handleCustomScroll() {
      this.handleResize();
      if (
        this.$refs.icon.getBoundingClientRect().top <
        this.customScrollRef.getBoundingClientRect().top
      ) {
        this.visible = false;
      }
    },
    nextId() {
      if (this.idIndex + 1 < this.ids.length) {
        this.$emit('nextBtnPressed');
        this.idIndex += 1;
        this.active = true;
      } else this.hide();
    },
    hide() {
      // if waypoint no longer visible, track close event
      if (this.active && this.visible) {
        const now = new Date().getTime();
        const value = (now - this.timestamp).toString();
        this.trackInsight(`${this.realId}-close`, value);
      }
      // hide the waypoint
      this.visible = false;
      // after animation, prevent waypoint from ever reopening
      setTimeout(() => {
        if (this.id) this.$store.commit('addClosedWaypoint', this.id);
        else {
          for (let i = 0; i <= this.idIndex; i += 1) {
            this.$store.commit('addClosedWaypoint', this.ids[i]);
          }
        }
      }, 600);
    },
    trackInsight(action, value, callback) {
      if (process.env.VUE_APP_INSIGHTS_URL && this.activationId) {
        let synced = false;
        axios
          .post(process.env.VUE_APP_INSIGHTS_URL, {
            activationId: this.activationId,
            sessionIdentifier: this.demoSessionId,
            action,
            value,
            createdAt: new Date(),
            updatedAt: new Date(),
          })
          .then(() => {
            synced = true;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(async () => {
            if (Capacitor.isNativePlatform()) {
              await addLocalRecord(this.activationId, this.demoSessionId, action, value, synced);
            }
            if (callback) callback();
          });
      } else if (process.env.VUE_APP_INSIGHTS_URL) {
        console.error('Metrics not tracked - no activation ID specified')
        if (callback) callback();
      } else {
        console.warn('Metrics not tracked in this environment');
        if (callback) callback();
      }
    },
    handleUserTyping() {
      this.$store.commit('setActiveWaypoint', -2);
    },
  },
  watch: {
    sidebarVisible() {
      this.handleResize();
    },
    customScrollRef: {
      immediate: true,
      handler() {
        if (this.customScrollRef) {
          window.removeEventListener("scroll", this.handleCustomScroll);
          this.customScrollRef.addEventListener(
            "scroll",
            this.handleCustomScroll
          );
        } else {
          window.addEventListener("scroll", this.handleResize);
        }
      },
    },
    idIndex(after, before) {
      // tracking for multi-page waypoints
      const idBefore = this.ids[before];
      const idAfter = this.ids[after];
      const now = new Date().getTime();
      const value = (now - this.timestamp).toString();
      this.trackInsight(`${idBefore}-close`, value);
      this.trackInsight(`${idAfter}-open`);
      this.timestamp = new Date().getTime();
    },
    activeId(after, before) {
      if (before === this.realId) {
        // if waypoint no longer visible, track close event
        if (this.visible) {
          const now = new Date().getTime();
          const value = (now - this.timestamp).toString();
          this.trackInsight(`${before}-close`, value);
        }
        // unless closed by typing, hide waypoint altogether
        if (after !== -2) this.hide();
      }
    }
  },
  mounted() {
    if (this.initRotation && this.$refs.icon)
      this.$refs.icon.style.rotate = `${this.initRotation}deg`;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // Close and hide waypoint if user clicks on the element it is targeting
    if (this.target) {
      const tgt = document.getElementById(this.target);
      if (tgt) tgt.addEventListener("click", this.hide);
    }
    // Close (but don't hide) any open waypoints if user inputs text
    const inputFields = document.querySelectorAll('input');
    inputFields.forEach((inputField) => {
      inputField.addEventListener('input', this.handleUserTyping);
    });
  },
  beforeDestroy() {
    // If user leaves the page with a waypoint still open, close and hide forever
    if (this.active) this.hide();
    this.active = false;
    // Remove all event listeners
    window.removeEventListener("resize", this.handleResize);
    if (this.customScrollRef) {
      this.customScrollRef.removeEventListener(
        "scroll",
        this.handleCustomScroll
      );
    } else {
      window.removeEventListener("scroll", this.handleResize);
    }
    if (this.target) {
      const tgt = document.getElementById(this.target);
      if (tgt) tgt.removeEventListener("click", this.hide);
    }
    const inputFields = document.querySelectorAll('input');
    inputFields.forEach((inputField) => {
      inputField.removeEventListener('input', this.handleUserTyping);
    });
  },
};
</script>

<style lang="scss" scoped>
.guided-tour-waypoint {
  position: fixed;
  // top: 100px;
  // left: 500px;
  z-index: 10;
  transition: opacity 0.5s ease;
  &.active {
    z-index: 20;
  }
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.bottomCircle {
    position: fixed;
    bottom: 80px;
    left: 40px;
    &.sidebarVisible {
      left: 300px;
    }
    &.active {
      bottom: 74px;
      left: 47px;
      &.sidebarVisible {
        left: 307px;
      }
    }
  }
}
.icon {
  width: 38px;
  height: 38px;
  color: #fff;
  position: absolute;
  z-index: 10;
  transition: rotate 0.5s ease;
  cursor: pointer;
  &.active {
    rotate: 135deg !important;
    z-index: 40;
  }
  &.yreverse {
    &.active {
      rotate: 315deg !important;
    }
  }
  &.bottomCircle {
    &.active {
      rotate: 45deg !important;
    }
  }
  .plus {
    // width: 20px;
    // height: 20px;
    // margin: 8px;

    width: 28px;
    height: 28px;
    margin: 10px;
    fill: #fff;
  }
  &.bottomCircle {
    &.active {
      // width: 36px;
      // height: 36px;
      width: 28.5px;
      height: 28.5px;
      left: -1px;
      top: -1px;
      background-color: #11365f;
      border-radius: 50%;
      outline: 1px dashed #05c3dd;
      outline-offset: 3px; // Old 4px
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.text {
  position: absolute;
  top: 25px;
  left: -20px;
  background-color: #0072ce;
  padding: 40px 20px 20px 20px; // Old 20px 20px 44px 20px
  font-family: Overpass;
  font-weight: 600;
  font-size: 16px; // Old 22px
  line-height: 1.25; // Old 26px
  width: 300px;
  color: #fff;
  transition: opacity 0.5s ease;
  z-index: 30;
  &.xreverse {
    left: -210px;
  }
  &.yreverse {
    top: unset;
    bottom: -25px;
    padding: 20px 20px 40px 20px; // Old 20px 20px 44px 20px
  }
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.multipleIds {
    width: 300px;
    padding-right: 12px;
    display: grid;
    grid-template-columns: auto 40px;
  }
  svg {
    width: 100%;
    margin-left: 8px;
    fill: #fff;
    cursor: pointer;
  }
  .dotted-line {
    position: absolute;
    bottom: -6px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: url('../../assets/Dotted-Line.svg?external');
    background-repeat: repeat-x;
    background-size: 100%;
    &.yreverse {
      bottom: unset;
      top: -6px;
    }
  }
}
</style>
