<template>
  <div class="chart-area flex">
    <div class="chart-wrap">
      <svg
        :width="svgWidth"
        :height="svgHeight"
        :class="{ small: small }"
        viewBox="0 0 32 32"
        class="chart"
      >
        <circle
          class="donut-ring"
          cx="16"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="var(--light-grey)"
          stroke-width="1.5"
          stroke-dasharray="50 50"
          stroke-dashoffset="50"
        />
        <path
          gml-template="GaugeTicks"
          class="gauge-ticks"
          fill="none"
          stroke-width="5"
          d="M-55,0 A55,55,0,0,1,55,0"
          stroke-dasharray="2 26.46"
          stroke-dashoffset="0"
        ></path>
        <circle
          class="donut-segment"
          cx="16"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          :stroke="speed ? 'var(--blue)' : 'var(--green)'"
          stroke-width="1.5"
          :stroke-dasharray="value / 2 + ' ' + (100 - value / 2)"
          stroke-dashoffset="50"
        />
      </svg>
      <div class="chart-text-small" v-if="small && speed">
        <span class="line1" v-if="value">
          {{ conversions.getMilesValue(value, $i18n.locale) }}
          <span class="sub">{{ $t("message.mph") }}</span>
        </span>
        <span class="line1" style="height: 24px" v-else />
        <span class="line2">{{ $t("message.speed") }}</span>
      </div>
      <div class="chart-text-small" v-else-if="small">
        <span class="line1" v-if="miles">
          <svg
            v-if="ev"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z"
            />
          </svg>
          <BaseIcon name="battery" v-if="ev" />
          <BaseIcon name="gasPump" v-else class="gas-pump-icon" />
          {{ value }}%
          <!-- <span class="sub">mi</span> -->
        </span>
        <span class="line1" style="height: 24px" v-else />
        <span class="line2" v-if="!$store.state.inDealerMode"
          >{{ $t("message.range") }}:
          {{ conversions.getMilesValue(miles, $i18n.locale)
          }}{{ $t("message.mi") }}</span
        >
      </div>
      <div class="chart-text" v-else>
        <!-- <span class="line1 flex center" v-if="ev" style="margin-right: 7px">
          <img src="@/assets/lightning_bolt@2x.png" class="ev-icon" />

          {{value}}%
        </span> -->
        <!-- <span class="line1" v-else>Fuel {{value}}%</span> -->
        <span class="line2">
          <BaseIcon name="battery" v-if="ev" />
          <BaseIcon name="gasPump" v-else class="gas-pump-icon" />
          {{ value }}%
        </span>
        <span class="line3"
          >{{ $t("message.range") }}:
          {{ conversions.getMilesValue(miles, $i18n.locale)
          }}{{ $t("message.mi") }}</span
        >
        <span class="line3" v-if="mpg">
          Lifetime Fuel Economy:
          {{
            conversions.getMpgValue(mpg, $i18n.locale) +
            " " +
            $t("message.mpg")
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as conversions from "../../utils/conversions.js";
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    value: Number, // fuel/charge percentage
    miles: Number,
    small: Boolean,
    speed: Boolean,
    ev: Boolean,
    mpg: Number,
  },
  data() {
    return {
      conversions: conversions,
    };
  },
  computed: {
    svgWidth() {
      if (this.small) return "150px";
      return "200px";
    },
    svgHeight() {
      if (this.small) return "130px";
      return "180px";
    },
  },
};
</script>

<style scoped>
.chart-text .line1,
.chart-text .line3,
.chart-text-small .line2 {
  font-size: 13px;
  color: var(--dark-grey);
  font-weight: 400;
}
.chart-text .line3 {
  margin-top: 3px;
}
.chart-text .line2,
.chart-text-small .line1 {
  margin-top: 18px;
  font-size: 18px;
  color: var(--dark);
  font-weight: 400;
  /* new */
  font-size: 21px;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
}
.chart-text-small .line1 .sub {
  margin-top: 5px;
}
.chart-text .line2 .sub,
.chart-text-small .line1 .sub {
  font-size: 14px;
}
.chart-wrap {
  position: relative;
  height: 180px;
}
.chart-text {
  position: absolute;
  top: 62px;
  width: 200px;
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart-text-small {
  position: absolute;
  top: 30px;
  width: 144px;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart-text h1 {
  margin: 0;
  margin-top: 8px;
  font-size: 32px;
  line-height: 23px;
}
.chart-key {
  display: grid;
  grid-template: repeat(auto-fit, 15px) / 8px 140px;
  grid-gap: 12px 8px;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 12px;
  margin-left: 32px;
}
.color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  grid-column: 1 / 1;
}
.chart-key span {
  color: var(--dark);
  font-size: 13px;
  grid-column: 2 / 2;
}
.small {
  transform: translate(-2px, -12px);
}
.ev-icon {
  /* transform: rotate(90deg) scaleY(-1);  */
  fill: var(--green);
  margin-right: 3px;
  width: 20px;
  height: 10px;
}
.gas-pump-icon {
  transform: scale(0.8);
}
.gauge-ticks {
  transform: scale(0.265) translate(60px, 79px);
  stroke: var(--light-grey);
}
</style>
