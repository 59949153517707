<template>
  <div
    class="map-screen"
    @contextmenu.prevent="handleRightClick"
    @click.prevent="rightClickMenu.show = false"
    :style="{ width: mapWidth + 'px', height: mapHeight + 'px' }"
  >
    <BaseAlert v-if="inEssentialsMode" type="info" :isDismissible="true" :hasLearnMore="true" class="alert-upgrade" :show="isAlertOpen" @closed="isAlertOpen = false">
      <h1>Upgrade to Maximize Your Business's Capabilities</h1>
      <p>Unlock features like Vehicle Location, Geofences, and Trip History</p>
    </BaseAlert>
    <MapVehicleHoverState
      v-if="hoverStateIndex !== -1"
      :vehicle="hoveredVehicle"
      :driver="hoveredDriver"
      :index="hoverStateIndex"
      :mapWidth="mapWidth"
    />
    <MapButtons
      @zoomOut="$store.commit('setShowView', 'Overlay Map')"
      @toggleMapLayers="showLayers = !showLayers"
    />
    <MapLayerPanel
      v-if="showLayers"
      @done="showLayers = false"
      :showChargers="showChargers"
      @toggleChargers="showChargers = $event"
    />
    <div
      class="map-wrapper"
      ref="map"
      v-touch-pan.prevent.mouse="(event) => handlePanForMap(event)"
    >
      <!-- @click.self="handleMapClick" -->
      <img
        class="map"
        :src="currentMap"
        @mousedown.self="isPanning = false"
        @mouseup.self="handleMapClick"
        draggable="false"
        :style="{
          objectPosition: `${centerX.neg + panOffset.x}px ${
            centerY.neg + panOffset.y
          }px`,
        }"
      />
    </div>
    <!-- START car icons driving around -->
    <div
      v-if="!rescaling"
      :style="{
        transform: `translateY(${panOffset.y}px) translateX(${panOffset.x}px)`,
      }"
    >
      <template v-if="showView === 'Overlay Map' && $store.state.inDealerMode">
        <div
          v-for="([key, lotDetails], index) of Object.entries(lotLocations)"
          :key="index"
          class="lot_overlay clickable"
          @click="$store.commit('setShowView', key)"
          v-touch-pan.prevent.mouse="
            (ev) => {
              if (rotateMode) {
                lotDetails.rotation += ev.delta.x;
              } else {
                lotDetails.x += ev.delta.x;
                lotDetails.y += ev.delta.y;
              }
            }
          "
          :style="{
            position: 'absolute',
            zIndex: 1,
            top: `${mapHeight / 2 + lotDetails.y}px`,
            left: `${mapWidth / 2 + lotDetails.x}px`,
            transform: `rotate(${lotDetails.rotation}deg)`,
            transformOrigin: 'center',
            width: `${lotDetails.width}px`,
            height: `${lotDetails.height}px`,
          }"
        >
          <div class="badge" style="">
            <img src="@/assets/icons-vehicles-24-px.svg?external" alt="" />
            <span class="bold">{{ lotDetails.capacity }}</span> |
            <span>{{ lotDetails.name }}</span>
          </div>
        </div>
      </template>
      <template v-else-if="Object.keys(lotCovers).indexOf(showView) !== -1">
        <div class="lot-cover" :style="lotCoverStyle" @click.self="clearCurrentCar" />
      </template>
      <template v-for="(vehicle, index) of filteredData">
        <div
          v-if="vehicle.details && vehicle.details.map && !inEssentialsMode"
          :key="`${vehicle.id}${index}`"
          class="car clickable"
          :class="!$store.state.inDealerMode ? 'animated' : ''"
          :style="{
            top: `${mapHeight / 2 + (vehicle.details.map[whichMap] ? vehicle.details.map[whichMap].y : vehicle.details.map.y)}px`,
            left: `${mapWidth / 2 + (vehicle.details.map[whichMap] ? vehicle.details.map[whichMap].x : vehicle.details.map.x) }px`,
            transform: `rotate(${(vehicle.details.map[whichMap] ? vehicle.details.map[whichMap].rotation : vehicle.details.map.rotation) }deg)`,
          }"
          @click="selectCar(vehicle, index)"
          @mouseover="hoverStateIndex = index"
          @mouseleave="hoverStateIndex = -1"
          ref="car"
        >
          <svg
            v-if="currentCarIndex === index"
            class="vehicle-sprite selected"
            viewBox="0 0 64 64"
            width="64"
            height="64"
            xmlns="http://www.w3.org/2000/svg"
            style="transform: translate(-28px, -32px) rotate(141deg)"
          >
            <g
              fill="#05B5E4"
              class="vehicle-sprite-disc"
              transform-origin="center"
            >
              <circle cx="32" cy="32" r="30" fill-opacity="0.15" />
              <circle cx="32" cy="32" r="25" fill-opacity="0.25" />
              <circle cx="32" cy="32" r="19" fill-opacity="0.35" />
            </g>
          </svg>
          <img
            :src="
              require('@/assets/vehicle-imgs/' +
                (vehicle.details.map[whichMap] ? vehicle.details.map[whichMap].icon : vehicle.details.map.icon) +
                '?external')
            "
          />
          <CarLabel
            v-if="$store.state.inDealerMode && !showView.includes('Lot')"
            :vehicleLabel="vehicle['Vehicle Label']"
            :rotation="vehicle.details.map[whichMap].rotation"
            :horizontalPosition="
              vehicle.details.map[whichMap].rotation === 67 ? 'left' : 'right'
            "
            :verticalPosition="
              vehicle.details.map[whichMap].rotation === 155 ? 'top' : 'bottom'
            "
          />
        </div>
      </template>
      <!-- END car icons driving around -->

      <template v-if="showChargers">
        <div
          v-for="(evCharger, i) of dataset.evChargers[whichMap]"
          class="ev-charger-icon"
          :class="{ clickable: evCharger.clickable }"
          @click="evCharger.clickable ? (currentCharger = evCharger) : ''"
          :style="{
            top: `${mapHeight / 2 + evCharger.map.y}px`,
            left: `${mapWidth / 2 + evCharger.map.x}px`,
            transform: evCharger.map.smallIcon ? 'scale(0.78)' : '',
            backgroundImage:
              'url(' +
              require('@/assets/map-ev-chargers/' +
                evCharger.map.icon +
                '?external') +
              ')',
          }"
          :key="'evc'+i"
        >
          <EVChargerInfo
            mini
            v-if="evCharger.clickable"
            class="charger-mini-info"
            :charger="evCharger"
          />
        </div>
      </template>

      <div
        v-if="currentPlaceId === 1"
        style="position: absolute;"
        :style="{ top: `${mapHeight / 2 + 465}px`, left: `${mapWidth / 2 + 655}px`, }"
      >
        <svg
          class="place-type-icon"
          width="50"
          height="55"
          viewBox="0 0 72 80"
        >
          <g fill="none" fill-rule="evenodd">
            <g>
              <path
                fill="#486F76"
                d="M36.5 3C22.475 3 11 14.028 11 27.6c0 5.373 1.842 10.463 5.1 14.704l19.267 25.167c.425.565 1.416.707 1.983.282l.283-.282L56.9 42.304c3.258-4.241 5.1-9.33 5.1-14.703C62 14.028 50.525 3 36.5 3z"
              ></path>
              <g fill="#FFF" transform="translate(20, 14) scale(1.4, 1.4)">
                $
                <path
                  d="M6.9 7.907v1.2H5.588l-1.252 11.6h15.326l-1.258-11.6H17.1v-1.2h2.381L20.88 20.8c.06.55-.337 1.043-.887 1.103l-.107.005H4.114c-.552 0-1-.447-1-1l.006-.107L4.512 7.907H6.9zM12 1.4c2.332 0 4.235 1.835 4.345 4.14l.005.21V12h-1.2V5.75c0-1.74-1.41-3.15-3.15-3.15-1.68 0-3.052 1.315-3.145 2.971l-.005.179V12h-1.2V5.75C7.65 3.348 9.598 1.4 12 1.4zm2.4 6.507v1.2H9.6v-1.2h4.8z"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>

    </div>
    <EVChargerInfo
      v-if="currentCharger"
      :charger="currentCharger"
      @done="currentCharger = null"
    />

    <!-- I didn't have time to factor out the showCurrentCar data and didn't want to deal with passing events around. The FilterModalCarItem component updates the store's version. -->
    <FilterModal
      v-if="!$store.state.showCurrentCar"
      :searchQuery="searchQuery"
      :dataset="dataset"
      @selectCharger="currentCharger = $event"
      @selectPlace="currentPlaceId = $event"
      @waypointOpened="isAlertOpen = false"
    />

    <!-- SELECTED CAR DETAILS -->
    <MapVehicleDetailsPanel
      v-if="showCurrentCar"
      :dataset="dataset"
      :whichMap="whichMap"
      :sidebarVisible="sidebarVisible"
      @clearCurrentCar="clearCurrentCar" 
    />

    <!-- SELECTED PLACE DETAILS -->
    <MapPlaceDetailsPanel
      v-if="currentPlaceId >= 0"
      :placeId="currentPlaceId"
      @done="currentPlaceId = -1" 
    />

    <MapRightClick
      v-show="rightClickMenu.show"
      :x="rightClickMenu.x"
      :y="rightClickMenu.y"
      @done="rightClickMenu.show = false"
    />
  </div>
</template>

<script>
import TouchPan from "@/directives/TouchPan.js";
import MapButtons from "@/components/dealer/MapButtons.vue";
import MapLayerPanel from "@/components/demo/MapLayerPanel.vue";
import MapVehicleDetailsPanel from "@/components/demo/MapVehicleDetailsPanel.vue";
import MapPlaceDetailsPanel from "@/components/demo/MapPlaceDetailsPanel.vue";
import CarLabel from "@/components/dealer/CarLabel.vue";
import FilterModal from "@/components/demo/FilterModal.vue";
import EVChargerInfo from "@/components/demo/EVChargerInfo.vue";
import MapVehicleHoverState from "@/components/demo/MapVehicleHoverState.vue";
import MapRightClick from "@/components/demo/MapRightClick.vue";
import BaseAlert from '@/components/BaseAlert.vue';

export default {
  props: {
    dataset: Object,
    sidebarVisible: Boolean,
    isMobile: Boolean,
    numIncomplete: Number,
    challenges: Array,
    activeChallenge: String,
    multiplier: Number,
  },
  directives: {
    "touch-pan": TouchPan,
  },
  components: {
    FilterModal,
    MapButtons,
    MapLayerPanel,
    MapVehicleDetailsPanel,
    MapPlaceDetailsPanel,
    CarLabel,
    EVChargerInfo,
    MapVehicleHoverState,
    MapRightClick,
    BaseAlert,
},
  data() {
    return {
      panOffset: {
        x: 0,
        y: 0,
      },
      rotateMode: false,
      shouldAnimate: true,
      mapWidth: 0,
      mapHeight: 0,
      imageWidth: 2048,
      imageHeight: 2000,
      rescaling: true,
      rescalingTimeout: -1,
      animationTriggerTimeout: -1,
      showLayers: false,
      showChargers: true,
      // currentCar: {},
      currentCharger: null,
      currentPlaceId: -1,
      bingo: false,
      lotLocations: {
        "Main Lot": {
          map: require("@/assets/lot_brush_street_cars.jpg"),
          filter_map: require("@/assets/lot_brush_street_cars_filtered.jpg"),
          x: 430,
          y: 15,
          width: 60,
          height: 60,
          rotation: 67,
          name: "Main Lot",
          capacity: 125,
        },
        "Remote Lot": {
          map: require("@/assets/lot_woodward_cars.jpg"),
          filter_map: require("@/assets/lot_woodward_cars_filtered.jpg"),
          x: 239,
          y: -95,
          width: 60,
          height: 60,
          rotation: 67,
          name: "Remote Lot",
          capacity: 75,
        },
        "Courtesy Lot": {
          map: require("@/assets/lot_john_r_cars.jpg"),
          filter_map: require("@/assets/lot_john_r_cars_filtered.jpg"),
          x: 364,
          y: -15,
          width: 60,
          height: 60,
          rotation: 67,
          name: "Courtesy Lot",
          capacity: 60,
        },
      },
      lotCovers: {
        'Main Lot': {
          top: -314,
          left: -163,
          width: 530,
          height: 711,
          rotation: -26,
        },
        'Courtesy Lot': {
          top: 57,
          left: -430,
          width: 702,
          height: 436,
          rotation: -30,
        },
        'Remote Lot': {
          top: -59,
          left: -151,
          width: 640,
          height: 652,
          rotation: -28,
        },
      },
      hoverStateIndex: -1,
      mouseX: 0,
      mouseY: 0,
      isPanning: false,
      rightClickMenu: {
        show: false,
        x: 0,
        y: 0,
      },
      isAlertOpen: true,
    };
  },
  mounted() {
    this.setMapSize();
    // Load maps for later use
    Object.values(this.lotLocations).forEach((lot) => {
      let i = new Image();
      i.src = lot.map;
      let i2 = new Image();
      i2.src = lot.filter_map;
    });
    if (this.$route.params.viewVehicle && this.$route.params.viewVehicleIndex) {
      // TODO: move this to the store
      this.currentCar = this.$route.params.viewVehicle;
      this.currentCarIndex = this.$route.params.viewVehicleIndex;
      this.showCurrentCar = true;
      // end todo

      this.$store.commit(
        "setCurrentCarIndex",
        this.$route.params.viewVehicleIndex
      );
      if (this.$store.state.inDealerMode && this.currentCar.Geofence) {
        this.$store.commit("setShowView", this.currentCar.Geofence);
      }
      this.$store.commit("turnOnCurrentCarVisibility");
    } else if (this.$store.state.inDealerMode) {
      this.$store.commit('setShowView', 'Courtesy Lot');
    } else {
      this.$store.commit("setShowView", "Overlay Map");
    }
  },
  computed: {
    currentCar: {
      get() {
        return this.$store.state.currentCar;
      },
      set(data) {
        this.$store.commit("setCurrentCar", data);
      },
    },
    showCurrentCar: {
      get() {
        return this.$store.state.showCurrentCar;
      },
      set(data) {
        if (data) {
          this.$store.commit("turnOnCurrentCarVisibility");
        } else {
          this.$store.commit("turnOffCurrentCarVisibility");
        }
      },
    },
    searchQuery: {
      get() {
        return this.$store.state.mapSearch;
      },
      set(data) {
        return this.$store.commit("setMapSearch", data);
      },
    },
    showView() {
      return this.$store.state.showView;
    },
    isFiltering: {
      get() {
        return this.$store.state.isFiltering;
      },
      set(data) {
        this.$store.commit("setFilteringState", data);
      },
    },
    currentCarIndex: {
      get() {
        return this.$store.state.currentCarIndex;
      },
      set(data) {
        this.$store.commit("setCurrentCarIndex", data);
      },
    },
    currentCarLabel() {
      const label = this.currentCar["Vehicle Label"];
      const start = label.toLowerCase().indexOf(this.searchQuery);
      if (start > -1) {
        const end = start + this.searchQuery.length;
        return [
          label.slice(0, start),
          '<mark class="mark">',
          label.slice(start, end),
          "</mark>",
          label.slice(end),
        ].join("");
      }
      return label;
    },
    centerX() {
      let value = this.imageWidth / 2 - this.mapWidth / 2;
      let neg = -1 * value;
      return {
        pos: value,
        neg,
      };
    },
    centerY() {
      let value = this.imageHeight / 2 - this.mapHeight / 2;
      let neg = -1 * value;
      return {
        pos: value,
        neg,
      };
    },
    currentMap() {
      if (this.$store.state.inDealerMode) {
        if (this.showView === "Main Lot") {
          if (this.isFiltering) {
            return this.lotLocations["Main Lot"].filter_map;
          }
          return this.lotLocations["Main Lot"].map;
        }
        if (this.showView === "Remote Lot") {
          if (this.isFiltering) {
            return this.lotLocations["Remote Lot"].filter_map;
          }
          return this.lotLocations["Remote Lot"].map;
        }
        if (this.showView === "Courtesy Lot") {
          if (this.isFiltering) {
            return this.lotLocations["Courtesy Lot"].filter_map;
          }
          return this.lotLocations["Courtesy Lot"].map;
        }
        if (this.showView === "Courtesy Lot") {
          return require("@/assets/lot_jefferson.jpg");
        }
        return require("@/assets/full_map_with_overlay.jpg");
      }
      if (
        this.$store.state.locale === "en-ca" ||
        this.$store.state.locale === "fr-ca"
      ) {
        return require("@/assets/full_map_ca.png");
      }
      if (this.$store.state.locale === "es") {
        return require("@/assets/full_map_mx.png");
      }
      return require("@/assets/full_map.png");
    },
    filteredData() {
      let data = this.$store.getters.filteredVehicles;
      // deep copy of dataset to prevent issues when coloring text later
      if (this.showView && this.showView === "Main Lot") {
        data = data.map((obj) => Object.assign({}, obj));
        data = data.filter((row) => row.details && row.Lot === "Main Lot");
      } else if (this.showView && this.showView === "Remote Lot") {
        data = data.map((obj) => Object.assign({}, obj));
        data = data.filter((row) => row.details && row.Lot === "Remote Lot");
      } else if (this.showView && this.showView === "Courtesy Lot") {
        data = data.map((obj) => Object.assign({}, obj));
        data = data.filter((row) => row.details && row.Lot === "Courtesy Lot");
      } else {
        data = this.$store.getters.datasetWithoutExtraCars.map((obj) =>
          Object.assign({}, obj)
        );
      }

      // filter items by search query
      if (!this.$store.state.inDealerMode && this.searchQuery) {
        data = data.filter((row) => this.search(row));
        // coloring text when typing
        for (let vehicleObj of data) {
          for (let i = 0; i < Object.values(vehicleObj).length; i++) {
            const value = Object.values(vehicleObj)[i];
            if (typeof value === "string") {
              const startIndex = value
                .toLowerCase()
                .indexOf(this.searchQuery.toLowerCase());
              const endIndex = startIndex + this.searchQuery.length;
              // ensure a result was found in the iteration,
              // and we aren't changing image str, model name or model year str
              if (startIndex !== -1 && i !== 0 && i !== 2 && i !== 3) {
                const formattedResult =
                  value.substring(0, startIndex) +
                  '<mark class="mark">' +
                  value.substring(startIndex, endIndex) +
                  "</mark>" +
                  value.substring(endIndex, value.length);
                vehicleObj[Object.keys(vehicleObj)[i]] = formattedResult;
              }
            }
          }
        }
      }
      // final search results
      return data;
    },
    
    timeLeft() {
      return this.$parent.timeLeft;
    },
    whichMap() {
      if (this.$store.state.inDealerMode) return "us";
      if (
        this.$store.state.locale === "en-ca" ||
        this.$store.state.locale === "fr-ca"
      ) {
        return "ca";
      }
      if (this.$store.state.locale === "es") {
        return "mx";
      }
      return "us";
    },
    // hoverStateTop() {
    //   if (this.hoverStateIndex < 0) return 0;
    //   let map = this.filteredData[this.hoverStateIndex].details.map;
    //   if (map[this.whichMap]) map = map[this.whichMap];
    //   return this.mapHeight / 2 + map.y;
    // },
    // hoverStateLeft() {
    //   if (this.hoverStateIndex < 0) return 0;
    //   let map = this.filteredData[this.hoverStateIndex].details.map;
    //   if (map[this.whichMap]) map = map[this.whichMap];
    //   return this.mapWidth / 2 + map.x;
    // },
    hoveredVehicle() {
      if (this.hoverStateIndex < 0) return null;
      return this.filteredData[this.hoverStateIndex];
    },
    hoveredDriver() {
      if (this.hoveredVehicle) {
        for (const driver of this.dataset.drivers) {
          if (driver["Vehicle"] === this.hoveredVehicle["Vehicle Label"]) {
            return driver;
          }
        }
      }
      return null;
    },
    lotCoverStyle() {
      if (Object.keys(this.lotCovers).indexOf(this.showView) !== -1) {
        const top = this.mapHeight / 2 + this.lotCovers[this.showView].top;
        const left = this.mapWidth / 2 + this.lotCovers[this.showView].left;
        const width = this.lotCovers[this.showView].width;
        const height = this.lotCovers[this.showView].height;
        const rotation = this.lotCovers[this.showView].rotation;
        return `top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; transform: rotate(${rotation}deg);`;
      }
      return '';
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
  created() {
    let { mode } = this.$route.meta;
    if (mode) {
      this.$store.commit("setMode", mode);
    }
    if (this.$attrs.activeChallenge) {
      this.activeChallenge = this.$attrs.activeChallenge;
    }
    window.addEventListener("resize", this.handleRescale);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleRescale);
  },
  methods: {
    handlePanForMap(event) {
      this.panOffset.x += event.delta.x;
      if (this.panOffset.x >= this.centerX.pos)
        this.panOffset.x = this.centerX.pos;
      else if (this.panOffset.x <= this.centerX.neg)
        this.panOffset.x = this.centerX.neg;
      this.panOffset.y += event.delta.y;
      if (this.panOffset.y >= this.centerY.pos)
        this.panOffset.y = this.centerY.pos;
      else if (this.panOffset.y <= this.centerY.neg)
        this.panOffset.y = this.centerY.neg;
    },
    // handlePanForVehicles(event, vehicle) {
    //   let map = vehicle.details.map[this.whichMap];
    //   if (!map) map = vehicle.details.map;
    //   if (this.rotateMode) {
    //     map.rotation += event.delta.x;
    //   } else {
    //     map.x += event.delta.x;
    //     map.y += event.delta.y;
    //   }
    // },
    // print(item) {
    //   console.log(item);
    // },
    updateFile(vehicles) {
      this.lotInfo = JSON.stringify(vehicles, null, 2);
    },
    determineColor(tag) {
      switch (tag) {
        case "Services":
          return "#024C52";
        case "Car":
          return "#FF6D00";
        case "EV":
          return "#EE5450";
        case "SUV":
          return "#BA68C8";
        case "Truck":
          return "#3B8E3F";
        default:
          return "";
      }
    },
    handleRescale() {
      // We need to regenerate the cars on the map as the user rescales
      // This will allow us to have the animations restart from the new coordinate plane
      this.rescaling = true;
      clearTimeout(this.rescalingTimeout);
      this.rescalingTimeout = setTimeout(() => {
        this.rescaling = false;
        this.rescalingTimeout = -1;
        this.setMapSize();
      }, 100);
    },
    setMapSize() {
      if (this.sidebarVisible) {
        this.mapWidth = window.innerWidth - 275;
      } else {
        this.mapWidth = window.innerWidth;
      }
      this.mapHeight = window.innerHeight - 56;
      this.rescaling = false;
      // begin car animation
      clearTimeout(this.animationTriggerTimeout);
      if (this.shouldAnimate) {
        this.animationTriggerTimeout = setTimeout(() => {
          let i = 0;
          let data = this.filteredData.filter(
            (row) => row.details && row.details.map
          );
          let cars = this.$refs["car"];
          if (cars === undefined) {
            return;
          }
          for (let vehicle of data) {
            //gets stuck at index 1
            let pos = vehicle.details.map[this.whichMap];
            if (this.$refs["car"][i]) {
              this.$refs["car"][i].style.left = `${
                this.mapWidth / 2 + pos.endX
              }px`;
  
              this.$refs["car"][i].style.top = `${
                this.mapHeight / 2 + pos.endY
              }px`;
            }
            i++;
          }
          this.animationTriggerTimeout = -1;
        }, 100);
      }
    },
    search(row) {
      //TODO: move to FilterModal
      for (let str of Object.values(row)) {
        if (
          str &&
          str
            .toString()
            .toLowerCase()
            .indexOf(this.searchQuery.toLowerCase()) !== -1
        )
          return true;
      }
      return false;
    },
    //TODO: remove this when done transferring stuff to the store
    selectCar(vehicle, index) {
      this.currentCar = { ...vehicle };
      this.currentCarIndex = index;
      this.showDropdown = false;
      this.showCurrentCar = true;
    },
    handleMapClick() {
      // When zoomed into a geofence, clicking outside the geofence area should zoom out the map to Overlay view
      // - Do NOT zoom out if the user is currently "dragging" the map around
      if (Object.keys(this.lotCovers).indexOf(this.showView) !== -1 && !this.isPanning) {
        this.$store.commit('setShowView', 'Overlay Map');
      }
      this.isPanning = false;
      this.clearCurrentCar();
    },
    clearCurrentCar() {
      //TODO: add to the store and hook it up to the component and remove when done
      this.showCurrentCar = false;
      this.searchQuery = "";
      this.currentCar = {};
      this.currentCarIndex = -1;
      // not sure if I can actually remove the above yet
      let { commit } = this.$store;
      commit("setCurrentCarIndex", -1);
      commit("turnOffCurrentCarVisibility");
    },
    delayHideDropdown() {
      // TODO: remove
      setTimeout(() => {
        this.showDropdown = false;
        this.searchQuery = "";
      }, 300);
    },
    
    challengeComplete(chal) {
      this.$emit("challengeComplete", chal);
    },
    bingoEmit() {
      this.bingo = true;
      this.$emit("pauseTimer");
    },
    noMoreChallenges() {
      this.$emit("noMoreChallenges");
    },
    handleMouseMove(e) {
      if (this.hoverStateIndex === -1) {
        this.mouseX = e.pageX;
        this.mouseY = e.pageY;
      }
    },
    handleRightClick(e) {
      console.log(e.layerX, e.layerY);
      this.rightClickMenu.show = !this.rightClickMenu.show;
      this.rightClickMenu.x = e.layerX;
      this.rightClickMenu.y = e.layerY;
    },
  },
  watch: {
    showView() {
      this.panOffset.x = 0;
      this.panOffset.y = 0;
      this.isFiltering = false;
    },
    currentCarIndex: function () {
      if (
        this.currentCarIndex === 0 &&
        this.activeChallenge === "where-is-watson"
      ) {
        setTimeout(() => {
          this.$emit("challengeComplete", 0);
          this.$emit("pauseTimer");
          this.bingo = true;
          this.$emit("bingo");
        }, 1000);
      }
    },
    sidebarVisible() {
      this.handleRescale();
    },
    panOffset: {
      handler() {
        this.isPanning = true;
      },
      deep: true,
    },
    currentPlaceId() {
      if (this.currentPlaceId === 1) {
        // x direction
        this.panOffset.x = -501;
        if (this.panOffset.x >= this.centerX.pos)
          this.panOffset.x = this.centerX.pos;
        else if (this.panOffset.x <= this.centerX.neg)
          this.panOffset.x = this.centerX.neg;
        // y direction
        this.panOffset.y = -511;
        if (this.panOffset.y >= this.centerY.pos)
          this.panOffset.y = this.centerY.pos;
        else if (this.panOffset.y <= this.centerY.neg)
          this.panOffset.y = this.centerY.neg;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.map-screen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  margin-top: 56px;
}

.lot_overlay {
  // background-color: rgba(247, 224, 150, 0.6);
  .badge {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: rotateZ(-67deg) translateX(-50%) translateY(-50%);
    transform-origin: top left;
    background: #fff;
    border-radius: 9999px;
    border: 1px solid var(--light-grey);
    padding: 0 5px;

    span {
      padding: 0 5px;
    }
  }
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: none;
}

.car {
  max-width: 70px;
  position: absolute;
  top: 20px;
  left: 20px;
  /* width: 27px; */
  padding: 8px 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.animated {
    transition-property: top, left;
    transition-duration: 60s;
    transition-timing-function: steps(15);
  }

  &.small {
    height: 50px;
  }
}

.car svg {
  position: absolute;
  top: 38px;
  left: 18px;
}
.car img {
  height: 100%;
  z-index: 10;
}

.ev-charger-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  background-size: contain;
  .charger-mini-info {
    display: none;
  }
  &:hover {
    .charger-mini-info {
      display: unset;
    }
  }
}

/* turns header blue when clicking on filter icon */
.search-header > .buttons > .icon.filter-icon:hover {
  fill: #05b5e4;
}
.search-divider {
  height: 80%;
  width: 1px;
  border-top: 0;
  border-left: solid 0.5px #d1d6e0;
  /* color pulled from https://app.onstarvehicleinsights.com/# */
  margin: 0 2px;
}
.search-field {
  font-size: 15px;
  height: auto;
  border: 0;
  outline: none;
  color: var(--grey);
  background: transparent;
}
.search-field::placeholder {
  color: var(--grey);
}
.dropdown {
  width: 320px;
  height: 200px;
  margin-left: 24px;
  border-radius: 0 0 8px 8px;
  transition: opacity 0.2s;
  opacity: 0;
  overflow-y: scroll;
  padding: 0 28px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dropdown::-webkit-scrollbar {
  display: none;
}
.dropdown.visible {
  opacity: 1; // ok to remove
}
.dropdown.filter {
  height: auto;
  padding: 20px 28px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}
// end TODO remove

/* tag filter dropdown styling */
.multi-select-field {
  /* includes the Tags label */
  display: flex;
  flex-wrap: wrap;
  color: #71798a;
}

// TODO: remove when done
.multi-select-field > .field-label {
  width: 100%;
  margin-bottom: 5px;
}
// TODO: remove when done
.multi-select-field > .tag-container {
  flex-grow: 1;
  border: 1px solid var(--light-grey-2);
  border-right: 0;
  background: #fff;
  padding: 5px 15px 5px 0;
  /* if the 15px is too low, the child input pokes out the right side */
}
.multi-select-field.focus-visible > .tag-container {
  border: 1px solid #05b5e4;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;
}

//TODO:  remove when done
.tag-container > input {
  width: 100%;
  border: 0;
  outline: 0;
  color: #171c26;
  padding-left: 0.625rem;
  touch-action: none;
  user-select: text;
  appearance: none;
  font-family: ProximaNova;
  font-size: 15px;
}

// TODO: remove when done
.multi-select-field > .results-popup {
  margin-top: 2px;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
  padding: 0.625rem;
  width: 100%;
}

.selected-tags-container {
  display: flex;
}

.lot-cover {
  width: 459px;
  height: 767px;
  position: absolute;
  transform-origin: top left;
  // top: 40px;
  // left: 150px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, 16%) rotate(61deg);
}

.alert-upgrade {
  z-index: 10;
  h1 {
    font-size: 19px;
    line-height: 1.21;
    font-weight: 600;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 4px;
  }
  p {
    font-size: 15px;
    line-height: 1.23;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
