<template>
  <div class="vertical-bar-chart" :class="{ noYAxis: !yLabel && !yScale }">
    <svg v-if="yLabel || yScale" gml-template="YAxis" class="y-axis chart-axis chart-part left-axis" xmlns="http://www.w3.org/2000/svg"><!-- begin (yaxis.items) -->
      <path class="axis-baseline" d="M58 0 v150" stroke="#C6C6CA" fill="none"></path>
      <text class="axis-label" fill="#76777A" text-anchor="end" dominant-baseline="alphabetic" transform="translate(47, 150)" style="display: block;">
        {{ yScale[0] }}
      </text>
      <text class="axis-label" fill="#76777A" text-anchor="end" dominant-baseline="middle" transform="translate(47, 75)" style="display: block;">
        {{ yScale[1] }}
      </text>
      <text class="axis-label" fill="#76777A" text-anchor="end" dominant-baseline="hanging" transform="translate(47, 0)" style="display: block;">
        {{ yScale[2] }}
      </text>
      <text class="axis-title" fill="#76777A" text-anchor="middle" dominant-baseline="alphabetic" transform="translate(22, 75) rotate(-90)">
        {{ yLabel }}
      </text><!-- end (yaxis.items) -->
    </svg>
    <svg
      gml-template="XAxis"
      class="x-axis chart-axis chart-part bottom-axis"
      xmlns="http://www.w3.org/2000/svg"
      :style="{ width: width + 'px', overflow: width ? 'hidden' : 'visible' }"
    ><!-- begin (xaxis.items) -->
      <path class="axis-baseline" d="M0 0 h385" stroke="#C6C6CA" fill="none"></path>
      <text
        v-for="lbl, i of xBarLabels"
        class="axis-label"
        fill="#76777A"
        text-anchor="end"
        dominant-baseline="central"
        :transform="`translate(${xBarOffsets[i] + ((xBarLabelOffsets && xBarLabelOffsets[i]) ? xBarLabelOffsets[i] : xRotateOffsets)}, ${6 + yRotateOffset}) rotate(${xBarLabelsRotated ? '-45' : '0'})`"
        ref="xbarlbl"
        :key="i"
      >
        {{ lbl }}
      </text>
    </svg>
    <svg gml-template="PlotArea" class="plot-area chart-part cartesian-projection" xmlns="http://www.w3.org/2000/svg"
      style="--chart-xgrid:false; --chart-ygrid:false;"><!-- begin (plotarea.items) --><!-- plotbackground -->
      <g gml-template="PlotBackground" class="plot-background">
        <!-- begin (plotbackground.items) --><!-- end (plotbackground.items) --></g><!-- plotgridlines -->
      <g gml-template="PlotGridlines" class="plot-gridlines">
        <!-- begin (plotgridlines.items) --><!-- end (plotgridlines.items) --></g><!-- barplot -->
      <g gml-template="BarPlot" class="bar-plot chart-plot">
        <!-- begin (barplot.items) -->
        <template v-for="color of Object.keys(values)">
          <line
            v-for="(bar, i) of xBarOffsets"
            :x1="horizontal ? (100 - (values[color][i] * 100)) + '%' : bar"
            :x2="horizontal ? '100%' : bar"
            :y1="horizontal ? bar : (100 - (values[color][i] * 100)) + '%'"
            :y2="horizontal ? bar : '100%'"
            :stroke="color"
            :stroke-width="barWidth"
            :stroke-linecap="(dotted && values[color][i] > 0) ? 'round' : 'butt'"
            :stroke-dasharray="dotted ? '1,8' : ''"
            :style="dotted ? `transform: scaleX(1.1) translateX(${3 - (4 * i)}px) translateY(4px);` : ''"
            :key="i + color"
          />
        </template>
      </g><!-- plotborder -->
    </svg>
    <slot name="legend" />
  </div>
</template>

<script>
export default {
  props: {
    horizontal: Boolean,
    values: Object,
    xBarOffsets: Array[Number],
    xBarLabels: Array[String],
    xBarLabelOffsets: Array[Number],
    xBarLabelsRotated: Boolean,
    yLabel: String,
    yScale: Array[String | Number],
    width: Number,
    barWidth: Number,
    dotted: Boolean,
  },
  computed: {
    xRotateOffsets() {
      if (this.xBarLabelsRotated) return 0;
      return 21;
    },
    yRotateOffset() {
      if (this.xBarLabelsRotated) return 0;
      return 4;
    },
  }
}
</script>

<style lang="scss" scoped>
.vertical-bar-chart {
  display: grid;
  grid-template-columns: 58px 380px;
  grid-template-rows: 150px 60px;
  &.noYAxis {
    grid-template-columns: 0 380px;
  }
}
.y-axis {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
.x-axis {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  width: 380px;
  height: 60px;
  overflow: visible;
}
.axis-label {
  font-size: 12px;
  width: 50px;
  text-align: center;
}
.plot-area {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  width: 380px;
}
</style>