<template>
  <div class="notifications-panel">
    <div class="sticky">
      <div class="sections">
        <div class="section-btn selected">{{ $t('message.notifications') }}</div>
        <div class="section-btn">{{ $t('message.announcements') }}</div>
      </div>
      <div class="gradient-divider" />
    </div>
    <NotificationsList :data="notifications" compact />
    <div class="bottom">
      <svg @click="goToNotificationSettings" id="gfs:icon1/i-settings" viewBox="0 0 24 24">
        <path
          d="M19.392 12.833a7.108 7.108 0 0 0 0-1.666c-.043-.324.072-.65.308-.875l1.058-1.025-2-3.475-1.425.375A1.05 1.05 0 0 1 16.425 6a7.383 7.383 0 0 0-1.467-.833 1.067 1.067 0 0 1-.6-.667L14 3.042h-4L9.642 4.5c-.084.312-.305.57-.6.7-.52.22-1.012.5-1.467.833a1.05 1.05 0 0 1-.908.134l-1.425-.384-2 3.475L4.3 10.283c.239.228.354.557.308.884a7.108 7.108 0 0 0 0 1.666c.043.324-.072.65-.308.875l-1.058 1.034 2 3.475 1.425-.384a1.05 1.05 0 0 1 .908.167c.456.333.948.612 1.467.833.296.126.517.381.6.692L10 20.958h4l.358-1.458c.084-.312.305-.57.6-.7a7 7 0 0 0 1.467-.833 1.05 1.05 0 0 1 .908-.134l1.425.409 2-3.475-1.058-1.025a1.025 1.025 0 0 1-.308-.909zm-1.242-1.541c.026.232.04.466.042.7a6.292 6.292 0 0 1-.042.691 2.308 2.308 0 0 0 .675 1.925l.367.35-1.034 1.775-.483-.133a2.267 2.267 0 0 0-2 .367 5.942 5.942 0 0 1-1.217.708 2.275 2.275 0 0 0-1.308 1.542l-.125.483h-2.05l-.125-.483a2.275 2.275 0 0 0-1.308-1.542A5.942 5.942 0 0 1 8.325 17a2.267 2.267 0 0 0-2-.367l-.483.134-1.034-1.8.367-.35A2.3 2.3 0 0 0 5.85 12.7a6.575 6.575 0 0 1 0-.7 6.292 6.292 0 0 1 0-.692 2.308 2.308 0 0 0-.675-1.925l-.367-.35 1.034-1.775.483.134a2.267 2.267 0 0 0 2-.392c.377-.282.785-.52 1.217-.708A2.275 2.275 0 0 0 10.85 4.75l.125-.483h2.05l.125.483a2.275 2.275 0 0 0 1.35 1.567c.416.183.81.412 1.175.683a2.267 2.267 0 0 0 2 .367l.483-.134 1.034 1.775-.367.35a2.3 2.3 0 0 0-.675 1.942v-.008zM12 8.042a3.775 3.775 0 1 0-.017 7.55A3.775 3.775 0 0 0 12 8.042zm0 6.291a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z">
        </path>
      </svg>
      <div class="view-all" @click="goToAllNotifications">{{ $t("message.viewAll") }}</div>
      <div class="gap" />
    </div>
  </div>
</template>

<script>
import NotificationsList from './NotificationsList.vue';

export default {
  components: { NotificationsList },
  props: {
    notifications: Array[Object],
  },
  methods: {
    goToNotificationSettings() {
      if (this.$route.name !== "notification-settings") {
        this.$router.push({ name: "notification-settings" });
      }
      this.$emit('done');
    },
    goToAllNotifications() {
      if (this.$route.name !== "all-notifications") {
        this.$router.push({ name: "all-notifications" });
      }
      this.$emit('done');
    },
  },
}
</script>

<style lang="scss" scoped>
.notifications-panel {
  position: fixed;
  top: 56px;
  right: 0;
  width: 400px;
  height: calc(100vh - 56px);
  background-color: #fff;
  overflow-y: scroll;
  z-index: 10000;
  box-shadow: 0 5px 5px -3px rgba(26 28 30 / 20%), 0 8px 10px 1px rgba(26 28 30 / 14%), 0 3px 14px 2px rgba(26 28 30 / 12%);
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.sections {
  justify-content: center;
  margin: 0;
  padding: 16px 0;
}

.section-btn.selected {
  color: var(--blue);
  border-bottom-color: var(--blue);
}

.gradient-divider {
  height: 32px;
  min-height: 32px;
  opacity: 0.67;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: var(--blue);
  width: 100%;
  height: 40px;
  border-top: 1px solid var(--light-grey-2);
  position: sticky;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding: 0 16px;
  box-sizing: border-box;
  svg, .gap {
    width: 18.75px;
  }
  .view-all, svg {
    cursor: pointer;
    fill: var(--blue);
    &:hover {
      color: var(--light-blue);
      fill: var(--light-blue);
    }
  }
}
</style>