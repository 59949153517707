<template>
  <div class="modal-view" @click.self="$emit('hide')">
    <div class="modal-content" :style="{ width: width, height: height }">
      <slot :multiplier="multiplier" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    multiplier: Number,
  },
};
</script>

<style scoped>
.modal-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.25);
  backdrop-filter: blur(3px) contrast(0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  touch-action: none;
}
.modal-content {
  max-width: 95%;
  min-height: 100px;
  background-color: var(--white);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>