<template>
  <div class="dropdown" v-on-clickaway="closeDropdown">
    <button class="btn dropdown-toggle" type="button" :aria-expanded="isOpen" @click="toggleDropdown">
      <template v-if="title">{{ title }}</template>
      <img v-else src="@/assets/icons/horizontal-dots.svg?external" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" v-if="isOpen">
      <li v-for="(option, index) in dropdownOptions" :key="index">
        <a
          v-if="type === 'default'"
          href="#"
          :class="{ active: title === option }"
          @click.prevent="selectionClicked(option)"
          >{{ option }}</a
        >
        <div v-else-if="type === 'checklist'" class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            :id="'checkbox' + index"
            @change="checkboxChange($event, option)"
            :checked="selectedDropdownOptions.includes(option)"
          />
          <label class="form-check-label" :for="'checkbox' + index">
            {{ option }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
// import IconEllipses from '@/assets/icons/ellipses_btn.svg';

export default {
  mixins: [clickaway],
  components: {
    // IconEllipses,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
    selectedDropdownOptions: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      isOpen: false,
      // isOpen: true,
    };
  },
  methods: {
    closeDropdown() {
      if (this.isOpen === true) {
        this.isOpen = false;
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$emit('dropdown-toggle-emitter', this.isOpen);
    },
    selectionClicked(option) {
      this.isOpen = false;
      this.$emit('selection-clicked-emitter', option);
      // this.btnText = option;
    },
    checkboxChange(event, option) {
      this.$nextTick(() => {
        // eslint-disable-next-line no-console
        // console.log(event.target.checked, option);
        this.$emit('selection-checkmark-emitter', { option, isChecked: event.target.checked });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown > .btn {
  background-color: transparent;
  border: 1px solid transparent;
  // width: 17rem;
  // background: url('~@/assets/icons/carat.svg?external') right/10% no-repeat;
  // background-color: #fff;
  // background-origin: content-box;
  // border-radius: 6px;
}
.dropdown .dropdown-menu {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  // width: 17rem;
  // display: none;
  min-width: 100px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
  > li > a,
  .form-check {
    display: flex;
    align-items: center;
    // width: 100%;
    padding: 6px 16px;
    clear: both;
    font-weight: 400;
    color: var(--dark);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
      color: var(--dark);
      background-color: var(--clear-blue);
    }
    .form-check-label {
      flex-grow: 1;
      margin-left: 0.25rem; // 4px
    }
  }
}
</style>
