import { render, staticRenderFns } from "./BehaviorEfficiencyView.vue?vue&type=template&id=b23c21ae&scoped=true&"
import script from "./BehaviorEfficiencyView.vue?vue&type=script&lang=js&"
export * from "./BehaviorEfficiencyView.vue?vue&type=script&lang=js&"
import style0 from "./BehaviorEfficiencyView.vue?vue&type=style&index=0&id=b23c21ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b23c21ae",
  null
  
)

export default component.exports