<template>
  <div class="performance">
    <h1 v-if="!isMobile">{{$t('message.performance')}}</h1>
    <div v-else style="margin-top: 20px;" />
    <!-- <ModalView v-show="showCustomizeModal" @hide="showCustomizeModal = false">
      <CustomizeMeasures
        @hide="showCustomizeModal = false"
        @toggleMeasure="handleToggleMeasure"
        :measuresToDisplay="measuresToDisplay"
        :availableMeasures="availableMeasures"
      />
    </ModalView> -->
    <BaseModal class="modal-customize" :show="showCustomizeModal" @closed="showCustomizeModal = false">
      <CustomizeMeasures
        @hide="showCustomizeModal = false"
        @toggleMeasure="handleToggleMeasure"
        :measuresToDisplay="updatedMeasuresToDisplay"
        :availableMeasures="availableMeasures"
      />
    </BaseModal>
    <div class="top-bar">
      <!-- <span v-if="!$store.state.inDealerMode" style="margin-bottom: 24px;">{{$t('message.fleet')}}</span> -->
      <span v-if="!$store.state.inDealerMode">{{ $t('message.fleet') }}</span>
      <DropDown
        v-if="!$store.state.inDealerMode"
        :options="options.map((o) => o.text)"
        :value="selectedFleet.text"
        @input="selectedFleet = options.find((o) => o.text === $event)"
        class="dropdown"
      />
      <div
        class="customize-btn"
        v-if="measuresToDisplay.length"
        @click="showCustomizeModal = true"
      >
        <svg
          id="gfs:icon1/edit"
          width="18px"
          height="18px"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--blue)"
            d="M16.995 3L5.007 14.997 3 21l6.003-1.998L21 7.005 16.995 3zM5.241 18.759l.9-2.637 1.8 1.8-2.7.837zm3.762-1.8l-1.998-1.962 7.992-7.992 1.998 1.998-7.992 7.956zm6.993-10.953l.999-.999 1.998 1.998-.999.999-1.998-1.998z"
          />
        </svg>
        <span>{{$t('message.measureSettings')}}</span>
      </div>
    </div>

    <div class="gradient-divider" />

    <DashboardArea
      class="dashboard-measures"
      :dataset="dataset"
      :measuresToDisplay="measuresToDisplay"
      :activeChallenge="activeChallenge"
      :challenges="challenges"
      :timeLeft="timeLeft"
      :numIncomplete="numIncomplete"
      @challengeComplete="challengeComplete($event)"
      @pauseTimer="pauseTimer()"
      @noMoreChallenges="noMoreChallenges()"
      v-if="measuresToDisplay.length"
    />

    <div class="no-measures flex column center" v-else>
      <img src="@/assets/dashboard-empty.svg?external" />
      <span>Your dashboard is empty</span>
      <div @click="showCustomizeModal = true" class="action-button major">
        Add Measures
      </div>
    </div>

    <GuidedTourWaypoint :id="21" target="gfs:icon1/edit" :xOffset="18" :yOffset="26" />
  </div>
</template>

<script>
import DashboardArea from '@/components/demo/DashboardArea.vue';
// import ModalView from '@/components/demo/ModalView.vue';
import BaseModal from '@/components/BaseModal.vue';
import CustomizeMeasures from '@/components/demo/CustomizeMeasures.vue';
import DropDown from '../../components/demo/DropDown.vue';
import GuidedTourWaypoint from '../../components/demo/GuidedTourWaypoint.vue';
import { mapGetters } from 'vuex';

export default {
  components: { DashboardArea, CustomizeMeasures, DropDown, GuidedTourWaypoint, BaseModal },
  data() {
    return {
      lots: { 1: 'Main Lot', 2: 'Remote Lot', 3: 'Courtesy Lot' },
      availableMeasures: [],
      measuresToDisplay: [],
      fleetMeasures: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      dealerMeasures: [1, 2, 6, 9, 10, 11, 16, 17, 18],
      essentialsMeasures: [5, 6, 11, 12],
      showCustomizeModal: false,
      fleetDropdownOpen: false,
      measuresOld: [ // Need to update above arrays to use ID then can delete this
        {
          img: "measure_vehicle_checkout.svg",
          name: "message.checkedOutVehicles",
          desc: "message.vehiclesCurrentlyCheckedOut",
        },
        {
          img: "measure_vehicle_diagnostics.svg",
          name: "message.diagnosticIssues",
          desc: "message.totalNumberDiagnosticIssues",
        },
        {
          img: "measure_vehicle_statuses.svg",
          name: "message.vehiclesSnapshot",
          desc: "message.currentSnapshotVehicleStatuses",
        },
        {
          img: "measure_vehicle_distance.svg",
          name: "message.distanceDriven",
          desc: "message.totalDistanceDriven",
        },
        {
          img: "measure_vehicle_fuel_economy.svg",
          name: "message.fuelEconomy",
          desc: "message.vehiclesWithHighestLowestFuelEconomy",
        },
        {
          img: "measure_vehicle_fuel_consumption.svg",
          name: "message.fuelConsumption",
          desc: "message.vehiclesFuelConsumption",
        },
        {
          img: "measure_vehicle_oil_life.svg",
          name: "message.lowOilLife",
          desc: "message.vehiclesWithLowestOilLife",
        },
        {
          img: "measure_driver_behavior.svg",
          name: "message.driverBehavior",
          desc: "message.lowestAndHighestPerformingDrivers",
        },
        {
          img: "measure_account_trends.svg",
          name: "message.trends",
          desc: "message.displaysMultipleFleetMetricsOverTime",
        },
        {
          img: "measure_recalls.svg",
          name: "message.recallVehicles",
          desc: "message.totalVehiclesInRecall",
        },
        {
          img: "measure_odometer.svg",
          name: "message.topMileage",
          desc: "message.vehiclesWithHighestOdometerRating",
        },
        {
          img: "measure_fuel_energy.svg",
          name: "message.fuelAndEnergyLevel",
          desc: "An overview of the fuel and energy level",
        },
        {
          img: "measure_engine_hours.svg",
          name: "message.engineHours",
          desc: "Vehicles with the highest engine hours reading",
        },
        {
          img: "measure_energy_consumption.svg",
          name: "message.energyConsumption",
          desc: "Vehicles energy consumed aggregation from the electricity grid",
        },
        {
          img: "measure_expenses_by_type.svg",
          name: "message.expensesByType",
          desc: "Snapshot of expenses by Type",
        },
        {
          img: "measure_expenses_over_time.svg",
          name: "message.expensesOverTime",
          desc: "Expenses over time by category and total",
        },
        {
          img: "measure_vehicle_condition.svg",
          name: "message.vehiclesCondition",
          desc: "Current breakdown of inventory vehicle types (new, used, etc.)",
        },
        {
          img: "measure_days_in_inventory.svg",
          name: "message.daysInInventory",
          desc: "Identify and promote sales according to time on the lot",
        },
        {
          img: "measure_top_vehicle_locations.svg",
          name: "message.topVehicleLocations",
          desc: "The total number of connected inventory vehicles within each Geofence",
        },
      ]
    };
  },
  methods: {
    handleToggleMeasure(number) {
      const index = this.measuresToDisplay.indexOf(number);
      if (index !== -1) {
        // toggle off
        this.measuresToDisplay.splice(index, 1);
      } else {
        // toggle on
        this.measuresToDisplay.push(number);
      }
    },
    challengeComplete(chal) {
      this.$emit('challengeComplete', chal);
    },
    pauseTimer() {
      this.$emit('pauseTimer');
    },
    noMoreChallenges() {
      this.$emit('noMoreChallenges');
    },
  },
  mounted() {
    this.$route.params.title = 'Performance';
    // if (this.activeChallenge === 'monitor-what-matters') {
    //   this.measuresToDisplay = [];
    // }
    if (this.$store.state.inDealerMode) {
      this.availableMeasures = [...this.dealerMeasures];
    } else if (this.$store.state.inEssentialsMode) {
      this.availableMeasures = [...this.essentialsMeasures];
    } else {
      this.availableMeasures = [...this.fleetMeasures];
    }
    // console.log(this.availableMeasures);
    this.measuresToDisplay = [...this.availableMeasures];
    // console.log(this.measuresToDisplay);
  },
  props: {
    dataset: Object,
    isMobile: Boolean,
    challenges: Array,
    activeChallenge: String,
    numIncomplete: Number,
    timeLeft: Number,
  },
  computed: {
    ...mapGetters(['measures']),
    options() {
      return this.$store.inDealerMode ? this.lots : this.$store.state.fleets;
    },
    selectedFleet: {
      get() {
        return this.$store.state.selectedFleet;
      },
      set(value) {
        this.$store.commit('setSelectedFleet', value);
      },
    },
    /* updatedmeasuresToDisplay() {
      return this.measuresOld.map(measureOld => {
        const matchedMeasure = this.measures.find(measure => measure.img === measureOld.img);
        if (matchedMeasure) {
          return { ...measureOld, id: matchedMeasure.id };
        }
        return measureOld;
      });
    } */
   updatedAvailableMeasures() {
    const updatedIndexes = [];
    this.availableMeasures.forEach(index => {
      const measureOld = this.measuresOld[index];
      if (measureOld) {
        const matchedMeasurIndex = this.measures.findIndex(measure => measure.img === measureOld.img);
        if (matchedMeasurIndex) {
          updatedIndexes.push(matchedMeasurIndex);
        }
      } else {
        updatedIndexes.push(index);
      }
    });
    return updatedIndexes;
   },
   updatedMeasuresToDisplay() {
    const updatedIndexes = [];
    this.measuresToDisplay.forEach(index => {
      const measureOld = this.measuresOld[index];
      if (measureOld) {
        const matchedMeasurIndex = this.measures.findIndex(measure => measure.img === measureOld.img);
        if (matchedMeasurIndex) {
          updatedIndexes.push(matchedMeasurIndex);
        }
      } else {
        updatedIndexes.push(index);
      }
    });
    return updatedIndexes;
   }
  },
};
</script>

<style lang="scss" scoped>
.performance {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-measures {
  margin-bottom: 32px;
}
.customize-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 13px;
  font-weight: 600;
  width: 100%;
  color: var(--blue);
  /* margin-top: 6px;
  margin-bottom: 28px; */
}
.customize-btn svg {
  padding-right: 7.5px;
}
.customize-btn svg,
.customize-btn span {
  cursor: pointer;
}
.no-measures {
  padding-top: 120px;
}
.no-measures img {
  margin-bottom: 12px;
}
.no-measures .action-button.major {
  width: 133px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-top: 36px;
}
.top-bar {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.dropdown {
  margin-left: 12px;
  white-space: nowrap;
}

@media screen and (max-width: 980px) {
  .gradient-divider {
    height: 25px;
    min-height: 25px;
  }
}

.modal-customize {
  ::v-deep .content.type--modal {
    max-width: 480px;
    margin-bottom: auto;
    overflow: hidden;
  }
}
</style>
