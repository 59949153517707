<template>
  <div class="drivers">
    <h1 v-if="!isMobile">{{ $t("message.users") }}</h1>
    <div v-else style="margin-top: 24px" />
    <input
      class="search-box"
      :placeholder="$store.state.inDealerMode ? $t('message.nameEmail') : $t('message.userNameEmailFleet')"
      v-model="searchQuery"
      type="text"
    />
    <div class="gradient-divider" />
    <DataTable
      id="driver-table"
      :data="filteredData"
      :columnsToShow="columnsToShow"
      @row-clicked="handleRowClick"
    >
      <template v-slot:image="{ obj }">
        <div class="circle" :class="{purple: obj.Role === 'Driver'}">
          {{ $t("message." + obj.Image) }}
        </div>
      </template>
      <template v-slot:dateAdded>
        <span class="data">{{ today }}</span>
      </template>
    </DataTable>
    <GuidedTourWaypoint :id="14" target="driver-table" :xOffset="-20" :yOffset="-60" />
  </div>
</template>

<script>
import DataTable from "@/components/demo/DataTable.vue";
import moment from "moment";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";

export default {
  components: { DataTable, GuidedTourWaypoint },
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  data() {
    return {
      searchQuery: "",
      columns: [
        {
          translationKey: 'message.image',
          slot: 'image',
          width: 60,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: 'message.name',
          dataKey: 'Name',
          width: 125,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: 'message.status',
          dataKey: 'Status',
          width: 70,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.fleet',
          dataKey: 'Fleet',
          width: 90,
          fleet: true,
          dealer: false,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.role',
          dataKey: 'Role',
          width: 100,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.email',
          dataKey: 'Email',
          width: 200,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.phone',
          dataKey: 'Phone',
          width: 115,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.address',
          dataKey: 'Address',
          width: 125,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.vehicle',
          dataKey: 'Vehicle',
          width: 110,
          fleet: true,
          dealer: false,
          manuallyHidden: false,
        },
        {
          translationKey: 'message.dateAdded',
          slot: 'dateAdded',
          width: 110,
          fleet: true,
          dealer: true,
          manuallyHidden: false,
        },
      ],
    };
  },
  computed: {
    filteredData() {
      let data;
      if (!this.$store.state.inDealerMode) {
        data = this.dataset.users;
      } else {
        data = this.$store.state.dataset.staff;
      }

      if (this.searchQuery) {
        data = data.filter((row) => this.search(row));
      }
      return data;
    },
    columnsToShow() {
      if (this.$store.state.inDealerMode) return this.columns.filter((obj) => obj.dealer);
      return this.columns.filter((obj) => obj.fleet);
    },
    today() {
      return moment().format("MMM D, YYYY");
    },
  },
  methods: {
    search(row) {
      for (let str of Object.values(row)) {
        if (
          str &&
          str
            .toString()
            .toLowerCase()
            .indexOf(this.searchQuery.toLowerCase()) !== -1
        )
          return true;
      }
      return false;
    },
    handleRowClick(user) {
      console.log(user);
      if (user.details?.clickable) {
        this.$router.push("/2/detail/" + user.id);
      }
    },
  },
};
</script>

<style scoped>
.drivers {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle {
  margin-left: 12px;
  background-color: #ee544f;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  line-height: unset;
  font-weight: 700;
}
.purple {
  background-color: #6f166d;
}
</style>
