<template>
  <svg
    class="bullet"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <circle id="c" cx="13" cy="13" r="13" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g stroke-linecap="square" transform="translate(7 7)">
        <use
          :fill="fill"
          stroke="#FFF"
          stroke-width="1.17"
          xlink:href="#c"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000'
    },
  },
};
</script>

<style></style>
