<template>
  <div class="carousel" ref="carousel">
    <!-- <div style="display: inline-block; white-space: nowrap; min-width: 100%; height: 100%;"> -->
    <div class="slide" ref="slide0">
      <div class="photo-group">
        <img class="frame" src="@/assets/camera-guide/frame.png" />
        <img class="camera-img" src="@/assets/camera-guide/photo1.png" />
      </div>
      <h2>Road and Driver Views</h2>
      <div class="light-txt">
        A camera solution can provide a beneficial view of driving events to help protect your assets and coach your drivers
      </div>
    </div>
    <div class="slide" ref="slide1" style="left: 100%">
      <div class="photo-group">
        <img class="frame" src="@/assets/camera-guide/frame.png" />
        <img class="camera-img" src="@/assets/camera-guide/photo2.png" />
      </div>
      <h2>Road and Driver Views</h2>
      <div class="light-txt">
        A camera solution can provide a beneficial view of driving events to help protect your assets and coach your drivers
      </div>
    </div>
    <div class="slide" ref="slide2" style="left: 200%">
      <div class="photo-group">
        <img class="frame" src="@/assets/camera-guide/frame.png" />
        <img class="camera-img" src="@/assets/camera-guide/photo3.png" />
      </div>
      <h2>Road and Driver Views</h2>
      <div class="light-txt">
        A camera solution can provide a beneficial view of driving events to help protect your assets and coach your drivers
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentSlide: Number,
  },
  watch: {
    currentSlide() {
      const { offsetWidth } = this.$refs["slide" + this.currentSlide];
      const scrollLeft = offsetWidth * this.currentSlide;
      requestAnimationFrame(() => {
        this.$refs.carousel.scrollTo({left: scrollLeft, behavior: 'smooth'});
      });
    },
  },
};
</script>

<style scoped>
.carousel {
  overflow: hidden;
  grid-area: carousel;
  position: relative;
  display: grid;
  grid-template-columns: 100% 100% 100%;
}
.slide {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.frame {
  width: 100%;
}
.camera-img {
  height: 200px;
  margin-bottom: 16px;
}
.photo-group {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photo-group svg {
  position: absolute;
}
.photo-group .camera-img {
  position: absolute;
}
.photo-group .lytx-logo {
  position: absolute;
  top: 230px;
}
h2 {
  font-size: 21px;
  font-weight: 700;
  margin: 32px 0 9px 0;
  text-align: center;
}
.light-txt {
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}
</style>