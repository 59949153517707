<template>
  <div class="page">
    <h1>Camera Status</h1>
    <header class="status-header">
      <div class="search-container">
        <input placeholder="Label, Model or VIN" type="text" class="search-box">
      </div>
    </header>
    <div class="page-body">
      <div class="table-header">
        <div class="camera-vehicles">12 results</div>
        <button class="btn-download"><DownloadIcon /></button>
        <BaseIcon class="btn-columns" name="columns" />

      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Camera IMEI</th>
              <th>Vehicle Label</th>
              <th>Make and Model</th>
              <th>VIN</th>
              <th>Status</th>
              <th>Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="camera in cameras" :key="camera.id" @click="goToVehicle(camera)">
              <td>123456789101112</td>
              <td>{{ camera['Vehicle Label'] ? camera['Vehicle Label'] : '--' }}</td>
              <td>{{ camera['Model Name'] ? camera['Model Year'] + ' ' + camera['Model Name'] : '--' }}</td>
              <td>{{ camera['VIN'] }}</td>
              <td><span class="camera-status" :class="camera.cameraStatusClass"></span>{{ camera.cameraStatus }}</td>
              <td>03/01/2022</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import BaseIcon from '@/components/icons/BaseIcon.vue';
// import BasePagination from '@/components/BasePagination.vue';
export default {
  components: {
    DownloadIcon,
    BaseIcon,
    // BasePagination,
  },
  data() {
    return {
      otherCameras: [
        {
          id: 1,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 2,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 3,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 4,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 5,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 6,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 7,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 8,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 9,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Unassigned',
          cameraStatusClass: 'unassigned',
        },
        {
          id: 10,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Last Seen 7+ Days',
          cameraStatusClass: 'lastSeen',
        },
        {
          id: 11,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Last Seen 7+ Days',
          cameraStatusClass: 'lastSeen',
        },
        {
          id: 12,
          VIN: '',
          "Vehicle Label": '',
          cameraStatus: 'Last Seen 7+ Days',
          cameraStatusClass: 'lastSeen',
        },
      ]
    };
  },
  computed: {
    cameras() {
      let data = JSON.parse(JSON.stringify(this.$store.state.dataset.vehicles));
      data.forEach((vehicle) => {
        vehicle.cameraStatus = 'Assigned';
        vehicle.cameraStatusClass = 'assigned';
      })
      data = data.filter((vehicle) => vehicle.details);
      data = data.concat(this.otherCameras);
      return data;
    }
  },
  methods: {
    goToVehicle(camera) {
      if (camera['VIN']) {
        this.$router.push(`/1/detail/${camera.id}`);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  *, *:before, *:after {
    box-sizing: border-box;
  }
}
.status-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .search-container {
    width: 100%;
    .search-box {
      max-width: 525px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3IiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuOTkyMTkgNi45OTk5M0MxLjk5MjE5IDkuNTk4MyA0LjA5ODU4IDExLjcwNDcgNi42OTY5NSAxMS43MDQ3QzcuODI0NjMgMTEuNzA0NyA4Ljg1OTY0IDExLjMwNzkgOS42NzAwMiAxMC42NDY0TDEzLjcyODYgMTQuNzA1QzE0LjEwMDUgMTQuMzMzMSAxNC4xMDA1IDEzLjczMDEgMTMuNzI4NiAxMy4zNTgxTDEwLjM0MzUgOS45NzNDMTEuMDA1IDkuMTYyNjIgMTEuNDAxNyA4LjEyNzYgMTEuNDAxNyA2Ljk5OTkzQzExLjQwMTcgNC40MDE1NiA5LjI5NTMyIDIuMjk1MTcgNi42OTY5NSAyLjI5NTE3QzQuMDk4NTggMi4yOTUxNyAxLjk5MjE5IDQuNDAxNTYgMS45OTIxOSA2Ljk5OTkzWk02LjY5Njk1IDEwLjc1MjNDOC43NjkzMyAxMC43NTIzIDEwLjQ0OTMgOS4wNzIzMSAxMC40NDkzIDYuOTk5OTNDMTAuNDQ5MyA0LjkyNzU1IDguNzY5MzMgMy4yNDc1NSA2LjY5Njk1IDMuMjQ3NTVDNC42MjQ1NyAzLjI0NzU1IDIuOTQ0NTcgNC45Mjc1NSAyLjk0NDU3IDYuOTk5OTNDMi45NDQ1NyA5LjA3MjMxIDQuNjI0NTcgMTAuNzUyMyA2LjY5Njk1IDEwLjc1MjNaIiBmaWxsPSIjNzE3OThBIi8+Cjwvc3ZnPg==");
      background-position: right 6px center;
      background-repeat: no-repeat;
    }
  }
}
.page-body {
  width: 100%;
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 50px);
  padding-top: 50px;
}
.table-header {
  display: flex;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  .camera-vehicles {
    color: var(--dark-grey);
    font-size: 14px;
  }
  .btn-download {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: auto;
    svg {
      width: 24px;
      fill: var(--blue);
    }
  }
  .btn-columns {
    width: 20px;
    height: 20px;
    fill: var(--blue);
    margin-top: 3px;
    margin-left: 8px;
    margin-right: 8px;
  }
}
.table-responsive {
  overflow: scroll;
  max-height: 485px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}
table.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #C1C8D6;
  thead {
    background: #EDF9FF;
    th {
      padding: 12px 8px;
      font-size: 12px;
      font-weight: 600;
      color: #71798A;
      text-align: left;
      border-bottom: 1px solid #C1C8D6;
      &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 8px;
        font-size: 15px;
        color: #333;
        white-space: nowrap;
        border-bottom: 1px solid #C1C8D6;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
        a {
          color: #171C26;
          text-decoration: none;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
}
.camera-status {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 50%;
  margin-right: 8px;
  &.assigned {
    background-color: var(--green);
  }
  &.unassigned {
    background-color: #FFAB40;
  }
  &.lastSeen {
    background-color: var(--red);
  }
}
</style>