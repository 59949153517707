<template>
  <div
    :class="{
      Tag: true,
      'Tag--contained': contained && !selected,
      'Tag--rounded': rounded,
    }"
    @click="setTags(tag)"
  >
    <BulletIcon
      v-if="tag.color || (color && color !== '')"
      :fill="tag.color || color"
      class="Tag__icon"
    />
    <svg v-else-if="checked" class="checkbox" id="gml:icon1/checkbox-checked" viewBox="0 0 24 24">
      <path
        d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z">
      </path>
    </svg>
    <svg v-else-if="!rounded" class="checkbox" id="gml:icon1/checkbox-unchecked" viewBox="0 0 24 24">
      <path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"></path>
    </svg>
    <div v-else class="Tag__icon__pad" />
    <span class="Tag__text">{{ translate(tag.text) }}</span>
    <CloseIcon v-if="x" class="Tag__icon x" />
    <div v-else class="Tag__icon__pad" />
  </div>
</template>

<script>
import BulletIcon from '@/components/demo/BulletIcon.vue';
import CloseIcon from '@/assets/close.svg';
import { camelize } from "../../utils/camelize";

export default {
  components: {
    BulletIcon,
    CloseIcon,
  },
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    contained: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object, // MUST have text property
      required: true,
    },
    x: {
      // show close icon
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      camelize: camelize,
    }
  },
  methods: {
    setTags(tag) {
      this.$store.commit('setSelectedTags', tag);
    },
    translate(str) {
      if (this.$te(`message.${camelize(str)}`)) return this.$t(`message.${camelize(str)}`);
      return str;
    },
  },
  computed: {
    selected() {
      // https://stackoverflow.com/questions/59405314/how-to-destructure-an-array-with-object-that-has-space-in-its-keys
      let {
        Color: color,
        ['Days in Inventory']: days,
      } = this.$store.state.dealerMode.selected;

      return color.includes(this.tag.text) || days.includes(this.tag.text);
    },
  },
};
</script>

<style lang="scss" scoped>
.Tag {
  width: fit-content;
  display: flex;
  align-items: center;
  // background: var(--pale-grey);
  border-radius: 0.25rem;
  max-height: 20px;
  margin: 0 8px 0 0;
  padding: 0 4px 0 2px;
  color: #606164;
  &--contained {
    background: none;
    border: 1px solid var(--pale-grey);
  }
  &--rounded {
    border-radius: 25px;
    padding: 0px;
    background-color: #1a1c1e0d;
    font-size: 11px;
    color: #606164;
    transform: scale(1.1);
  }
  &__icon {
    margin-right: 5px;
    margin-left: 5px;
    height: 0.75rem;
    width: 0.75rem;
    &__pad {
      margin-right: 8px;
    }
  }
  &__text {
    margin-bottom: 1px;
  }
}
.checkbox {
  width: 20px;
  margin-right: 8px;
  fill: #76777a;
}
</style>
