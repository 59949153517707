import fleets from './json/fleets.json';
import vehicles from './json/vehicles.json';
import staff from './json/staff.json';
import dealerModeVehicles from './json/dealerModeVehicles.json';
import users from './json/users.json';
import drivers from './json/drivers.json';
import managers from './json/managers.json';
import tripHistory from './json/tripHistory.json';
import { notificationEvents, notifications } from './json/notifications.json';
import overview from './json/overview.json';
import dashboard from './json/dashboard.json';
import evChargers from './json/evChargers.json';
import expenses from './json/expenses.json';
import recalls from './json/recalls.json';
export default {
    fleets,
    vehicles,
    staff,
    dealerModeVehicles,
    users,
    drivers,
    managers,
    tripHistory,
    notificationEvents,
    notifications,
    overview,
    dashboard,
    evChargers,
    expenses,
    recalls,
};