<template>
  <nav class="sidebar" :class="{hideSidebar}">
    <div class="btn-collapse-container">
      <button class="btn-collapse" @click="toggleSidebar"><IconDoubleChevon /></button>
    </div>
    <ul class="nav-top">
      <li class="nav-item"><a href="#" class="active"><IconDashboard /> The Hub</a></li>
      <li class="nav-item"><a href="#"><IconFleetManagement /> Fleet Management</a></li>
      <li class="nav-item"><router-link to="tour"><IconVehicleInsights /> Vehicle Insights</router-link></li>
      <li class="nav-item"><a href="#"><IconPurchaseOffers /> Purchase Offers</a></li>
    </ul>
    <ul class="nav-bottom">
      <li class="nav-item"><a href="#"><IconNotifications /> Notifications&nbsp;<span class="count">9+</span></a></li>
      <li class="nav-item"><a href="#"><IconAccountSettings /> Account Settings</a></li>
      <li class="nav-item"><a href="#"><IconSupport /> Support</a></li>
      <li class="nav-item"><a href="#"><IconLegalTerms /> Legal Terms</a></li>
    </ul>
  </nav>
</template>

<script>
import IconDoubleChevon from '@/components/icons/myba/IconDoubleChevon.vue';
import IconDashboard from '@/components/icons/myba/IconDashboard.vue';
import IconFleetManagement from '@/components/icons/myba/IconFleetManagement.vue';
import IconVehicleInsights from '@/components/icons/myba/IconVehicleInsights.vue';
import IconPurchaseOffers from '@/components/icons/myba/IconPurchaseOffers.vue';
import IconNotifications from '@/components/icons/myba/IconNotifications.vue';
import IconAccountSettings from '@/components/icons/myba/IconAccountSettings.vue';
import IconSupport from '@/components/icons/myba/IconSupport.vue';
import IconLegalTerms from '@/components/icons/myba/IconLegalTerms.vue';

export default {
  data() {
    return {
    };
  },
  methods: {
    test() {
      console.log('test');
    },
    toggleSidebar() {
      console.log('toggleSidebar');
    }
  },
  components: {
    IconDoubleChevon,
    IconDashboard,
    IconFleetManagement,
    IconVehicleInsights,
    IconPurchaseOffers,
    IconNotifications,
    IconAccountSettings,
    IconSupport,
    IconLegalTerms,
  },
  props: {
    hideSidebar: Boolean,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';

.sidebar {
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
.sidebar {
  // position: fixed;
  display: flex;
  flex-direction: column;
  // flex-shrink: 0;
  width: 280px;
  min-width: 280px;
  position: sticky;
  top: $topbar-height;
  height: calc(100vh - $topbar-height);
  transition: transform 0.25s ease-in;
  background-color: #FAFAFA;
  &.hideSidebar {
    min-width: unset;
    transform: translateX(-180px);

  }
  .btn-collapse-container {
    padding: 12px;
    text-align: right;
    .btn-collapse {
      padding: 8px;
      border: 0;
      background-color: transparent;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .nav-item {
    list-style: none;
    a {
      display: flex;
      align-items: center;
      padding: 18px 32px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.4;
      svg {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
    .count {
      display: inline-block;
      background-color: $danger;
      color: $white;
      padding: 2px;
      border-radius: 50%;
      font-size: 10px;
      width: 18px;
      height: 18px;
      text-align: center;
      margin-left: auto;
    }
  }
  .nav-top, .nav-bottom {
    padding: 0;
    margin: 0;
  }
  .nav-top .nav-item a {
    background-color: $poseidon-blue;
    color: white;
    &.active {
      background-color: $pacific-blue;
    }
  }
  .nav-bottom {
    margin-top: auto;
    margin-bottom: 12px;
    .nav-item a {
      color: $accent-blue;
    }
  }
  .icon {
    transform: scale(1.4);
    margin: 20px 10px 20px auto;
  }
  .sidebar-options {
    width: 100%;
    &.top {
      .option {
        background-color: #11365F;
        color: white;
        &.active {
          background-color: #00478C;
        }
      }
    }
    &.bottom {
      margin: auto 0 50px 0;
      .option {
        color: #75787A;
      }
    }
    .option {
      width: calc(100% - 30px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      .label {
        margin-left: 10px;
        font-family: Overpass;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>