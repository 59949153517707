// Get additional data for markers/dots
export function getMarker(marker) {
  // Type 0 is a start point
  if (marker.type === 1) {
    return getHardBreak(marker);
  }
  if (marker.type === 2) {
    return getHardAcceleration(marker);
  }
  // Type 3 is an endpoint
  // Type 4 is a trip snapshot
  // Type 5 is a non event (like a turn I think)
  // Type 6 is a camera event
  if (marker.type === 6 && marker.data) {
    // Camera events have subtypes
    if (marker.data.subtype === 0) {
      return getFoodAndDrink(marker);
    }
    if (marker.data.subtype === 1) {
      return getCellphoneUse(marker);
    }
    if (marker.data.subtype === 2) {
      return getHardBreak(marker);
    }
    if (marker.data.subtype === 3) {
      return getHardAcceleration(marker);
    }
  }
  // Type 7 is an orange donut
  // Type 8 is a purple donut
  // Type 9 is a multiIcon / stacked marker
  if (marker.type == 9) {
    // MultiIcons have a subtypes array that matches the actual types
    // Until I come up with a better idea, skip and loop on original call
    return marker;
  }
  // Type 10 is excessive idling
  if (marker.type === 10) {
    return getExcessiveIdling(marker);
  }
  // Type 11 is Speeding
  if (marker.type === 11) {
    return getSpeeding(marker);
  }
  // Type 12 is Fixed Speed
  if (marker.type === 12) {
    return getFixedSpeed(marker);
  }
  // Type 13 is ADAS Forward Automatic Braking
  if (marker.type === 13) {
    return getADASForwardAutomaticBraking(marker);
  }
  return null;
}

// Marker type 1 = Hard Break
function getHardBreak(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Hard Break';
  updatedMarker.icon = 'hardBrake';
  return updatedMarker;
}

// Marker type 2 = Hard Acceleration
function getHardAcceleration(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Hard Acceleration';
  updatedMarker.icon = 'hardAcceleration';
  return updatedMarker;
}

// Marker subtype 0 = Food and Drink
function getFoodAndDrink(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Food and Drink';
  updatedMarker.icon = 'video';
  return updatedMarker;
}

// Marker subtype 1 = Cellphone Use
function getCellphoneUse(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Cellphone Use';
  updatedMarker.icon = 'video';
  return updatedMarker;
}

// Marker type 10 = Excessive Idling
function getExcessiveIdling(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Excessive Idling';
  updatedMarker.icon = 'idling';
  return updatedMarker;
}

// Marker type 11 = Speeding
function getSpeeding(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Road Speed';
  updatedMarker.icon = 'speeding';
  return updatedMarker;
}

// Marker type 12 = Fixed Speed
function getFixedSpeed(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'Fixed Speed';
  updatedMarker.icon = 'fixedSpeed';
  return updatedMarker;
}

// Marker type 13 = ADAS Forward Automatic Braking
function getADASForwardAutomaticBraking(marker) {
  const updatedMarker = marker;
  updatedMarker.label = 'ADAS Forward Automatic Braking';
  updatedMarker.icon = 'adasSystem';
  return updatedMarker;
}

/* Guide
if (this.dot.type === 1) {
        return this.$t("message.hardBrake");
      } else if (this.dot.type === 2) {
        return this.$t("message.hardAcceleration");
      } else if (this.dot.type === 6) {
        if (this.dot.data.subtype === 0) {
          return this.$t("message.foodAndDrink");
        } else if (this.dot.data.subtype === 1) {
          return this.$t("message.cellphoneUse");
        } else if (this.dot.data.subtype === 2) {
          return this.$t("message.hardBrake");
        } else {
          return this.$t("message.hardAcceleration");
        }
      } else if (this.dot.type === 10) {
        return 'Excessive Idling';
      } else {
        return this.$t("message.tripSnapshot");
      }
        */