<template>
  <div class="video-player show-controls">
    <div class="close-icon-container icon flex">
      <CloseIcon @click="$emit('close')" style="cursor: pointer" />
    </div>
    <video
      ref="video"
      :src="src"
      autoplay
      controls
      @loadedmetadata="videoLoaded"
      @ended="isPlaying = false"
      @timeupdate="getVideoPlaybackTime"
    />
    <!-- <Icon :icon="isPlaying ? icons.pause : icons.play" @click.native="togglePlay" class="play-btn" type="mdi" :width="20" :height="20" />
    <span class="playback-time">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
    <progress :value="playbackPercentage" max="100" /> -->
  </div>
</template>

<script>
import CloseIcon from "@/assets/close.svg";
// import { Icon } from '@iconify/vue2';
import playIcon from '@iconify/icons-mdi/play';
import pauseIcon from '@iconify/icons-mdi/pause';

export default {
  components: {
    CloseIcon,
    // Icon,
  },
  props: {
    src: String,
  },
  data() {
    return {
      isPlaying: true,
      currentTime: 0,
      duration: 0,
      icons: {
        play: playIcon,
        pause: pauseIcon,
      },
    };
  },
  computed: {
    playbackPercentage() {
      return this.currentTime / this.duration * 100;
    },
  },
  methods: {
    videoLoaded() {
      this.duration = this.$refs.video.duration;
    },
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.video.pause();
        this.isPlaying = false;
      } else {
        this.$refs.video.play();
        this.isPlaying = true;
      }
    },
    getVideoPlaybackTime() {
      if (this.$refs.video) this.currentTime = this.$refs.video.currentTime;
    },
    formatTime(num) {
      const sec_num = parseInt(num, 10); // don't forget the second param
      let minutes = Math.floor(sec_num / 60);
      let seconds = sec_num - (minutes * 60);
      if (minutes < 10) {minutes = "0" + minutes;}
      if (seconds < 10) {seconds = "0" + seconds;}
      return `${minutes}:${seconds}`;
    }
  },
}
</script>

<style lang="scss" scoped>
.close-icon-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}
.video-player {
  --opacity-transition: opacity 0.25s linear;
  margin: -15px -15px 10px -15px;
  border-radius: 0.25rem 0.25rem 0 0;
  position: relative;
  overflow: hidden;
  height: 240px;
  aspect-ratio: 16 / 9;
  video {
    height: 100%;
    aspect-ratio: 16 / 9;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .close-icon-container {
    position: absolute;
    top: 0;
    z-index: 10;
    svg {
      padding: 8px;
      path {
        fill: #fff;
      }
    }
  }
  // &::before,
  // &::after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   left: 0px;
  //   z-index: 1;
  //   opacity: 0;
  //   transition: var(--opacity-transition);
  // }
  // &::before {
  //   top: 0px;
  //   box-shadow: 0 12px 32px 12px #000;
  // }
  // &::after {
  //   bottom: 0px;
  //   box-shadow: 0 -20px 50px 32px #000;
  // }
  .play-btn {
    position: absolute;
    bottom: 19px;
    left: 12px;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
    transition: var(--opacity-transition);
    ::v-deep path {
      fill: #fff;
    }
  }
  .playback-time {
    position: absolute;
    bottom: 21px;
    left: 38px;
    z-index: 10;
    opacity: 0;
    font-size: 12px;
    color: white;
    transition: var(--opacity-transition);
  }
  progress {
    position: absolute;
    bottom: 10px;
    left: 0px;
    margin: 0 16px;
    width: calc(100% - 32px);
    height: 4px;
    --border-radius: 4px;
    border-radius: var(--border-radius);
    z-index: 10;
    appearance: none;
    opacity: 0;
    transition: var(--opacity-transition);
    &::-webkit-progress-bar {
      background-color: #7e889a;
      border-radius: var(--border-radius);
    }
    &::-webkit-progress-value {
      background-color: #fff;
      border-radius: var(--border-radius);
    }
  }
  &:hover {
    .close-icon-container,
    .play-btn,
    .playback-time,
    progress,
    &::before,
    &::after {
      opacity: 1;
    }
  }
}
</style>