<template>
  <div class="expenses">
    <h1 v-if="!isMobile">{{ $t('message.recallVehicles') }}</h1>
    <div v-else style="margin-top: 20px;" />
    <div class="search-area">
      <input
        class="search-box"
        placeholder="Label, Model, Type or VIN"
        type="text"
      />
      <SearchIcon class="search-icon" />
    </div>
    <div class="gradient-divider" />
    <DataTable
      :data="dataset.recalls"
      :columnsToShow="columnsToShow"
      :upperIconsToShow="['download']"
      @row-clicked="handleRowClick"
    >
      <template v-slot:type="{ obj }">
        <span class="data status">
          <div class="connected-dot" style="background-color: rgb(177, 174, 173);" />
          {{ $t(`message.${camelize(obj.type)}`) }}
        </span>
      </template>
    </DataTable>
    <ModalForm
      :header="currentExpenseLabel"
      :fields="newExpenseModalFields"
      :data="selectedRow"
      :button1="Object.keys(selectedRow).length ? 'Delete Expense' : ''"
      button2="Save"
      :maxColumns="3"
      class="modal-form"
    >
      <template v-slot:upload>
        <div class="upload-area">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>image</title>
            <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>
          {{ $t('message.uploadImage') }}
        </div>
      </template>
      <template v-slot>
        <div class="created-by">
          <div class="left">
            <div>{{ $t('message.createdBy') }}</div>
            <div class="created-by-info">
              <svg id="gml:icon1/clock-picker" viewBox="0 0 24 24">
                <path d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"></path>
              </svg>
              <span>Jan 28, 2023</span>
              <img src="@/assets/systemExpense.svg?external" />
              <span>{{ $t('message.system') }}</span>
            </div>
          </div>
          <b>{{ selectedRow && selectedRow.type ? $t(`message.${camelize(selectedRow.type)}`) : '' }} {{ $t('message.event') }}</b>
        </div>
      </template>
    </ModalForm>
    <!-- <div class="main">
      <img src="@/assets/expenses.svg?external" />
      <p>{{ $t('message.noResultsWereFound') }}</p>
      <div class="action-button">{{ $t('message.addExpenses') }}</div>
      <div class="action-button minor">
        <svg id="gfs:icon1/upload-file" viewBox="0 0 24 24">
          <path
            d="M13.988 2.249h.043l.298.001 2.259 2.277L20.53 8.47c.048.048.088.099.12.153l.1.102.001.244v.03L20.75 21.75H14v-1.5h5.25V9.75H14c-.38 0-.693-.282-.743-.648L13.25 9l-.001-5.25H6c-.118 0-.217.082-.243.193L5.75 4v16.25H9.5v1.5H4.25V4c0-.918.707-1.671 1.606-1.744L6 2.25zm-2.204 8.64l3.787 3.788c-.586.586-1.535.586-2.121 0l-.95-.95v8.023H11v-7.957l-.879.88c-.585.586-1.535.586-2.121 0l3.784-3.783zm2.966-6.078V8.25h3.417l-2.615-2.637-.802-.802z">
          </path>
        </svg>
        {{ $t('message.addExpensesFromFile') }}
      </div>
    </div> -->
  </div>
</template>

<script>
import DataTable from '@/components/demo/DataTable.vue';
import ModalForm from '../../components/demo/ModalForm.vue';
import SearchIcon from "@/assets/search-icon.svg";

import { camelize } from '../../utils/camelize';

export default {
  components: {
    DataTable,
    ModalForm,
    SearchIcon,
},
  props: {
    dataset: Object,
    isMobile: Boolean,
  },
  data() {
    return {
      camelize,
      columnsToShow: [
        {
          translationKey: "message.vin",
          dataKey: "vin",
          width: 200,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: "message.vehicleLabel",
          dataKey: "label",
          width: 100,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: "message.makeAndModel",
          dataKey: "makeModel",
          width: 150,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: "message.dateIssued",
          dataKey: "date",
          width: 100,
          fleet: true,
          dealer: true,
        },
        {
          translationKey: "message.type",
          slot: "type",
          width: 300,
          fleet: true,
          dealer: true,
        },
      ],
      selectedRow: {},
      newExpenseModalFields: [
        {
          label: '',
          slot: 'upload',
          wide: true,
        },
        {
          label: 'Expense Type',
          type: 'text',
          placeholder: 'Select',
          dataKey: 'type',
          required: true,
          disabled: true,
        },
        {
          label: 'Merchant',
          type: 'text',
          placeholder: 'Merchant name',
          dataKey: 'merchant',
        },
        {
          label: 'Amount',
          type: 'text',
          placeholder: '$  00.00',
          dataKey: 'amount',
          required: true,
        },
        {
          label: 'Date',
          type: 'date',
          placeholder: new Date().toLocaleDateString('en-US'),
          dataKey: 'dateRaw',
          required: true,
          disabled: true,
        },
        {
          label: 'Fleet',
          type: 'search',
          placeholder: 'Search...',
          dataKey: 'fleet',
          required: true,
        },
        {
          label: '',
          slot: 'none',
        },
        {
          label: 'Vehicle',
          type: 'search',
          placeholder: 'Search VIN...',
          dataKey: 'vehicle',
          disabled: true,
        },
        {
          label: 'Driver',
          type: 'search',
          placeholder: 'Search...',
          dataKey: 'driver',
        },
        {
          label: 'Description',
          type: 'bigtext',
          wide: true,
        },
      ],
    };
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    },
    currentExpenseLabel() {
      const type = this.selectedRow.type || '';
      const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
      const date = new Date(`${this.selectedRow?.dateRaw}T09:00:00.000-04:00`);
      if (date instanceof Date && !isNaN(date)) {
        const dateStr = date.toLocaleDateString('en-US', {
          year: 'numeric',
          day: '2-digit',
          month: 'short',
        });
        return `${typeCapitalized} ${this.$t('message.expense')} | ${dateStr}`;
      }
      return 'New Expense';
    }
  },
  methods: {
    handleRowClick(obj) {
      this.selectedRow = obj;
      this.$store.commit('toggleModal');
    },
  },
  watch: {
    showModal() {
      if (!this.showModal) this.selectedRow = {};
    },  
  },
}
</script>

<style lang="scss" scoped>
.expenses {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  p {
    color: #606164;
    margin-bottom: 32px;
  }
}

.action-button {
  width: 218px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  // margin-left: 24px;
  // margin-right: 4px;
  margin-bottom: 16px;
  padding: 0 8px;
}

.action-button.minor {
  background-color: var(--white);
  border-color: var(--light-grey);
  color: var(--blue);
  // width: 106px;
  // margin-right: 0;
  svg {
    width: 18px;
    fill: var(--blue);
    margin-right: 8px;
  }
}
.no-image {
  height: 40px !important;
}

.upload-area {
  background-color: rgba(105, 205, 255, 0.12);
  // height: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--blue);
  svg {
    width: 18px;
    margin-right: 8px;
    path {
      fill: var(--blue);
    }
  }
}

.modal-form {
  ::v-deep button {
    width: 140px;
    padding: 6px 0;
  }
  ::v-deep button.button1 {
    color: var(--red);
    border-color: var(--red);
    margin-right: auto;
  }
}

.created-by {
  margin-top: -15px;
  padding: 0 30px 15px 30px;
  color: #76777a;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .created-by-info {
    margin-top: 4px;
    display: flex;
    gap: 4px;
    svg {
      width: 16px;
      path {
        fill: #76777a;
      }
    }
    img {
      margin-left: 4px;
    }
  }
  b {
    color: var(--blue);
    font-size: 15px;
    font-weight: 600;
  }
}
.search-area {
  position: relative;
  width: 400px;
  .search-box {
    box-sizing: border-box;
    width: 100%;
    max-width: unset;
  }
  .search-icon {
    position: absolute;
    top: 5px;
    right: 8px;
    width: 18px;
    fill: #9d9da1;
  }
  button {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
    height: 26px;
    white-space: nowrap;
    background-color: white;
    border: 1px solid rgba(0, 28, 56, 0.24);
    border-radius: 4px;
    color: #05b5e4;
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: ProximaNova;
    font-weight: 600;
  }
  svg {
    width: 18px;
    fill: #05b5e4;
  }
}

</style>