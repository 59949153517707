export function camelize(str) {
  if (!str || str === '') {
    return '';
  }
  if (typeof str !== 'string') str = str.toString();
  return str.replace('-', ' ')
  .replace('/', ' ')
  .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  })
  .replace(/\s+/g, '')
}
