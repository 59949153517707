<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 13">
    <circle cx="6" cy="6.5" r="4.5" fill="#fff" :stroke="color" stroke-width="3"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#171C26'
    }
  }
}
</script>