<template>
  <div class="map-right-click" :style="{ top: `${y}px`, left: `${xWithOffset}px` }">
    <BaseIcon @click="goToGeofencePage" name="geofence" />
    <span @click="goToGeofencePage">Create Geofence</span>
    <BaseIcon @click="goToNearestVehicles" name="vehicle" />
    <span @click="goToNearestVehicles">Find Nearest Vehicles</span>
    <BaseIcon name="map" class="disabled" />
    <span class="disabled">Get Address</span>
  </div>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    x: Number,
    y: Number,
  },
  computed: {
    xWithOffset() {
      let x = this.x + 50;
      if (x < 230) x += 230;
      return x;
    },
  },
  methods: {
    goToGeofencePage() {
      this.$emit('done');
      this.$router.push({
        name: "set-notification",
        params: { type: "8", title: this.$t('message.geofence'), editing: false },
      });
    },
    goToNearestVehicles() {
      this.$emit('done');
      this.$store.commit('setActiveFilterTab', 0);
      setTimeout(() => {
        this.$store.commit('openMapFilterDropdown');
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-right-click {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  display: grid;
  align-items: center;
  grid-template-rows: 30px 30px 30px;
  grid-template-columns: 18px 150px;
  gap: 0 8px;
  padding: 8px 20px;
  background-color: white;
  color: #606164;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(26 28 30 / 20%),
    0 16px 24px 2px rgba(26 28 30 / 14%), 0 6px 30px 5px rgba(26 28 30 / 12%);
  svg {
    fill: #606164;
  }
  span {
    transition: color 0.25s ease;
    &:hover:not(.disabled) {
      color: #05b5e4;
    }
  }
  svg, span {
    cursor: pointer;
    &.disabled {
      cursor: unset;
    }
  }
}
</style>
