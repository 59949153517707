<template>
  <div
    class="odo-wrap"
    :class="{ xs: xs, 'height-full': $route.name.includes('map-screen') }"
  >
    <div class="spacer" v-if="$route.name.includes('map-screen')"></div>
    <div class="flex-grow">
      <div class="odo" :class="{ small: small || xs, xs: xs }">
        <span>{{ valueArr[0] }}</span>
        <span>{{ valueArr[1] }}</span>
        <span>{{ valueArr[2] }}</span>
        <span>{{ valueArr[3] }}</span>
        <span>{{ valueArr[4] }}</span>
        <span>{{ valueArr[5] }}</span>
      </div>
    </div>
    <span class="odo-lbl" v-if="xs">{{ $t("message.mi") }}</span>
    <span class="odo-lbl" v-else :style="small ? 'margin-top: 16px;' : ''"
      >{{ $t("message.odometer") }} ({{ $t("message.mi") }})</span
    >
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    small: Boolean,
    xs: Boolean,
  },
  computed: {
    valueArr() {
      let arr = ("" + this.value).split("");
      while (arr.length < 6) {
        arr.unshift("0");
      }
      return arr;
    },
  },
};
</script>

<style scoped>
.odo-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.odo-wrap.xs {
  flex-direction: row;
  /* align-items: unset; */
}
.odo {
  width: 177px;
  height: 28px;
  border-radius: 28px;
  border: 2px solid var(--light-grey-2);
  background-color: var(--light-grey-2);
  padding: 0;
  display: grid;
  grid-template: 28px / repeat(6, 28.68px);
  grid-gap: 1px;
}
.odo.small {
  width: 140px;
  height: 23px;
  border-radius: 23px;
  grid-template: 23px / repeat(6, 22.5px);
  border-width: 1px;
}
.odo.xs {
  width: 128px;
  height: 20px;
  border-radius: 20px;
  grid-template: 20px / repeat(6, 20.5px);
  font-weight: bold;
}
.odo span {
  background-color: var(--white);
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 27px;
  font-weight: 100;
}
.odo.small span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.odo.xs span {
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
  color: #606164;
}
.odo span:first-child {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}
.odo span:last-child {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.odo-lbl {
  font-size: 13px;
  color: var(--dark-grey);
  margin-top: 6px;
}
.odo-wrap.xs .odo-lbl {
  margin-top: 0;
  margin-left: 6px;
}
.flex-grow {
  flex-grow: 1;
}
.spacer {
  height: 40%;
}
</style>
