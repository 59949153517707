<template>
  <div
    :class="{ Dropdown: true, expanded: isExpanded }"
    @click="isExpanded = !isExpanded"
  >
    <div :class="{ Dropdown__top: true, expanded: isExpanded }">
      <!-- <img
        class="Dropdown__arrow"
        src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png"
      /> -->
      <div class="Dropdown__arrow">
        <DownCaret class="arrow-icon" />
      </div>
      <div class="Dropdown__current-option">{{ selectedFleet.text }}</div>
    </div>

    <div
      :class="{ Dropdown__options: true, expanded: isExpanded }"
      v-if="isExpanded"
    >
      <div
        v-for="option in options"
        class="Dropdown__option"
        :key="option.value"
        @click="selectedFleet = option"
        tabindex="0"
        @focus="isExpanded = true"
        @focusout="isExpanded = false"
      >
        <span>{{ option.text }}</span>
        <!-- <img
          class="Dropdown__checkmark"
          v-if="option.value === selectedFleet.value"
          src="https://img.icons8.com/android/24/4a90e2/checkmark.png"
        /> -->
        <div
          class="Dropdown__checkmark"
          v-if="option.value === selectedFleet.value"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 8.25">
            <title>check</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  fill="var(--light-blue)"
                  d="M2.92,8.23a.18.18,0,0,1-.09-.06L.08,5.42A.27.27,0,0,1,0,5.23.3.3,0,0,1,.08,5,.27.27,0,0,1,.27,5,.3.3,0,0,1,.47,5L3,7.59,10.53.08a.3.3,0,0,1,.2-.08.27.27,0,0,1,.19.08.28.28,0,0,1,.08.2.27.27,0,0,1-.08.19l-7.7,7.7-.09.06L3,8.25Z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownCaret from "@/assets/icons/down_caret.svg";

export default {
  name: 'Dropdown',
  components: {
    DownCaret,
  },
  data() {
    return {
      isExpanded: false,
      selected: { text: 'OSVI Industries', value: 'OSVI Industries' },
    };
  },
  computed: {
    options() {
      return this.$store.state.fleets;
    },
    selectedFleet: {
      get() {
        return this.$store.state.selectedFleet;
      },
      set(value) {
        this.$store.commit('setSelectedFleet', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// background: url()
// background: url('/assets/icons/down_caret.svg') no-repeat left; // I cannot get this asset to load for the life of me
.Dropdown {
  width: 26%;
  height: 100%;
  min-width: 100px;
  &.expanded {
    height: auto;
    overflow-y: visible;
  }
}

.Dropdown__top {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  height: 100%;
  gap: 4px;

  .Dropdown__arrow {
    width: 0.8rem;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-icon {
      width: 100%;
      fill: #76777a;
    }
  }
  .Dropdown__current-option {
    padding-left: 2%;
    white-space: nowrap;
  }

  &.expanded {
    background: #edf9ff;
    margin-top: 90px;
    height: 35px;
    .Dropdown__arrow {
      transform: rotate(180deg);
    }
  }
}

.Dropdown__options {
  // border: 1px solid red;
  margin-top: 10px;
  padding: 5px 2%;
  border-radius: 5px;
  background: white;

  &.expanded {
    // horizontal length, vertical length, blur radius, spread radius, color
    -webkit-box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 1);
    -moz-box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 1);
    box-shadow: 1px 1px 4px 1px rgba(110, 110, 110, 1);
  }

  .Dropdown__option {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    // the text overflows beyond the container, and I can't get the container to expand. this makes it look like it's expanding anyway.
    // padding: 0 5%;
    &:first-child {
      border-top-right-radius: 5px;
      position: relative;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      padding-bottom: 5px;
    }
    span {
      padding-left: 10%;
      white-space: nowrap;
    }
    .Dropdown__checkmark {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 5%;
    }
  }
}
</style>
