<template>
  <div class="data-table">
    <div class="table-wrap" v-if="headings.length" :class="$route.name">
      <div class="table">
        <!-- table header -->
        <div
          class="head-cell"
          v-for="(heading, index) of headings"
          :key="'a' + index"
        >
          <b>{{
            index === 0 || index === headings[headings.length - 1]
              ? ' '
              : heading
          }}</b>
        </div>
      </div>
      <div
        class="table"
        v-for="(obj, index) of data"
        @click="$emit('row-clicked', index)"
        :key="'A' + index"
      >
        <div class="body-cell">
          <div class="icon-container">
            <svg
              class="add-icon icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10.45 10.45"
              fill="var(--dark-blue)"
            >
              <title>add</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    d="M10.17,5a.27.27,0,0,1,.28.28.24.24,0,0,1-.08.19.28.28,0,0,1-.2.08H5.5v4.67a.3.3,0,0,1-.08.2.27.27,0,0,1-.19.08A.27.27,0,0,1,5,10.17V5.5H.27A.27.27,0,0,1,0,5.23.28.28,0,0,1,.08,5,.24.24,0,0,1,.27,5H5V.27A.27.27,0,0,1,5,.08.3.3,0,0,1,5.23,0,.27.27,0,0,1,5.5.27V5Z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="body-cell bold">{{ obj['Vehicle Label'] }}</div>
        <div class="body-cell">{{ obj['Model'] }}</div>
        <div class="body-cell">{{ obj['VIN'] }}</div>
        <div class="body-cell">{{ obj['Fleet'] }}</div>
        <div class="body-cell">
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="var(--dark-grey)"
              class="move-icon icon"
            >
              <path
                d="M20.57 14.915c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46zm-8.57 0c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46zm-8.57 0c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46zm0-8.75c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46zm8.57 0c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46zm8.57 0c.79 0 1.43.654 1.43 1.46 0 .806-.64 1.46-1.43 1.46-.79 0-1.43-.654-1.43-1.46 0-.806.64-1.46 1.43-1.46z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array[Object],
    templateColumns: String,
    noClick: Boolean,
  },
  computed: {
    headings() {
      let arr = [];
      if (this.data) {
        arr = Object.keys(this.data[0]).filter((heading) => {
          return (
            heading === 'Vehicle Label' ||
            heading === 'Model' ||
            heading === 'VIN' ||
            heading === 'Fleet'
          );
        });
        arr.unshift('');
        arr.push('');
        return arr;
      }

      return arr; // this sets the initial state in data
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-top: 10px;
  padding-bottom: 40px;
}

.table-wrap {
  width: 80%;
  border: 1px solid var(--light-grey-2);
  overflow: scroll;
  z-index: 1;
  margin: 0 auto;

  // hide scroll bars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  //chrome
  &::-webkit-scrollbar {
    display: none;
  }

  &.reports-screen {
    width: 100%;
  }
}

.table {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 30px repeat(4, 1fr) 30px;
}

.table .head-cell,
.table .body-cell {
  &:first-child {
    padding-left: 5%;
    min-width: 24px;
  }
  &:last-child {
    min-width: 24px;
  }
}
.table .head-cell {
  height: 39px;
  border-bottom: 1px solid var(--light-grey-2);
  background-color: #eaf6f9;
  display: flex;
  display: flex;
  align-items: center;
  color: var(--dark-grey);
  font-size: 13px;
}

b {
  font-weight: 600;
}

.table .body-cell {
  height: 39px;
  font-size: 12px;
  border-bottom: 1px solid var(--light-grey-2);
  display: flex;
  align-items: center;
  word-break: break-all;
  cursor: pointer;
  @media screen and (max-width: 1112px) {
    height: 50px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  .icon {
    height: 15px;
    width: 15px;
  }
  .move-icon {
    transform: rotate(90deg);
  }
}
</style>
