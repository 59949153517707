<template>
  <Modal x class="modal" v-show="$store.state.showModal" :style="{ '--num-cols': maxColumns }">
    <span slot="header">{{ header }}</span>
    <div slot="body">
      <form @submit.prevent="$store.commit('toggleModal')" class="fields-grid">
        <div v-for="(field, i) of fields" class="field" :class="{ wide: field.wide }" :key="i">
          <!-- Label -->
          <label :for="field.label">
            {{ $te(`message.${camelize(field.label)}`) ? $t(`message.${camelize(field.label)}`) : field.label }}
            <span v-if="field.required" class="required">*</span>
          </label>
          <!-- Begin Form Input Area -->
          <!-- Left Aligned Icons -->
          <svg v-if="field.type === 'email'" id="gml:icon1/field-email" class="email icon">
            <path
              d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
            ></path>
          </svg>
          <svg v-else-if="field.type === 'tel'" id="gml:icon1/field-phone" class="phone icon">
            <path
              d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
            ></path>
          </svg>
          <!-- Actual Input Field -->
          <slot v-if="field.slot" :name="field.slot" :obj="field" />
          <ModalDropdown v-else-if="field.type === 'language-dropdown'" language class="modal-dropdown" />
          <textarea v-else-if="field.type === 'bigtext'" />
          <input
            v-else
            :id="field.label"
            :type="field.type"
            :placeholder="field.placeholder"
            :value="getDataValue(field)"
            :required="field.required"
            :disabled="field.disabled"
            :class="{ 
              'has-left-icon': field.type === 'email' || field.type === 'tel',
              'has-right-icon': field.type === 'search',
            }"
          />
          <!-- Right Aligned Icons -->
          <svg
            v-if="field.type === 'search'"
            id="gml:icon1/field-search"
            class="search icon"
            viewBox="0 0 24 24"
          >
            <path
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            ></path>
          </svg>
        </div>
      </form>
      <slot />
      <div class="buttons">
        <button
          v-show="button1"
          type="cancel"
          class="button1"
          @click="$store.commit('toggleModal')"
          >
          {{ $t(`message.${camelize(button1)}`) }}
        </button>
        <button
          v-show="button2"
          type="submit"
          class="button2"
          @click="$store.commit('toggleModal')"
        >
          {{ $t(`message.${camelize(button2)}`) }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/demo/Modal.vue';
import ModalDropdown from '@/components/demo/ModalDropdown.vue';

import { camelize } from '../../utils/camelize';

export default {
  components: {
    Modal,
    ModalDropdown,
  },
  props: {
    header: String,
    fields: Array[Object],
    data: Object,
    button1: String,
    button2: String,
    maxColumns: Number,
  },
  data() {
    return {
      camelize,
    };
  },
  methods: {
    getDataValue(field) {
      // Figure out what the data value is
      let returnValue = '';
      if (this.data?.[field?.dataKey]) returnValue = this.data[field.dataKey];
      else if (this.data?.[field?.label]) returnValue = this.data[field.label];
      // Return translated value if it exists
      if (this.$te(`message.${this.camelize(returnValue)}`)) return this.$t(`message.${this.camelize(returnValue)}`);
      return returnValue;
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  --num-cols: 2;
  --col-size: 200px;
  ::v-deep .modal-container {
    width: 80%;
    // max-width = size of all columns combined + gap between each column + padding around form
    max-width: calc(var(--num-cols) * var(--col-size) + 30px * (var(--num-cols) - 1) + 60px);
    // @media screen and (max-width: 1200px) {
    //   width: 50%;
    //   min-width: 50%;
    // }
    // @media screen and (max-width: 800px) {
    //   width: 90%;
    //   min-width: 90%;
    // }
  }
}
.fields-grid {
  padding: 30px;
  display: grid;
  grid-template-rows: repeat(auto-fill, 50px);
  grid-template-columns: repeat(auto-fill, minmax(var(--col-size), 1fr));
  gap: 30px;
  // @media screen and (max-width: 800px) {
  //   grid-template-columns: 1fr;
  // }
}
.field {
  position: relative;
  &.wide {
    grid-column: 1 / -1;
  }
  .icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 24px;
    fill: var(--grey);
    padding: 3px;
    &.search {
      left: unset;
      right: 0;
      border-left: 1px solid var(--pale-grey);
      padding-left: 1px;
    }
  }
  input, textarea {
    color: #76777a;
    font-family: inherit;
    border: 1px solid rgba(0, 28, 56, 0.24);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    padding: 0 8px;
    &::-webkit-calendar-picker-indicator {
      filter: contrast(0.001);
      transform: scale(1.25);
    }

    &.has-left-icon {
      padding-left: 32px;
    }
    &.has-right-icon {
      padding-right: 32px;
    }
  }
  textarea {
    height: 90px;
  }
}
label {
  color: #76777a;
  margin-bottom: 1px;
  .required {
    color: var(--red);
  }
}
// form label {
//   display: unset;
// }
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.1px solid var(--grey);
  height: 50px;
  margin-top: 10px;
  padding: 0px 30px;

  button {
    font-weight: bold;
    background: transparent;
    border-radius: 0.25rem;
    padding: 5px 5%;
    width: 25%;
    &.button1 {
      color: var(--blue);
      border: 0.3px solid var(--pale-grey);
      margin-right: 3%;
    }
    &.button2 {
      border: 0;
      border: 0.3px solid var(--blue);
      background-color: var(--blue);
      color: #fff;
    }
    &.button2:disabled {
      border: 0.3px solid var(--pale-grey);
      color: var(--grey);
      background-color: var(--pale-grey);
    }
  }
}
.modal-dropdown {
  height: unset;
  ::v-deep .Dropdown__top {
    padding: 2px 0;
    border: 1px solid rgba(0, 28, 56, 0.24);
    border-radius: 4px;
  }
}
</style>