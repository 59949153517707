<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6 18.9534V20.5004L20.5 20.6004H3.50002L3.40002 20.5004V18.9534L3.50002 18.8534H20.5L20.6 18.9534ZM12.874 3.50039V13.7204L15.975 10.4164L16.037 10.3554C16.3387 10.0686 16.7423 9.91409 17.1583 9.92611C17.5744 9.93812 17.9684 10.1157 18.253 10.4194V10.5554L12.073 17.1684H11.927L5.74702 10.5554L5.75002 10.4164L5.81202 10.3554C6.44513 9.76333 7.43703 9.79188 8.03502 10.4194L11.126 13.7194V3.50039L11.226 3.40039H12.773L12.874 3.50039Z" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#0072C8'
    }
  }
}
</script>