<template>
  <div
    class="Filter-Modal-Car-Item"
    :key="'B' + index"
    :class="{ clickable }"
    @click="selectCar(vehicle, index)"
  >
    <img
      v-if="vehicle['Image']"
      :src="require('@/assets/' + vehicle['Image'] + '?external')"
    />
    <div>
      <b v-html="vehicleLabel"></b>
      <span>
        {{
          `${
            $store.state.inDealerMode ? vehicle.Make : vehicle['Model Year']
          } ${vehicle['Model Name']} `
        }}
      </span>
    </div>
    <div class="small">
      <span> {{ vehicle['VIN'] }} </span>
      <span>{{ vehicle['License Plate'] }} </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      clickable: this.$store.state.showMapFilterDropdown,
    };
  },
  computed: {
    searchQuery() {
      return this.$store.state.mapSearch.toLowerCase();
    },
    vehicleLabel() {
      const label = this.vehicle['Vehicle Label'];
      const start = label.toLowerCase().indexOf(this.searchQuery);
      if (start > -1) {
        const end = start + this.searchQuery.length;
        return [
          label.slice(0, start),
          '<mark class="mark">',
          label.slice(start, end),
          '</mark>',
          label.slice(end),
        ].join('');
      }
      return label;
    },
  },
  methods: {
    selectCar(vehicle, index) {
      // strip highlighted characters from vehicle info
      // let str1 = '';
      // let str2 = '';
      // let str3 = '';
      // const index1start = vehicle['Vehicle Label'].search(
      //   '<mark class="mark">'
      // );
      // const index1end = index1start + 19;
      // const index2start = vehicle['Vehicle Label'].search('</mark>');
      // const index2end = index2start + 7;
      // if (index1start !== -1 && index2start !== -1) {
      //   str1 = vehicle['Vehicle Label'].substring(0, index1start);
      //   str2 = vehicle['Vehicle Label'].substring(index1end, index2start);
      //   str3 = vehicle['Vehicle Label'].substring(
      //     index2end,
      //     vehicle['Vehicle Label'].length
      //   );
      //   vehicle['Vehicle Label'] = str1 + str2 + str3;
      // }
      // TODO: check if this is used elsewhere and connect those things when done
      // then, set vehicle accordingly, and show it
      this.$store.commit('setCurrentCar', vehicle);
      this.$store.commit('setCurrentCarIndex', index);
      this.$store.commit('closeMapFilterDropdown');
      this.$store.commit('turnOnCurrentCarVisibility');
      this.$store.commit('setShowView', vehicle.Lot);
    },
  },
};
</script>

<style>
/* car search results list dropdown styling */
.Filter-Modal-Car-Item {
  display: grid;
  grid-template: 38px 38px / 110px 206px;
  margin: 3px;
  grid-gap: 8px;
  border-bottom: 1px solid var(--light-grey-2);
  padding-bottom: 4px;
}
.clickable {
  cursor: pointer;
}
.Filter-Modal-Car-Item img {
  width: 100%;
  grid-row: 1 / 2;
  margin-top: 10px;
  justify-self: center;
}
.Filter-Modal-Car-Item div {
  grid-column: 2 / 2;
  display: flex;
  flex-direction: column;
}
.Filter-Modal-Car-Item b,
.Filter-Modal-Car-Item span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Filter-Modal-Car-Item div.small {
  font-size: 13px;
  color: var(--dark-grey);
}

.car-details {
  /* display: grid;
  grid-template-columns: auto auto; */

  /* Temporarily Switched */
  /* grid-template-rows: auto 95px 70px 70px; */
  /* grid-template-rows: auto;
  grid-row-gap: 30px; */
  width: 284px;
  height: calc(100% - 80px);
  /* max-height: 605px; */

  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-left: 24px;
  margin-top: 32px;
  padding: 16px 24px;
  animation: slideMapDetail 0.5s ease;
  touch-action: none;
}
</style>
