<template>
  <div class="hover-state" :style="{ top: top + 'px', left: left + 'px' }" ref="hover">
    <b>{{ vehicle['Vehicle Label'] }}</b>
    <span>{{ vehicle['Model Year'] }} {{ vehicle['Model Name'] }}</span>
    <span>{{ vehicle['VIN'] }}</span>
    <span v-if="driver" class="driver">
      <svg id="gfs:icon1/i-driver" viewBox="0 0 24 24">
        <path d="M18.948 14.154c-.78-1.562-2.286-2.529-3.918-2.515h-1.186c1.44-.762 2.357-2.352 2.362-4.097v-.964C16.206 4.05 14.325 2 12.004 2c-2.32 0-4.201 2.05-4.201 4.578v.964c.005 1.745.921 3.335 2.362 4.097H8.979c-1.59-.016-3.067.9-3.865 2.4L2 20.458l2.716 1.118c.026-.508.098-1.011.212-1.504l-1.053-.385 2.327-4.82.062-.115c.555-1.06 1.595-1.707 2.715-1.687h6.05c1.144-.022 2.205.65 2.752 1.745l2.353 4.819-1.053.443c.11.48.177.971.203 1.465L22 20.42l-3.052-6.265zM9.13 7.542v-.964c0-1.73 1.287-3.132 2.874-3.132 1.588 0 2.875 1.402 2.875 3.132v.964c0 1.73-1.287 3.133-2.875 3.133-1.587 0-2.874-1.403-2.874-3.133zM17.975 22h-1.327c0-2.795-2.079-5.06-4.644-5.06-2.564 0-4.644 2.265-4.644 5.06H6.034c0-3.593 2.673-6.506 5.97-6.506 3.298 0 5.971 2.913 5.971 6.506z"></path>
      </svg>
      {{ driver['Name'] }}
    </span>
    <span v-else />
    <div class="line" />
    <span class="car-last-seen">
      <p>{{ $t("message.lastSeenOnToday") }}, 09:04:14 AM</p>
      <p>{{ $t("message.shortAddress") }}</p>
    </span>
    <PRNDL current="D" />
  </div>
</template>

<script>
import PRNDL from '@/components/demo/PRNDL.vue';

export default {
  components: {
    PRNDL,
  },
  data() {
    return {
      interval: -1,
      top: 0,
      left: 0,
    };
  },
  props: {
    vehicle: Object,
    driver: Object,
    index: Number,
    mapWidth: Number,
  },
  computed: {
    car() {
      return this.$parent.$refs?.car?.[this.index];
    },
    // top() {
    //   if (!this.rect) return 0;
      
    //   if (this.rect.top > offsetTop) return this.rect.top;
    //   return this.rect.bottom;
    // },
    // left() {
    //   if (!this.rect) return 0;
    //   const offsetTop = 250;
    //   if (this.rect.top > offsetTop) return this.rect.top;
    //   return this.rect.bottom;
    // },
  },
  methods: {
    setPosition() {
      if (this.car) {
        const rect = this.car.getBoundingClientRect();
        // y-axis
        const offsetTop = 260;
        if (rect.top > offsetTop) {
          this.top = rect.top;
        } else {
          this.top = rect.bottom + (offsetTop - 30);
        }
        // x-axis
        const offsetLeft = 320;
        if (rect.left < offsetLeft / 2) {
          this.left = offsetLeft / 2;
        } else if (rect.left + offsetLeft > this.mapWidth) {
          this.left = rect.left - offsetLeft;
        } else {
          this.left = rect.left;
        }
      }
    }
  },
  mounted() {
    this.setPosition();
    this.interval = setInterval(() => {
      this.setPosition();
    }, 4 * 1000);
  }
  // :style="{ transform: `rotate(${360 - (vehicle.details.map[whichMap] ? vehicle.details.map[whichMap].rotation : vehicle.details.map.rotation) }deg)`}"
  
}
</script>

<style lang="scss" scoped>
.hover-state {
  position: fixed;
  width: 320px;
  height: 240px;
  background-color: #fff;
  // transform: translateX(-30%);
  margin-top: -230px;
  z-index: 1000;
  padding: 12px 8px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 21px 16px 16px 20px 8px 38px 24px;
  gap: 12px;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.33));
}
.line {
  width: 100%;
  height: 0.5px;
  background-color: var(--light-grey-2);
  align-self: end;
}
.car-last-seen {
  font-size: 12px;
  color: #76777a;
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
  }
}
.driver {
  color: var(--blue);
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: var(--blue);
    width: 15px;
  }
}
</style>