export function getMpgValue(mpg, locale) {
  // If subdomain does not contain a language, do not convert.
  // Otherwise, returns the L/100 value.
  if (locale === 'en') {
    if (Number.isInteger(mpg)) return mpg;
    return mpg.toFixed(1);
  }
  return (235.214583 / mpg).toFixed(1);
}
export function getGalValue(gal, locale) {
  // If subdomain does not contain a language, do not convert.
  // Otherwise, returns the Liters value.
  if (locale === 'en') {
    if (Number.isInteger(gal)) return gal;
    return gal.toFixed(1);
  }
  return (gal * 3.78541).toFixed(1);
}
export function getMilesValue(mi, locale) {
  // If subdomain does not contain a language, do not convert.
  // Otherwise, returns the KM value.
  if (locale === 'en') {
    if (Number.isInteger(mi)) return mi;
    return mi.toFixed(1);
  }
  return (mi * 1.609).toFixed(1);
}
export function getMphValue(mph, locale) {
  if (locale === 'en') {
    if (Number.isInteger(mph)) return mph;
    return mph.toFixed(1);
  }
  return (mph * 1.609).toFixed(1);
}