export default {
  state: {
    isCameraAdded: false,
  },
  getters: {
    isCameraAdded: (state) => state.isCameraAdded,
  },
  mutations: {
    SET_CAMERA: (state, isCameraAdded) => {
      state.isCameraAdded = isCameraAdded;
    },
  },
  actions: {
    setCamera({ commit }, payload) {
      commit('SET_CAMERA', payload);
    },
  }
};