<template>
  <div class="assign-driver-vehicle" ref="assigndrivervehicle">
    <div class="assign-head">
      <h2>{{ header }}</h2>
      <img src="@/assets/clear.svg?external" @click="$emit('hide')" />
    </div>
    <div class="assign-main">
      <div class="search-wrap">
        <input class="search" placeholder="Search" />
        <svg class="search-icon" viewBox="0 0 24 24">
          <path
            d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
          </path>
        </svg>
      </div>
      <template v-for="(vehicle, index) of vehicles">
        <div
          v-if="vehicle.details && vehicle.details.profileImage"
          @click="
            $emit('assign-vehicle', vehicle);
            selected();
          "
          class="assign-list-item"
          :key="'L' + index"
        >
          <img
            class="img"
            v-if="index < 5"
            :src="require('@/assets/' + vehicle.details.profileImage)"
          />
          <div class="flex column" style="gap: 8px">
            <b>{{ vehicle["Vehicle Label"] }}</b>
            <span>{{ vehicle["VIN"] }}</span>
          </div>
          <!-- <div class="flex" style="justify-content: space-between"> -->
          <div
            v-if="vehicle['Vehicle Label'] === '' && index < 5"
            class="flex center"
          >
            <div class="status-light green" />
            <span class="status">Available</span>
          </div>
          <div v-else class="flex center">
            <div class="status-light" />
            <span class="status">Assigned</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    header: String,
    vehicles: Array[Object],
    activeChallenge: String,
    numIncomplete: Number,
    timeLeft: Number,
    multiplier: Number,
  },
  data() {
    return {
      bingo: false,
    };
  },
  methods: {
    assignVehicle(vehicleObj, vehicleIndex) {
      Vue.set(this.drivers[vehicleIndex], "Vehicle", vehicleObj["Name"]);
      this.$emit("hide");
      this.$forceUpdate();
    },
    selected() {
      if (
        this.activeChallenge === "presto-changeo" &&
        this.$route.path === "/2/detail/4"
      ) {
        this.$emit("challengeComplete", 8);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      } else {
        this.$refs.assigndrivervehicle.scrollTop = 0;
        this.$emit("hide");
      }
    },
  },
  watch: {
    timeLeft: function() {
      if (this.timeLeft <= 0) {
        this.bingo = false;
        this.$emit("hide");
      }
    },
    numIncomplete: function() {
      if (this.numIncomplete === 0) {
        this.$emit("noMoreChallenges");
      }
    },
  },
};
</script>

<style scoped>
.assign-driver-vehicle {
  width: 440px;
  height: 500px;
  overflow-y: scroll;
  max-width: 100%;
  color: #606164;
}
.assign-head {
  position: sticky;
  top: 0;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}
.assign-head h2 {
  margin: 20px 24px;
  font-weight: 700;
  font-size: 21px;
}
.assign-head img {
  width: 11px;
  height: 11px;
  margin: 28px;
  /* margin-top: 24px; */
  cursor: pointer;
}
/* .assign-main {
  background: linear-gradient(to bottom, #eaf6f9, var(--white) 30px);
} */
.search-wrap {
  position: relative;
  margin-top: 8px;
  padding-bottom: 32px;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(0, 28, 56, 0.16);
}
.search {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 64px);
  height: 30px;
  padding-left: 8px;
  font-family: ProximaNova;
  font-size: 15px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 28, 56, 0.24);
}
.search-icon {
  position: absolute;
  top: 7.5px;
  right: 40px;
  fill: #9d9da1;
  width: 16px;
}
.assign-list-item {
  display: grid;
  grid-template-rows: auto 19px;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 28, 56, 0.16);
  cursor: pointer;
}
.assign-list-item:hover {
  background-color: rgba(105, 205, 255, 0.12);
}
.assign-list-item .img {
  width: 100%;
  justify-self: center;
}
.status-light {
  background-color: var(--orange);
  width: 8px;
  height: 8px;
  margin: 0 16px 0 8px;
  border-radius: 50%;
}
.status-light.green {
  background-color: var(--green);
}
.status {
  font-size: 13px;
  margin-right: 8px;
}
.action-btn {
  width: 100%;
  height: 28px;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: -4px;
  cursor: pointer;
}
.line {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 1px;
  background-color: var(--light-grey);
}
.long {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
