import { CapacitorSQLite } from '@capacitor-community/sqlite';

const database = "ovi";
const table = "interactions";

const migrations = [
  `CREATE TABLE IF NOT EXISTS ${table} (
    id INTEGER PRIMARY KEY NOT NULL,
    activationId TEXT,
    sessionIdentifier TEXT,
    action TEXT,
    value TEXT,
    timestamp INTEGER DEFAULT (unixepoch('now')),
    date TEXT DEFAULT (date('now'))
  ); PRAGMA user_version = 1;`,
  `ALTER TABLE ${table} 
    ADD COLUMN synced INTEGER DEFAULT 0; 
    PRAGMA user_version = 2;`,
];

export async function connectLocalDB() {
  // if (!CapacitorSQLite.isDBExists({ database }) || !CapacitorSQLite.isDBOpen({ database })) {
  // create a connection for NoEncryption
  await CapacitorSQLite.createConnection({ database });
  // open NoEncryption database
  await CapacitorSQLite.open({ database });
  return true;
  // }
  // return false;
}

async function getSchemaVersion() {
  const pragmaQuery = "PRAGMA user_version;";
  const pragmaRes = await CapacitorSQLite.query({ database, statement: pragmaQuery, values: [] });
  const version = pragmaRes.values[1].user_version;
  return version;
}

export async function createLocalTable() {
  // Create tables
  const version = await getSchemaVersion();
  const pendingMigrations = migrations.slice(version);
  const statements = pendingMigrations.join(" ");
  if (statements) {
    const res = await CapacitorSQLite.execute({ database, statements });
    if (res.changes.changes !== 0) {
      console.log("WARN: Some rows were modified during table creation");
      return false;
    }
  }
  return true;
}

export async function addLocalRecord(activationId, sessionIdentifier, action, value, synced = false) {
  const statement = `
    INSERT INTO ${table} 
      (activationId, sessionIdentifier, action, value, synced) 
      VALUES (?, ?, ?, ?, ?);
  `;
  const values = [activationId, sessionIdentifier, action, value, synced];
  const res = await CapacitorSQLite.run({ database, statement, values });
  if (res.changes.changes !== 1) {
    console.log("addRecord failed");
    return false;
  }
  return true;
}

export async function getAllLocalRecords() {
  const statement = `SELECT * FROM ${table};`;
  const res = await CapacitorSQLite.query({ database, statement, values: [] });
  return res;
}

export async function getUnsyncedLocalRecords() {
  const statement = `SELECT * FROM ${table} WHERE synced = 0;`;
  const res = await CapacitorSQLite.query({ database, statement, values: [] });
  return res;
}

export async function getDemoSessionTable() {
  const statement = `SELECT date, COUNT(*) AS count, AVG(value) AS avgTime
    FROM ${table} 
    WHERE action = "Reset" 
    GROUP BY date;`;
  const res = await CapacitorSQLite.query({ database, statement, values: [] });
  return res;
}

export async function getMenuItemClickTable() {
  const statement = `SELECT date, COUNT(*) AS count, action AS page
    FROM ${table} 
    WHERE action != "Reset" 
    GROUP BY date, action;`;
  const res = await CapacitorSQLite.query({ database, statement, values: [] });
  return res;
}

export async function updateSyncStatus(id, synced) {
  const statement = `
    UPDATE ${table} 
      SET synced = ? 
      WHERE id = ?;
  `;
  const values = [synced, id];
  const res = await CapacitorSQLite.run({ database, statement, values });
  if (res.changes.changes !== 1) {
    console.log("updateRecord failed");
    return false;
  }
  return true;
}

export async function deleteAllLocalRecords() {
  const statements = `DELETE FROM ${table};`;
  // Create tables
  const res = await CapacitorSQLite.execute({ database, statements });
  if (res.changes.changes !== 0 && res.changes.changes !== 1) {
    console.log("deleteAllRecords failed");
    return false;
  }
  return true;
}

export async function disconnectLocalDB() {
  // Close Connection NoEncryption
  // if (CapacitorSQLite.isDBExists({ database }) && CapacitorSQLite.isDBOpen({ database })) {
  await CapacitorSQLite.closeConnection({ database });
  return true;
  // }
  // return false;
}
