<template>
  <div class="vehicle-details">
    <h1 v-if="!isMobile">
      {{ `${obj.Make || ""} ${obj["Model Name"]} ${obj.Trim || ""}` }}
    </h1>
    <div v-else style="margin-top: 16px" />
    <div class="sections" id="vehicle-detail-sections">
      <div
        class="section-btn"
        :class="{ selected: page === 1 }"
        @click="page = 1"
      >
        {{ $t("message.overview") }}
      </div>
      <div
        class="section-btn"
        id="trip-history-section-btn"
        v-if="!$store.state.inDealerMode"
        :class="{ selected: page === 2, disabled: !canSwitchToTripHistory }"
        @click="switchToTripHistory"
      >
        {{ $t("message.tripHistory") }}
      </div>
      <div
        class="section-btn"
        :class="{ selected: page === 3 }"
        @click="page = 3"
      >
        {{ $t("message.timeline") }}
      </div>
    </div>
    <!-- <GuidedTourWaypoint :id="7" target="vehicle-detail-sections" :hidden="page !== 1" /> -->
    <GuidedTourWaypoint :id="8" target="trip-history-section-btn" :xOffset="20" :yOffset="24" :zIndex="100" :copyOverride="waypointCopy" />
    <!-- Need new waypoint just for essentials -->
    <GuidedTourWaypoint v-if="inEssentialsMode" :id="999" target="trip-history-section-btn" :xOffset="130" :yOffset="24" :zIndex="100" :copyOverride="'View details of past trips taken by connected vehicles during the OnStar Insights trial period. Current trip details are not available with Standard Content.'" />
    <div class="gradient-divider" :style="page === 2 ? 'z-index: 12;' : ''" />

    <ModalView v-if="remoteLearnMore" @hide="remoteLearnMore = false">
      <RemoteLearnMore @hide="remoteLearnMore = false" />
    </ModalView>
    <ModalView v-else-if="showRemoteModal" @hide="hideRemoteModal()">
      <RemoteCommands
        :shown="sidebarVisible"
        :activeChallenge="activeChallenge"
        :timeLeft="timeLeft"
        :numIncomplete="numIncomplete"
        @challengeComplete="challengeComplete($event)"
        @noMoreChallenges="noMoreChallenges()"
        @bingo="bingoEmit()"
        @:noMoreChallenges="noMoreChallenges()"
        @hide="showRemoteModal = false"
        @learn-more="remoteLearnMore = true"
      />
    </ModalView>
    <ModalView
      v-else-if="showDiagnosticModal"
      @hide="showDiagnosticModal = false"
    >
      <DiagnosticView :obj="obj" @hide="showDiagnosticModal = false" />
    </ModalView>
    <ModalView
      v-else-if="showAssignmentModal"
      @hide="
        showAssignmentModal = false;
        setDriver();
      "
    >
      <AssignDriverVehicle
        :shown="sidebarVisible"
        :activeChallenge="activeChallenge"
        :timeLeft="timeLeft"
        :numIncomplete="numIncomplete"
        :drivers="dataset.drivers"
        :currentVehicle="obj['Vehicle Label']"
        @hide="
          showAssignmentModal = false;
          setDriver();
        "
      />
    </ModalView>

    <!-- EDIT INFO FORM -->
    <ModalForm
      :header="`${$t('message.edit')} ${obj['Vehicle Label']}`"
      :fields="modalFields"
      :data="obj"
      button1="Cancel"
      button2="Submit"
      :maxColumns="2"
    />
    <!-- <Modal v-show="$store.state.showModal">
      <span slot="header"
        >{{ $t("message.edit") }} {{ obj["Vehicle Label"] }}</span
      >
      <div slot="body">
        <ModalForm
          :tags="$store.state.inDealerMode ? [] : obj['Tags'].split(' ')"
        />
      </div>
    </Modal> -->
    <!-- Overview page -->
    <div class="section" v-if="page === 1">
      <div class="details-top-grid">
        <div class="vehicle-img-and-tags">
          <img
            v-if="obj.details.profileImage"
            class="fleet"
            :src="require('@/assets/' + obj.details.profileImage)"
          />
          <TagsList v-if="obj.Tags" :tags="obj.Tags" />
          <b class="blue">{{ $t("message.editTags") }}</b>
        </div>
        <div class="info-grid" :class="{ dealer: $store.state.inDealerMode }">
          <span>{{ $t("message.model") }}</span>
          <b v-if="$store.state.inDealerMode"
            >{{ obj["Model Year"] }} {{ obj["Model Name"] }}
            {{ obj["Trim"] }}</b
          >
          <b v-else>{{ obj["Model Name"] }}</b>
          <span>{{ $t("message.vIN") }}</span>
          <b>{{ obj["VIN"] }}</b>
          <!-- <span>{{ $t("message.licensePlate") }}</span>
          <b>{{ obj["License Plate"] || obj["License"] }}</b> -->
          <span v-if="$store.state.inDealerMode">{{
            $t("message.stockNumber")
          }}</span>
          <b v-if="$store.state.inDealerMode">{{ obj["Stock"] }}</b>
          <span>{{ $t("message.status") }}</span>
          <b class="status">
            <div class="connected-dot" />
            {{ $t("message." + camelize(obj["Status"])) }}
          </b>
          <span v-if="!$store.state.inDealerMode">{{
            $t("message.assignedDriver")
          }}</span>
          <b
            v-if="!$store.state.inDealerMode"
            style="
              display: flex;
              justify-content: space-between;
              line-height: unset;
              position: relative;
            "
          >
            <span
              class="blue"
              style="cursor: pointer"
              @click="goToDriver(driver)"
            >
              <svg style="width: 18px; fill: #76777a; margin: 4px 0 -4px -22px;" id="gfs:icon1/i-driver" viewBox="0 0 24 24">
                <path d="M18.948 14.154c-.78-1.562-2.286-2.529-3.918-2.515h-1.186c1.44-.762 2.357-2.352 2.362-4.097v-.964C16.206 4.05 14.325 2 12.004 2c-2.32 0-4.201 2.05-4.201 4.578v.964c.005 1.745.921 3.335 2.362 4.097H8.979c-1.59-.016-3.067.9-3.865 2.4L2 20.458l2.716 1.118c.026-.508.098-1.011.212-1.504l-1.053-.385 2.327-4.82.062-.115c.555-1.06 1.595-1.707 2.715-1.687h6.05c1.144-.022 2.205.65 2.752 1.745l2.353 4.819-1.053.443c.11.48.177.971.203 1.465L22 20.42l-3.052-6.265zM9.13 7.542v-.964c0-1.73 1.287-3.132 2.874-3.132 1.588 0 2.875 1.402 2.875 3.132v.964c0 1.73-1.287 3.133-2.875 3.133-1.587 0-2.874-1.403-2.874-3.133zM17.975 22h-1.327c0-2.795-2.079-5.06-4.644-5.06-2.564 0-4.644 2.265-4.644 5.06H6.034c0-3.593 2.673-6.506 5.97-6.506 3.298 0 5.971 2.913 5.971 6.506z"></path>
              </svg>
              {{ driver !== "" ? $t("message." + camelize(driver)) : "N/A" }}
            </span>
            <svg
              @click="showDriverDropdown = !showDriverDropdown"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              style="cursor: pointer"
              viewBox="0 0 24 24"
            >
              <title>dots-horizontal</title>
              <path
                fill="#76777a"
                d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
              />
            </svg>
            <div class="dropdown" v-show="showDriverDropdown">
              <div @click="assignDriver">Change Driver</div>
              <div @click="releaseDriver">Unassign Driver</div>
            </div>
          </b>
          <span v-if="$store.state.inDealerMode">{{
            $t("message.condition")
          }}</span>
          <b v-if="$store.state.inDealerMode">{{ obj["Condition"] }}</b>
          <span v-if="$store.state.inDealerMode">{{
            $t("message.connectedOn")
          }}</span>
          <b v-if="$store.state.inDealerMode">{{ obj.details.connectedOn }}</b>
          <span v-if="$store.state.showCameraComingSoonStuff && !$store.state.inEssentialsMode">{{ $t("message.camera") }}</span>
          <div v-if="$store.state.showCameraComingSoonStuff && !$store.state.inEssentialsMode" style="display: flex; align-items: center;">
            <svg style="width: 18px; height: 18px; margin: 1px 4px 0 -22px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.03857 1.23431C1.75852 1.23431 1.50925 1.4441 1.50925 1.72717V6.68819C1.50925 6.97126 1.75852 7.18105 2.03857 7.18105H8.73649C9.01655 7.18105 9.26582 6.97126 9.26582 6.68819V1.72717C9.26582 1.4441 9.01655 1.23431 8.73649 1.23431H2.03857ZM10.1917 2.61136V1.72717C10.1917 1.01405 9.55233 0.416016 8.73649 0.416016H2.03857C1.22273 0.416016 0.583328 1.01405 0.583328 1.72717V6.68819C0.583328 7.40131 1.22273 7.99935 2.03857 7.99935H8.73649C9.55233 7.99935 10.1917 7.40131 10.1917 6.68819V5.804L13.4167 7.38251V1.03285L10.1917 2.61136ZM10.1917 3.54678V4.86858L12.4907 5.99388V2.42149L10.1917 3.54678Z"
                fill="#76777a"
              />
            </svg>
            <b style="display: flex; justify-content: space-between; line-height: unset; width: 100%; position: relative;">
              <span style="color: unset">
                Lytx 123456789101112
              </span>
              <svg
                @click="showCameraDropdown = !showCameraDropdown"
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                style="cursor: pointer"
                viewBox="0 0 24 24"
              >
                <title>dots-horizontal</title>
                <path
                  fill="#76777a"
                  d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
                />
              </svg>
              <div class="dropdown" v-show="showCameraDropdown">
                <div @click="showCameraDropdown = false; isChangeCameraModalOen = true">Change Camera</div>
                <div @click="showCameraDropdown = false">Unassign Camera</div>
              </div>
            </b>
          </div>
          <span>{{ $t("message.fleetName") }}</span>
          <div style="display: flex; align-items: center;">
            <svg style="width: 18px; fill: #76777a; margin: 1px 4px 0 -22px;" id="gfs:icon1/i-manage-fleet" viewBox="0 0 24 24">
              <path d="M21.4 8.042a1.042 1.042 0 0 1-.95-.609l-1.667-3.558h-8.591L8.525 7.433c-.17.372-.541.61-.95.609H5.158l-1.625 4.241c-.129.418-.497.718-.933.759H2v1.25h.6c.149-.003.297-.022.442-.059v6.934h1.25v-2.134c.322.167.679.255 1.041.259h8.334c.362-.004.72-.092 1.041-.259v2.134h1.25v-6.934c.145.037.293.056.442.059h2.267c.362-.004.72-.092 1.041-.259v2.134h1.25V9.242c.145.033.293.05.442.05h.6v-1.25h-.6zM9.683 7.958l1.309-2.833h7.016l1.309 2.833.05.084H9.633l.05-.084zM6.017 9.292h6.966l1.317 3.441c.042.107.092.21.15.309h-9.9c.058-.099.108-.202.15-.309l1.317-3.441zm7.65 8.75H5.333A1.042 1.042 0 0 1 4.292 17v-2.708h10.416V17c0 .575-.466 1.042-1.041 1.042zm5-5H16.4a1.083 1.083 0 0 1-.933-.759l-1.15-2.991h5.391V12c0 .575-.466 1.042-1.041 1.042zM17 10.542h1.667v1.25H17v-1.25zm-5 5h1.667v1.25H12v-1.25zm-6.667 0H7v1.25H5.333v-1.25z"></path>
            </svg>
            <b style="display: flex; justify-content: space-between; line-height: unset; width: 100%; position: relative;">
              <span style="color: unset">
                {{ $te("message." + camelize(obj["Fleet"]))
                  ? $t("message." + camelize(obj["Fleet"]))
                  : obj["Fleet"] }}
              </span>
              <svg
                @click="showFleetDropdown = !showFleetDropdown"
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                style="cursor: pointer"
                viewBox="0 0 24 24"
              >
                <title>dots-horizontal</title>
                <path
                  fill="#76777a"
                  d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
                />
              </svg>
              <div class="dropdown" v-show="showFleetDropdown">
                <div @click="showFleetDropdown = false">Change Fleet</div>
                <div @click="showFleetDropdown = false">Offboard From Account</div>
              </div>
            </b>
          </div>
          <span v-if="$store.state.inDealerMode">{{
            $t("message.deliveryDate")
          }}</span>
          <b v-if="$store.state.inDealerMode">{{ obj.details.deliveryDate }}</b>
          <span v-if="!$store.state.inDealerMode" style="display: flex; gap: 4px;">
            <svg id="gfs:icon1/ivc" style="width: 15px; fill: #76777a" viewBox="0 0 24 24">
              <path d="M19.45 8.43a9.67 9.67 0 01.24 13.7l-.99-1a8.27 8.27 0 00-.25-11.71l1-1zm-2.13 2.12a6.68 6.68 0 01.25 9.45l-.99-.99a5.29 5.29 0 00-.25-7.47l1-.99zM8.15 3c3.4 0 4.83 1.89 5.5 4.93l.04.16 1.71 3.53a.95.95 0 01-.75 1.36h-.61v2.3a1.7 1.7 0 01-1.56 1.7h-1.77v2.72H9.3v-4.12h3.03a.3.3 0 00.29-.23v-3.77h1.2l-1.44-2.95-.05-.17C11.8 5.85 10.77 4.4 8.15 4.4a4.7 4.7 0 00-4.91 4.8c0 1.46.26 2.42.96 3.89l.26.53.11.21c1 2.01 1.43 3.37 1.47 5.47l.01.4h-1.4a9.9 9.9 0 00-1.16-4.88l-.38-.79A9.65 9.65 0 011.84 9.2 6.1 6.1 0 018.15 3z"></path>
            </svg>
            {{ $t("message.inVehicleCoaching") }}
          </span>
          <b class="status" style="text-transform: capitalize;">
            <div class="connected-dot" />
            {{ $t("message.enrolled") }}
          </b>
          <span v-if="obj.details.ev" style="display: flex; gap: 4px;">
            <svg xmlns="http://www.w3.org/2000/svg" style="width: 12px; padding: 2px 2px 0 2px;" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66667 0C9.03486 0 9.33333 0.275517 9.33333 0.615385V13.0253L10.8236 13.0256C11.9281 13.0256 12.8236 12.1302 12.8236 11.0256L12.8227 5.33333H12C11.2636 5.33333 10.6667 4.73638 10.6667 4V2.66667C10.6667 2.17322 10.9347 1.74237 11.3332 1.51181L11.3333 0.666667C11.3333 0.298477 11.6318 0 12 0C12.3682 0 12.6667 0.298477 12.6667 0.666667V1.33333H14V0.666667C14 0.298477 14.2985 0 14.6667 0C15.0349 0 15.3333 0.298477 15.3333 0.666667L15.3348 1.51257C15.7325 1.74332 16 2.17376 16 2.66667V4C16 4.73638 15.403 5.33333 14.6667 5.33333H14.156L14.1569 11.0256C14.1569 12.8666 12.6645 14.359 10.8236 14.359L9.33333 14.3587V15.3846C9.33333 15.7245 9.03486 16 8.66667 16H0.666667C0.298477 16 0 15.7245 0 15.3846V0.615385C0 0.275517 0.298477 0 0.666667 0H8.66667ZM8 5.33333H1.33333V14.6667H8V5.33333ZM8 1.33333H1.33333V4H8V1.33333ZM14.6667 2.66667H12V4H14.6667V2.66667Z" fill="#76777a"/>
            </svg>
            Linked Providers
          </span>
          <b v-if="obj.details.ev" class="status" style="text-transform: capitalize;">
            <div class="connected-dot" style="background-color: #76777a;" />
            EVgo
          </b>
        </div>
        <div class="button-grid">
          <div v-if="!inEssentialsMode" @click="$store.commit('toggleModal')">
            {{ $t("message.editInfo") }}
          </div>
          <div v-if="!inEssentialsMode"
            @click="
              $router.push({
                name: $store.state.inDealerMode
                  ? 'map-screen--dealer'
                  : 'map-screen',
                params: { viewVehicle: obj, viewVehicleIndex: index },
              })
            "
          >
            {{ $t("message.viewOnMap") }}
          </div>
          <div @click="showRemoteModal = true">
            {{ $t("message.remoteCommands") }}
          </div>
          <!-- <div @click="releaseOrAssignDriver" v-if="!$store.state.inDealerMode">
            {{
              driver !== ""
                ? $t("message.releaseDriver")
                : $t("message.assignDriver")
            }}
          </div> -->
          <!-- <div v-if="!$store.state.inDealerMode">
            {{ $t("message.offboardVehicles") }}
          </div> -->
        </div>
      </div>
      <div class="border-line" />
      <div class="stats-grid">
        <div class="grid-item">
          <div class="flex column">
            <h4>{{ $t("message.overview") }}</h4>
            <!-- <h4 v-if="obj.details.ev">{{ $t("message.energy") }} & {{ $t("message.odometer") }}</h4>
            <h4 v-else>{{ $t("message.fuelAndMileage") }}</h4> -->
            <span class="label">{{ today }}</span>
          </div>
          <div class="flex gauges">
            <FuelGauge
              :value="obj.details.fuel"
              :miles="obj.details.range"
              :ev="obj.details.ev ? true : false"
              :mpg="obj.details.fuelEconomy"
            />
            <OdometerGauge
              :value="obj.details.odometer || obj['Odometer (mi)']"
              class="odo-gauge"
            />
          </div>
        </div>
        <div class="grid-item">
          <div class="flex" style="justify-content: flex-end">
            <div class="flex column hide-text-items">
              <h4>{{ $t("message.overview") }}</h4>
              <span class="label">{{
                lastUpdated.format("MMM d, hh:mm A")
              }}</span>
            </div>
            <div v-if="updating" class="circle-loading" />
            <span
              v-else-if="!$store.state.inDealerMode && !inEssentialsMode"
              @click="updateData"
              style="font-weight: 600; color: var(--blue); cursor: pointer"
              >{{ $t("message.realTimeUpdate") }}</span
            >
          </div>
          <div
            class="overview"
            :style="
              obj.details.oilLife || obj.details.fuelEconomy
                ? ''
                : 'justify-content: center;'
            "
          >
            <div class="oil-and-fuel" v-if="obj.details.ev">
              <div class="flex column">
                <div class="flex" style="align-items: center">
                  <svg
                    style="margin-left: -35px; margin-right: 10px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1CD8AA"
                      fill-rule="evenodd"
                      d="M12.75 17.216v3.034a.75.75 0 1 1-1.5 0v-3.034A8.25 8.25 0 0 1 3.75 9V7.5a.75.75 0 0 1 .75-.75h3v-3a.75.75 0 0 1 1.5 0v3h6v-3a.75.75 0 1 1 1.5 0v3h3a.75.75 0 0 1 .75.75V9a8.25 8.25 0 0 1-7.5 8.216zM5.25 8.25V9a6.75 6.75 0 1 0 13.5 0v-.75H5.25z"
                    />
                  </svg>
                  <b>{{ $t("message.charging") }}</b>
                  <svg
                    style="margin-left: 8px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#000"
                      fill-rule="evenodd"
                      d="M11.908 7.5c.486 0 .778.56.463.953L7.688 14.28c-.344.424-1.052.195-1.052-.368V8.5H4.092c-.486 0-.78-.562-.463-.953L8.31 1.721c.344-.427 1.055-.197 1.055.366V7.5h2.543zm-2.952 1a.59.59 0 0 1-.591-.587V3.25L4.95 7.5h2.094a.59.59 0 0 1 .592.587v4.663L11.05 8.5H8.956z"
                    />
                  </svg>
                </div>
                <span class="ev-charge-time"
                  >{{ $t("message.completeBy") }} {{ laterToday }}</span
                >
                <BarChart
                  class="bar-chart"
                  :value="85"
                  :width="160"
                  :height="5.5"
                  rounded
                />
              </div>
              <div class="flex column">
                <b class="mpg">34.8 mi/kWh</b>
                <span>{{ $t("message.efficiency") }}</span>
              </div>
            </div>
            <div class="oil-and-fuel" v-else>
              <div class="flex column" v-if="obj.details.oilLife">
                <div class="status-dot-grid">
                  <div class="dot" />
                  <span class="data">{{ obj.details.oilLife }}%</span>
                  <span class="label" style="white-space: nowrap">{{
                    $t("message.oilLife")
                  }}</span>
                </div>
                <div class="status-dot-grid" v-if="obj.details.engineFilter && !inEssentialsMode">
                  <div class="dot" />
                  <span class="data">{{ obj.details.engineFilter }}%</span>
                  <span class="label">Engine Air Filter</span>
                </div>
                <div class="double-status-dot-grid" v-if="obj.details.brakePad && !inEssentialsMode">
                  <div
                    class="status-dot-grid"
                    v-if="obj.details.brakePad.front"
                  >
                    <span class="label">Front</span>
                    <div class="dot" />
                    <span class="data">{{ obj.details.brakePad.front }}%</span>
                  </div>
                  <div class="status-dot-grid" v-if="obj.details.brakePad.rear">
                    <span class="label">Rear</span>
                    <div class="dot" />
                    <span class="data">{{ obj.details.brakePad.rear }}%</span>
                  </div>
                  <span class="label">Brake Pads</span>
                </div>
                <!-- <BarChart
                  class="bar-chart"
                  :value="obj.details.oilLife"
                  :width="160"
                  :height="5.5"
                  :color="oilColor(obj.details.oilLife)"
                  rounded
                  />
                  <span>{{ $t("message.oilLife") }}</span> -->
              </div>
              <!-- <div
                class="flex column"
                v-if="obj.details.fuelEconomy && !$store.state.inDealerMode"
                >
                <b class="mpg">{{
                  conversions.getMpgValue(
                    obj.details.fuelEconomy,
                    $i18n.locale
                    ) +
                    " " +
                    $t("message.mpg")
                  }}</b>
                  <span>{{ $t("message.fuelEconomy") }}</span>
                </div> -->
              </div>
              <CarTireDiagram
              v-if="obj.details.tirePressure && !inEssentialsMode"
              :frontLeftPSI="obj.details.tirePressure.frontLeft"
              :frontRightPSI="obj.details.tirePressure.frontRight"
              :rearLeftPSI="obj.details.tirePressure.rearLeft"
              :rearRightPSI="obj.details.tirePressure.rearRight"
              :recommendedFront="obj.details.tirePressure.recommendedFront"
              :recommendedRear="obj.details.tirePressure.recommendedRear"
              />
              <i class="disclaimer" v-if="!inEssentialsMode">
                Engine Air Filter and Brake Pad Monitoring available on select
                vehicles
              </i>
            </div>
        </div>
        <div class="grid-item" v-if="!obj.details.ev">
          <div class="flex" style="justify-content: space-between">
            <h4>{{ $t("message.diagnostics") }}</h4>
            <span v-if="!inEssentialsMode"
              @click="showDiagnosticModal = true"
              style="font-weight: 600; color: var(--blue); cursor: pointer"
              >{{ $t("message.moreInfo") }}</span
            >
          </div>
          <!-- use obj.details.diagnostics array -->
          <div
            class="flex column center"
            v-if="
              !obj.details.diagnostics.tire &&
              !obj.details.diagnostics.oil &&
              !obj.details.diagnostics.fuel &&
              !obj.details.diagnostics.battery
            "
            style="height: 180px; color: var(--dark-grey)"
          >
            <div class="check-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                viewBox="0 0 24 24"
                width="30"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </div>
            {{ $t("message.allGood") }}
          </div>
          <div
            class="flex center"
            v-else
            style="height: 180px; color: var(--dark-grey)"
          >
            <img
              v-if="obj.details && obj.details.diagnostics.oil"
              style="width: 48px; margin-right: 4px"
              src="@/assets/diag-icons/oil-life.svg?external"
            />
            <img
              v-if="obj.details && obj.details.diagnostics.tire && !inEssentialsMode"
              style="width: 48px; margin-right: 4px"
              src="@/assets/diag-icons/flat-tire.svg?external"
            />
            <img
              v-if="obj.details && obj.details.diagnostics.fuel"
              style="width: 48px; margin-right: 4px"
              src="@/assets/diag-icons/low-fuel.svg?external"
            />
            <img
              v-if="obj.details && obj.details.diagnostics.battery"
              style="width: 48px; margin-right: 4px"
              src="@/assets/diag-icons/battery.svg?external"
            />
          </div>
        </div>
        <!-- permissions (if needed) -->
        <div class="grid-item" v-if="!$store.state.inDealerMode && !inEssentialsMode">
          <h4>{{ $t("message.permissions") }}</h4>
          <div class="perms">
            <svg
              id="gml:icon/checkbox"
              @click="
                obj.details.allowDriverCheckIn =
                  !obj.details.allowDriverCheckIn;
                $forceUpdate();
              "
              viewBox="0 0 24 24"
            >
              <path
                v-if="obj.details.allowDriverCheckIn === false"
                fill="var(--grey)"
                d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
              />
              <path
                v-else
                fill="var(--blue)"
                d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
              />
            </svg>
            <span>{{ $t("message.allowDriversToCheckOutCheckIn") }}</span>
            <svg
              id="gml:icon/checkbox"
              @click="
                obj.details.allowDriverRemoteCommands =
                  !obj.details.allowDriverRemoteCommands;
                $forceUpdate();
              "
              viewBox="0 0 24 24"
            >
              <path
                v-if="obj.details.allowDriverRemoteCommands === false"
                fill="var(--grey)"
                d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
              />
              <path
                v-else
                fill="var(--blue)"
                d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
              />
            </svg>
            <span>{{ $t("message.allowDriversToPerformRemoteCommands") }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Trip History page -->
    <!-- use obj.details.tripHistory array -->
    <div
      class="section"
      ref="triphistsec"
      :style="
        isMobile
          ? 'height: calc(100vh - 178px);'
          : 'height: calc(100vh - 250px);'
      "
      v-else-if="page === 2"
    >
      <TripHistory
        fromVehicle
        :mainSidebarVisible="sidebarVisible"
        :isMobile="isMobile"
        :isTablet="isTablet"
        :trips="trips"
        :vehicleLabel="obj['Vehicle Label']"
        :summary="obj.details.tripHistorySummary[$t('tripHistoryKey')]"
      />
    </div>

    <!-- :triphistory="dataset.tripHistory" // for bulk id generation if needed -->

    <!-- Notifications page -->
    <!-- use obj.details.notifications array -->
    <div class="section" v-else-if="page === 3">
      <!-- <NotificationsListSearchable :notifications="notifications" /> -->
      <TimelinePanel
        :events="notifications"
        :activeDriver="driver"
        :vehicleLabel="obj['Vehicle Label']"
        vin="XXXXXXXXXXXXXXXXX"
        :odo="obj.details.odometer || obj['Odometer (mi)']"
        :oilLife="obj.details.oilLife"
        :oilLifeColor="oilColor(obj.details.oilLife)"
        @tripHistory="switchToTripHistory"
      />
    </div>
    <BaseModal class="modal-change-camera" :show="isChangeCameraModalOen" @closed="isChangeCameraModalOen = false">
      <template v-slot:header>
        <div class="modal-header">
          Assign camera to vehicle
          <button class="btn-close" @click="isChangeCameraModalOen = false"><IconClose /></button>
        </div>
      </template>
      <div class="modal-content">
        <div class="search">
          <div class="search-container">
            <input placeholder="Search" type="text" class="search-box">
          </div>
        </div>
        <div class="camera" v-for="index in 2" :key="index">
          <div class="img-status-container">
            <img src="@/assets/img/camera-angle1.png" alt="camera" class="camera-img">
            <div class="status-dot">
              <div class="dot"></div><span>Available</span>
            </div>
          </div>
          <div class="camera-name">Lytx 36252831</div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import FuelGauge from "@/components/demo/FuelGauge.vue";
import OdometerGauge from "@/components/demo/OdometerGauge.vue";
import CarTireDiagram from "@/components/demo/CarTireDiagram.vue";
import BarChart from "@/components/demo/BarChart.vue";
import ModalView from "@/components/demo/ModalView.vue";
// import Modal from "@/components/demo/Modal.vue";
// import ModalForm from "@/components/demo/ModalForm.vue";
import RemoteCommands from "@/components/demo/RemoteCommands.vue";
import RemoteLearnMore from "@/components/demo/RemoteLearnMore.vue";
import DiagnosticView from "@/components/demo/DiagnosticView.vue";
import TripHistory from "@/components/demo/TripHistory.vue";
// import NotificationsListSearchable from '@/components/demo/NotificationsListSearchable.vue';
import TimelinePanel from "@/components/demo/TimelinePanel.vue";
import AssignDriverVehicle from "@/components/demo/AssignDriverVehicle.vue";
import TagsList from "@/components/demo/TagsList.vue";
import Vue from "vue";
import moment from "moment";
import { camelize } from "../../utils/camelize.js";
import * as conversions from "../../utils/conversions.js";
import ModalForm from "../../components/demo/ModalForm.vue";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";
import BaseModal from '@/components/BaseModal.vue';
import IconClose from '@/assets/icons/close-icon.svg';

export default {
  props: {
    id: String,
    dataset: Object,
    isMobile: Boolean,
    isTablet: Boolean,
    sidebarVisible: Boolean,
    numIncomplete: Number,
    challenges: Array,
    multiplier: Number,
  },
  components: {
    AssignDriverVehicle,
    BarChart,
    CarTireDiagram,
    DiagnosticView,
    FuelGauge,
    // Modal,
    // ModalForm,
    ModalView,
    // NotificationsListSearchable,
    TimelinePanel,
    OdometerGauge,
    RemoteCommands,
    RemoteLearnMore,
    TripHistory,
    TagsList,
    ModalForm,
    GuidedTourWaypoint,
    BaseModal,
    IconClose,
},
  data() {
    return {
      page: 1,
      showRemoteModal: false,
      remoteLearnMore: false,
      showDiagnosticModal: false,
      showAssignmentModal: false,
      driver: "",
      lastUpdated: moment(),
      updating: false,
      activeChallenge: "",
      bingo: false,
      camelize: camelize,
      conversions: conversions,
      modalFields: [
        {
          label: "Vehicle Label",
          type: "text",
          required: true,
        },
        {
          label: "License Plate",
          type: "text",
        },
        {
          label: "Make",
          type: "text",
        },
        {
          label: "Model",
          type: "text",
          dataKey: "Model Name",
        },
        {
          label: "VIN",
          type: "text",
        },
        {
          label: "Year",
          type: "number",
          dataKey: "Model Year",
        },
      ],
      showDriverDropdown: false,
      showCameraDropdown: false,
      showFleetDropdown: false,
      isChangeCameraModalOen: false,
    };
  },
  computed: {
    index() {
      return parseInt(this.id);
    },
    obj() {
      console.log(this.id);
      if (this.$store.state.inDealerMode) {
        console.log(
          this.$store.state.dataset.dealerModeVehicles.find((v) => {
            return `${v.id}` === this.id;
          })
        );
        return this.$store.state.dataset.dealerModeVehicles.find((v) => {
          return `${v.id}` === this.id;
        });
      } else {
        return this.$store.state.dataset.vehicles.find((v) => v.id === this.id);
      }
    },
    trips() {
      let arr = [];
      const vehicle = this.obj["Vehicle Label"];
      for (let i = 0; i < this.dataset.tripHistory.length; i++) {
        const obj = this.dataset.tripHistory[i];
        if (!this.$store.state.inDealerMode) {
          if (obj.vehicle === vehicle) arr.push(obj);
        } else {
          if (obj.vehicleID === this.obj.id) arr.push(obj);
        }
      }
      return arr;
    },
    notifications() {
      let arr = [];
      const vehicle = this.obj["Vehicle Label"];
      for (let i = 0; i < this.dataset.notifications.length; i++) {
        const obj = this.dataset.notifications[i];
        if (obj.vehicle.includes(vehicle)) {
          arr.push(obj);
        }
      }
      console.log(arr)
      return arr;
    },
    today() {
      // Nov 26, 07:10 PM
      return moment().format("MMM d, hh:mm A");
    },
    laterToday() {
      return moment().add(3, "hours").format("h:00 A");
    },
    timeLeft() {
      return this.$parent.timeLeft;
    },
    canSwitchToTripHistory() {
      // Temporarily disable trip history for MX region
      // Set enable/disable state in translations file
      return this.$t("allowTripHistory") === "TRUE";
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
    waypointCopy() {
      if (this.inEssentialsMode) {
        return 'Past Trip Details are only available from vehicles that were connected during the OnStar Insights trial period. Current trip details are not available with Standard Content.';
      }
      return '';
    }
  },
  methods: {
    setDriver() {
      this.driver = "";
      for (const driver of this.dataset.drivers) {
        if (driver["Vehicle"] === this.obj["Vehicle Label"]) {
          this.driver = driver["Name"];
        }
      }
    },
    goToDriver(driverName) {
      const filteredDriverArr = this.dataset.drivers.filter(
        (driver) => driver["Name"] === driverName
      );
      // const index = this.dataset.drivers.indexOf(filteredDriverArr[0]);
      // if (index !== -1) {
      // this.$router.push("/2/detail/" + index);
      // }
      this.$router.push("/2/detail/" + filteredDriverArr[0].id);
    },
    releaseDriver() {
      const driverObjArr = this.dataset.drivers.filter((driver) => {
        return driver["Name"] === this.driver;
      });
      const index = this.dataset.drivers.indexOf(driverObjArr[0]);
      if (index !== -1) {
        Vue.set(this.dataset.drivers[index], "Vehicle", "");
        this.$forceUpdate();
        this.setDriver();
      }
      this.showDriverDropdown = false;
    },
    assignDriver() {
      this.showAssignmentModal = true;
      this.showDriverDropdown = false;
    },
    releaseOrAssignDriver() {
      const driverObjArr = this.dataset.drivers.filter((driver) => {
        return driver["Name"] === this.driver;
      });
      const index = this.dataset.drivers.indexOf(driverObjArr[0]);
      if (
        !this.dataset.drivers[index] ||
        this.dataset.drivers[index]["Vehicle"] === ""
      ) {
        this.showAssignmentModal = true;
      } else {
        Vue.set(this.dataset.drivers[index], "Vehicle", "");
        this.$forceUpdate();
        this.setDriver();
      }
    },
    updateData() {
      this.updating = true;
      setTimeout(() => {
        this.updating = false;
        this.lastUpdated = moment();
        if (this.obj["Vehicle Label"] === "Enclave 98") {
          this.obj.details.tirePressure.frontLeft = 35;
          this.obj.details.tirePressure.frontRight = 35;
          this.obj.details.tirePressure.rearLeft = 35;
          this.obj.details.tirePressure.rearRight = 35;
          this.obj.details.oilLife = 100;
          this.obj.details.diagnostics.oil = false;
          this.obj.details.diagnostics.tire = false;
        } else {
          const rand = Math.floor(Math.random() * 6 + 1); // rand from [1, 6]
          // roll 6
          if (rand === 6) {
            this.obj.details.tirePressure.frontLeft += 1;
            this.obj.details.tirePressure.frontRight += 1;
          }
          // roll 4
          if (rand === 4) {
            this.obj.details.tirePressure.frontLeft += 2;
            this.obj.details.tirePressure.frontRight += 3;
            this.obj.details.tirePressure.rearLeft += 1;
            this.obj.details.tirePressure.rearRight += 2;
          }
          // roll 1 or 2
          if (rand < 3) {
            this.obj.details.tirePressure.rearLeft -= 1;
            this.obj.details.tirePressure.frontRight -= 1;
          }
        }

        if (
          this.activeChallenge === "lets-get-real" &&
          this.$attrs.title === "Enclave 98"
        ) {
          this.bingo = true;

          this.$emit("pauseTimer");
          setTimeout(() => {
            this.$emit("challengeComplete", 3);
          }, 1000);
        }
        // show modals
      }, 1000);
    },
    oilColor(score) {
      if (score > 30) return "var(--green)";
      else if (score < 10) return "var(--red)";
      return "var(--orange)";
    },
    challengeComplete(chal) {
      this.$emit("challengeComplete", chal);
    },
    hideRemoteModal() {
      if (!this.bingo) {
        this.showRemoteModal = false;
      }
    },
    bingoEmit() {
      this.bingo = true;
      this.$emit("pauseTimer");
    },
    noMoreChallenges() {
      this.$emit("noMoreChallenges");
    },
    switchToTripHistory() {
      if (this.canSwitchToTripHistory) {
        if (
          !this.$store.state.inDealerMode ||
          (this.$store.state.inDealerMode &&
            (this.id === "16" || this.id === "17" || this.id === "21"))
        ) {
          this.page = 2;
        }
      }
    },
  },
  watch: {
    numIncomplete: function () {
      if (this.numIncomplete === 0) {
        this.$emit("noMoreChallenges");
      }
    },
  },
  mounted() {
    this.setDriver();
    this.$route.params.title = this.obj["Vehicle Label"];
    // Check if 'trip' variable is in the URL
    if (this.$route.query.trip) {
      this.page = 2;
    }
  },
  created() {
    if (this.$attrs.activeChallenge) {
      this.activeChallenge = this.$attrs.activeChallenge;
    }
  },
};
</script>

<style scoped lang="scss">
.vehicle-details,
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: calc(100vh - 57px); */
}
.label {
  color: var(--dark-grey);
  font-size: 13px;
}
img.fleet {
  width: 300px;
  margin-top: 24px;
}
img.notifications {
  max-width: 360px;
  max-height: 187px;
  margin-top: 24px;
}
.button-grid.vert {
  grid-template: 30px 30px 30px 30px / 165px;
}
.stats-grid {
  display: grid;
  /* grid-template: repeat(4, auto) / 100%; */
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  grid-gap: 1px;
  width: 90%;
  background-color: var(--white);
}
.stats-grid .grid-item {
  padding: 24px;
  border-top: 1px solid var(--pale-grey);
  // &:last-child {
  //   padding-right: 50%;
  // }
}
.gauges {
  margin-top: 32px;
  /* margin-left: 60px; */
  justify-content: center;
  align-items: flex-end;
}
.overview {
  display: grid;
  grid-template-columns: 340px 180px;
  justify-content: space-evenly;
  justify-items: center;
}
.odo-gauge {
  margin-left: 70px;
  margin-bottom: 36px;
}
@media screen and (max-width: 1112px) {
  .stats-grid {
    grid-template-columns: 100%;
  }
  .gauges {
    gap: 40px;
    align-items: center;
    @media screen and (max-width: 490px) {
      flex-direction: column;
    }
  }
  .overview {
    grid-template-columns: auto;
  }
  .odo-gauge {
    margin-left: 0;
  }
  .oil-and-fuel {
    margin-bottom: 24px;
    width: unset !important;
  }
  .oil-and-fuel .flex.column {
    // align-items: center;
    align-items: flex-start;
  }
}
.oil-and-fuel {
  // margin-top: 32px;
  /* margin-left: 60px; */
  width: 340px;
}
.disclaimer {
  color: var(--dark-grey);
  font-size: 13px;
  margin-top: 20px;
  grid-column: 1 / -1;
  justify-self: start;
}
.blue {
  color: var(--blue);
}
.oil-and-fuel div span {
  color: var(--dark-grey);
  font-size: 13px;
}
.oil-and-fuel div .mpg {
  text-transform: none;
  margin-top: 24px;
}
.bar-chart {
  margin: 4px 0 6px 0;
}
h4 {
  margin: 0;
}

.circle-loading {
  border: 2px solid var(--white);
  border-radius: 50%;
  border-top: 2px solid var(--blue);
  width: 14px;
  height: 14px;
  animation: spin 1s cubic-bezier(0.75, -0.01, 0.44, 0.7) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.oil-and-fuel div span.ev-charge-time {
  color: var(--dark);
  margin-bottom: 5px;
}

.check-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid var(--light-grey);
  border-radius: 50%;
  margin-bottom: 2px;
}
.check-circle svg {
  fill: var(--green);
}
.perms {
  height: 180px;
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  align-content: center;
  gap: 8px;
}
.perms svg {
  width: 100%;
}
.ivc-icon {
  position: relative;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  border-radius: 50%;
  border: 1px solid var(--light-grey-2);
}
.ivc-icon svg {
  width: 20px;
  height: 20px;
  fill: var(--dark);
}
.ivc-status-text {
  font-size: 12px;
  color: var(--dark);
}
.ivc-icon .ivc-green-indicator {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 50%;
}
.hide-text-items {
  h4,
  span {
    opacity: 0;
    user-select: none;
  }
  @media screen and (max-width: 1112px) {
    display: none;
  }
}
.vehicle-img-and-tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.info-grid.dealer {
  grid-template-rows: repeat(8, 20px) 40px;
}
b.status {
  display: flex;
  align-items: center;
  gap: 6px;

  .connected-dot {
    margin-left: -16px;
    margin-right: 2px;
    margin-top: 1px;
    width: 8px;
    height: 8px;
    background-color: var(--green);
    border-radius: 50%;
  }
}

.dropdown {
  position: absolute;
  top: 16px;
  right: 0;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(26 28 30 / 20%), 0 2px 2px 0 rgba(26 28 30 / 14%),
    0 3px 1px -2px rgba(26 28 30 / 12%);
  z-index: 10;
  div {
    padding: 6px 16px;
    cursor: pointer;
    font-weight: 400;
    &:hover {
      background-color: rgba(105, 205, 255, 0.12);
    }
  }
}
.status-dot-grid {
  display: grid;
  grid-template-columns: 10px auto;
  align-items: center;
  gap: 0 6px;
  margin-bottom: 24px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--green);
    margin-right: 8px;
    display: inline-block;
  }
  .data {
    font-size: 15px;
    color: #606164;
  }
  .label {
    font-size: 12px;
    grid-column: 1 / 3;
  }
}
.double-status-dot-grid {
  display: grid;
  grid-template-columns: 60px 60px;
  gap: 0 10px;
  .status-dot-grid {
    margin-bottom: 0;
  }
  .label {
    grid-column: 1 / 3;
  }
}

.modal-change-camera {
  ::v-deep .content {
    width: 100%;
    max-width: 375px;
    min-height: 450px;
    border-radius: 4px;
  }
  .modal-header {
    padding: 24px;
    font-size: 21px;
    line-height: 1.14;
    font-weight: bold;
  }
  .modal-content {
    padding: 0;
  }
  .btn-close{
    margin-left: auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    svg {
      width: 14px;
    }
  }
  .search-container {
    width: 100%;
    padding: 10px 24px 22px 24px;
    border-bottom: 1px solid var(--light-grey);
    .search-box {
      width: 100%;
      max-width: none;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3IiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuOTkyMTkgNi45OTk5M0MxLjk5MjE5IDkuNTk4MyA0LjA5ODU4IDExLjcwNDcgNi42OTY5NSAxMS43MDQ3QzcuODI0NjMgMTEuNzA0NyA4Ljg1OTY0IDExLjMwNzkgOS42NzAwMiAxMC42NDY0TDEzLjcyODYgMTQuNzA1QzE0LjEwMDUgMTQuMzMzMSAxNC4xMDA1IDEzLjczMDEgMTMuNzI4NiAxMy4zNTgxTDEwLjM0MzUgOS45NzNDMTEuMDA1IDkuMTYyNjIgMTEuNDAxNyA4LjEyNzYgMTEuNDAxNyA2Ljk5OTkzQzExLjQwMTcgNC40MDE1NiA5LjI5NTMyIDIuMjk1MTcgNi42OTY5NSAyLjI5NTE3QzQuMDk4NTggMi4yOTUxNyAxLjk5MjE5IDQuNDAxNTYgMS45OTIxOSA2Ljk5OTkzWk02LjY5Njk1IDEwLjc1MjNDOC43NjkzMyAxMC43NTIzIDEwLjQ0OTMgOS4wNzIzMSAxMC40NDkzIDYuOTk5OTNDMTAuNDQ5MyA0LjkyNzU1IDguNzY5MzMgMy4yNDc1NSA2LjY5Njk1IDMuMjQ3NTVDNC42MjQ1NyAzLjI0NzU1IDIuOTQ0NTcgNC45Mjc1NSAyLjk0NDU3IDYuOTk5OTNDMi45NDQ1NyA5LjA3MjMxIDQuNjI0NTcgMTAuNzUyMyA2LjY5Njk1IDEwLjc1MjNaIiBmaWxsPSIjNzE3OThBIi8+Cjwvc3ZnPg==");
      background-position: right 6px center;
      background-repeat: no-repeat;
      margin-bottom: 0;
    }
  }
  .camera {
    display: flex;
    align-items: center;
    padding: 20px 24px;
    gap: 12px;
    border-bottom: 1px solid var(--light-grey);
    .img-status-container {
      width: 105px;
      img {
        display: block;
        width: 100%;
        max-width: 65px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
      .status-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--green);
          margin-right: 8px;
        }
        span {
          font-size: 17px;
          color: var(--dark-grey);
        }
      }
      
    }
    .camera-name {
      color: var(--dark);
      font-weight: 700;
      font-size: 17px;
    }
  }
}
</style>
