<template>
  <div class="data-table-component">
    <DataTableUpperButtons
      :numResults="data.length"
      :headings="columnsToShow"
      :tags="tags"
      :iconsToShow="upperIconsToShow"
    />
    <div v-if="columnsToShow.length" class="table">
      <!-- Header of table -->
      <div
        class="row head-cells"
        :style="{ gridTemplateColumns: '40px ' + gridTemplateColumns }"
      >
        <div class="head-cell">
          <CheckBox disabled />
        </div>
        <div
          v-for="(heading, index) of columnsToShow"
          v-show="!heading.manuallyHidden"
          class="head-cell anchor"
          :class="{ sortable: heading.dataKey }"
          @click="sortByColumn(heading)"
          :key="'a' + index"
        >
          <b>{{ $t(heading.translationKey) }}</b>

          <!-- <FilterPopup
            :last="index === columnsToShow.length - 1"
            v-show="heading.selected"
          /> -->

          <!-- Sort arrow (appears when sort is active) -->
          <svg
            id="gml:icon1/dataview-sortasc"
            viewBox="0 0 24 21"
            class="sort-arrow"
            v-show="sortColumn === heading.dataKey"
            :style="reverseSort ? 'transform: rotate(180deg);' : ''"
          >
            <path d="M15 18H9v-7H4.16L12 3.16 19.84 11H15v7Z"></path>
          </svg>
        </div>
      </div>
      <!-- Body of table -->
      <div
        v-for="(row, i) of sortedData"
        @click="$emit('row-clicked', row)"
        class="row"
        :class="{ clickable: row.details && row.details.clickable }"
        :style="{ gridTemplateColumns: '40px ' + gridTemplateColumns }"
        :key="i"
      >
        <div class="body-cell">
          <CheckBox disabled />
        </div>
        <div 
          v-for="(meta, index) of columnsToShow"
          v-show="!meta.manuallyHidden"
          class="body-cell anchor"
          :key="'b' + index"
        >
          <slot v-if="meta.slot" :name="meta.slot" :obj="row" />
          <span v-else-if="meta.dataKey && objGetDeep(row, meta.dataKey)" class="data">
            {{ getTranslationIfExists(objGetDeep(row, meta.dataKey)) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";

// import FilterPopup from "./FilterPopup.vue";
// import Modal from "@/components/demo/Modal.vue";
// import ModalForm from "@/components/demo/ModalForm.vue";
// import TagsList from "@/components/demo/TagsList.vue";
// import { camelize } from "../../utils/camelize.js";
import CheckBox from "./CheckBox.vue";
import DataTableUpperButtons from "./DataTableUpperButtons.vue";
import { camelize } from "../../utils/camelize";

export default {
  components: { DataTableUpperButtons, CheckBox },
  props: {
    data: Array[Object],
    columnsToShow: Array[Object],
    tags: Array[Object],
    upperIconsToShow: Array[String],
  },
  data() {
    return {
      camelize,
      sortColumn: '',
      reverseSort: false,
    };
  },
  computed: {
    gridTemplateColumns() {
      let str = '';
      for (let i = 0; i < this.columnsToShow.length; i += 1) {
        const col = this.columnsToShow[i];
        if (!col.manuallyHidden) str += `${col.width}px `;
      }
      return str.slice(0, -1); // remove trailing space
    },
    sortedData() {
      // Handle sort order
      let compareFn;
      if (this.reverseSort) {
        // Descending
        compareFn = (a, b) => this.objGetDeep(b, this.sortColumn).toString()
                .localeCompare(this.objGetDeep(a, this.sortColumn).toString());
      } else {
        // Ascending
        compareFn = (a, b) => this.objGetDeep(a, this.sortColumn).toString()
                .localeCompare(this.objGetDeep(b, this.sortColumn).toString());
      }
      // Actually sort the array
      if (this.sortColumn) {
        return [...this.data].sort(compareFn);
      }
      return this.data;
    },
  },
  methods: {
    getTranslationIfExists(data) {
      if (typeof data === 'string') {
        const translationKey = `message.${camelize(data)}`;
        if (this.$te(translationKey)) return this.$t(translationKey);
      }
      return data;
    },
    sortByColumn(column) {
      // Don't do anything if there's no valid data key to sort by
      if (!column.dataKey) return;
      // First click: Sort in ascending order by column
      if (this.sortColumn !== column.dataKey) {
        this.sortColumn = column.dataKey;
        this.reverseSort = false;
        return;
      }
      // Second click: Sort in descending order by column
      if (!this.reverseSort) {
        this.reverseSort = true;
        return;
      }
      // Third click: Disable sort
      this.sortColumn = '';
      this.reverseSort = false;
    },
    /**
     * Get value from an object by key (string or string variable) using deep lookup.
     * Similar to obj[key], but it can also find a key in a nested object.
     * @param {Object} obj The object (or nested object) to search in
     * @param {String} key The key to search for
     */
    objGetDeep(obj, key) {
      return key.split('.').reduce((value,el) => value[el], obj);
    }
  },
};
</script>

<style scoped lang="scss">
.data-table-component {
  max-width: 80%;
  height: calc(100vh - 250px);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.table {
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
  border: 1px solid var(--light-grey-2);
}

.row {
  display: grid;
  grid-template-columns: 40px repeat(auto-fit, minmax(125px, 1fr));
  align-items: center;
  z-index: 1;
  &.clickable {
    cursor: pointer;
  }
}

.row .head-cell {
  height: 39px;
  border-bottom: 1px solid var(--light-grey-2);
  background-color: #eaf6f9;

  display: flex;
  align-items: center;
  color: var(--dark-grey);
  font-size: 13px;
  padding-left: 12px;
  &.sortable {
    cursor: pointer;
    &:hover {
      color: #05b5e4;
      svg path {
        fill: #05b5e4;
      }
    }
  }
}

.heading-hover-active > .head-cell:hover {
  background: #0071c71a;
}
.row .head-cell:first-child,
.row .body-cell:first-child {
  justify-content: center;
  padding: 0;
}

b {
  font-weight: 600;
  line-height: 14px;
}

.row .body-cell {
  font-size: 12px;
  height: 70px;
  border-bottom: 1px solid var(--light-grey-2);
  display: flex;
  align-items: center;
  word-break: break-word;
}
.row .check,
.row .body-cell:first-child {
  background-color: var(--white);
}
.row .car-img {
  background-color: var(--white);
}
.row .circle-img {
  background-color: var(--white);
  padding: 0 13px;
}
.row .body-cell img {
  width: 6rem;
  height: 2rem;
}
.row .body-cell .data {
  padding-left: 12px;
}
.row .body-cell .check {
  margin: 0 auto;
}
svg.check,
.head-check {
  width: 20px;
  height: 20px;
  line-height: 10px;
  cursor: auto;
}
.circle {
  background-color: #ee544f;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  line-height: unset;
  font-weight: 700;
}
.purple {
  background-color: #6f166d;
}

.anchor {
  position: relative;
}

.head-cells {
  position: sticky;
  top: 0;
}

.data.status {
  display: flex;
  align-items: center;
  gap: 6px;
  .connected-dot {
    margin-top: 1px;
    width: 8px;
    height: 8px;
    background-color: var(--green);
    border-radius: 50%;
  }
}

.sort-arrow {
  width: 12px;
  margin-left: 2px;
  path {
    fill: #76777a;
  }
}
</style>
